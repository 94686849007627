<template>
    <div class="memorandum">
        <div v-if="!username" class="username-container">
            <input
                    v-model="inputUsername"
                    placeholder="输入名字"
            />
            <button @click="setUsername" class="confirm-btn">
                <i class="fas fa-check"></i>
            </button>
        </div>
        <h1>备忘录</h1>
        <div v-if="username" class="welcome-message">
            欢迎你, {{ username }}
        </div>
        <div class="input-container">
            <input
                    v-model="newMemo"
                    @keyup.enter="addMemo"
                    placeholder="输入新的备忘录..."
            />
            <button @click="addMemo" class="add-btn">
                <i class="fas fa-plus"></i>
            </button>
        </div>
        <transition-group name="list" tag="ul">
            <li v-for="(memo, index) in memos" :key="index" class="memo-item">
                <span>{{ memo.info }}</span>
                <button @click="removeMemo(index)" class="delete-btn">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </li>
        </transition-group>
    </div>
</template>

<script>
import {addMemorandum, getMemorandum, updateMemorandum} from "@/api/yunoApi";

export default {
    name: "Memorandum",
    data() {
        return {
            newMemo: '',
            memos: [],
            username: '',
            inputUsername: ''
        };
    },
    methods: {
        getMemo() {
            const memorandum = {isDelete: 0}
            if (this.username.trim() !== '') {
                memorandum.username = this.username
            }
            getMemorandum(memorandum).then(res => {
                this.memos = res
            })
        },
        addMemo() {
            if (this.newMemo.trim() !== '') {
                if (this.username.trim() !== '') {
                    const memorandum = {isDelete: 0, username: this.username, info: this.newMemo}
                    addMemorandum(memorandum).then(res => {
                        this.getMemo()
                    })
                    this.newMemo = '';
                } else {
                    alert("输入用户名完成添加")
                }
            } else {
                alert("先输入内容")
            }


        },
        removeMemo(index) {
            const memorandum = {id: this.memos[index].id, isDelete: 1}
            updateMemorandum(memorandum).then(res => {
                this.getMemo()
            })
        },
        setUsername() {
            if (this.inputUsername.trim() !== '') {
                this.username = this.inputUsername;
                this.inputUsername = '';
                this.getMemo()
            }
        }
    }, mounted() {
        this.getMemo()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.memorandum {
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif;
}

.memorandum h1 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
    font-size: 3em;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.input-container {
    display: flex;
    margin-bottom: 30px;
}

.memorandum input {
    flex-grow: 1;
    padding: 15px 20px;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    transition: all 0.3s ease;
}

.memorandum input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.memorandum input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.add-btn {
    width: 50px;
    height: 50px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.3s ease;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-btn:hover {
    background-color: #45a049;
    transform: rotate(90deg);
}

.memorandum ul {
    list-style: none;
    padding: 0;
}

.memo-item {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    color: #ffffff;
}

.memo-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
}

.delete-btn {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: #ff4757;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-btn:hover {
    background-color: #ff6b6b;
    transform: rotate(360deg);
}

.list-enter-active, .list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.memo-item {
    animation: float 5s ease-in-out infinite;
}

.username-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 40%;
    min-width: 200px;
}

.username-container input {
    width: 30%; /* 减小宽度至添加输入框的一半左右 */
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    background-color: #ffffff;
    color: #4a4a4a;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.username-container input::placeholder {
    color: #9e9e9e;
}

.username-container input:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.confirm-btn {
    width: 35px;
    height: 35px;
    background-color: #FFD700;
    color: #4a4a4a;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.confirm-btn:hover {
    background-color: #FFC400;
    transform: rotate(360deg);
}

.welcome-message {
    text-align: right;
    color: #FFD700;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

</style>