<template>
    <div class="container">
        <div>
            <back></back>
        </div>
        <div class="title">今天吃啥好呢？</div>
        <button class="big-button" @click="getFoodList()">换一批</button>
        <ul class="menu-list">
            <li v-for="row in foodList">{{ row }}</li>
        </ul>
        <div style="margin-top: 30vh"></div>
        <input v-model="foodName">
        <div style="border:2px solid #8a3939;background: #d77a7a;color: white;border-radius: 8px;padding: 3px"
             @click="add()">提交菜✅
        </div>
        <div>提交状态：{{ result ? '成功' : '失败' }}</div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {getEat, addFood} from "@/api/yunoApi";

export default {
    name: "whatToEat",
    components: {Back},
    data() {
        return {
            foodName: '',
            foodList: [],
            result: false,
        };
    },
    methods: {
        changeButtonColor() {
            // 定义一组颜色
            const colors = [
                '#ff6384', // 粉色
                '#36a2eb', // 亮蓝色
                '#cc65fe', // 紫色
                '#ffce56', // 黄色
                '#4bc0c0', // 薄荷绿
                '#9966ff', // 暗紫罗兰
                '#ff9f40', // 橙色
                '#ffdb58', // 浅黄色
                '#96c93d', // 橄榄绿
                '#ff99cc', // 玫瑰色
                '#4286f4', // 亮钢蓝
                '#f44336', // 红色
                '#9c27b0', // 深紫红色
                '#e91e63', // 粉红
                '#673ab7', // 深紫色
                '#3f51b5', // 靛蓝
                '#2196f3', // 亮蓝色
                '#00bcd4', // 水绿色
                '#009688', // 茶色
                '#4caf50', // 绿色
                '#cddc39', // 柠檬黄
                '#ffeb3b', // 浅柠檬黄
                '#ffc107', // 琥珀色
                '#ff9800', // 橙色
                '#ff5722', // 深橙色
                '#795548', // 棕色
                '#9e9e9e', // 灰色
                '#607d8b', // 蓝色灰
                '#78909c', // 浅青色
                '#5c6bc0', // 深蓝色
                '#3949ab', // 深蓝紫
                '#ee32d6', // 紫罗兰
                '#d500f9', // 亮紫罗兰
                '#651fff', // 深紫
                '#6200ea', // 深紫罗兰
                '#b388ff', // 浅紫罗兰
                '#7c4dff', // 淡紫色
                '#ce93d8', // 浅紫色
                '#ba68c8', // 深粉色
                '#eceff1', // 浅灰色
                '#cfd8dc', // 蓝灰色
                '#b0bec5', // 浅青色
                '#a7ffeb', // 浅水绿色
                '#ffff00', // 黄色
                '#ffeb3b', // 黄绿色
                '#ffcdd2', // 浅粉色
                '#bcaaa4', // 浅棕色
                '#8d6e63', // 深棕色
                '#bdbdbd', // 灰色
                '#78909c'  // 浅青色
            ];
            // 随机选择一个颜色
            const randomColor = colors[Math.floor(Math.random() * colors.length)];
            // 应用到按钮上
            this.$el.querySelector('.big-button').style.backgroundColor = randomColor;
        },
        add() {
            if (this.foodName === '') {
                return;
            }
            addFood(this.foodName).then(res => {
                this.result = true
                this.foodName = ''
            }).catch(err => {
                this.result = false
            })
        },
        getFoodList() {
            this.changeButtonColor(); // 在点击时改变按钮颜色
            getEat().then(res => {
                this.foodList = res
            })
        }
    },
    mounted() {
        this.getFoodList()
    }
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    padding: 3vh;
    font-size: 24px;
    margin-bottom: 20px;
}

.big-button {
    width: 150px;
    min-height: 150px;
    border-radius: 50%;
    border: none;
    background-color: #007BFF;
    color: rgba(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 40px;
    box-shadow: 0 3px 6px rgba(0, 123, 255, 0.5); /* 添加按钮阴影 */
}

.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.menu-list li {
    font-size: 16px;
    margin: 4px;
    padding: 15px;
    background-color: #e2e8f0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease; /* 列表项悬停效果 */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* 列表项阴影 */
}

</style>