<template>
    <div class="game">
        <h1>人生模拟器</h1>
        <p>{{ gameText }}</p>
        <div v-if="options.length">
            <button
                    v-for="option in options"
                    :key="option.text"
                    @click="chooseOption(option)"
            >
                {{ option.text }}
            </button>
        </div>
    </div>
</template>

<script>
import { game } from '@/utils/game.js';

export default {
    name: "Game",
    data() {
        return {
            currentScene: game.currentScene,
            gameText: '',
            options: []
        };
    },
    created() {
        this.updateScene();
    },
    methods: {
        updateScene() {
            this.gameText = game.scenes[this.currentScene].text;
            this.options = game.scenes[this.currentScene].options;
        },
        chooseOption(option) {
            this.currentScene = option.next;
            this.updateScene();
        }
    }
};
</script>

<style scoped>
/* 添加一些基本的样式 */
.game {
    text-align: center;
    max-width: 600px;
    margin: auto;
}
button {
    margin: 8px;
    padding: 10px;
}
</style>