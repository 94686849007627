<template>
    <div>
        <back></back>
        <div class="log">
            <el-collapse accordion v-model="activeNames">
                <div v-for="item in logList" :key="item.id" class="logList">
                    <el-collapse-item :title=" item.title+' 更新记录'" :name="item.id">
                        <div class="logInfo">{{ item.info }}</div>
                    </el-collapse-item>
                </div>
            </el-collapse>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    name: "log",
    components: {Back},
    data() {
        return {
            activeNames: 1,
            logList: [
                {id: 1, title: '2024-06-06 14:09', info: '完成初期项目'},
                {id: 2, title: '2024-06-06 16:32', info: '云顶阵容装备栏完成'},
                {id: 3, title: '2024-06-07 03:26', info: '云顶阵容完成创建阵容'},
                {
                    id: 4,
                    title: '2024-06-08 03:08',
                    info: '对于版本更换放入了缓存中' + '\n' + '不用每次返回变回初版本对阵容详情中添加了人物列表' + '\n' + '添加了装备等优化了展示逻辑以及背景边框等'
                },
                {
                    id: 5,
                    title: '2024-06-08 20:18',
                    info: '创建新模块-答案之书' + '\n' + '将原先云顶阵容调整到了/tft目录下' + '\n' + '现在根目录为菜单'
                },
                {id: 6, title: '2024-06-09 02:30', info: '添加点击获取答案逻辑新增了查询答案API文件'},
                {
                    id: 7,
                    title: '2024-06-09 15:54',
                    info: '设置了seo的TDK标签' + '\n' + '对于答案之书添加了过渡动画' + '\n' + '答案之书添加了再来一次的按钮' + '\n' + '调整了back组件的逻辑，现在能被正确的返回了'
                },
                {
                    id: 8,
                    title: '2024-06-11 02:13',
                    info: '新增了每天吃什么功能' + '\n' + '新增了星座分析模块' + '\n' + '添加了几张图片以及对应的代理'
                },
                {id: 9, title: '2024-06-12 00:13', info: '新增了微信余额截图工具'},
                {id: 10, title: '2024-06-24 19:42', info: '对于阵容部分添加了一个根据英雄查询阵容的功能'},
                {id: 11, title: '2024-07-04 20:51', info: '新增64卦算命转盘'},
                {id: 12, title: '2024-07-06 03:30', info: '完善八卦图样式' + '\n' + '创建奥特曼大全功能'},
                {
                    id: 13,
                    title: '2024-07-07 03:48',
                    info: '修改云顶阵容列表加载更多的逻辑，现在直接滑动去加载下一页' + '\n' + '调整奥特曼页面中点击奥特曼头像跳出的介绍以及对应相关的逻辑'
                },
                {id: 14, title: '2024-07-08 03:10', info: '创建星座相关逻辑' + '\n' + '后端写了星座计算的规则'},
                {id: 15, title: '2024-07-09 01:42', info: '开始美化星座页面'},
                {
                    id: 16,
                    title: '2024-07-09 20:32',
                    info: '创建了星座选择的逻辑' + '\n' + '通过今天的日期来判定当前是什么月' + '\n' + '添加了访问记录' + '\n' + '添加了星座的图片'
                },
                {id: 17, title: '2024-07-10 02:06', info: '创建了一个新页面' + '\n' + '用来传递秘密消息以及分享'},
                {id: 18, title: '2024-07-10 21:14', info: '创建aes加密解密类，然后定义了一个长度固定方法'},
                {id: 19, title: '2024-07-11 23:39', info: '完成传递纸条页面' + '\n' + '实现了写内容、加密、解密、判重等'},
                {id: 20, title: '2024-07-11 23:55', info: '修复了几个小问题'},
                {id: 21, title: '2024-07-12 00:04', info: '取纸条码随机生成进行优化'},
                {
                    id: 22,
                    title: '2024-07-13 03:58',
                    info: '创建了一个上传文件，存件取件的一个页面，叫做文件转发' + '\n' + '实现了文件上传，文件下载'
                },
                {id: 22, title: '2024-07-14 03:16', info: '创建了管理员界面，实现了菜单目录的查询以及文件的管理'},
                {id: 23, title: '2024-07-15 03:46', info: '管理员界面完成数据库管理'},
                {
                    id: 24,
                    title: '2024-07-20 17:18',
                    info: '管理端添加了横线来更加清晰每一行' + '\n' + '星座给contain重命名' + '\n' + 'note部分添加了携带链接访问的逻辑'
                },
                {
                    id: 25,
                    title: '2024-07-20 21:01',
                    info: '添加算命算盘详细信息' + '\n' + '每天吃什么调整换一批按钮样式'
                },
                {
                    id: 26,
                    title: '2024-07-22 00:49',
                    info: '添加了后台页面，将原先admin页面改为了-2页' + '\n' + '新增了计算器页面' + '\n' + '新增了金价走势' + '\n' + '新增了天气预报' + '\n' + '新增了家规模块'
                },
                {id: 27, title: '2024-07-22 01:17', info: '修改返回按钮'},
                {
                    id: 28,
                    title: '2024-07-24 02:32',
                    info: '修改了金价逻辑，后续修改方向为' + '\n' + '查看当前金价' + '\n' + '查看今天历史金价' + '\n' + '查看时间范围内金价'
                },
                {id: 29, title: '2024-07-25 01:01', info: '修改金价显示逻辑'},
                {id: 30, title: '2024-07-27 01:11', info: '完成金价三重页面显示逻辑'},
                {id: 31, title: '2024-07-27 01:52', info: '开始创建待办事项'},
                {id: 32, title: '2024-07-28 03:58', info: '修改待办事项' + '\n' + '开始实现逻辑'},
                {id: 33, title: '2024-07-30 01:11', info: '除了删除事项' + '\n' + '全部完成'},
                {id: 34, title: '2024-07-30 01:18', info: '修改当前金价样式'},
                {id: 35, title: '2024-07-30 13:45', info: '修改十万个为什么' + '\n' + '对创建事项进行了空判定'},
                {id: 36, title: '2024-07-30 16:55', info: '创建账本模块，实现基本功能'},
                {id: 37, title: '2024-08-01 00:52', info: '创建粘贴板功能，实现查询，新增，删除，点击复制功能'},
                {id: 38, title: '2024-08-01 18:11', info: '完成云顶之弈s12赛季功能'},
                {id: 39, title: '2024-08-01 19:57', info: '修复一些小问题'},
                {id: 40, title: '2024-08-04 01:19', info: '创建导航栏'},
                {id: 41, title: '2024-08-05 01:41', info: '完成刑法大全'},
                {id: 42, title: '2024-08-05 21:21', info: '创建了几个html页面' + '\n' + '创建卡牌页面'},
                {
                    id: 43,
                    title: '2024-08-09 01:44',
                    info: '创建了两个加载组件' + '\n' + '调整了mp3播放逻辑' + '\n' + '新增了一个工具类生成随机字符串'
                },
                {id: 44, title: '2024-08-11 02:59', info: '修改奥特曼大全，改为人物大全'},
                {id: 45, title: '2024-08-11 03:10', info: '修改人物大全逻辑'},
                {id: 46, title: '2024-08-14 03:23', info: '新增“每天一百个单词”模块' + '\n' + '模糊查询还没做'},
                {id: 47, title: '2024-08-14 15:00', info: '完善每天一百个单词'},
                {id: 48, title: '2024-08-15 13:01', info: '新增导航栏'},
                {id: 49, title: '2024-08-15 23:01', info: '新增人生模拟器以及对应功能'},
                {id: 50, title: '2024-08-16 04:09', info: '新增老虎机'},
                {id: 51, title: '2024-08-17 02:20', info: '完善老虎机，可以旋转上分了'},
                {id: 52, title: '2024-08-18 02:49', info: '完善老虎机2，有音效了，完善大部分功能，可以长按上分了'},
                {
                    id: 53,
                    title: '2024-08-19 02:25',
                    info: '删除原先导航栏中反馈菜单' + '\n' + '新增了更新日志' + '\n' + '修改刑法大全，现在改为刑法&劳动法' + '\n' + '修改原先刑法逻辑以及对应数据库字段' + '\n' + '天气预报api有问题，现在如果返回err，那么直接隐藏天气预报这个模块' + '\n' + '创建折叠面板'
                },
                {
                    id: 54,
                    title: '2024-08-21 15:24',
                    info: '修改更新日志：默认全部展开\n' +
                        '新建几个html页面'
                },
                {
                    id: 55,
                    title: '2024-08-22 01:45',
                    info: '新建二维码生成页\n' +
                        '后续方向是创建扫一扫传送文件功能'
                },
                {
                    id: 56,
                    title: '2024-08-22 19:47',
                    info: '二维码页面新增设置\n' +
                        '二维码创建参数以及上传logo等设置'
                }, {
                    id: 57,
                    title: '2024-08-22 19:47',
                    info: '完成二维码文本以及参数的设置\n' +
                        '完成下载功能\n' +
                        '完成本地logo选择以及上传logo\n' +
                        '拆分css文件'
                }, {
                    id: 58,
                    title: '2024-08-24 02:42',
                    info: '新增了一个下载图标\n' +
                        '实现了扫码文件互传功能（一开始用的websocket直传，但是分片有问题，只能用ws进行推送后查询）\n' +
                        '新增了一个扫码上传接口\n' +
                        '重写了websocket逻辑\n' +
                        '新增了一个生成随机值的方法'
                }, {
                    id: 59,
                    title: '2024-08-24 02:49',
                    info: '调整跳转地址'
                }, {
                    id: 60,
                    title: '2024-08-24 17:20',
                    info: '完成二维码页面所有功能'
                }, {
                    id: 61,
                    title: '2024-08-25 01:57',
                    info: '新建了一个起始页，并完成基础功能'
                }, {
                    id: 62,
                    title: '2024-09-21 02:57',
                    info: '新建xls页面，实现数据计算并保存图片'
                },
                {
                    id: 63,
                    title: '2024-10-12 14:22',
                    info: '创建维基页\n' +
                        'xls页新增二维码读取器\n' +
                        '实现两页面互相跳转'
                },{
                    id: 64,
                    title: '2024-10-14 13:57',
                    info: '优化逻辑'
                }

            ]
        }
    },
    methods: {
        invertArray() {

            this.logList = this.logList.reverse();
            const a = []
            this.logList.forEach(v => {
                let year = v.title.substring(0, 4) + '年'
                let mouth = v.title.substring(5, 7) + '月'
                let day = v.title.substring(8, 10) + '日 '
                let minute = v.title.substring(11, 13) + '点'
                let second = v.title.substring(14, 16) + '分'
                v.title = year + mouth + day + minute + second
                a.push(v.id)
                // v.title = updateFormDate(v.title)
                this.activeNames = a
            })
        }
    },
    mounted() {
        this.invertArray()
    }
}
</script>

<style scoped>
.log {
    margin-top: 30px;
}

.logList {
    border-bottom: 3px dotted #343434;
}

.logInfo {
    text-align: left;
    white-space: pre-wrap;
    text-decoration: underline;
    text-decoration-color: rgba(255, 0, 0, 0.32);
    font-style: italic;
}
</style>