<template>
    <div style="background-color: #f3f3f3;height: 101vh;margin:  -10px -5px -5px -5px ">
        <quit v-if="!isAdding"></quit>
        <div class="create-button" @click="isAdding=true" v-if="!isAdding">+</div>
        <div class="image-repeater"></div>
        <div class="title">TO DO LIST</div>
        <div class="search">
            <div>
                <label for="status">状态：</label>
                <select id="status" name="status" v-model="status" @blur="handleInputEnd">
                    <option value=''>全部</option>
                    <option value=0>待完成</option>
                    <option value=1>已完成</option>
                </select>
            </div>
            <div>作者: <input type="text" v-model="author" @blur="handleInputEnd" placeholder="作者"></div>
            <!-- 显示绑定的author值 -->
            <div>内容: <input type="text" v-model="info" @blur="handleInputEnd" placeholder="内容"></div>
        </div>
        <div class="toDoList" v-if="!isAdding">
            <div v-for="item in toDoList" :key="item.id" class="todoInfo">
                <div class="checkBox" @click="finish(item)">{{ item.status === 1 ? '✔️' : '' }}</div>
                <div v-if="!itemEditing[item.id]" @click="startEdit(item)">
                    {{ item.info }}
                </div>
                <!-- 编辑状态 -->
                <textarea v-else type="text" v-model="item.info" @blur="stopEdit(item)"
                          @keyup.enter="stopEdit(item)" class="textareaPlace"></textarea>
                <div style="text-align: right;font-size: 12px;">
                    <div> {{ item.author }}</div>
                    <div> {{ parseTime3(item.createTime) }}</div>
                </div>
            </div>
        </div>
        <transition name="gta-slide">
            <div class="addPage" v-if="isAdding">
                <div class="addPageLine"></div>
                <div class="addPageConfirm" @click="create()">
                    创建✔︎
                </div>
                <div class="addPageCancel" @click="cancel()">
                    取消✘
                </div>
                <div>
                    <div>
                    <textarea placeholder="点此输入内容" class="addPageInfo" rows="15"
                              v-model="toDoThing.info"></textarea>
                    </div>
                    <div>
                        <input placeholder="作者" class="addPageAuthor" v-model="toDoThing.author">
                    </div>

                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {createToDoThing, getToDoList, updateToDos} from "@/api/yunoApi";
import {parseTime3} from "@/utils/parse";
import Quit from "@/components/quit.vue";

export default {
    name: "todoList",
    components: {Quit, Back},
    data() {
        return {
            toDoList: [],
            pageNum: 1,
            totalCount: 0,
            author: null,
            status: 0,
            info: null,
            isFetching: false, // 新增变量控制加载状态
            itemEditing: {},
            isAdding: false,
            toDoThing: {info: '', author: ''}
        }
    },
    methods: {
        create() {
            if (this.toDoThing.info === '' || this.toDoThing.author === '') {
                this.isAdding = false
            } else {
                this.isAdding = false
                createToDoThing(this.toDoThing).then(res => {
                    this.clean()
                    this.pageNum = 1
                    this.getToDoList()
                })
            }
        },
        cancel() {
            this.isAdding = false
            this.clean()
        },
        clean() {
            this.toDoThing = {info: '', author: this.toDoThing.author}
        },
        handleInputEnd() {
            this.getToDoList()
        },
        parseTime3,
        async getToDoList() {
            const todos = {
                pageNum: this.pageNum
            }
            const addTodoParam = (key, value) => {
                if (value !== null && value !== '') {
                    todos[key] = value;
                }
            }
            addTodoParam('author', this.author);
            addTodoParam('status', this.status);
            addTodoParam('info', this.info);
            getToDoList(todos).then(res => {
                this.toDoList = res.list
                this.totalCount = res.totalCount
            })
        }, handleScroll() {
            if (this.isFetching) return; // 如果正在加载，则不执行
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 30) { // 距离底部100像素时触发
                if (this.toDoList.length < this.totalCount) {
                    this.pageNum++; // 增加页码
                    this.fetchLineUpList(); // 请求下一页数据
                }

            }
        },
        fetchLineUpList() {
            this.isFetching = true;
            const todos = {
                pageNum: this.pageNum
            }
            const addTodoParam = (key, value) => {
                if (value !== null && value !== '') {
                    todos[key] = value;
                }
            }
            addTodoParam('author', this.author);
            addTodoParam('status', this.status);
            addTodoParam('info', this.info);
            getToDoList(todos).then(data => {
                this.toDoList = [...this.toDoList, ...data.list];
                this.isFetching = false;
            })
        },
        finish(item) {
            item.status = 1
            const todos = {
                id: item.id,
                status: 1
            }
            updateToDos(todos).then(res => {
                const index = this.toDoList.findIndex(todo => todo.id === item.id);
                if (index !== -1) {
                    this.toDoList.splice(index, 1);
                }
            })
        },
        startEdit(item) {
            // 如果找到了元素并且其status === 0，则启动编辑模式
            if (item.status === 0) {
                // 为指定item的id添加editing属性
                this.$set(this.itemEditing, item.id, true);
            } else {
                // 如果元素的status不是0，则提示不可修改
                alert("已完成，不可修改");
            }
        },
        stopEdit(item) {
            // 停止编辑模式，将editing属性设置为false
            this.$set(this.itemEditing, item.id, false);
            this.editToDoThing(item)
        },
        editToDoThing(item) {
            const todos = {
                id: item.id,
                info: item.info
            }
            updateToDos(todos).then()
        },
    }, mounted() {
        this.getToDoList()
    }, created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>


<style scoped>
.image-repeater {
    width: 100%; /* 或者你希望的任何宽度 */
    height: 60px; /* 或者你希望的高度 */
    background-image: url('@/assets/img/Sheet.png'); /* 图像路径 */
    background-repeat: repeat-x; /* 水平重复图像 */
    background-size: auto 100%; /* 保持图像高度不变，宽度自适应 */
    background-position: center; /* 居中显示图像 */
}

/* 引入本地字体文件 */
@font-face {
    font-family: 'Festive';
    src: url('@/assets/ttf/STONB.TTF') format('truetype'); /* 现代浏览器支持的格式 */
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* 允许字体在加载时显示备用字体 */
}

.title {
    font-family: "Festive";
    font-size: 45px;
}

.search {
    justify-content: space-between;
    display: flex;
}

/* 基本样式 */
select {
    color: black;
    padding: 4px;
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
    border-radius: 4px;
    font-size: 16px;
    outline: none; /* 点击时不显示轮廓 */
}


/* 优化下拉框的箭头样式 */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input {
    margin-top: 3px;
    font-size: 16px;
    height: 20px;
    width: 60px;
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;

}

/* 移除IE和Edge的默认箭头 */
select::-ms-expand {
    display: none;
}

.toDoList {
    background-color: #f3f3f3;
    margin-top: 20px;
    border: 1px dashed black;
}

.todoInfo {
    border-bottom: black 1px solid;
    margin-top: 10px;
}

.textareaPlace {
    font-size: 16px;
}

.checkBox {
    cursor: pointer;
    font-size: 35px;
    height: 30px;
    border: 3px solid black;
    width: 30px;
    position: absolute;
}

.create-button {
    background-color: #05cb00; /* 按钮背景颜色 */
    color: white; /* 文字颜色 */
    border-radius: 50%; /* 圆形按钮 */
    width: 60px; /* 按钮宽度 */
    height: 60px; /* 按钮高度，圆形时宽高应一致 */
    padding: 0; /* 由于是圆形按钮，移除padding，文字居中可能需要其他方法 */
    position: fixed; /* 使用 fixed 定位 */
    bottom: 50px; /* 距离底部的距离，增加以提供更多空间 */
    right: 20px; /* 距离右侧的距离，增加以提供更多空间 */
    z-index: 999; /* 确保按钮在其他内容上方 */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* 鼠标悬浮时显示手型图标 */
    user-select: none; /* 防止用户选择文本 */
    font-size: 24px; /* 增大文字大小 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影，提升按钮层次感 */
}

.create-button:hover {
    background-color: #11de0d; /* 悬浮时的背景颜色 */
}

.addPage {
    width: 97%;
    margin-right: 1%;
    margin-left: 1%;
    background-color: rgba(162, 162, 162, 0.67);
    position: fixed;
    min-height: 80vh;
    margin-top: 20vw;
    border-radius: 40px 40px 0 0;
}

.addPageLine {
    border-radius: 15px;
    margin: 10px auto;
    background-color: #f3f3f3;
    width: 30%;
    height: 8px;
}

.addPageConfirm, .addPageCancel {
    position: absolute;
    margin-top: -20px;
    font-size: 20px;
    font-weight: bolder;
}

.addPageConfirm {
    cursor: pointer;
    right: 40px;
    color: rgba(0, 129, 0);

}

.addPageCancel {
    cursor: pointer;
    left: 40px;
    color: rgba(255, 77, 77);

}

.addPageInfo {
    margin-top: 20px;
    background-color: rgba(241, 240, 240, 0.5);
    width: 90%;
    font-size: 18px;
    color: #000000;
    font-weight: bolder;
}

/* 定义 GTA 风格的滑动动画 */
.gta-slide-enter-active,
.gta-slide-leave-active {
    transition: transform 0.5s ease;
}

.gta-slide-enter,
.gta-slide-leave-to {
    transform: translateY(100%); /* 从屏幕底部滑出/滑入 */
}

.gta-slide-leave-to {

}
</style>