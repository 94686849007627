<template>
    <div class="admin-total">
        <back></back>
        <div class="admin-title">
            <img src="../assets/YunOx2.png" @click="backUpLevel()">
        </div>
        <div class="module" v-if="module == null">
            <div class="file" @click="choseModule(1)">文件管理</div>
            <div class="dataBase" @click="choseModule(2)">数据库信息</div>
<!--            <div class="ftp" @click="choseModule(3)">服务器文件</div>-->
        </div>
        <div class="module2" v-if="module !== null">
            <transition name="fade" mode="out-in">
                <div class="file-module" v-if="module === 1">
                    <div style="height: 100%" v-if="files === null">
                        <div class="module-title" @click="isShowImgDist=false">目录</div>
                        <div class="module-menu" @click="getDistInfo('/file/')">文件</div>
                        <div class="module-menu" v-if="!isShowImgDist" @click="isShowImgDist=true">图片</div>
                        <div class="module-menu" v-if="isShowImgDist" @click="getDistInfo('/image/ultraman/')">奥特曼
                        </div>
                        <div class="module-menu" v-if="isShowImgDist" @click="getDistInfo('/image/ttf/equipment/')">
                            云顶装备
                        </div>
                        <div class="module-menu" v-if="isShowImgDist" @click="getDistInfo('/image/ttf/character/')">
                            云顶英雄
                        </div>
                        <div class="module-menu" @click="getDistInfo('/doc/')">文档</div>
                        <div class="module-menu" @click="getDistInfo('/icon/')">头像</div>
                    </div>
                    <div style="height: 100%" v-if="files !== null">
                        <div class="module-title" @click="files=null">返回上一级</div>
                        <div class="file-module-fileList">
                            <div v-for="file in files" :key="file" class="fileList">
                                <div>{{ formatFileName(file.fileName) }}</div>
                                <div class="fileSize">{{ file.size }}</div>
                                <img :src="getFileSrc(file)" style="width: 20px" @click="getImg(file)">
                                <div class="edit">
                                    <div @click="download(file)">下载</div>
                                    <div @click="deleteFile(file)">删除</div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in">
                <div class="dataBase-module" v-if="module === 2">
                    <div style="height: 100%" v-if="dbFiles === null">
                        <div class="module-title" @click="isShowImgDist=false">目录</div>
                        <div class="module-menu" @click="getDBInfo(1)">文件</div>
                        <div class="module-menu" @click="getDBInfo(2)">消息</div>
                    </div>
                    <div style="height: 100%" v-if="dbFiles !== null">
                        <div class="module-title" @click="dbFiles=null">返回上一级</div>
                        <div class="file-module-fileList">
                            <div v-for="file in dbFiles" :key="file" class="file2List">
                                <div>{{ file.content }}</div>
                                <div style="display: flex">
                                    <div class="codeTag">code</div>
                                    <div>{{ file.code }}</div>
                                    <div class="edit">
                                        <div @click="deleteFromDBFile(file)">删除</div>
                                    </div>
                                </div>
                                <div class="line2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {deleteDBFile, deleteFile, getDBFileInfo, getFolderInfo} from "@/api/yunoApi";
import ClipboardJS from "clipboard";

export default {
    name: "admin",
    components: {Back},
    data() {
        return {
            path: null,
            module: null,
            files: null,
            dbFiles: null,
            isShowImgDist: false,
            type: null,
        }
    },
    methods: {
        deleteFromDBFile(file) {
            deleteDBFile(this.type, file.id).then(res => {
                this.getDBInfo(this.type)
            })
        },
        getDBInfo(type) {
            this.type = type
            getDBFileInfo(type).then(res => {
                this.dbFiles = res
            })
        },
        getFileSrc(file) {
            if (file.type === 'PNG' || file.type === 'png' ||
                file.type === 'JPEG' || file.type === 'jpeg' ||
                file.type === 'JPG' || file.type === 'jpg' ||
                file.type === 'GIF' || file.type === 'gif' ||
                file.type === 'BMP' || file.type === 'bmp' ||
                file.type === 'TIFF' || file.type === 'tiff' ||
                file.type === 'TIF' || file.type === 'tif' ||
                file.type === 'WebP' || file.type === 'webp' ||
                file.type === 'SVG' || file.type === 'svg') {
                return file.url
            } else {
                return 'https://www.yunox.cn/image/blank.png'
            }
        },
        deleteFile(file) {
            const info = {
                path: file.path
            }
            deleteFile(info).then(res => {
                if (res === true) {
                    this.getFolderInfo()
                } else {
                    alert("删除失败")
                }
            })
        },
        download(file) {
            const link = document.createElement('a');
            link.href = file.url;
            link.setAttribute('download', file.url);
            link.click();
        },
        getImg(file) {
            if (file.type === 'PNG' || file.type === 'png' ||
                file.type === 'JPEG' || file.type === 'jpeg' ||
                file.type === 'JPG' || file.type === 'jpg' ||
                file.type === 'GIF' || file.type === 'gif' ||
                file.type === 'BMP' || file.type === 'bmp' ||
                file.type === 'TIFF' || file.type === 'tiff' || file.type === 'tif' ||
                file.type === 'WebP' || file.type === 'webp' ||
                file.type === 'SVG' || file.type === 'svg') {
                const link = document.createElement('a');
                link.href = file.url;
                link.setAttribute('download', file.url);
                link.click();
                // 这里是你的图片处理逻辑
            }
        },
        formatFileName(fileName) {
            const dotIndex = fileName.lastIndexOf('.'); // 找到最后一个点的位置
            // 检查最后一个点前的字符数量是否大于6
            if (dotIndex > 0 && dotIndex > 6) { // 长度大于7是因为要包括点本身和点后的字符
                // 截取最后一个点前的最后一部分，长度为6个字符
                const lastSixCharsBeforeDot = fileName.substring(dotIndex - 6, dotIndex);
                // 拼接截取的部分、点和点之后的所有内容
                return lastSixCharsBeforeDot + fileName.slice(dotIndex);
            }
            // 如果不满足条件，返回原始文件名
            return fileName;
        },
        choseModule(value) {
            this.module = value
        },
        backUpLevel() {
            this.module = null
        },
        getDistInfo(path) {
            this.path = path; // 调用path方法
            this.getFolderInfo(); // 调用getDistInfo方法
        },
        getFolderInfo() {
            const info = {
                path: this.path
            }
            getFolderInfo(info).then(res => {
                this.files = res

            })
        }
    },

}
</script>

<style scoped>
.admin-total {
    height: 92vh;
}

.admin-title {
    margin-top: 50px;
    border: solid 1px #ff0000;
    background-color: rgba(218, 174, 142, 0.2);
    height: 12%;
    border-radius: 10px;
}

.admin-title img {
    margin-top: 10px;
    width: 98%;
}

.module {
    padding-top: 5px;
    margin-top: 5px;
    border-radius: 10px;
    background-color: rgba(159, 194, 232, 0.43);
    height: 88%;
    flex-wrap: wrap; /* 允许元素换行 */
}

.module2 {
    margin-top: 5px;
    border-radius: 10px;
    background-color: rgba(159, 194, 232, 0.43);
    height: 88%;
    display: flex;
    flex-wrap: wrap; /* 允许元素换行 */
}

.file, .todo, .dataBase, .ftp {
    font-weight: bolder;
    font-size: 25px;
    display: flex; /* 使用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin: 20px 10px 10px 10px;
    height: 15%;
    border-radius: 20px;
    padding: 20px; /* 根据需要添加内边距 */
    box-shadow: 4px 5px 6px rgba(0, 0, 0, 0.5); /* 更深的阴影 */

}

.file-module {
    font-weight: bolder;
    font-size: 25px;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin: 10px;
    border-radius: 20px;
    padding: 20px; /* 根据需要添加内边距 */
    background-color: rgb(245, 123, 123); /* 红色背景 */
    width: 95%;

}

.todo-module {
    font-weight: bolder;
    font-size: 25px;
    display: flex; /* 使用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin: 10px;
    border-radius: 20px;
    padding: 20px; /* 根据需要添加内边距 */
    background-color: rgba(116, 80, 243, 0.34); /* 蓝色背景 */
    width: 95%;

}

.dataBase-module {
    font-weight: bolder;
    font-size: 25px;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin: 10px;
    border-radius: 20px;
    padding: 20px; /* 根据需要添加内边距 */
    background-color: rgb(239, 226, 88); /* 红色背景 */
    width: 95%;
    /* 移除固定高度或百分比高度，让高度自适应内容 */

}

/* 为.file和.todo设置不同的背景颜色 */
.file {
    background-color: rgb(245, 123, 123); /* 红色背景 */
}

.todo {
    background-color: rgba(116, 80, 243, 0.34); /* 蓝色背景 */
}

.dataBase {
    background-color: rgb(239, 226, 88); /* 黄色背景 */
}

.ftp {
    background-color: rgb(182, 245, 123); /* 红色背景 */

}

.file:hover, .todo:hover, .dataBase:hover .ftp:hover {
    cursor: pointer;
    transform: scale(0.98);
    box-shadow: -8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.5s ease; /* 平滑过渡效果 */
}

.todo:hover {
    cursor: pointer;
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.5s ease; /* 平滑过渡效果 */
}

.module-title {
    margin-top: -10px;
    display: flex;
    color: white;
    font-weight: bolder;
    font-size: 28px;
    border-radius: 10px;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    border: dashed thick white;
    height: 10%;
}

.module-menu {
    margin-top: 10px;
    display: flex;
    border-radius: 10px;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    border: double thick #000000;
    height: 10%;
}

.file-module-fileList {
    margin-top: 10px;
    position: absolute;
}

.fileList {
    margin-bottom: 10px;
    border: 1px white;
    border-radius: 10px;
    width: 85vw;
    height: 30px;
    gap: 10px;
    display: flex;
    font-size: 15px;
}

.line {
    margin-top: 30px;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.73);
    position: absolute;
}

.line2 {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-color: rgba(239, 20, 20, 0.73);
    position: absolute;
}

.file2List {
    margin-bottom: 30px;
    width: 85vw;
    height: 30px;
    flex-wrap: wrap; /* 子元素在必要时换行显示 */
    align-items: center; /* 子元素在交叉轴上居中对齐，如果需要垂直方向上也居中的话 */
    justify-content: flex-start; /* 子元素在主轴上靠左对齐 */
    font-size: 13px;
    /* 如果需要子元素内部的文本也靠左对齐，可以添加以下属性 */
    text-align: left; /* 确保文本内容在子元素内靠左对齐 */
}

.fileSize {
    font-size: 12px;
    position: absolute;
    right: 0;
    display: flex;
    margin-right: 90px; /* 最后一个元素靠右 */
    gap: 10px;
}

.edit {
    position: absolute;
    right: -20px;
    display: flex;
    margin-right: 30px; /* 最后一个元素靠右 */
    gap: 10px;
}

.codeTag {
    margin-right: 20px;
    background-color: rgba(66, 185, 131, 0.9);
    border-radius: 4px;
    font-weight: lighter;
    padding: 1px;
}

/* 定义动画的CSS类 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s, transform 0.3s;
}

.fade-enter {
    opacity: 0;
    transform: translateX(120%) scale(0.5) rotate(45deg);
}

.fade-enter-to {
    opacity: 1;
    transform: translateX(0) scale(1.2) rotate(360deg); /* 弹性效果 */
}

.fade-leave {
    opacity: 1;
    transform: scale(1.2) rotate(360deg); /* 弹性效果 */
}

.fade-leave-to {
    opacity: 0;
    transform: translateX(-120%) scale(0.5) rotate(-360deg); /* 反向弹性效果 */
}

/* 添加弹性动画效果 */
@keyframes bounceIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1.1);
    }
}

@keyframes bounceOut {
    from {
        opacity: 1;
        transform: scale(1.1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}

/* 应用弹性动画 */
.fade-enter-active {
    animation: bounceIn 0.3s ease forwards;
}

.fade-leave-active {
    animation: bounceOut 0.3s ease forwards;
}
</style>