<template>
    <div class="navigationBar">
        <div style="display: flex">
            <div v-if="isShowNavigationBar" class="navigation-menu">
                <div class="navigation-menu-info" :class="{ 'currentPage': path === 'home'}"
                     @click="goto(path==='home'?'homePage':'home')">切换首页
                </div>
                <div class=" navigation-menu-info
                " :class="{ 'currentPage': path === 'game'}" @click="goto('game')">
                    小游戏
                </div>
                <div class="navigation-menu-info" :class="{ 'currentPage': path === 'feedback'}" @click="goto('log')">
                    更新日志
                </div>
            </div>
            <img src="../assets/menu.png" @click="showNavigationBar()" class="navigationBarImg">
        </div>
    </div>
</template>

<script>
import {goto} from "@/utils/pathUtil";

export default {
    name: "navigationBar",
    data() {
        return {
            isShowNavigationBar: false,
            path: this.$route.name
        }
    },
    methods: {
        goto,
        showNavigationBar() {
            this.isShowNavigationBar = !this.isShowNavigationBar
        }
    },
}
</script>

<style scoped>
.navigationBar {
    position: absolute; /* 设置图片为绝对定位 */
    right: 5vw; /* 将图片定位到容器的最左边 */
    top: 1px; /* 如果需要，可以设置图片的顶部位置，这里假设为顶部对齐 */
}

.navigationBarImg {
    height: 5vh; /* 图片高度设置为视口高度的3% */
    cursor: pointer;

}

.navigationBarImg:hover {
    scale: 0.9;
}

.navigation-menu {
    border: 2px dashed #969696;
    background-color: rgba(255, 255, 255, 0.8);
    left: -80px;
    width: 24vw;
    max-width: 200px;
    margin-top: 10px;
    padding: 6px;
}

.navigation-menu-info {
    color: #000000;
    font-weight: normal;
    padding: 2px;
    border-bottom: 1px black solid;
    cursor: pointer;
}

.currentPage {
    color: tomato;
    font-weight: bolder;
}
</style>