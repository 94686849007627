<template>
    <div>
        <div class="gold_home_title">
            <img src="../assets/img/YunOx3.png" @click="goBack()">
        </div>
        <div class="gold_home">
            <div class="backOfficeMenu">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div v-if="goldTimeRange === 1">当前金价</div>
                    <div v-if="goldTimeRange === 2">今日金价</div>
                    <div v-if="goldTimeRange === 3">历史金价</div>
                    <div
                            style="margin-left: auto; color: black; border: solid 1px limegreen; border-radius: 10px; background-color: #ff4aa8; text-shadow: 0 0 0;cursor: pointer"
                            @click="changeRange()">切换时间
                    </div>
                </div>
                <div class="priceRightNow" v-if="goldTimeRange === 1">{{ goldPrice }}</div>
                <div class="gold" v-if="goldTimeRange === 2">
                    <div class="goldClass">
                        <div class="goldInfo" v-for="goldInfo in todayGoldPrice">
                            <div style="color: rgb(255,196,53);font-style: italic;font-weight: lighter;font-size: 16px">
                                {{ parseTime4(goldInfo.createTime) }}
                            </div>
                            <div style="color: #1566ff;background-color: rgba(199,248,52,0.27);font-weight: bolder">
                                {{ goldInfo.price.toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gold" v-if="goldTimeRange === 3">
                    <el-calendar v-model="calendarRange">
                        <template
                                slot="dateCell"
                                slot-scope="{ date, data }">
                            <div>
                                <div style="font-size: 16px;font-weight: lighter;opacity: 0.8">{{
                                    formatDate2(date)
                                    }}
                                </div>
                                <div style="font-size: 13px;color: #ff4d4d;font-weight: 900" v-if="!data.isSelected"> {{
                                    findPrice(date)
                                    }}
                                </div>
                                <div style="font-size: 13px;color: #ff4d4d;font-weight: 900" v-if="data.isSelected">
                                    {{ getNowPrice(data) ? price : price }}
                                </div>
                            </div>
                        </template>
                    </el-calendar>

                    <!--                                        {{ historyGoldPrice }}-->
                </div>
            </div>
            <div class="weather" v-if="weather">
                <div style="color: #000000" class="weatherInfo">今日天气{{ getTodayAndTomorrow(0) }}
                    <div>
                        白天：{{ weather[0].textDay }}
                        夜间：{{ weather[0].textNight }}
                    </div>
                    <div>
                        最高温度{{ weather[0].tempMax }}°
                        最低温度{{ weather[0].tempMin }}°
                    </div>
                    <div>
                        {{ weather[0].windDirDay }}
                        紫外线指数：{{ weather[0].uvIndex }}
                    </div>
                    <div>
                        日出时间{{ weather[0].sunrise }}
                        日落时间{{ weather[0].sunset }}
                    </div>
                </div>
                <div class="line"></div>
                <div style="color: rgba(0,0,0,0.6)" class="weatherInfo">明天天气{{ getTodayAndTomorrow(1) }}
                    <div>
                        白天:{{ weather[1].textDay }}
                        夜间:{{ weather[1].textNight }}
                    </div>
                    <div>
                        最高温度{{ weather[1].tempMax }}°
                        最低温度{{ weather[1].tempMin }}°
                    </div>
                    <div>
                        {{ weather[1].windDirDay }}
                        紫外线指数：{{ weather[1].uvIndex }}
                    </div>
                    <div>
                        日出时间{{ weather[1].sunrise }}
                        日落时间{{ weather[1].sunset }}
                    </div>
                </div>
                <div class="line"></div>
                <div style="color: rgba(0,0,0,0.3)" class="weatherInfo">后天天气{{ getTodayAndTomorrow(2) }}
                    <div>
                        白天：{{ weather[2].textDay }}
                        夜间：{{ weather[2].textNight }}
                    </div>
                    <div>
                        最高温度{{ weather[2].tempMax }}°
                        最低温度{{ weather[2].tempMin }}°
                    </div>
                    <div>
                        {{ weather[2].windDirDay }}
                        紫外线指数：{{ weather[2].uvIndex }}
                    </div>
                    <div>
                        日出时间{{ weather[2].sunrise }}
                        日落时间{{ weather[2].sunset }}
                    </div>
                </div>
            </div>
            <div v-for="row in page" :key="row" @click="goto(row.path)" class="backOfficeMenu"
                 :style="`background-image: url(${row.background})`"> {{ row.name }}
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {goto} from "@/utils/pathUtil";
import {
    addWeather,
    findWeather,
    getGoldPrice,
    getGoldPriceHistory,
    getGoldPriceToday,
    getLastPrice,
    getWeather
} from "@/api/yunoApi";
import {
    get60Days,
    getTodayAndTomorrow,
    getTodayTime,
    parseTime4
} from "../utils/parse";

/**
 * TODO：
 * 1.金价7天走势
 * 2.家训页面
 * 3.首页代办事项
 *
 */
export default {
    name: "BackOffice",
    components: {Back},
    data() {
        return {
            goldTimeRange: 1,
            clickTime: 0,
            goldPrice: localStorage.getItem('goldPrice') ? 0 : localStorage.getItem('goldPrice'),
            areaCode: 101220110,
            weather: null,
            page: [
                {'name': '王氏祖训', 'path': 'familyMotto', 'background': require('@/assets/img/cover/role.png')},
                {'name': '计算器', 'path': 'calculator', 'background': require('@/assets/img/cover/calculator.png')},
                {'name': '数据管理', 'path': 'admin', 'background': require('@/assets/img/cover/database.png')},
            ],
            todayGoldPrice: [],
            historyGoldPrice: [],
            calendarRange: new Date(),
            price: 0,
            timeRange: get60Days(new Date())
        }
    },
    watch: {
        calendarRange(val, oldVal) {//监听日历时间组件
            if (val) {
                this.timeRange = get60Days(val)
                this.getGoldHistory()
            }
        },
    },
    methods: {
        getNowPrice(date) {
            let time = {createTime: new Date(date.day)}; // 创建时间对象
            getLastPrice(time).then(res => { // 等待获取最新价格
                this.price = res === null ? 0.00 : res.toFixed(2); // 将获取到的最新价格赋值给price
            });
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = ((date.getMonth() + 1)); // 月份从0开始，所以需要+1
            const day = (date.getDate());
            return `${year}-${month}-${day}`;
        },
        formatDate2(date) {
            const day = (date.getDate());
            return `${day}`;
        },
        findPrice(date) {
            const dateString = this.formatDate(date);
            const priceData = this.historyGoldPrice.find(item => {
                const itemDateString = `${item.day[0]}-${item.day[1]}-${item.day[2]}`;
                return itemDateString === dateString;
            });
            return priceData ? `${priceData.price.toFixed(2)}` : '';
        },
        parseTime4,
        changeRange() {
            this.goldTimeRange += 1;
            if (this.goldTimeRange > 3) {
                this.goldTimeRange = 1
            }
        },
        getTodayAndTomorrow,
        goto,
        goBack() {
            window.history.back(); // 使用 history.back() 返回上一个页面
        },
        getGold() {
            getGoldPrice().then(res => {
                this.goldPrice = res
                localStorage.setItem('goldPrice', res)
            })
        },
        getGoldHistory() {
            let time = {beginTime: this.timeRange.beginTime, endTime: this.timeRange.endTime}
            getGoldPriceHistory(time).then(res => {
                this.historyGoldPrice = res
            })
        },
        getGoldToday() {
            getGoldPriceToday(getTodayTime()).then(res => {
                res.reverse();
                this.todayGoldPrice = res;
            })
        },
        getWeatherByCode() {
            getWeather(this.areaCode).then(res => {
                this.weather = res
            }).catch(err => {
                this.findWeather()
            })
        },
        findWeather() {
            findWeather(this.areaCode).then(res => {
                this.weather = res.daily
                this.addWeather(this.weather)
            }).catch(err => {
            })
        },
        addWeather(weather) {
            let CityWeatherVO = {code: this.areaCode, furtherWeather: weather}
            addWeather(CityWeatherVO).then()
        },
    }
    ,
    mounted() {
        this.getGold()
        this.getWeatherByCode()
        this.getGoldToday()
        this.getGoldHistory()
    }
}
</script>

<style>
.gold_home {
    display: flex;
    margin-top: 3vh;
    flex-wrap: wrap; /* 允许子元素换行 */
    width: 100vw; /* 宽度设为视口宽度 */
    max-width: 100%; /* 确保最大宽度不超过视口宽度 */
    height: 98%;
    margin-left: -5px;
    padding: 1%; /* 给内容留出一些空间，避免紧贴边缘 */
    justify-content: space-around; /* 子元素在主轴上平均分布 */
}

.backOfficeMenu {
    color: rgb(251, 252, 251);
    text-shadow: 0 0 10px rgba(243, 4, 88, 0.7),
    0 0 20px rgba(243, 4, 88, 0.5),
    0 0 30px rgba(243, 4, 88, 0.3);
    font-weight: 900; /* 增加字重，使文字更粗 */
    font-size: 25px;
    font-family: "Heiti TC", sans-serif; /* 确保有合适的后备字体 */
    text-align: left;
    background-size: cover; /* 背景图片覆盖整个元素，保持宽高比 */
    flex: 1 1 20%;
    min-height: 30vh;
    min-width: 400px;
    border-radius: 8px;
    margin: 1vh;
    box-sizing: border-box;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
}

.priceRightNow {
    font-size: 100px;
    font-weight: bolder;
    color: #ff4d4d;
    text-decoration: underline; /* 添加下划线 */
    text-shadow: 0 0 0 rgba(243, 4, 88, 0.7);
    font-style: italic; /* 添加斜体 */
}

.weather {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 900; /* 增加字重，使文字更粗 */
    font-size: 25px;
    font-family: "Heiti TC", sans-serif; /* 确保有合适的后备字体 */
    text-align: left;
    background-size: cover; /* 背景图片覆盖整个元素，保持宽高比 */
    flex: 1 1 20%;
    min-height: 30vh;
    min-width: 400px;
    border-radius: 8px;
    margin: 1vh;
    box-sizing: border-box;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
}

.gold {
    text-shadow: 0 0 0 rgba(243, 4, 88, 0.7);
    color: #000000;
    /*font-size: 110px;*/
}

.line {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 1px;
    background-color: rgba(10, 141, 87, 0.73);
}

.backOfficeMenu:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.gold_home_title {
    display: flex;
    padding-bottom: 1px;
}

.gold_home_title img {
    height: 3vh; /* 图片高度设置为视口高度的3% */
    position: absolute; /* 设置图片为绝对定位 */
    left: 2vh; /* 将图片定位到容器的最左边 */
    top: 10px; /* 如果需要，可以设置图片的顶部位置，这里假设为顶部对齐 */
    cursor: pointer;
}

.goldClass {
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    width: 100%;
}

.goldInfo {
    margin-right: 1px;
    border-bottom: solid 1px #ff0000;
    padding-bottom: 3px;
    width: calc(16.4%); /* 减去左右间距 */
    height: 12vw;
    font-size: 18px;
    box-sizing: border-box; /* 边框和内边距包含在宽度内 */
}

.el-calendar__body {
    padding: 0;
}

.el-calendar-table .el-calendar-day {
    height: auto;
}
</style>