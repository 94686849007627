let limitConnect = 3; // 连接尝试次数限制
let timeConnect = 0; // 连接尝试次数计数
let timerWs = null; // 心跳检测定时器
let SendMessage = '';
let sessionId = ''
let Socket = '';
let socketUrl = `wss://www.yunox.cn/fileTransfer`; // WebSocket服务端URL
// let socketUrl = `ws://192.168.0.111:30000/fileTransfer`; // WebSocket服务端URL

// 创建一个socket连接
export const connectSocket = (firstSign) => {
    if (firstSign){
        sessionId = firstSign
    }
    Socket = new WebSocket(socketUrl);
    // 连接心跳
    Socket.onopen = onOpenSocket;
    // 接收消息
    Socket.onmessage = getMessage;
    // 处理连接关闭
    Socket.onclose = onCloseWs;
    // 处理连接错误
    Socket.onerror = (error) => {
        console.error('WebSocket 错误: ', error);
    };
}

const onOpenSocket = () => {
    console.log(sessionId)
    // 发送初始ping
    socketSendPing();
}

// 从服务器接收消息
const getMessage = (e) => {
    // console.log('接收到消息: ', e.data);
    window.dispatchEvent(
        new CustomEvent('getMessage', {
            detail: {
                data: e.data
            }
        })
    );
}

const onCloseWs = () => {
    console.log('WebSocket 连接已关闭');
    reconnectWs(); // 重新连接WebSocket
}

export function reconnectWs() {
    if (limitConnect > 0) {
        limitConnect--; // 减少尝试次数
        timeConnect++; // 增加尝试计数
        console.log(`正在重新连接... 剩余尝试次数: ${limitConnect}`);
        setTimeout(() => {
            connectSocket(); // 延迟2秒后重新连接
        }, 2000);
    } else {
        console.log('WebSocket 超时');
    }
}

// 断开WebSocket连接
export function closeSocketWs() {
    if (Socket) {
        Socket.close(); // 关闭WebSocket连接
        Socket = null; // 清空Socket引用
        limitConnect = 0; // 重置尝试次数
        console.log('WebSocket 已关闭');
    }
}

/**
 * 通过WebSocket发送数据
 * @param {any} message 要发送的数据
 */
export const sendWSPush = (message) => {
    if (Socket.readyState === WebSocket.OPEN) {
        Socket.send(message); // 发送消息
        console.log('发送成功')
    } else {
        SendMessage = message; // 如果未连接，则存储消息以便稍后发送
        console.log('发送失败')
    }
}

// 发送ping以保持连接活跃
export function socketSendPing() {
    let time = 3000; // ping间隔时间
    let ping = "ping"; // ping消息内容
    Socket.send(sessionId)
    if (timerWs) {
        clearInterval(timerWs); // 清除已有定时器
        timerWs = null;
    }
    timerWs = setInterval(() => {
        if (Socket && Socket.readyState === WebSocket.OPEN) {
            Socket.send(ping); // 如果连接开启，则发送ping
        } else {
            connectSocket(); // 如果连接未开启，则尝试重新连接
        }
    }, time);
}

// 发送文件
export const sendFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        if (Socket.readyState === WebSocket.OPEN) {
            Socket.send(arrayBuffer); // 发送文件数据
        } else {
            console.error('WebSocket 未连接，无法发送文件');
        }
    };
    reader.readAsArrayBuffer(file); // 读取文件为 ArrayBuffer
}

