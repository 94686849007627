<template>
    <div>
        <div class="container" :style="styleA">
            <back></back>
            <div class="left">
                    <textarea class="codeA" v-model="code" :style="styleB" placeholder="设置取件码"
                    ></textarea>
                <div class="upload" :style="styleC">
                    <el-upload
                            class="upload-demo"
                            drag
                            action=""
                            :before-upload="handleBeforeUpload"
                            multiple="false"
                            limit="1">
                        <div class="confirm">拖拽上传</div>
                    </el-upload>
                    <!--                    <input type="file" @change="handleFileChange" ref="fileInput"/>-->
                </div>
            </div>
            <div class="right">
                    <textarea class="codeB" v-model="code2" :style="styleB" placeholder="输入取件码"
                    ></textarea>
                <div class="download" :style="styleC">
                    <div class="confirm2" @click="getFile">点击下载</div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {getFile, uploadFile} from "@/api/yunoApi";

export default {
    name: "postal",
    components: {Back},
    data() {
        return {
            code: null,
            code2: null,
            screenHeight: 0,
            screenWidth: 0,
            isDownLoad: false,
            styleA: {
                width: '0px',// 初始化height为0px
                height: '0px'
            },
            styleB: {
                width: '0px',// 初始化height为0px
                height: '0px', // 初始化height为0px
            },
            styleC: {
                width: '0px',// 初始化height为0px
                height: '0px', // 初始化height为0px
            },

            selectedFile: null // 用于存储选择的文件
        };
    },
    methods: {
        getFile() {
            if (this.code2 != null) {
                getFile(this.code2).then(res => {
                    const link = document.createElement('a');
                    link.href = res;
                    link.setAttribute('download', res);
                    link.click();
                })
            }
        },
        handleBeforeUpload(file) {
            // 阻止默认的上传行为
            this.selectedFile = file; // 存储选择的文件
            // 调用自定义的上传方法
            this.uploadFile();
            return false; // 返回 false 以阻止默认行为
        },
        uploadFile() {
            if (this.code === null) {
                alert('先设置取件码')
                return
            }
            if (this.selectedFile) {
                // 发送 POST 请求上传文件
                uploadFile(this.selectedFile, this.code).then(res => {
                    // 处理响应
                    alert('文件上传成功');
                })
                    .catch(error => {
                        alert('请重试')
                    });
            } else {
                alert('请选择要上传的文件');
            }
        },
        getScreenHeight() {
            this.screenHeight = window.innerHeight;
            this.screenWidth = window.innerWidth;
            this.styleA.width = `${this.screenWidth}px`; // 更新styleObject的高度
            this.styleA.height = `${this.screenHeight - 60}px`; // 更新styleObject的高度
            this.styleB.height = `${this.screenHeight * 0.2}px`; // 更新styleObject的高度
            this.styleB.width = `${this.screenWidth / 2.25}px`; // 更新styleObject的高度
            this.styleC.height = `${this.screenHeight * 0.8 - 65}px`; // 更新styleObject的高度
            this.styleC.width = `${this.screenWidth / 2.25}px`; // 更新styleObject的高度
        }
    },
    mounted() {
        this.getScreenHeight()

    }
}
</script>

<style>
.container {
    margin-top: 50px;
    display: flex;
    height: 95vh;
    width: 100%;
}

.left {
    flex: 1;
    background-color: #42b983;
}

.right {
    flex: 1;
    background-color: #007BFF;
}

.codeA {
    margin-top: 10px;
    font-size: 24px;
    width: 100%;
    background-color: #b8f1c3;
    height: 20%;
}

.codeB {
    margin-top: 10px;
    font-size: 24px;
    width: 100%;
    background-color: #a7bcff;
    height: 20%;
}

.confirm {
    margin-left: -10px;
    color: white;
    cursor: pointer;
    font-size: 100px;
    height: 90%;
    width: 110%;
    background-color: #42b983;
}

.confirm:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.confirm2 {
    color: white;
    cursor: pointer;
    font-size: 100px;
    height: 90%;
    width: 110%;
    background-color: #007BFF;
}

.confirm2:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.el-upload-dragger {
    border: 1px dashed rgba(217, 217, 217, 0.1);
    background-color: rgba(226, 232, 240, 0);
    width: 50vw;
    height: 120%;
}
</style>