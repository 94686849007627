<template>
    <div>
        <back></back>
        <div class="author"><input type="text" v-model="author" @blur="handleInputEnd" placeholder="作者"></div>
        <div class="copyContainer">
            <div class="copyBoard">
                <div v-for="(item, index) in pasteBoardList" :key="index" class="copyDiv1">
                    <div class="copyDiv2" @click="copyText(pasteBoardList[index])">{{ item }}</div>
                    <div class="deletePasteDiv" @click="deletePasteboard(index)">❌</div>
                </div>
                <div class="copyAddDiv">
                    <textarea type="text" v-model="sentence" @blur="handleInputAddEnd" @input="beginInputAdd"
                              placeholder="新增" class="inputDiv" :style="styleObj"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {addPasteboard, deletePasteboard, getPasteboardList} from "@/api/yunoApi";


export default {
    name: "copy",

    components: {Back},
    data() {
        return {
            author: localStorage.getItem('billName') ? localStorage.getItem('billName') : null,
            sentence: '',
            pasteBoardList: [],
            oldPasteBoardList: [],
            styleObj: {
                height: '70px',
                width: '300px'
            }
        }
    }, methods: {
        copyText(text) {
            const input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            // 可以在这里添加一些反馈逻辑，比如提示用户复制成功
        },
        deletePasteboard(index) {
            if (index > -1 && index < this.pasteBoardList.length) {
                const id = this.oldPasteBoardList[index].id;
                const pasteboard = {id: id};
                // 删除服务器上的数据
                deletePasteboard(pasteboard).then(() => {
                    if (this.pasteBoardList.length === 1 && this.oldPasteBoardList.length === 1) {
                        this.pasteBoardList = []
                        this.oldPasteBoardList = []
                    } else {
                        this.pasteBoardList.splice(index, 1); // 从pasteBoardList中移除指定索引位置的元素
                        this.oldPasteBoardList.splice(index, 1); // 从oldPasteBoardList中移除指定索引位置的元素
                    }
                })
            }
        }
        ,
        beginInputAdd() {
            this.styleObj.width = '85vw';
            this.styleObj.height = '30vw';
        }
        ,
        handleInputEnd() {
            if (this.author === '') {
                localStorage.removeItem('billName')
            } else {
                localStorage.setItem('billName', this.author)
            }
        }
        ,
        handleInputAddEnd() {
            if (this.sentence !== '') {
                this.addPasteBoard()
            }
            this.styleObj.height = '70px';
            this.styleObj.width = '300px';
        }
        ,
        getPasteBoardList() {
            const pasteboard = {author: this.author};
            getPasteboardList(pasteboard).then(res => {
                this.oldPasteBoardList = res
                const pasteBoardList = [];
                // 假设res是一个数组，我们使用forEach来遍历它
                this.pasteBoardList = res.map(item => item.sentence);
                // 将处理后的数据赋值给组件的属性
            });
        }
        ,
        addPasteBoard() {
            if (this.sentence !== '') {
                this.pasteBoardList.push(this.sentence)
                if (this.author !== null) {
                    const pasteboard = {author: this.author, sentence: this.sentence}
                    addPasteboard(pasteboard).then(() =>
                        this.getPasteBoardList()
                    )
                }
            }
            this.sentence = ''
        }
    }, mounted() {
        this.getPasteBoardList()
    }
}
</script>

<style scoped>
.copyContainer {
    min-height: 95vh;
    background-color: #d7d7d7;
    background-image: url("https://img0.baidu.com/it/u=1881430330,1888299632&fm=253&fmt=auto&app=138&f=JPEG");
}

.copyBoard {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.copyDiv1 {
    width: 310px; /* 固定宽度 */
    display: flex;

}

.copyDiv2 {
    cursor: pointer;
    text-align: left;
    padding: 2px 2px 2px 2px;
    border-radius: 10px;
    width: 300px; /* 固定宽度 */
    height: 70px; /* 假设每行高度为40px，这里设置为3行的最大高度 */
    background-color: #b7ffdd;
    margin: 10px;
    overflow: hidden; /* 隐藏超出部分的内容 */
    word-wrap: break-word; /* 允许长单词换行 */
    overflow-wrap: break-word; /* 允许长单词换行 */
    box-sizing: border-box; /* 边框和内边距计算在宽度内 */
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.copyDiv2:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
}

.author {
    margin-top: -5px;
    position: absolute;
    right: 10%;
}

.author input {
    margin-top: 3px;
    font-size: 16px;
    height: 20px;
    width: 60px;
    border: 0px solid rgba(243, 243, 243, 0);
    background-color: rgba(243, 243, 243, 0);
}

.inputDiv {
    border-radius: 10px;
    margin-top: 10px;
    font-size: 16px;
    width: 300px; /* 固定宽度 */
    height: 70px; /* 假设每行高度为40px，这里设置为3行的最大高度 */
    border: 0 solid rgba(243, 243, 243, 0);
    background-color: #b7ffdd;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
}

.deletePasteDiv {
    margin-top: 35px;
    margin-left: -5px;
    font-size: 10px;
    cursor: pointer;
    z-index: 1; /* Ensure the deletePasteDiv is above the copyDiv */
}

</style>