<template>
    <div class="create-button" @click="goto('add')">+</div>
</template>

<script>
import {goto} from "@/utils/pathUtil";

export default {
    name: "create",
    methods: {goto}
}
</script>

<style scoped>
.create-button {
    background-color: #ff6262; /* 按钮背景颜色 */
    color: white; /* 文字颜色 */
    border-radius: 50%; /* 圆形按钮 */
    width: 60px; /* 按钮宽度 */
    height: 60px; /* 按钮高度，圆形时宽高应一致 */
    padding: 0; /* 由于是圆形按钮，移除padding，文字居中可能需要其他方法 */
    position: fixed; /* 使用 fixed 定位 */
    bottom: 20px; /* 距离底部的距离，增加以提供更多空间 */
    right: 20px; /* 距离右侧的距离，增加以提供更多空间 */
    z-index: 999; /* 确保按钮在其他内容上方 */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* 鼠标悬浮时显示手型图标 */
    user-select: none; /* 防止用户选择文本 */
    font-size: 24px; /* 增大文字大小 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影，提升按钮层次感 */
}

.create-button:hover {
    background-color: #ff4d4d; /* 悬浮时的背景颜色 */
}
</style>