<template>
    <div class="constellation-container">
        <quit></quit>
        <div class="constellation-title">
            <div style="text-align: center">
                <div style="width: 110px;color: #ffffff;border-radius: 5px;font-size: 13px">
                    {{ current.currentTime }}
                </div>
                <div style="padding: 2px 8px;border-radius: 10px;font-size: 12px;width: 42px;background-color: rgba(246,246,246,0.2);color: white;margin-left: 8px">
                    {{ current.zodiacSign }}月
                </div>

                <div style="margin-top: 30px;width: 80px">
                    <img :src="zodiacSign.img" alt="">
                </div>
                <div style="display: flex;background-color: rgba(246,246,246,0.15);width: 80px;border-radius: 10px;margin-left: 5px;margin-top: 8px;"
                     @click="choseZodiacSign()">
                    <div style="font-size: 16px;font-weight: bolder;color: white;margin-left: 5px">
                        {{ zodiacSign.zodiacSign }}座
                    </div>
                    <div>
                        <img src="@/assets/img/zhankai.png" style="width: 16px;margin-top: 4px;margin-left: 3px;"
                        >
                    </div>
                </div>
                <div style="width: 100px;font-size: 14px;color: white;text-align: center">
                    {{ zodiacSign.timeRange }}
                </div>
            </div>
        </div>
        <div class="block" v-if="!isChoseZodiacSign">
            <el-date-picker
                    v-model="time2"
                    type="year"
                    format="yyyy年"
                    placeholder="年">
            </el-date-picker>
            <el-select id="day" v-model="month" placeholder="月">
                <el-option
                        v-for="day in months"
                        :key="day"
                        :label="day"
                        :value="day">
                </el-option>
            </el-select>
            <el-select id="day" v-model="day" placeholder="日">
                <el-option
                        v-for="day in days"
                        :key="day"
                        :label="day"
                        :value="day">
                </el-option>
            </el-select>
            <template>
                <el-time-picker
                        format="HH时mm分"
                        v-model="time"
                        :picker-options="{
      selectableRange: '00:00:00 - 23:59:59'
    }"
                        placeholder="时间">
                </el-time-picker>

            </template>
            <form class="date-input-form" @submit.prevent="submitDateTime">
                <button type="submit" class="submit-button">查询</button>
            </form>

        </div>
        <!-- 使用flex容器来一行显示所有输入框 -->
        <div class="result-container" v-if="result.length > 0 && !isChoseZodiacSign">
            <div class="result-item">
                <p>{{ result[13] }}</p>
            </div>
            <div class="result-item">
                <p>{{ result[14] }}</p>
            </div>
            <div class="result-item">
                <p>{{ result[15] }}</p></div>
            <div class="result-item">
                <p>{{ result[16] }}</p></div>
            <div class="result-item">
                <p>{{ result[17] }}</p></div>
            <div class="result-item">
                <p>{{ result[18] }}</p></div>
            <div class="result-item">
                <p>{{ result[19] }}</p></div>
            <div class="result-item">
                <p>{{ result[20] }}</p></div>
            <div class="result-item">
                <p>{{ result[21] }}</p></div>
            <div class="result-item">
                <p>{{ result[22] }}</p></div>
            <div class="result-item">
                <p>{{ result[23] }}</p></div>
            <div class="result-item">
                <p>{{ result[24] }}</p></div>
            <div class="result-item">
                <p>{{ result[25] }}</p></div>
            <div class="result-item">
                <p>{{ result[26] }}</p></div>
            <div class="result-item">
                <p>{{ result[27] }}</p></div>
            <div class="result-item">
                <p>12星座宫位</p>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[0] }}</div>
                </div>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[1] }}</div>
                    <div class="zodiacSigns">{{ result[2] }}</div>
                </div>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[3] }}</div>
                    <div class="zodiacSigns">{{ result[4] }}</div>
                </div>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[5] }}</div>
                    <div class="zodiacSigns">{{ result[6] }}</div>
                </div>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[7] }}</div>
                    <div class="zodiacSigns">{{ result[8] }}</div>
                </div>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[9] }}</div>
                    <div class="zodiacSigns">{{ result[10] }}</div>
                </div>
                <div style="display: flex">
                    <div class="zodiacSigns">{{ result[11] }}</div>
                    <div class="zodiacSigns">{{ result[12] }}</div>
                </div>
            </div>
        </div>
        <div class="choseZodiac" v-if="isChoseZodiacSign">
            <div class="zodiac-border">
                <div style="margin-top: -10%; margin-left: -5%; margin-right: -5%; height: 100%; width: 100%">
                    <img src="@/assets/img/cloud3.png" height="80px" style="position: absolute;margin-left: -15%">
                    <img src="@/assets/img/cloud3.png" height="80px" width="100%">
                </div>
            </div>
            <div class="zodiac-logo">
                <div style="display: flex;justify-content: space-between">
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[0] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[0])">
                        <img :src="constellation[0].img">
                        <div>{{ constellation[0].zodiacSign }}座</div>
                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[1] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[1])">
                        <img :src="constellation[1].img">
                        <div>{{ constellation[1].zodiacSign }}座</div>
                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[2] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[2])">
                        <img :src="constellation[2].img">
                        <div>{{ constellation[2].zodiacSign }}座</div>
                    </div>
                </div>
                <div style="display: flex;justify-content: space-between">
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[3] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[3])">
                        <img :src="constellation[3].img">
                        <div>{{ constellation[3].zodiacSign }}座</div>

                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[4] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[4])">
                        <img :src="constellation[4].img">
                        <div>{{ constellation[4].zodiacSign }}座</div>

                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[5] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[5])">
                        <img :src="constellation[5].img">
                        <div>{{ constellation[5].zodiacSign }}座</div>

                    </div>
                </div>
                <div style="display: flex;justify-content: space-between">
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[6] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[6])">
                        <img :src="constellation[6].img">
                        <div>{{ constellation[6].zodiacSign }}座</div>

                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[7] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[7])">
                        <img :src="constellation[7].img">
                        <div>{{ constellation[7].zodiacSign }}座</div>

                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[8] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[8])">
                        <img :src="constellation[8].img">
                        <div>{{ constellation[8].zodiacSign }}座</div>

                    </div>
                </div>
                <div style="display: flex;justify-content: space-between">
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[9] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[9])">
                        <img :src="constellation[9].img">
                        <div>{{ constellation[9].zodiacSign }}座</div>

                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[10] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[10])">
                        <img :src="constellation[10].img">
                        <div>{{ constellation[10].zodiacSign }}座</div>

                    </div>
                    <div class="zodiac-logo-div" :class="{ 'chose-zodiac-logo':constellation[11] === zodiacSign }"
                         @click="choseZodiacLogo(constellation[11])">
                        <img :src="constellation[11].img">
                        <div>{{ constellation[11].zodiacSign }}座</div>

                    </div>
                </div>

            </div>
        </div>
        <div v-if="result.length === 0 && isChoseZodiacSign===false">
            <div style="font-weight: bolder;margin-top: 30px">
                《从入门到放弃》
            </div>
            <div style="margin-top: 30px;font-weight: bolder">实在写不下去了，比奥特曼大全难写一万倍</div>
            <div style="font-size: 12px">先是算儒略日，再算公转周期</div>
            <div style="font-size: 12px">又是算各个行星的真近点角</div>
            <div style="font-size: 12px">写一半开始学《开普勒方程迭代求解行星的偏近点角》</div>
            <div style="font-size: 12px">开普勒方程是求解行星运动的方程，这里使用迭代法逼近解</div>
            <div style="font-size: 12px">然后计算行星在轨道上的位置</div>
            <div style="font-size: 12px">这里使用简化的开普勒方程求解器</div>
            <div style="font-size: 12px">计算日期那天行星与太阳之间的距离</div>
            <div style="font-size: 12px">又写了一个根据偏心率和偏近点角计算行星位置的辅助方法</div>
            <div style="font-size: 12px">还有星球位置的径向距离</div>
            <div style="margin-top: 20px;font-size: 12px">一共计算了：</div>
            <div style="font-size: 12px">太阳星座，月亮星座，上升星座，行星位置，宫位</div>
            <div style="font-size: 12px">还没有完成的有：相位，运势周期，个人星盘，当前天象</div>
            <div style="font-size: 12px;margin-top: 30px">
                什么星座具体哪天跟哪个星座好，跟哪个星座不好，当天的综合运势，桃花，事业学业，财运，健康，幸运色，幸运数字，宜忌什么，这东西到底是怎么算的啊,随机数摇出来的吗？
            </div>
            <div style="font-weight: bolder;margin-top: 30px">
                然后 日期那几个选项选了以后可以算基本信息了
            </div>


        </div>
    </div>

</template>

<script>
import Back from "@/components/back.vue";
import {getConstellation} from "@/api/yunoApi";
import {DatePicker, Select, TimePicker} from "element-ui";
import {getDateArray, getTime, getTimeyyyyMMddHHmm} from "@/utils/parse";
import {getZodiacSign} from "@/utils/constellatiUtil";
import Quit from "@/components/quit.vue";


export default {

    name: "Constellation",
    components: {
        Quit,
        Back
    },
    data() {
        return {
            time: '',
            time2: '',
            days: Array.from({length: 31}, (_, i) => i + 1),
            months: Array.from({length: 12}, (_, i) => i + 1),
            year: '',
            month: '',
            day: '',
            hour: '',
            minute: '',
            result: [],
            current: '',
            zodiacSign: '',
            isChoseZodiacSign: false,
            constellation: [
                {zodiacSign: '水瓶', img: require('@/assets/img/constellation/aquarius.png'), timeRange: '1月20-2月18'},//水瓶
                {zodiacSign: '双鱼', img: require('@/assets/img/constellation/pisces.png'), timeRange: '2月19-3月20'},//双鱼
                {zodiacSign: '白羊', img: require('@/assets/img/constellation/aries.png'), timeRange: '3月21-4月19'},//白羊
                {zodiacSign: '金牛', img: require('@/assets/img/constellation/taurus.png'), timeRange: '4月20—-5月20'},//金牛
                {zodiacSign: '双子', img: require('@/assets/img/constellation/gemini.png'), timeRange: '5月21-6月21'},//双子
                {zodiacSign: '巨蟹', img: require('@/assets/img/constellation/cancer.png'), timeRange: '6月22-7月22'},//巨蟹
                {zodiacSign: '狮子', img: require('@/assets/img/constellation/leo.png'), timeRange: '7月23-8月22'},//狮子
                {zodiacSign: '处女', img: require('@/assets/img/constellation/virgo.png'), timeRange: '8月23-9月22'},//处女
                {zodiacSign: '天秤', img: require('@/assets/img/constellation/libra.png'), timeRange: '9月23-10月23'},//天秤

                {
                    zodiacSign: '天蝎',
                    img: require('@/assets/img/constellation/scorpio.png'),
                    timeRange: '10月24-11月22'
                },//天蝎
                {
                    zodiacSign: '射手',
                    img: require('@/assets/img/constellation/sagittarius.png'),
                    timeRange: '11月23-12月21'
                },//射手
                {
                    zodiacSign: '摩羯',
                    img: require('@/assets/img/constellation/capricornus.png'),
                    timeRange: '12月22-1月19'
                },//摩羯


            ]
        };
    },
    methods: {
        handleClickOutside(event) {
            console.log(123)
            if (this.isChoseZodiacSign && !this.$refs.choseZodiacWrapper.contains(event.target)) {
                this.choseZodiacSign();
            }
        },
        submitDateTime() {
            if (!this.validateInput()) return;
            const data = {
                year: this.year,
                month: this.month,
                day: this.day,
                hour: this.hour,
                minute: this.minute
            };
            getConstellation(data).then(res => {
                this.result = res;
            }).catch(error => {
            });
        },
        validateInput() {
            if (
                this.time2 === '' ||
                this.month === '' ||
                this.day === '' ||
                this.time === ''
            ) {
                alert('请填写所有字段。');
                return false;
            }
            this.year = getDateArray(this.time2)[0]
            this.hour = getDateArray(this.time)[3]
            this.minute = getDateArray(this.time)[4]
            return true;
        },
        getZodiacSignInfo() {
            const zodiacName = getZodiacSign();
            // 使用 find 方法查找 constellation 数组中匹配的星座对象
            this.zodiacSign = this.constellation.find(sign => sign.zodiacSign === zodiacName);
            this.current = this.zodiacSign
            this.current.currentTime = getTimeyyyyMMddHHmm()
        },
        choseZodiacSign() {
            this.isChoseZodiacSign = !this.isChoseZodiacSign;
        },
        choseZodiacLogo(zodiacSign) {
            this.zodiacSign = zodiacSign
            this.choseZodiacSign()
        },
    },
    mounted() {
        this.getZodiacSignInfo()
    },
}
</script>

<style>
.constellation-container {
    /*height: 90vh;*/
    max-width: 100vh;
    margin: -2%;
    text-align: center;
}

.constellation-title {
    padding: 20px;
    background-color: rgb(126, 130, 252);
    text-align: left;
}

.constellation-title img {
    width: 65px;
}

.date-input-form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 10px;
    width: 100%; /* 确保输入框容器占满其父元素宽度 */
}

.form-input {
    display: flex;
    align-items: center; /* 垂直居中对齐 */
}

.form-input label,
.form-input input {
    margin: 0 5px; /* 给标签和输入框设置外边距 */
}

.submit-button {
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #389e77;
}

.result-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-item {
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
    border-left: 3px solid #42b983;
    border-radius: 4px;
}

.zodiacSigns {
    line-height: 50px;
    width: 130px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
}

.choseZodiac {
    border-top-right-radius: 30px;
    /*height: 550px;*/
    padding: 10px;
    background-image: linear-gradient(to bottom, rgba(148, 188, 221, 1), rgba(148, 188, 221, 0.5));
    /* 渐变从顶部的不透明颜色到底部的完全透明 */
}

.zodiac-border {
    display: flex;
    height: 50px;
}

.zodiac-logo {
    margin-top: -30px;
}

.zodiac-logo-div {
    margin-bottom: 10%;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    width: 65px;
    height: 65px;
    border-radius: 40px;
}

.chose-zodiac-logo {
    background-color: #f8e727;
}

.zodiac-logo img {
    width: 60px;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 120px;
}

.el-input, .el-textarea {
    width: 80px;
}
</style>