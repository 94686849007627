<template>
    <div class="wordTotal">
        <back></back>
        <div style="font-weight: bolder;color: #ff7891;font-size: 22px;margin-top: -10px">每天一百个单词</div>
        <div class="wordSearch">
            <input v-model="keyword" placeholder="查找">
            <div class="button" @click="getWordByKeyword()">
                <img src="@/assets/img/search.png">
            </div>
        </div>
        <div class="wordList" v-if="!isSearch">
            <div v-for="(item, index) in words" :key="index" class="word" @click="copyText(words[index].word)">
                <div class="wordContent">{{ item.word }}</div>
                <div class="wordInterpretation">{{ item.interpretation }}</div>
            </div>
            <div class="wordFlush" @click="flushWords()">
                <img src="@/assets/img/undo.png">
                换一批
            </div>
        </div>
        <div class="wordList" v-if="isSearch">
            <div class="wordClose" @click="isSearch=false">x</div>
            <div v-for="(item, index) in searchResult" :key="index" class="word"
                 @click="copyText(searchResult[index].word)">
                <div class="wordContent">{{ item.word }}</div>
                <div class="wordInterpretation">{{ item.interpretation }}</div>
            </div>
        </div>


    </div>
</template>

<script>


import {getWordByKeyword, getWords} from "@/api/yunoApi";
import Back from "@/components/back.vue";
import {checkIsToday} from "@/utils/parse";

export default {
    name: "show",
    components: {Back},
    data() {
        return {
            keyword: '',
            isSearch: false,
            searchResult: [],
            words: [{word: 'apple', interpretation: '苹果'}],
        }
    },
    methods: {
        flushWords(){
            localStorage.removeItem('words')
            localStorage.removeItem('wordDate')
            this.getWords()
        },
        copyText(text) {
            const input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        },
        getWords() {
            const words = localStorage.getItem('words')
            const time = localStorage.getItem('wordDate')
            if (words && time && checkIsToday(time)) {
                this.words = JSON.parse(words)
            } else {
                getWords().then(res => {
                    const originalRes = res;
                    this.words = originalRes.sort((a, b) => {
                        // 这里可以根据word字段的值来定义排序的方式
                        // 如果word字段是字符串，可以使用localeCompare来进行字典序排序
                        return a.word.localeCompare(b.word);
                    });
                    localStorage.setItem('words', JSON.stringify(this.words))
                    localStorage.setItem('wordDate', new Date())
                })
            }
        },
        getWordByKeyword() {
            this.isSearch = true
            if (this.keyword === '') {
                this.keyword = 'apple'
            }
            const word = {word: this.keyword}
            getWordByKeyword(word).then(res => {
                this.searchResult = res
                this.keyword = ''
            })
        }
    },
    mounted() {
        this.getWords()
    }
}
</script>

<style scoped>
.wordTotal {

}

.wordList {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap; /* 允许子元素换行 */
    justify-content: flex-start; /* 子元素在容器内水平排列，如果超出则换行 */
    background: rgba(255, 221, 221, 0.23);
    padding-top: 20px;
    padding-bottom: 20px;
}

.word {
    box-shadow: 2px 2px 4px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    padding: 5px;
    border-radius: 10px;
    min-height: 40px;
    display: flex;
    width: 480px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.37);
}


.wordContent {
    flex: 1;
    padding-left: 5px;
    text-align: left;
    align-content: center;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}

.wordInterpretation {
    flex: 1;
    text-align: left;
    border: 1px solid #c4ffe7;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 10px;
    background: rgba(66, 185, 131, 0.45);

}

.wordSearch {
    display: flex;
    position: absolute;
    margin-top: -30px;
    right: 0;
}
.wordSearch input{
    width: 70px;
}

.button {
    cursor: pointer;
    margin-top: 1px;
    border-radius: 10px;
    height: 32px;
    width: 32px;
    background-color: #e2ff95;
}

.button :hover {
    scale: 0.9;
}

.button img {
    height: 28px;
    width: 28px;
    margin-top: 4px;
}

.wordClose {
    position: absolute;
    right: 20px;
    margin-top: -20px;
    background: red;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    color: white;
    font-size: 22px;
    text-align: center;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    cursor: pointer;

}

.wordClose:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.wordFlush {
    color: #ff1919;
    font-size: smaller;
    cursor: pointer;
    position: fixed;
    z-index: 999;
    width: 40px;
    height: 40px;
    right: 0;
    margin-top: 70vh;
    border-radius: 30px;
}
.wordFlush img{
    width: 40px;
    animation: spin 6s linear infinite; /* 添加动画效果 */
}
@keyframes spin {
    from {
        transform: rotate(0deg); /* 动画开始时，旋转0度 */
    }
    to {
        transform: rotate(-360deg); /* 动画结束时，旋转360度 */
    }
}
</style>