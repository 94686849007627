<template>
    <div class="return-button">
        <img src="../assets/img/YunOx5.png" @click="goBack()">
    </div>
</template>

<script>

export default {
    name: "back",
    methods: {
        goBack() {
            window.history.back(); // 使用 history.back() 返回上一个页面
        }
    }
}
</script>

<style scoped>
.return-button {
    /*background-color: #ff6262;*/
    border-radius: 5px;
    padding: 2px;
    top: 5px; /* 距离顶部的距离 */
    left: 5px; /* 距离左侧的距离 */
    z-index: 999; /* 确保按钮在其他内容上方 */
    cursor: pointer;
}
.return-button img{
    height: 3vh; /* 图片高度设置为视口高度的3% */
    position: absolute; /* 设置图片为绝对定位 */
    left: 1vh; /* 将图片定位到容器的最左边 */
    top: 5px; /* 如果需要，可以设置图片的顶部位置，这里假设为顶部对齐 */
    cursor: pointer;
}
.return-button img:hover{
    scale: 0.9;
}
</style>