<template>
    <div style="display: flex;justify-content: center;align-items: center; ">
        <div class="uploadButton">
                        <label for="file-upload">选择文件</label>
<!--            <label @click="sendMessage()">选择文件</label>-->
            <input
                    type="file"
                    id="file-upload"
                    @change="upload()"
                    accept="*"
                    multiple
                    class="file-upload"
                    style="display: none;"/>
        </div>
        <div style="margin-top: 30vh">
            <div v-for="item in files">
                <div>{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {connectSocket, sendFile, sendWSPush} from "@/utils/websocket";
import {generateRandomCode} from "@/utils/generalUtil";
import {scanUpload, uploadFile} from "@/api/yunoApi";

export default {
    name: "scanUpload",
    data() {
        return {
            sessionId: null,
            files: [],
        }
    },
    methods: {
        upload() {
            const fileInput = document.getElementById('file-upload');
            if (fileInput.files) {
                this.files = Array.from(fileInput.files); // 将文件列表转换为数组
            }
            if (this.files.length > 0 && this.sessionId) {
                for (let i = 0; i < this.files.length; i++) {
                    scanUpload(this.files[i], this.sessionId).then(res => {
                        sendWSPush(JSON.stringify({sessionId: this.sessionId, path: this.sessionId}))
                    })
                        .catch(error => {
                            alert('请重试')
                        });
                }
            } else {
                alert('请选择要上传的文件');
            }
        },
        checkSessionId() {
            if (window.location.href.includes('session=')) {
                this.sessionId = window.location.href.split('session=')[1];
            }
            connectSocket(JSON.stringify({sessionId: this.sessionId})); // 立即执行connectSocket函数以尝试建立WebSocket连接
        },
    }, mounted() {
        this.checkSessionId()
    }
}
</script>

<style scoped>
.uploadButton {
    position: absolute;
    width: 100px;
    top: 20%;
    height: 40px;
    border-radius: 5px;
    background: #42b983;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 水平居中子元素 */
    align-items: center; /* 垂直居中子元素 */
    padding: 5px; /* 可选：如果你希望按钮的内边距更大些 */
    box-sizing: border-box; /* 确保border和padding不会增加宽度 */
    cursor: pointer; /* 鼠标悬停时显示指针手势 */
    color: white;
}
</style>