import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import {RadioGroup} from "element-ui";
import {RadioButton} from "element-ui";
import {Notification} from "element-ui";
import {DatePicker} from "element-ui";
import {Select} from "element-ui";
import {Option} from "element-ui";
import {TimePicker} from "element-ui";
import {Upload} from "element-ui";
import {Calendar} from "element-ui";
import {Collapse} from "element-ui";
import {CollapseItem} from "element-ui";
import VueQr from 'vue-qr';

Vue.config.productionTip = false
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(DatePicker);
Vue.use(Select);
Vue.use(Option);
Vue.use(TimePicker);
Vue.use(Upload);
Vue.use(Calendar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(VueQr);

Vue.prototype.$notify = Notification;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
