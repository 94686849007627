export function getZodiacSign() {
    const date = new Date();
    const month = date.getMonth() + 1; // 获取月份，月份从1开始
    const day = date.getDate(); // 获取日期
    if (month < 1 || month > 12 || day < 1 || day > 31) {
        return "日期无效";
    }
    if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
        return "白羊";
    } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
        return "金牛";
    } else if ((month === 5 && day >= 21) || (month === 6 && day <= 21)) {
        return "双子";
    } else if ((month === 6 && day >= 22) || (month === 7 && day <= 22)) {
        return "巨蟹";
    } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
        return "狮子";
    } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
        return "处女";
    } else if ((month === 9 && day >= 23) || (month === 10 && day <= 23)) {
        return "天秤";
    } else if ((month === 10 && day >= 24) || (month === 11 && day <= 22)) {
        return "天蝎";
    } else if ((month === 11 && day >= 23) || (month === 12 && day <= 21)) {
        return "射手";
    } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
        return "摩羯";
    } else if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
        return "水瓶";
    } else if ((month === 2 && day === 29)) {
        return "双鱼";
    } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
        return "双鱼";
    }
}