<template>
    <div>

        <div class="answerBackground" v-if="!begin">
            <back v-if="!begin"></back>
            <div class="leftTopCenter">┏</div>
            <div class="rightTopCenter">┓</div>
            <div class="leftBottomCenter">┗</div>
            <div class="rightBottomCenter">┛</div>
            <div class="illustrate">
                <div style="font-size: larger;font-weight: bolder">如何使用本书</div>
                <div style="margin-top: 20px"></div>
                <div>10-15秒集中精力思考你的问题</div>
                <div>问题必须是一句完整的话</div>
                <div>例如：“这项新的工作好吗？”</div>
                <div>"我会幸福吗?"等等</div>
                <div style="margin-top: 20px"></div>
                <div>拿起手机，点击下方答案之书</div>
                <div>默念或说出你想要问的问题</div>
                <div>（每次只问一个）</div>
                <div style="margin-top: 20px"></div>
                <div>在感觉合适的时候</div>
                <div>你就会找到问题的答案</div>
                <div style="margin-top: 10px"></div>
            </div>
        </div>
        <div class="flipBook" v-if="loading">
            <div></div>
            <img src="@/assets/img/filpBook.gif">
            <div>稍等，正在翻书</div>
        </div>
        <div class="answerBookContainer" v-if="!begin">
            <div class="answerBook" @click="clickBook()">
                <div>
                    <div class="italic-text">THE BOOK OF ANSWERS</div>
                    <div class="italic-text2">答案之书</div>
                </div>
            </div>
        </div>
        <div class="answer" v-if="begin && loading === false">
            <div class="answerPageSide-left">
                <img src="@/assets/img/line.png">
            </div>
            <div class="answerPageSide-left2">
                <img src="@/assets/img/line2.png">
            </div>
            <div class="answerPageSide-right">
                <img src="@/assets/img/line.png">
            </div>
            <div class="answerPageSide-right2">
                <img src="@/assets/img/line2.png">
            </div>
            <div style="margin-top: 10px"></div>
            <div>▼</div>
            <div style="margin-top: -10px">●</div>
            <div style="margin-top: 10px"></div>
            <div style="margin-top: 20%;font-size: 24px;font-weight: bold">{{ answer.answerZH }}</div>
            <div style="margin-top: 120px">
                <img src="@/assets/img/wildflower.png">
            </div>
            <div style="margin-top: 35%;font-size: 24px;font-weight: normal;font-style: italic;">{{ answer.answerEn }}</div>
            <div style="margin-top: 70px" @click=flushPage()>
                <img src="@/assets/img/astrology.png">
                <div style="font-size: small">再试一次</div>
            </div>
        </div>
    </div>
</template>

<script>

import {getAnswer} from "@/api/yunoApi";
import Back from "@/components/back.vue";

export default {
    name: "answerBook",
    components: {Back},
    data() {
        return {
            begin: false,
            imageSrc: require('@/assets/img/wildflower.png'),
            answer: {
                "answerZH": "稍等",
                "answerEn": "WAIT"
            },
            loading: false,
        }
    },
    methods: {
        flushPage() {
           this.clickBook()
        },
        clickBook() {
            this.begin = true
            this.loading = true
            this.getAnswer();
            setTimeout(() => {
                this.loading = false;
            }, 2200); // 2500 毫秒 = 2.5 秒
        },
        getAnswer() {
            getAnswer().then(res => {
                this.answer = res
            })
        },
        setSeo() {
            // 设置关键词标签
            let keywordsContent = '答案之书, 心灵探索, 问题解答, 自我反思, 生活指导';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            // 设置描述标签
            let descriptionContent = '在答案之书中寻找指引。只需提出你的问题，集中精神，让答案之书为你揭晓答案。体验独特的心灵探索之旅，现在就开始。';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            // 设置标题
            document.title = '答案之书在线免费测试 - 探索你内心的答案';
        }
    },
    mounted() {
        this.setSeo()
    }
}
</script>

<style scoped>
.answerBackground {
    padding-top: 30px;
    display: flex; /* 启用Flexbox布局 */
    justify-content: center; /* 水平居中 */
}

.illustrate {
    margin-top: 30px;
}

.leftTopCenter {
    position: absolute;
    left: 0;
    font-size: 20px;
}

.rightTopCenter {
    position: absolute;
    right: 0;
    font-size: 20px;
}

.leftBottomCenter {
    margin-top: 330px;
    position: absolute;
    left: 0;
    font-size: 20px;
}

.rightBottomCenter {
    margin-top: 330px;
    position: absolute;
    right: 0;
    font-size: 20px;
}

.answerBookContainer {
    display: flex;
    justify-content: center;
    width: 100%; /* 容器宽度设置为100%，以便水平居中 */
}

.answerBook {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 300px;
    color: tomato; /* 文字颜色 */
    border-radius: 10px; /* 添加圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), /* 暗色阴影 */ 0 6px 20px rgba(0, 0, 0, 0.25); /* 更深的阴影，增加深度 */
    background-image: linear-gradient(to bottom, #393939, #26261d); /* 渐变背景 */
    overflow: hidden; /* 防止内容溢出背景 */
}

.italic-text {
    font-style: italic;
    font-size: 24px;
    font-weight: bolder;
    color: #9d7660; /* 文本颜色 */
    text-shadow: 2px 2px 4px #755d38; /* 文本阴影 */
    text-align: center; /* 文本居中对齐 */
    line-height: 1; /* 行间距 */
    letter-spacing: 1px; /* 字符间距 */
}

.italic-text2 {
    padding-top: 30px;
    font-size: 40px;
    font-weight: bolder;
    color: #6a79a4; /* 文本颜色 */
    text-shadow: 2px 2px 1px #4c54a9; /* 文本阴影 */
    text-align: center; /* 文本居中对齐 */
    line-height: 1; /* 行间距 */
    letter-spacing: 1px; /* 字符间距 */
}

.answer {
    height: 85%;
    width: 95%;
    border: 12px solid #2c3e50;
}

.answerPageSide-left {
    position: absolute;
    top: 24%;
    right: 20px;
}

.answerPageSide-right {
    position: absolute;
    top: 24%;
    left: 25px;
}

.answerPageSide-left2 {
    position: absolute;
    top: 34%;
    right: 35px;
}

.answerPageSide-right2 {
    position: absolute;
    top: 34%;
    left: 41px;
}

.answerPageSide-left img {
    height: 350px;
    width: 8px;
}

.answerPageSide-left2 img {
    height: 220px;
    width: 3px;
}

.answerPageSide-right img {
    height: 350px;
    width: 8px;
}

.answerPageSide-right2 img {
    height: 220px;
    width: 3px;

}

.flipBook {
    display: flex;
    flex-direction: column; /* 使子元素垂直排列 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 90vh; /* 容器高度 */
}

.flipBook img {
    margin-top: -20px;
    width: 60%; /* 根据需要调整 */
    height: auto; /* 保持图片宽高比 */
    object-fit: cover; /* 图片覆盖 */
}

.flipBook div {
    font-size: larger;
    font-weight: bolder;
    margin-top: 40px;
    text-align: center; /* 如果需要文字也水平居中 */
}
</style>