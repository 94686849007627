<template>
    <div class="introductory-container">
        <back></back>
        <div class="introductory-type">
            <div class="type-classification">全部</div>
            <div class="type-classification">武器</div>
            <div class="type-classification">技能</div>
            <div class="type-classification">人物</div>
        </div>
        <div class="totalCard">
            <div class="card"  v-for="item in card">
                <div class="image">{{ item.name }}</div>
                <div class="describe">{{ item.describe }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    name: "Introductory",
    components: {Back},
    data() {
        return {
            card:
                [   {name: 'A', describe: 'a'},
                    {name: 'B', describe: 'b'},
                    {name: 'C', describe: 'c'},
                    {name: 'D', describe: 'd'},
                    {name: 'E', describe: 'e'},
                    {name: 'F', describe: 'f'},
                    {name: 'G', describe: 'g'},
                    {name: 'H', describe: 'h'},
                    {name: 'I', describe: 'i'},
                    {name: 'J', describe: 'j'},
                    {name: 'K', describe: 'k'},
                    {name: 'L', describe: 'l'},
                    {name: 'M', describe: 'm'},
                    {name: 'N', describe: 'n'},
                    {name: 'O', describe: 'o'},
                    {name: 'P', describe: 'p'},
                    {name: 'Q', describe: 'q'},
                    {name: 'R', describe: 'r'},
                    {name: 'S', describe: 's'},
                    {name: 'T', describe: 't'},
                    {name: 'U', describe: 'u'},
                    {name: 'V', describe: 'v'},
                    {name: 'W', describe: 'w'},
                    {name: 'X', describe: 'x'},
                    {name: 'Y', describe: 'y'},
                    {name: 'Z', describe: 'z'},
                ]
        }
    }
}
</script>

<style scoped>
.introductory-container {
    margin-top: 40px;
}

.introductory-type {
    display: flex;
    gap: 15px;
}

.type-classification {
    background: black;
    height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    color: white;
    flex: 1;
}

.totalCard {
    margin-right: -8px;
    margin-top: 10px;
    background: #a8d2ff;
    display: flex; /* 使用flex布局 */
    flex-wrap: wrap; /* 允许换行 */
    gap: 5px; /* 保持卡片之间的间距 */
    justify-content: left; /* 可选：水平居中对齐卡片 */
}

.card {
    border-radius: 5px;

    border: 7px #ffffff solid;
    width: 186px;
    height: 300px;
    background: black;
    flex-shrink: 0; /* 防止卡片缩小 */
    margin: 5px 3px 0 3px
}

.card .image {
    display: flex; /* 使用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    text-align: left;
    border-top: 2px black solid;
    border-left: 2px black solid;
    border-right: 2px black solid;
    background: #e0c283;
    height: 268px;
    font-size: 40px;
}

.card .describe {
    display: flex; /* 使用Flexbox布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    border: 2px black solid;
    height: 26px;
    background: #ffffff;
}
</style>