<template>
    <div class="machine_body">
        <audio ref="audioPlayer1" src="@/assets/mp3/投币音.mp3" muted></audio>
        <audio ref="audioPlayer2" src="@/assets/mp3/旋转音.mp3"></audio>
        <audio ref="audioPlayer3" src="@/assets/mp3/按键音.mp3" muted></audio>
        <div class="machine_screen">
            <div style="height: 1vh"></div>
            <div class="machine_display">
                <div class="machine_win">{{ winScore }}</div>
                <div class="machine_remainder">{{ remainderScore }}</div>
            </div>
            <div class="coin_flip" @click="charge() ">
                <img src="@/assets/img/machine/coinSlot.png">
            </div>
            <div class="machine_icon">
                <!-- 7x7 网格布局 -->
                <div class="grid-item AA" :class="{'choseElement':roulette[num]===11}">
                    <img src="@/assets/img/machine/tangerine.png">
                </div>
                <div class="grid-item AB" :class="{'choseElement':roulette[num]===12}">
                    <img src="@/assets/img/machine/bell.png">
                </div>
                <div class="grid-item AB" :class="{'choseElement':roulette[num]===13}">
                    <img src="@/assets/img/machine/bar60.png">
                </div>
                <div class="grid-item AB" :class="{'choseElement':roulette[num]===14}">
                    <img src="@/assets/img/machine/bar120.png">
                </div>
                <div class="grid-item AB" :class="{'choseElement':roulette[num]===15}">
                    <img src="@/assets/img/machine/bar60.png">
                </div>
                <div class="grid-item AB" :class="{'choseElement':roulette[num]===16}">
                    <img src="@/assets/img/machine/apple.png">
                </div>
                <div class="grid-item AC" :class="{'choseElement':roulette[num]===17}">
                    <img src="@/assets/img/machine/hamimelon.png">
                </div>
                <div class="grid-item BA" :class="{'choseElement':roulette[num]===21}">
                    <img src="@/assets/img/machine/bell.png" style="scale: 0.75">
                </div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item BA AC" :class="{'choseElement':roulette[num]===27}">
                    <img src="@/assets/img/machine/watermelon.png">
                </div>
                <div class="grid-item BA" :class="{'choseElement':roulette[num]===31}">
                    <img src="@/assets/img/machine/apple.png">
                </div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item BA AC" :class="{'choseElement':roulette[num]===37}">
                    <img src="@/assets/img/machine/watermelon.png" style="scale: 0.75">
                </div>
                <div class="grid-item BA" :class="{'choseElement':roulette[num]===41}">
                    <img src="@/assets/img/machine/treasure.png">
                </div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item BA AC" :class="{'choseElement':roulette[num]===47}">
                    <img src="@/assets/img/machine/treasure.png">
                </div>
                <div class="grid-item BA" :class="{'choseElement':roulette[num]===51}">
                    <img src="@/assets/img/machine/star.png" style="scale: 0.75">
                </div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="machine_screen_middle_dot_enable" v-if="isLight"></div>
                <div class="machine_screen_middle_dot_unable" v-if="!isLight"></div>
                <div class="machine_screen_middle_display">{{ show }}</div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item BA AC" :class="{'choseElement':roulette[num]===57}">
                    <img src="@/assets/img/machine/apple.png">
                </div>
                <div class="grid-item BA" :class="{'choseElement':roulette[num]===61}">
                    <img src="@/assets/img/machine/star.png">
                </div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item" style="opacity: 0"></div>
                <div class="grid-item BA AC" :class="{'choseElement':roulette[num]===67}">
                    <img src="@/assets/img/machine/tangerine.png" style="scale: 0.75">
                </div>
                <div class="grid-item BA" :class="{'choseElement':roulette[num]===71}">
                    <img src="@/assets/img/machine/hamimelon.png">
                </div>
                <div class="grid-item BA AB" :class="{'choseElement':roulette[num]===72}">
                    <img src="@/assets/img/machine/hamimelon.png" style="scale: 0.75">
                </div>
                <div class="grid-item BA AB" :class="{'choseElement':roulette[num]===73}">
                    <img src="@/assets/img/machine/lucky.png">
                </div>
                <div class="grid-item BA AB" :class="{'choseElement':roulette[num]===74}">
                    <img src="@/assets/img/machine/diamond.png">
                </div>
                <div class="grid-item BA AB" :class="{'choseElement':roulette[num]===75}">
                    <img src="@/assets/img/machine/lucky.png" style="scale: 0.75">

                </div>
                <div class="grid-item BA AB" :class="{'choseElement':roulette[num]===76}">
                    <img src="@/assets/img/machine/bell.png">
                </div>
                <div class="grid-item BA AB" :class="{'choseElement':roulette[num]===77}">
                    <img src="@/assets/img/machine/tangerine.png">
                </div>
            </div>
        </div>
        <div class="machine_line">
            <div v-for="item in buttonElements">
                <div class="machineButtonElement">
                    <div class="buttonScore">{{ item.multiple }}</div>
                    <div class="buttonDisplay">{{ item.score }}</div>
                    <img :src="item.icon">
                </div>
            </div>
        </div>
        <div class="machine_button">
            <div class="machine_button_lineOne">
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: #006000">退</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png" @click="refunds()">
                </div>
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: pink">⇦</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png"
                         @click="changeDirection('right')">
                </div>
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: blue">⇨</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png"
                         @click="changeDirection('left')">
                </div>
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: black">單</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png" @click="guessIsSingle(true)">
                </div>
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: darkblue">雙</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png"
                         @click="guessIsSingle(false)">
                </div>
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: darkgreen">小</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png" @click="guessIsBig(false)">
                </div>
                <div class="machine_total_button">
                    <div class="machine_button_title" style="color: darkblue">大</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png" @click="guessIsBig(true)">
                </div>
                <div class="machine_total_button" @click="begin">
                    <div class="machine_button_title" style="color: darkred">開始</div>
                    <img class="machine_real_button" src="@/assets/img/machine/button.png">
                </div>
            </div>
            <div class="machine_button_lineTwo">
                <div v-for="item in buttonElements" class="">
                    <img :src="item.icon" class="machine_button_lineTwo_icon">
                    <img class="machine_real_button"
                         src="@/assets/img/machine/button.png"
                         @click="addScore(item)"
                         @mousedown="handleMouseDown($event, item)"
                         @mouseup="handleMouseUp"
                         @touchstart="handleTouchStart($event, item)"
                         @touchend="handleTouchEnd">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "slotMachine",
    data() {
        return {
            show: '0',
            num: 3,
            spend: 0,
            isLight: false,
            remainderScore: 0,
            winScore: 0,
            direction: 'left',
            roulette: [11, 12, 13, 14, 15, 16, 17, 27, 37, 47, 57, 67, 77, 76, 75, 74, 73, 72, 71, 61, 51, 41, 31, 21],
            elements: [
                {name: 'king', icon: require('@/assets/img/machine/crown.png'), score: 0, location: 14, relation: ''},
                {
                    name: 'bigBar',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 120,
                    location: 14,
                    relation: 'bar'
                }, {
                    name: 'smallBar',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 60,
                    location: 15,
                    relation: 'bar'
                },
                {
                    name: 'bigApple',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 5,
                    location: 16,
                    relation: 'apple'
                },
                {
                    name: 'bigHamiMelon',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 15,
                    location: 17,
                    relation: 'hamiMelon'
                },
                {
                    name: 'bigWatermelon',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 20,
                    location: 27,
                    relation: 'watermelon'
                },
                {
                    name: 'smallWatermelon',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 2,
                    location: 37,
                    relation: 'watermelon'
                },
                {name: 'box', icon: require('@/assets/img/machine/crown.png'), score: 0, location: 47, relation: ''},
                {
                    name: 'bigApple',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 5,
                    location: 57,
                    relation: 'apple'
                },
                {
                    name: 'smallTangerine',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 2,
                    location: 67,
                    relation: 'tangerine'
                },
                {
                    name: 'bigTangerine',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 10,
                    location: 77,
                    relation: 'tangerine'
                },
                {
                    name: 'bigBell',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 20,
                    location: 76,
                    relation: 'bell'
                },
                {
                    name: 'smallSeven',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 2,
                    location: 75,
                    relation: 'seven'
                },
                {
                    name: 'bigDiamond',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 0,
                    location: 74,
                    relation: ''
                },
                {
                    name: 'bigSeven',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 40,
                    location: 73,
                    relation: 'seven'
                },
                {
                    name: 'smallHamiMelon',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 2,
                    location: 72,
                    relation: 'hamiMelon'
                },
                {
                    name: 'bigHamiMelon',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 15,
                    location: 71,
                    relation: 'hamiMelon'
                },
                {
                    name: 'bigStar',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 30,
                    location: 61,
                    relation: 'star'
                },
                {
                    name: 'smallStar',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 2,
                    location: 51,
                    relation: 'star'
                },
                {name: 'box', icon: require('@/assets/img/machine/crown.png'), score: 0, location: 41, relation: ''},
                {
                    name: 'bigApple',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 5,
                    location: 31,
                    relation: 'apple'
                },
                {
                    name: 'smallBell',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 2,
                    location: 21,
                    relation: 'bell'
                },
                {
                    name: 'bigTangerine',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 10,
                    location: 11,
                    relation: 'tangerine'
                },
                {
                    name: 'bigBell',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 20,
                    location: 12,
                    relation: 'bell'
                },
                {
                    name: 'smallBar',
                    icon: require('@/assets/img/machine/crown.png'),
                    score: 60,
                    location: 13,
                    relation: 'bar'
                }
            ],
            buttonElements: [
                {name: 'bar', icon: require('@/assets/img/machine/bar120.png'), multiple: 120, score: 0},
                {name: 'seven', icon: require('@/assets/img/machine/lucky.png'), multiple: 40, score: 0},
                {name: 'star', icon: require('@/assets/img/machine/star.png'), multiple: 30, score: 0},
                {name: 'watermelon', icon: require('@/assets/img/machine/watermelon.png'), multiple: 20, score: 0},
                {name: 'bell', icon: require('@/assets/img/machine/bell.png'), multiple: 20, score: 0},
                {name: 'hamiMelon', icon: require('@/assets/img/machine/hamimelon.png'), multiple: 15, score: 0},
                {name: 'tangerine', icon: require('@/assets/img/machine/tangerine.png'), multiple: 10, score: 0},
                {name: 'apple', icon: require('@/assets/img/machine/apple.png'), multiple: 5, score: 0}
            ],
            isBegin: false,
            status: 0,
            isRolling: false,
            isFirst: true,
            // 状态0:待投币
            // 状态1:按键上分
            // 状态 3:启动中
            // 状态4:待结算
            // 状态5:旋转中
            // 状态6:退币
            longPressTimer: null, // 用于存储setTimeout的引用
        }
    },
    methods: {
        handleMouseDown(event, item) {
            event.preventDefault(); // 阻止默认行为，如文本选中等

            // 如果已经存在定时器，则先清除之前的定时器
            if (this.longPressTimer) {
                clearInterval(this.longPressTimer);
            }

            // 立即执行一次addScore方法
            this.addScore(item);

            // 设置setTimeout在1000ms后调用setInterval
            this.delayTimer = setTimeout(() => {
                // 使用setInterval来周期性调用addScore方法
                this.longPressTimer = setInterval(() => {
                    this.addScore(item);
                }, 100); // 间隔100毫秒
            }, 1000); // 延迟1000毫秒
        },

// 需要添加一个方法来清除setTimeout的定时器
        clearDelayTimer() {
            clearTimeout(this.delayTimer);
            this.delayTimer = null;
        },

// 更新clearLongPressTimer方法以清除setInterval和setTimeout的定时器
        clearLongPressTimer() {
            clearInterval(this.longPressTimer);
            this.longPressTimer = null;
            this.clearDelayTimer();
        },

// 处理鼠标抬起和触摸结束的事件来清除定时器
        handleMouseUp(event, item) {
            this.clearLongPressTimer();
        },

        handleTouchEnd(event, item) {
            this.clearLongPressTimer();
        },
        revolve() {
            this.$refs.audioPlayer2.muted = false;
            this.$refs.audioPlayer2.play();
        },
        pressButton() {
            this.$refs.audioPlayer3.muted = false;
            this.$refs.audioPlayer3.play();
        },
        coinFlip() {
            this.$refs.audioPlayer1.muted = false;
            this.$refs.audioPlayer1.play();
        },
        guessIsBig(result) {
            if (this.winScore !== 0) {
                const num = Math.floor(Math.random() * 10) + 1;
                if ((num % 2 === 1)) {
                    this.show = '大'
                } else {
                    this.show = '小'
                }
                if ((num % 2 === 1) === result) {
                    this.winScore = this.winScore * 2
                } else {
                    this.winScore = 0
                }
            }
        },
        guessIsSingle(result) {
            if (this.winScore !== 0) {
                const num = Math.floor(Math.random() * 10) + 1;
                if ((num % 2 === 1)) {
                    this.show = '單'
                } else {
                    this.show = '雙'
                }
                if ((num % 2 === 1) === result) {
                    this.winScore = this.winScore * 2
                } else {
                    this.winScore = 0
                }
            }
        },
        changeDirection(direction) {
            this.show = direction === 'left' ? '顺时针' : '逆时针'
            this.direction = direction
        },
        refunds() {//退币
            if (this.remainderScore > 0) {
                this.winScore = this.winScore + this.remainderScore
                this.remainderScore = 0
            } else if (this.remainderScore === 0 && this.winScore > 0) {
                this.winScore = this.winScore % 10
            } else if (this.winScore === 0 && this.remainderScore === 0 && this.isBegin === true) {
                this.cleanSet()
            }
        },
        addScore(item) {
            this.show = item.multiple
            if (this.isBegin) {
                this.reset()
            }
            if (this.remainderScore >= 1) {
                this.pressButton()
                item.score += 1
                this.remainderScore -= 1
                this.status = 2
                this.spend += 1
            }
        },
        charge() {
            this.coinFlip()
            this.remainderScore += 10
            this.showManyNum(59)
        },
        checkIsBet() {
            const totalScore = this.buttonElements.reduce((sum, element) => {
                return sum + element.score;
            }, 0); // 初始值为 0
            return totalScore >= 1;
        },
        //检查剩余积分够不够
        checkRemainingPoint() {
            const totalScore = this.buttonElements.reduce((sum, element) => {
                return sum + element.score;
            }, 0); // 初始值为 0
            return this.remainderScore >= totalScore
        },
        //扣分
        calculatePoints() {
            this.remainderScore = this.remainderScore - this.spend
        },
        ready() {
            this.remainderScore = this.winScore + this.remainderScore
            this.winScore = 0
        },
        begin() {
            if (this.winScore !== 0) {
                this.ready()
            } else {
                if (this.remainderScore - this.spend > 0) {
                    if (this.checkIsBet()) {
                        if (this.isFirst === false) {
                            this.calculatePoints()
                        } else {
                            this.isFirst = false
                        }
                        if (this.direction === 'left') {
                            this.leftBegin()
                        } else {
                            this.rightBegin()
                        }
                    }
                } else {
                    if (this.isFirst === true) {
                        if (this.checkIsBet()) {
                            this.isFirst = false
                            if (this.direction === 'left') {
                                this.leftBegin()
                            } else {
                                this.rightBegin()
                            }
                        }
                    }
                }

            }
        },
        rightBegin() {
            this.isBegin = true
            const randomNum = Math.floor(Math.random() * 32) + 42;
            let timeout = 50
            this.isRolling = true
            this.showManyNum()
            const executeLoop = (i) => {
                if (i < randomNum) {
                    this.num -= 1; // 或者根据需要增加 num 的值
                    if (this.num <= -1) {
                        this.num = this.roulette.length - 1; // 确保 num 不超出范围
                    }
                    if (i === 1) {
                        timeout = 300
                    }
                    if (i === 2) {
                        timeout = 270
                    }
                    if (i === 3) {
                        timeout = 240
                    }
                    if (i === 4) {
                        timeout = 210
                    }
                    if (i === 5) {
                        timeout = 180
                    }
                    if (i === 6) {
                        timeout = 150
                    }
                    if (i === 7) {
                        timeout = 120
                    }
                    if (i === 8) {
                        timeout = 90
                    }
                    if (i === randomNum - 6) {
                        timeout = 120
                    }
                    if (i === randomNum - 5) {
                        timeout = 300
                    }
                    if (i === randomNum - 4) {
                        timeout = 500
                    }
                    if (i === randomNum - 3) {
                        timeout = 500
                    }
                    if (i === randomNum - 2) {
                        this.isRolling = false
                        timeout = 600
                    }
                    this.isLight = !this.isLight
                    this.revolve()
                    setTimeout(() => executeLoop(i + 1), timeout); // 使用递归和 setTimeout
                } else {
                    // 循环结束，确保 isLight 为 true
                    this.calculateScore(this.roulette[this.num])
                }
            };
            executeLoop(0); // 开始循环

        },
        showManyNum() {
            let a = [1111, 2222, 3333, 4444, 5555, 6666, 7777, 8888, 9999];
            let index = 0;
            const loop = () => {
                // 检查索引是否超出数组长度
                if (index >= a.length) {
                    index = 0; // 重置索引
                }
                // 显示当前索引的数字
                this.show = a[index];
                // 递增 index，准备下一次循环
                index++;

                // 如果还需要继续循环，则设置下一个 setTimeout
                if (this.isRolling) {
                    setTimeout(loop, 300);
                }
            };
            // 初始调用循环函数
            loop();
        },
        leftBegin() {
            this.isBegin = true
            const randomNum = Math.floor(Math.random() * 32) + 42;
            this.isRolling = true
            this.showManyNum()
            let timeout = 50
            const executeLoop = (i) => {
                if (i < randomNum) {
                    this.num += 1; // 或者根据需要增加 num 的值
                    if (this.num >= this.roulette.length) {
                        this.num = 0; // 确保 num 不超出范围
                    }
                    if (i === 1) {
                        timeout = 300
                    }
                    if (i === 2) {
                        timeout = 270
                    }
                    if (i === 3) {
                        timeout = 240
                    }
                    if (i === 4) {
                        timeout = 210
                    }
                    if (i === 5) {
                        timeout = 180
                    }
                    if (i === 6) {
                        timeout = 150
                    }
                    if (i === 7) {
                        timeout = 120
                    }
                    if (i === 8) {
                        timeout = 90
                    }
                    if (i === randomNum - 6) {
                        timeout = 120
                    }
                    if (i === randomNum - 5) {
                        timeout = 300
                    }
                    if (i === randomNum - 4) {
                        timeout = 500
                    }
                    if (i === randomNum - 3) {
                        timeout = 500
                    }
                    if (i === randomNum - 2) {
                        this.isRolling = false
                        timeout = 600
                    }
                    this.isLight = !this.isLight
                    this.revolve()
                    setTimeout(() => executeLoop(i + 1), timeout); // 使用递归和 setTimeout
                } else {
                    // 循环结束，确保 isLight 为 true
                    this.isLight = true;
                    this.calculateScore(this.roulette[this.num])
                }
            };
            executeLoop(0); // 开始循环
        },
        leftBoxMethod() {
            //再来一次
            this.show = 200
            this.winScore = 200
        },
        rightBoxMethod() {
            //再来两次
            this.show = 400
            this.winScore = 400
        },
        diamondMethod() {
            //闪烁，闪烁五个格子对应相加
            this.show = 800
            this.winScore = 800
        },
        calculateScore(location) {
            if (location === 41) {
                this.leftBoxMethod()
            } else if (location === 47) {
                this.rightBoxMethod()
            } else if (location === 74) {
                this.diamondMethod()
            } else {
                const targetElement = this.elements.find(element => element.location === location);
                const targetElement2 = this.buttonElements.find(element => element.name === targetElement.relation);
                const score = targetElement2.score * targetElement.score
                this.show = score
                this.winScore = score
            }
        },
        reset() {
            this.buttonElements.forEach(element => {
                element.score = 0;
            });
            this.isFirst = true
            this.spend = 0
            this.isBegin = false
        },
        cleanSet() {
            this.reset()
            this.status = 0
            this.remainderScore = 0
            this.winScore = 0
            this.num = 3
            this.direction = 'left'
            this.show = '0'
        }
    }, beforeDestroy() {
        this.clearLongPressTimer(); // 确保在组件销毁前清除定时器
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'lcd';
    src: url('@/assets/ttf/lcd.TTF') format('truetype'); /* 现代浏览器支持的格式 */
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* 允许字体在加载时显示备用字体 */
}

.machine_body {
    margin: -1vh -1vw;
    background-image: url("@/assets/img/machine/machineCover.png");
    background-size: cover;

}

.machine_screen {
    height: 50%;
}

.machine_display {
    display: flex;
    margin-right: 23%;
    margin-left: 24%;
    width: 50%;
    height: 5vh;
    font-family: lcd;
}

.machine_win, .machine_remainder {
    flex: 1;
    border: #ffcc5a solid 5px;
    border-radius: 5px;
    background: black;
    text-align: right;
    margin-right: -3px;
    font-size: 28px;
    color: red;
    text-shadow: 0 0 10px rgba(243, 4, 88, 0.7),
    0 0 20px rgba(243, 4, 88, 0.5),
    0 0 30px rgba(243, 4, 88, 0.3);
}

.machine_line {
    margin-left: 7%;
    margin-right: 7%;
    display: flex;
    background: rgba(253, 252, 252, 0.5);
    gap: 5px;
    border-radius: 10px;
    border-right: 5px solid #ffec74;
    border-left: 5px solid #ffec74;
    border-bottom: 5px solid #ffec74;
}

.machine_button {
    /*background: white;*/
    color: white;
}

.machineButtonElement {

}

.machineButtonElement img {
    width: auto; /* 根据父容器宽度自动调整图片宽度 */
    max-width: 90%; /* 图片最大宽度不超过父容器宽度 */
    height: auto; /* 保持图片的宽高比 */
    border-bottom: 3px solid #ffec74;
    border-left: 2px solid #ffec74;
    border-right: 2px solid #ffec74;
}

.buttonScore {
    background: white;
    height: 3vh;
    border-radius: 40px;
    border: 5px solid #ffec74;
    color: black;
    font-weight: bolder;
    font-size: 20px;
}

.buttonDisplay {
    font-family: lcd;
    background: #1e0d0d;
    border: 2px solid #ffec74;
    color: red;
    text-shadow: 0 0 10px rgba(243, 4, 88, 0.7),
    0 0 20px rgba(243, 4, 88, 0.5),
    0 0 30px rgba(243, 4, 88, 0.3);
    font-weight: 500;
    text-align: right;
    font-size: 28px;
    height: 4vh;
    padding-right: 2px; /* 根据需要调整这个值 */
}

.machine_icon {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 创建7列 */
    grid-template-rows: repeat(7, 1fr); /* 创建7行 */
    width: 77%; /* 占满父容器宽度 */
    margin-right: 12%;
    margin-left: 10%;
    height: 70%; /* 占满父容器高度 */
}

.grid-item {
    background: rgba(255, 255, 255, 0.68);
    border: 3px solid #dc5d03;
}

.grid-item img {
    width: 10.5vw;
    height: 10vw;
    margin-top: 3px;
}

.AB {
    margin-left: -3px;
}

.AC {
    margin-left: -3px;

}

.BA {
    margin-top: -3px;
}

.choseElement {
    background: #ffffff;
    /* 添加外部阴影 */
    box-shadow: 0 3px 8px rgba(255, 46, 46, 1), /* 模糊半径和透明度产生发光效果 */ 0 0 15px rgba(255, 46, 46, 1); /* 第二层阴影，更大的模糊半径和更低的透明度 */

}

.machine_button_lineOne, .machine_button_lineTwo {
    display: flex;
}

.machine_total_button {
    flex: 1;
}

.machine_button_title {
    font-weight: bolder;
    margin-left: 10%;
    border-radius: 5px;
    width: 80%;
    height: 2.5vh;
    background: linear-gradient(to bottom, yellow, #ff6a00, #ff6a00, #ff6a00, yellow); /* 从上到下的黄橙黄渐变 */
}

.machine_real_button {
    background: black;
    height: 11vw;
    width: 11vw;
    border-radius: 30px;
}

.machine_button_lineTwo_icon {
    border: 2px double #d25d00;
    background: white;
    height: 10vw;
}

.coin_flip {
    position: absolute;
    right: 0;
    top: 10px;
}

.coin_flip img {
    width: 10vw;
    height: 7vh;
}

.machine_screen_middle_display {
    position: absolute;
    background: black;
    height: 4vh;
    width: 20vw;
    border: #e99036 2px solid;
    left: 40%;
    text-align: center;
    top: 40%;
    font-family: lcd;
    color: red;
    font-size: 30px;
}

.machine_screen_middle_dot_enable {
    position: absolute;
    width: 8vw;
    height: 8vw;
    border-radius: 30px;
    top: 33%;
    border: #e99036 2px solid;
    background: rgba(252, 38, 38, 0.76);
    left: 45%;
    box-shadow: 0 0 30px #ff4524; /* 添加红色外发光效果 */

}

.machine_screen_middle_dot_unable {
    position: absolute;
    width: 8vw;
    height: 8vw;
    border-radius: 30px;
    top: 33%;
    border: #e99036 2px solid;
    background: rgba(255, 255, 255, 0.5);
    left: 45%;
}
</style>
