export const game = {
    currentScene: 'start',
    scenes: {
        start: {
            text: '你出生了',
            options: [
                {text: '长大', next: '五岁了'},
            ]
        },
        五岁了: {
            text: '五岁了，你打算',
            options: [
                {text: '上幼儿园', next: '上幼儿园'},
                {text: '窝在家里', next: '窝在家里'},
            ]
        },
        上幼儿园: {
            text: '到了全是同龄人的幼儿园的你',
            options: [
                {text: '无恶不作', next: '无恶不作'},
                {text: '听话懂事', next: '听话懂事'},
            ]
        },
        无恶不作: {
            text: '无恶不作的你有一天',
            options: [
                {text: '拿虫子吓别的小孩', next: '拿虫子吓别的小孩'},
                {text: '扯小女孩头发', next: '扯小女孩头发'},
                {text: '溜出幼儿园', next: '溜出幼儿园'},
            ]
        },
        拿虫子吓别的小孩: {
            text: '你抓了一只虫子，想要吓吓别的小孩。',
            options: [
                {text: '把虫子丢到小孩身上', next: '把虫子丢到小孩身上'},
                {text: '放到倒霉蛋的铅笔盒里', next: '放到倒霉蛋的铅笔盒里'}
            ]
        },
        把虫子丢到小孩身上: {
            text: '小孩被你吓着了，当天就发烧了回家了，此时的你',
            options: [
                {text: '悔恨交加', next: '回家坦白放虫子'},
                {text: '心安理得无所吊谓', next: '无所吊谓放虫子'},
            ]
        },
        回家坦白放虫子: {
            text: '你内心煎熬，回家后准备跟爸爸妈妈说这件事',
            options: [
                {text: '一五一十坦白', next: '一五一十坦白'},
                {text: '避重就轻的提一嘴', next: '避重就轻的提一嘴'},
            ]
        }, 一五一十坦白: {
            text: '你不敢撒谎，一五一十的跟爸妈说了这件事',
            options: [
                {text: '挨了一顿打', next: '爸妈给你一顿打'}
            ]
        }, 爸妈给你一顿打: {
            text: '挨完打后爸妈当晚就带着你去小孩家里道歉',
            options: [
                {text: '所幸小孩没事', next: '所幸小孩没事'}
            ]
        }, 所幸小孩没事: {
            text: '挨了一顿打的你，此时决定',
            options: [
                {text: '越想越气晚上不吃饭了', next: '越想越气晚上不吃饭了'},
                {text: '流着眼泪背乘法口诀表', next: '流着眼泪背乘法口诀表'}
            ]
        }, 越想越气晚上不吃饭了: {
            text: '你决定不吃晚饭来表达抗议',
            options: [
                {text: '又被打了一顿', next: '生气睡觉'},
            ]
        }, 生气睡觉: {
            text: '第二天去幼儿园遇到了昨天被你丢虫子的小孩了，他朝你骂了几句',
            options: [
                {text: '直接冲拳出击，打在一起', next: '跟小孩打在一起'},
                {text: '懒得理他', next: '懒得搭理小孩'},
                {text: '你骂了回去', next: '对小孩骂了回去'},
            ]
            //TODO TODO TODO ODO TODO TODO ODO TODO TODO ODO TODO TODO ODO TODO TODO
        }, 流着眼泪背乘法口诀表: {
            text: '你爸妈看到了你挨完打还在背乘法口诀表心疼不已，吃过晚饭借着遛弯带你去楼下小店买了你最喜欢的奥特曼卡片',
            options: [
                {text: '你已经知道错了', next: '知道错了第二天去幼儿园'},
                {text: '你觉得这是因祸得福', next: '给收藏奥特曼卡重新整理一下'},
            ]
        }, 知道错了第二天去幼儿园: {
            text: '第二天来到了有幼儿园，然后你决定',
            options: [
                {text: '跟他正式道歉', next: '送给他一张你最喜欢的奥特曼卡片'},
                {text: '跟他主动示好', next: '揶揄的问他没事吧'},
            ]
            //TODO TODO TODO ODO TODO TODO ODO TODO TODO ODO TODO TODO ODO TODO TODO
        }, 给收藏奥特曼卡重新整理一下: {
            text: '你带着卡开开心心的回家了',
            options: [
                {text: '整理了一遍自己的卡', next: '整理了一遍自己的卡'},
            ]
        }, 整理了一遍自己的卡: {
            text: '你决定明天带到幼儿园去炫耀你的',
            options: [
                {text: '奥特曼卡', next: '炫耀奥特曼卡'},
                {text: '怪兽卡', next: '炫耀怪兽卡'},
            ]
            //TODO TODO TODO ODO TODO TODO ODO TODO TODO ODO TODO TODO ODO TODO TODO
        },避重就轻的提一嘴: {
            text: '你觉得没有多大事，就吃饭的时候随口说了一下，爸妈听到后',
            options: [
                {text: '爸妈继续吃饭压根没听到', next: '爸妈继续吃饭压根没听到'},
                {text: '说了你几句，让你以后不要捉弄别的小孩了', next: '让你以后不要捉弄别的小孩了'},
            ]
        }, 爸妈继续吃饭压根没听到: {
            text: '此时爸妈正在聊天，压根没搭理你',
            options: [
                {text: '第二天继续上学', next: '第二天继续上学'},
            ]
        }, 让你以后不要捉弄别的小孩了: {
            text: '爸妈听到后微微皱眉，让你以后不要欺负小孩了，后面也没说什么了',
            options: [
                {text: '无事发生，上床睡觉', next: '上床睡觉'},
            ]
        }, 无所吊谓放虫子: {
            text: '你决定调皮一下，扯了一下旁边小女孩的头发。',
            options: [
                {text: '小女孩没搭理你', next: '小女孩没搭理你'},
                {text: '小女孩哇哇大哭', next: '小女孩哇哇大哭'},
            ]
        }, 放到倒霉蛋的铅笔盒里: {
            text: '你决定调皮一下，扯了一下旁边小女孩的头发。',
            options: [
                {text: '小女孩没搭理你', next: '小女孩没搭理你'},
                {text: '小女孩哇哇大哭', next: '小女孩哇哇大哭'},
            ]
        },
        扯小女孩头发: {
            text: '你决定调皮一下，扯了一下旁边小女孩的头发。',
            options: [
                {text: '小女孩没搭理你', next: '小女孩没搭理你'},
                {text: '小女孩哇哇大哭', next: '小女孩哇哇大哭'},
            ]
        },
        溜出幼儿园: {
            text: '你想溜出去，你决定从',
            options: [
                {text: '从大门溜出去', next: '从大门溜出去'},
                {text: '从操场的围栏翻出去', next: '从操场的围栏翻出去'},
            ]
        },
        听话懂事: {
            text: '有一天放学家里还没人来接，你决定',
            options: [
                {text: '继续等等', next: '继续等等'},
                {text: '自己回去', next: '自己回去'},
            ]
        },
        继续等等: {
            text: '你决定再等一会儿，看看是否有人来接你。',
            options: [
                {text: '', next: ''}
            ]
        },
        自己回去: {
            text: '你决定自己走回家。',
            options: [
                {text: '', next: ''}
            ]
        },
        窝在家里: {
            text: '在小区里抢比自己年龄小的小孩玩具车',
            options: [
                {text: '被小朋友打了', next: '被小朋友打了'},
                {text: '把小孩车霸占了', next: '把小孩车霸占了'},
                {text: '人家父母到家里告状', next: '人家父母到家里告状'},
            ]
        },
        被小朋友打了: {
            text: '一个小朋友因为你抢了他的玩具车，所以打了你一下。',
            options: [
                {text: '', next: ''}
            ]
        },
        把小孩车霸占了: {
            text: '你发现那个比你小的小孩把你的玩具车抢走了。',
            options: [
                {text: '', next: ''}
            ]
        },
        人家父母到家里告状: {
            text: '那个小孩的父母来到你家，向你的父母告状。',
            options: [
                {text: '', next: ''}
            ]
        }
    }
};