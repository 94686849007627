<template>
    <div>
        <back></back>
        <div class="ultramanTitle" :class="{ transparent: !isShow}" @click="isShow=!isShow">
            <img src="@/assets/img/ultramanBg.png">
        </div>
        <div class="grid-container">

            <div v-for="item in animatedCharacterList" :key="item.id" class="grid-item" @click="getDetail(item)"
                 :class="{ transparent: !isShow}">
                <div class="top-part">
                    <img :src="item.icon">
                </div>
                <div class="bottom-part">
                    <div>{{ item.name }}</div>
                </div>
            </div>
        </div>
        <div v-if="!isShow && animatedCharacter !== null" @click="isShow=!isShow" class="ultramanDesc">
            <div style="display: flex">
                <div>
                    <img :src="animatedCharacter.icon" style="height: 100px">
                </div>
                <div style="display: flex;align-content: center;align-items: center;padding-left: 50px">
                    <div style="font-weight: bolder;font-size: 20px">{{ animatedCharacter.name }}</div>
                    <div class="tag-style">{{ animatedCharacter.description }}{{animatedCharacter.source}}</div>
                </div>
                <div style="position: fixed;margin-top: -50px;left: 83%;font-size: 10px;color: #8f8f8f">再次点击关闭
                </div>

            </div>
            <img :src="animatedCharacter.imgUrl">
            <div>{{ animatedCharacter.introduce }}</div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {getAnimatedCharacterList, getUltramanList} from "@/api/yunoApi";

export default {
    name: "animatedCharacters",
    components: {Back},
    data() {
        return {
            animatedCharacter: {},
            isShow: true,
            pageNum: 1,
            pageSize: 28,
            animatedCharacterList: [],
            isFetching: false // 新增变量控制加载状态
        };
    },
    methods: {
        getDetail(item) {
            this.isShow = !this.isShow
            this.animatedCharacter = item

        },
        getAnimatedCharacterList() {
            if (this.isFetching) return; // 如果正在加载，则不执行
            this.isFetching = true; // 设置加载状态为true
            getAnimatedCharacterList(this.pageNum, this.pageSize).then(res => {
                this.animatedCharacterList = [...this.animatedCharacterList, ...res.list];
                this.isFetching = false; // 请求完成后设置加载状态为false
            });
        },
        handleScroll() {
            if (this.isFetching) return; // 如果正在加载，则不执行
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 200) { // 距离底部100像素时触发
                this.pageNum++; // 增加页码
                this.getAnimatedCharacterList(); // 请求下一页数据
            }
        }
    },
    mounted() {
        if (window.innerWidth > 600) {
            this.pageSize = 70;
            this.getAnimatedCharacterList(); // 初始加载数据
        } else {
            this.getAnimatedCharacterList(); // 初始加载数据
        }
        window.addEventListener('scroll', this.handleScroll); // 监听滚动事件
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll); // 组件销毁时移除事件监听
    }
}
</script>

<style scoped>

.ultramanTitle img {
    border-radius: 5px;
    width: 100%;
    height: 8%;
    background-color: #c00000;
}

.ultramanTitle {
    margin-top: 40px;
}

.grid-container {
    background: rgba(47, 158, 217, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.grid-item {
    width: 100px;
    margin: 1px;
    box-sizing: border-box;
    border: 1px solid #989191;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column; /* 子元素垂直排列 */
    overflow: hidden;
}

.top-part, .bottom-part {
    width: 100%; /* 与父容器同宽 */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-part {
    height: 100px; /* 第一部分的高度 */
    background-color: #ddd; /* 可以自定义背景颜色 */
}

.top-part img {
    height: 100px; /* 设置图片的高度 */
    width: 100px; /* 保持图片的宽高比，你也可以根据需要调整宽度 */
    object-fit: cover; /* 保持图片内容的覆盖，也可以选择其他值如 'contain' */
}

.bottom-part {
    height: 30px; /* 第二部分的高度 */
    background-color: #e5e5e5; /* 可以自定义背景颜色，与第一部分区分 */
}

/* 鼠标悬停效果 */
.grid-item:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.ultramanDesc {
    position: fixed; /* 固定定位 */
    top: 50%; /* 垂直方向上居中 */
    left: 50%; /* 水平方向上居中 */
    transform: translate(-50%, -50%); /* 使用CSS的transform属性来居中 */
    z-index: 1000; /* 确保它在页面上其他元素的上方 */
    padding: 10px; /* 内边距 */
    background: rgba(255, 255, 255, 0.55); /* 背景颜色 */
    border-radius: 8px; /* 边框圆角 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 阴影效果 */
    width: 90%; /* 宽度 */
    max-width: 600px; /* 最大宽度 */
}

.ultramanDesc img {
    max-width: 100%;
}

.tag-style {
    display: inline-block;
    padding: 4px 8px;
    font-size: 0.9em;
    font-weight: bolder;
    color: #8fa6f3;
    background-color: rgba(208, 201, 201, 0.4);
    border-radius: 12px;
    text-transform: uppercase; /* 使文本大写，看起来更像标签 */
    letter-spacing: 1px; /* 增加字母间距 */
    margin: 2px; /* 标签之间的间距 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
    cursor: pointer; /* 鼠标悬停时显示手形图标 */
}

.transparent {
    opacity: 0.2;
    transition: opacity 0.3s ease; /* 平滑过渡效果 */
}
</style>