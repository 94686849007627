import axios from "axios";

import router from "@/router";

const service = axios.create({
    timeout: 16000,
})


service.interceptors.request.use(config => {

        if (localStorage.getItem('token')) {
            config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
            config.headers['Content-Type'] = 'application/json';
        }
        return config
    }, error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {

        if (response.data.code === 200 && response.status === 200) {
            return response.data
        }
        if (response.data.code === 401 || response.status === 401) {
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token')
            }
            return Promise.resolve(response)
        }
        if (response.data.code === 500 || response.status === 500) {
            return Promise.reject(response.data)
        }
        if (response.data.code === 405 || response.status === 405) {
            return Promise.resolve(response.data)
        }

        return Promise.resolve(response.data);
    },
    error => {

        return Promise.reject(error)
    })


export default service
