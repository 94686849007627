<template>
    <div class="criminalLaw_container">
        <back></back>
        <div class="criminalLaw_search">
            <input v-model="keyword" placeholder="查找">
            <div class="button" @click="searchLaw()">
                <img src="@/assets/img/search.png">
            </div>
        </div>
        <div class="lawType">
            <div class="law_type" v-if="type === 1" @click="changeType()">刑法⇌</div>
            <div class="law_type" v-if="type === 2" @click="changeType()">劳动法⇌</div>
        </div>
        <div class="lawList" v-if="!isSearch">
            <div v-if="type === 1">
                <div class="lawTitle">中华人民共和国刑法</div>
                <a class="lawExplain" href="https://baike.baidu.com/item/中华人民共和国刑法/721359?fr=ge_ala"
                   target="_blank">（根据《刑法修正案(十二)》修正）</a>
            </div>
            <div v-if="type === 2">
                <div class="lawTitle">中华人民共和国劳动法</div>
                <a class="lawExplain" href="https://baike.baidu.com/item/中华人民共和国劳动法/207140?fr=aladdin"
                   target="_blank">根据2018年12月29日第十三届全国人民代表大会常务委员会第七次会议《关于修改〈中华人民共和国劳动法〉等七部法律的决定》第二次修正</a>
            </div>
            <div v-for="item in lawList" key="item.id" class="lawInfo1">{{ item.info }}</div>
            <div class="changeLaw" @click="changeLaw()">换一些</div>
        </div>
        <div class="searchLawList" v-if="isSearch">
            <div class="criminalLawCloseButton" @click="closeSearch()">✘</div>
            <div v-for="item in searchLawList" key="item.id" class="lawInfo2">{{ item.info }}</div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {fuzzyGetCriminalLaw, getRandomCriminalLaws} from "@/api/yunoApi";

export default {
    name: "law",
    components: {Back},

    data() {
        return {
            lawList: [],
            searchLawList: [],
            keyword: '',
            isSearch: false,
            type: 1,
        }
    },
    methods: {
        changeType(){
          if (this.type === 1){
              this.type =2
          }else {
              this.type = 1
          }
            this.getCriminalLaws()
        },
        changeLaw() {
            this.getCriminalLaws()
        },
        getCriminalLaws() {
            getRandomCriminalLaws(this.type).then(res => {
                this.lawList = res
            })
        }, searchLaw() {
            this.isSearch = true
            const keyWord = {info: this.keyword, id: this.type}
            fuzzyGetCriminalLaw(keyWord).then(res => {
                this.searchLawList = res
            })
        }, closeSearch() {
            this.keyword = ''
            this.searchLawList = []
            this.isSearch = false
        }
    },
    mounted() {
        this.getCriminalLaws()
    }
}
</script>

<style scoped>
.criminalLaw_search {
    display: flex;
    position: absolute;
    margin-top: -8px;
    right: 10px;
}

.lawType {
    display: flex;
    position: absolute;
    left: 30%;
    gap: 20px;
}

.law_type {
    background: #ef9898;
    border: 3px solid rgba(0, 0, 0, 0.44);
    border-radius: 5px;
    margin-top: -8px;
    padding: 3px;
    color: white;
    cursor: pointer;
}

.button {
    cursor: pointer;
    margin-top: 1px;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    background-color: #fc8471;
}

.button :hover {
    scale: 0.9;
}

.button img {
    height: 28px;
    width: 28px;
    margin-top: 4px;
}

.criminalLaw_search input {
    height: 30px;
    color: #8a3939;
    font-weight: bold;
    border: rgba(40, 0, 0, 0.16) 1px solid;
}

.lawTitle {
    font-family: 微软雅黑;
    font-weight: bolder;
    color: red;
    font-size: 30px;
}

.lawExplain {
    font-weight: lighter;
    color: #1154ff;
    text-decoration: underline; /* 添加下划线 */
}

.lawList {
    margin-top: 50px;
}

.searchLawList {
    margin-top: 50px;

}

.lawInfo1 {
    border: #2a738d 1px solid;
    text-align: left;
    padding: 20px 8px 20px 8px;
    white-space: pre-line; /* 允许属性中定义的换行和空白被保留 */
}

.lawInfo2 {
    border: #742a8d 1px solid;
    text-align: left;
    padding: 20px 8px 20px 8px;
    white-space: pre-line; /* 允许属性中定义的换行和空白被保留 */
}

.criminalLawCloseButton {
    position: absolute;
    right: 10px;
    background: red;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    font-size: 25px;
    cursor: pointer;
}

.criminalLawCloseButton :hover {
    scale: 0.9;
}

.changeLaw {
    margin-left: calc(50% - 50px);
    margin-top: 30px;
    width: 100px;
    height: 100px; /* 添加高度属性，使其与宽度相匹配 */
    min-height: 100px;
    border-radius: 50%;
    border: none;
    background-color: #fa7462;
    color: rgba(255, 255, 255, 1); /* 稍微增加文字的透明度 */
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 40px;
    box-shadow: 0 3px 6px rgba(0, 123, 255, 0.5); /* 按钮阴影 */
    line-height: 100px; /* 使文本垂直居中，值与高度相同 */
    text-align: center; /* 使文本水平居中 */
}
</style>