<template>
    <div>
        <back></back>
        <div class="author"><input type="text" v-model="author" @blur="handleInputEnd" placeholder="作者"></div>
        <div class="billTable">
            <table>
                <thead>
                <tr>
                    <th>总价</th>
                    <th>数量</th>
                    <th>名字</th>
                    <th>单价</th>
                    <th>售价</th>
                    <th>已售</th>
                    <th>剩余</th>
                    <th>毛利</th>
                    <th>净利</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in billList" :key="item.id">
                    <td>{{ item.cost }}</td>
                    <td>{{ item.count }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.costPrice }}</td>
                    <td>{{ item.sellPrice }}</td>
                    <td>{{ item.sold }}</td>
                    <td>{{ item.remainder }}</td>
                    <td>{{ item.grossProfit }}</td>
                    <td>{{ item.netProfit }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="billAddButton" @click="addBill()">+</div>

        <div class="statistics">
            <table>
                <thead>
                <tr>
                    <th>总价</th>
                    <th>数量</th>
                    <th>已售</th>
                    <th>剩余</th>
                    <th>毛利</th>
                    <th>净利</th>
                    <th>总盈利</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{ statistics.totalCost.toFixed(2) }}</td>
                    <td>{{ statistics.totalCount }}</td>
                    <td>{{ statistics.totalSold }}</td>
                    <td>{{ statistics.totalRemainder }}</td>
                    <td>{{ statistics.totalGrossProfit.toFixed(2) }}</td>
                    <td>{{ statistics.totalNetProfit.toFixed(2) }}</td>
                    <td>{{ statistics.totalProfit.toFixed(2) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {addBill, getBillList} from "@/api/yunoApi";

export default {
    name: "Bill",
    components: {Back},
    data() {
        return {
            billList: [
                {
                    id: 1,
                    cost: 5.98,
                    count: 2,
                    name: '伸缩刀',
                    costPrice: 2.99,
                    sellPrice: 10,
                    sold: 0,
                    remainder: 2,
                    grossProfit: 0,
                    netProfit: 0
                },
                {
                    id: 2,
                    cost: 58.38,
                    count: 12,
                    name: '光剑',
                    costPrice: 4.86,
                    sellPrice: 15,
                    sold: 0,
                    remainder: 12,
                    grossProfit: 0,
                    netProfit: 0
                },
                {
                    id: 3,
                    cost: 19.2,
                    count: 3,
                    name: '月球灯',
                    costPrice: 6.4,
                    sellPrice: 15,
                    sold: 0,
                    remainder: 3,
                    grossProfit: 0,
                    netProfit: 0
                }

            ],
            bill: {
                id: 0,
                cost: 0,
                count: 0,
                name: '',
                costPrice: 0,
                sellPrice: 0,
                sold: 0,
                remainder: 0,
                grossProfit: 0,
                netProfit: 0,
                user: null
            },
            statistics: {
                totalCost: 0,
                totalCount: 0,
                totalSold: 0,
                totalRemainder: 0,
                totalGrossProfit: 0,
                totalNetProfit: 0,
                totalProfit: 0
            },
            author: localStorage.getItem('billName') ? localStorage.getItem('billName') : null
        };
    }, methods: {
        handleInputEnd() {
            if (this.author === '') {
                localStorage.removeItem('billName')
            } else {
                localStorage.setItem('billName', this.author)
                this.getBillList()
            }
        },
        addBill() {
            this.bill.user = this.author
            addBill(this.bill).then(res => {
                this.bill.id = res
            })
            this.billList.push(this.bill)
            this.bill = {
                id: 0,
                cost: 0,
                count: 0,
                name: '',
                costPrice: 0,
                sellPrice: 0,
                sold: 0,
                remainder: 0,
                grossProfit: 0,
                netProfit: 0,
                author: null
            }
        },
        getBillList() {
            if (this.author === '') {
                return;
            }
            const bill = {user: this.author}
            getBillList(bill).then(res => {
                this.billList = res
            })
        },
        calculateStatistics() {
            // 初始化统计数据
            let totalCost = 0;
            let totalCount = 0;
            let totalSold = 0;
            let totalRemainder = 0;
            let totalGrossProfit = 0;
            let totalNetProfit = 0;
            let totalProfit = 0;
            // 遍历 billList 数组
            this.billList.forEach(item => {
                if (item.count > 0) {
                    item.costPrice = (item.cost / item.count).toFixed(2)
                } else {
                    item.costPrice = 0;
                }
                item.remainder = item.count - item.sold     //剩余数量 = 总数 - 已售卖数量
                item.grossProfit = item.sold * item.sellPrice      //总毛利润 = 售价 * 已售卖数量
                item.netProfit = item.grossProfit - (item.costPrice * item.sold)      //总净利润 = 毛利润 -（进价 * 已售卖数量）
                totalCost += item.cost;           // 累加总成本
                totalCount += item.count;         // 累加总数量
                totalSold += item.sold;           // 累加已售数量
                totalRemainder += item.remainder; // 累加剩余数量
                totalGrossProfit += item.grossProfit; // 累加毛利
                totalNetProfit += item.netProfit;     // 累加净利
            });
            totalProfit = totalGrossProfit - totalCost
            // 将计算结果赋值给 statistics 对象
            this.statistics = {
                totalCost,
                totalCount,
                totalSold,
                totalRemainder,
                totalGrossProfit,
                totalNetProfit,
                totalProfit
            };
        }
    }, mounted() {
        this.getBillList()
        this.calculateStatistics()
    }, watch: {
        billList(newVal) {
            this.calculateStatistics()
        }
    }
};
</script>

<style scoped>
.billTable th {
    background-color: #ffd5d5;

}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 1px;
    text-align: left;
}

.billTable {
    margin-top: 30px;
}

.statistics th {
    background-color: #d8b0ff;
}

input {
    margin-top: 3px;
    font-size: 16px;
    height: 20px;
    width: 60px;
    border: 1px solid rgba(243, 243, 243, 0);
    background-color: rgba(243, 243, 243, 0);
}

.billAddButton {
    cursor: pointer;
    font-size: 24px; /* 可以根据需要调整字体大小 */
    font-weight: lighter; /* 加粗字体 */
    color: #754343;
    background-color: rgba(1, 122, 143, 0.2);
    width: 35px;
    position: relative;
    left: 45%;
    border-radius: 100px;
}

.author {
    margin-top: -5px;
    position: absolute;
    right: 10%;
}

</style>