<template>
    <div style="background: rgba(239,239,239,0.58);height: 98vh">
        <div class="loadingDiv" :class="{'openDialog' :isChoseIconIng === true}">
            <div class="qr_info">
                <div class="qr_title">
                    <div class="qrTitle" @click="updateSelectType(1)" :class="{'selectType':selectType===1}">文本</div>
                    <div class="qrTitle" @click="updateSelectType(2)" :class="{'selectType':selectType===2}">网址</div>
                    <div class="qrTitle" @click="updateSelectType(3)" :class="{'selectType':selectType===3}">图片</div>
                    <div class="qrTitle" @click="updateSelectType(4)" :class="{'selectType':selectType===4}">音视频
                    </div>
                    <div class="qrTitle" @click="updateSelectType(5)" :class="{'selectType':selectType===5}">
                        文件互传
                    </div>
                </div>
                <div v-if="selectType===1">
                    <div class="qr-textarea">
                        <textarea class="textarea" v-model="qrText" placeholder="请输入文字内容"></textarea>
                    </div>
                    <div class="GenerateCode">
                        <div class="codeButton" @click="isShow=true">生成二维码</div>
                    </div>
                    <div class="qrSetting" v-if="">
                        <div class="qrField">
                            <div style="width: 100px">码颜色:</div>
                            <div class="border" @click="updateAdjustSettings(1)">
                                <div v-bind:style="{ background: colorDark }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码背景色:</div>
                            <div class="border" @click="updateAdjustSettings(2)">
                                <div v-bind:style="{ background: colorLight }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5; float: right;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码边距:</div>
                            <div class="border" @click="updateAdjustSettings(3)">
                                {{ (margin - 5) / 5 }}个色块
                                <img src="@/assets/down-arrow.png" style="width: 15px; opacity: 0.5;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">容错率:</div>
                            <div class="border" @click="updateAdjustSettings(4)">
                                {{ faultList[correctLevel].name }}
                                <img src="@/assets/down-arrow.png"
                                     style="width: 15px;opacity: 0.5;">

                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">尺寸大小:</div>
                            <div class="border" @click="updateAdjustSettings(5)">
                                {{ qrSize * 0.2 }}*{{ qrSize * 0.2 }}mm
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码点大小:</div>
                            <div class="border" @click="updateAdjustSettings(6)">
                                {{ (dotScale * 10).toFixed(0) }}
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">

                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 1">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorDark(item)"></div>
                        </div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 2">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorLight(item)"></div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 3">
                        <div v-for="item in marginList">
                            <div class="settingBlock" @click="updateMargin(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 4">
                        <div v-for="item in faultList">
                            <div class="settingBlock" @click="updateCorrectLevel(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 5">
                        <div v-for="item in sizeList">
                            <div class="settingBlock2" @click="updateSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 6">
                        <div v-for="item in dotSizeList">
                            <div class="settingBlock2" @click="updateDotSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="selectType===2">
                    <div class="qr_urlDesc">网址直接生成二维码，生成后无法修改</div>
                    <input class="qr_urlInput" placeholder="https://" v-model="qrText">
                    <div class="GenerateCode">
                        <div class="codeButton" @click="generateUrl()">生成二维码</div>
                    </div>
                    <div class="qrSetting">
                        <div class="qrField">
                            <div style="width: 100px">码颜色:</div>
                            <div class="border" @click="updateAdjustSettings(1)">
                                <div v-bind:style="{ background: colorDark }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码背景色:</div>
                            <div class="border" @click="updateAdjustSettings(2)">
                                <div v-bind:style="{ background: colorLight }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5; float: right;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码边距:</div>
                            <div class="border" @click="updateAdjustSettings(3)">
                                {{ (margin - 5) / 5 }}个色块
                                <img src="@/assets/down-arrow.png" style="width: 15px; opacity: 0.5;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">容错率:</div>
                            <div class="border" @click="updateAdjustSettings(4)">
                                {{ faultList[correctLevel].name }}
                                <img src="@/assets/down-arrow.png"
                                     style="width: 15px;opacity: 0.5;">

                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">尺寸大小:</div>
                            <div class="border" @click="updateAdjustSettings(5)">
                                {{ qrSize * 0.2 }}*{{ qrSize * 0.2 }}mm
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码点大小:</div>
                            <div class="border" @click="updateAdjustSettings(6)">
                                {{ (dotScale * 10).toFixed(0) }}
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">

                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 1">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorDark(item)"></div>
                        </div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 2">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorLight(item)"></div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 3">
                        <div v-for="item in marginList">
                            <div class="settingBlock" @click="updateMargin(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 4">
                        <div v-for="item in faultList">
                            <div class="settingBlock" @click="updateCorrectLevel(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 5">
                        <div v-for="item in sizeList">
                            <div class="settingBlock2" @click="updateSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 6">
                        <div v-for="item in dotSizeList">
                            <div class="settingBlock2" @click="updateDotSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="selectType===3">
                    <div class="qr_urlDesc">保存时间为一天</div>
                    <label class="qr_uploadImg" for="file-upload2">上传图片</label>
                    <input
                            type="file"
                            id="file-upload2"
                            @change="onFileChange2"
                            accept="image/*"
                            class="file-upload"
                            style="display: none;"/>
                    <div class="qrSetting">
                        <div class="qrField">
                            <div style="width: 100px">码颜色:</div>
                            <div class="border" @click="updateAdjustSettings(1)">
                                <div v-bind:style="{ background: colorDark }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码背景色:</div>
                            <div class="border" @click="updateAdjustSettings(2)">
                                <div v-bind:style="{ background: colorLight }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5; float: right;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码边距:</div>
                            <div class="border" @click="updateAdjustSettings(3)">
                                {{ (margin - 5) / 5 }}个色块
                                <img src="@/assets/down-arrow.png" style="width: 15px; opacity: 0.5;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">容错率:</div>
                            <div class="border" @click="updateAdjustSettings(4)">
                                {{ faultList[correctLevel].name }}
                                <img src="@/assets/down-arrow.png"
                                     style="width: 15px;opacity: 0.5;">

                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">尺寸大小:</div>
                            <div class="border" @click="updateAdjustSettings(5)">
                                {{ qrSize * 0.2 }}*{{ qrSize * 0.2 }}mm
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码点大小:</div>
                            <div class="border" @click="updateAdjustSettings(6)">
                                {{ (dotScale * 10).toFixed(0) }}
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">

                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 1">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorDark(item)"></div>
                        </div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 2">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorLight(item)"></div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 3">
                        <div v-for="item in marginList">
                            <div class="settingBlock" @click="updateMargin(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 4">
                        <div v-for="item in faultList">
                            <div class="settingBlock" @click="updateCorrectLevel(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 5">
                        <div v-for="item in sizeList">
                            <div class="settingBlock2" @click="updateSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 6">
                        <div v-for="item in dotSizeList">
                            <div class="settingBlock2" @click="updateDotSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="selectType===4">
                    <div class="qr_urlDesc">保存时间为一天</div>
                    <label class="qr_uploadImg" style="background: #ff7b7b;font-weight: bolder" for="file-upload2">选择音频/视频</label>
                    <input
                            type="file"
                            id="file-upload2"
                            @change="onFileChange2"
                            accept="audio/*,video/*"
                            class="file-upload"
                            style="display: none;"/>
                    <div class="qrSetting">
                        <div class="qrField">
                            <div style="width: 100px">码颜色:</div>
                            <div class="border" @click="updateAdjustSettings(1)">
                                <div v-bind:style="{ background: colorDark }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码背景色:</div>
                            <div class="border" @click="updateAdjustSettings(2)">
                                <div v-bind:style="{ background: colorLight }" class="colorBorder"></div>
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5; float: right;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码边距:</div>
                            <div class="border" @click="updateAdjustSettings(3)">
                                {{ (margin - 5) / 5 }}个色块
                                <img src="@/assets/down-arrow.png" style="width: 15px; opacity: 0.5;">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">容错率:</div>
                            <div class="border" @click="updateAdjustSettings(4)">
                                {{ faultList[correctLevel].name }}
                                <img src="@/assets/down-arrow.png"
                                     style="width: 15px;opacity: 0.5;">

                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">尺寸大小:</div>
                            <div class="border" @click="updateAdjustSettings(5)">
                                {{ qrSize * 0.2 }}*{{ qrSize * 0.2 }}mm
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">
                            </div>
                        </div>
                        <div class="qrField">
                            <div style="width: 100px">码点大小:</div>
                            <div class="border" @click="updateAdjustSettings(6)">
                                {{ (dotScale * 10).toFixed(0) }}
                                <img src="@/assets/down-arrow.png" style="width: 15px;opacity: 0.5">

                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 1">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorDark(item)"></div>
                        </div>
                    </div>
                    <div class="colorBoard" v-if="adjustSettings === 2">
                        <div v-for="item in colorList" v-bind:style="{ background: item }">
                            <div class="colorBlock" @click="updateColorLight(item)"></div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 3">
                        <div v-for="item in marginList">
                            <div class="settingBlock" @click="updateMargin(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 4">
                        <div v-for="item in faultList">
                            <div class="settingBlock" @click="updateCorrectLevel(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 5">
                        <div v-for="item in sizeList">
                            <div class="settingBlock2" @click="updateSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="options" v-if="adjustSettings === 6">
                        <div v-for="item in dotSizeList">
                            <div class="settingBlock2" @click="updateDotSize(item.value)">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="selectType===5" style="justify-content: center">
                    <div>文件列表
                        <div v-for="item in fileList" v-if="selectType===5"
                             style="justify-content: center;align-items: center;">
                            <div style="display: flex">
                                <div>{{ item.fileName }}</div>
                                <div style="margin-left: 20px">{{ item.size }}</div>
                                <img @click="downLoad(item)" src="@/assets/img/download.png"
                                     style="width: 20px;margin-left: 20px;cursor: pointer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectType!== 5">
                <div class="qr_img" v-if="isShow">
                    <vue-qr
                            :text="qrText"
                            :size="qrSize"
                            :colorDark="colorDark"
                            :colorLight="colorLight"
                            :margin="margin"
                            :correctLevel="correctLevel"
                            :logoSrc="logoSrc"
                            :whiteMargin=true
                            :dotScale="dotScale"
                            ref="vueQr"
                            class="qrImg"></vue-qr>
                    <div class="qr_desc">
                        {{
                        correctLevel === 0 ? '7%' : (correctLevel === 1 ? '15%' : (correctLevel === 2 ? '25%' : '30%'))
                        }}容错，{{ ((dotScale - 0.1) * 10).toFixed(0) }}码点大小，{{ qrSize }} x {{ qrSize }}px
                    </div>
                    <div class="qr_button" @click="addLogo()" v-if="!isAddLogo">添加Logo</div>
                    <div class="qr_button3" v-if="isAddLogo">
                        <label for="file-upload" class="qr_button4">
                            上传Logo
                        </label>
                        <input
                                type="file"
                                id="file-upload"
                                @change="onFileChange"
                                accept="image/*"
                                class="file-upload"
                                style="display: none;"/>
                        <div class="qr_button4" @click="openChoseIcon()">Logo图库</div>
                    </div>
                    <div class="qr_button" @click="downloadQRCode">下载图片</div>
                    <div class="qr_button2">排版到A4纸上</div>
                </div>
                <div class="qr_img2" v-if="!isShow" @click="isShow=true">此处预览二维码</div>
            </div>
            <div v-if="selectType===5" style="margin-top: 50px">

                <vue-qr
                        :text="'https://www.yunox.cn/#/scanUpload?session='+sessionId"
                        :size="200"
                        :colorDark="'#000000'"
                        :colorLight="'#ffffff'"
                        :margin="10"
                        :correctLevel="0"
                        :dotScale="1"
                        ref="vueQr"
                        class="qrImg"></vue-qr>
                <div>扫码上传文件</div>
            </div>
        </div>
        <div class="dialog" v-if="isChoseIconIng">
            <div class="dialog_title" @click="closeChoseIcon()">x</div>
            <div class="dialog_iconList">
                <div v-for="item in localImg" class="icon" @click="choseIcon(item)">
                    <div>
                        <div class="icon_image">
                            <img :src="item.url">
                        </div>
                        <div class="icon_name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {generateRandomCode2} from "@/utils/generalUtil";
import {connectSocket, sendWSPush} from "@/utils/websocket";
import {getFolderInfo, uploadTempFile} from "@/api/yunoApi";

export default {
    name: "new",

    data() {
        return {
            sessionId: null,
            selectType: 3,
            SocketData: [],
            adjustSettings: 0,
            isChoseColorDark: true,
            isChoseColorLight: true,
            isShow: false,
            isAddLogo: false,
            dotScale: 1,
            selected: 1,
            qrText: '', // 你的字符串内容
            qrSize: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            margin: 10,
            correctLevel: 0,
            // logoSrc: require('@/assets/img/cover/jackpot.png'), // 路径根据你的项目结构调整
            logoSrc: '', // 路径根据你的项目结构调整
            localImg: [
                {name: '微信', url: 'https://www.yunox.cn/icon/logo/微信.png'},
                {name: '企业微信', url: 'https://www.yunox.cn/icon/logo/企业微信.png'},
                {name: '小程序', url: 'https://www.yunox.cn/icon/logo/小程序.png'},
                {name: '微信支付', url: 'https://www.yunox.cn/icon/logo/微信支付.png'},
                {name: '抖音', url: 'https://www.yunox.cn/icon/logo/抖音.png'},
                {name: '微信', url: 'https://www.yunox.cn/icon/logo/微信2.png'},
                {name: '支付宝', url: 'https://www.yunox.cn/icon/logo/支付宝.png'},
                {name: '拼多多', url: 'https://www.yunox.cn/icon/logo/拼多多.png'},
                {name: '淘宝', url: 'https://www.yunox.cn/icon/logo/淘宝.png'},
                {name: '京东', url: 'https://www.yunox.cn/icon/logo/京东.png'},
                {name: '小红书', url: 'https://www.yunox.cn/icon/logo/小红书.png'},
                {name: '闲鱼', url: 'https://www.yunox.cn/icon/logo/闲鱼.png'},
                {name: '知乎', url: 'https://www.yunox.cn/icon/logo/知乎.png'},
                {name: '腾讯文档', url: 'https://www.yunox.cn/icon/logo/腾讯文档.png'},
                {name: '钉钉', url: 'https://www.yunox.cn/icon/logo/钉钉.png'},
                {name: '云闪付', url: 'https://www.yunox.cn/icon/logo/云闪付.png'},
                {name: '麦当劳', url: 'https://www.yunox.cn/icon/logo/麦当劳.png'},
                {name: '分享文件', url: 'https://www.yunox.cn/icon/logo/分享文件.png'},
                {name: '华为', url: 'https://www.yunox.cn/icon/logo/华为.png'},
                {name: '今日头条', url: 'https://www.yunox.cn/icon/logo/今日头条.png'},
                {name: '微博', url: 'https://www.yunox.cn/icon/logo/微博.png'},
                {name: '携程旅行', url: 'https://www.yunox.cn/icon/logo/携程旅行.png'},
                {name: '中国石油', url: 'https://www.yunox.cn/icon/logo/中国石油.png'},
                {name: '星巴克', url: 'https://www.yunox.cn/icon/logo/星巴克1.png'},
                {name: '亚朵', url: 'https://www.yunox.cn/icon/logo/亚朵.png'},
                {name: '国家电网', url: 'https://www.yunox.cn/icon/logo/国家电网.png'},
                {name: '医院', url: 'https://www.yunox.cn/icon/logo/医院.png'},
                {name: '中国交建', url: 'https://www.yunox.cn/icon/logo/中国交建.png'},
                {name: '中国电信', url: 'https://www.yunox.cn/icon/logo/中国电信.png'},
                {name: '中国联通', url: 'https://www.yunox.cn/icon/logo/中国联通.png'},
                {name: '中国铁建', url: 'https://www.yunox.cn/icon/logo/中国铁建.png'},
                {name: '中国移动', url: 'https://www.yunox.cn/icon/logo/中国移动.png'},
                {name: '中国中铁', url: 'https://www.yunox.cn/icon/logo/中国中铁.png'},
                {name: '铁路', url: 'https://www.yunox.cn/icon/logo/铁路.png'},
                {name: '星巴克', url: 'https://www.yunox.cn/icon/logo/星巴克.png'},
                {name: '亚马逊', url: 'https://www.yunox.cn/icon/logo/亚马逊.png'},
                {name: '宜家', url: 'https://www.yunox.cn/icon/logo/宜家.png'},
                {name: '无印良品', url: 'https://www.yunox.cn/icon/logo/无印良品.png'},
                {name: '飞书', url: 'https://www.yunox.cn/icon/logo/飞书.png'},
                {name: '微信支付', url: 'https://www.yunox.cn/icon/logo/微信支付2.png'},
                {name: '公众号', url: 'https://www.yunox.cn/icon/logo/公众号.png'},
                {name: '支付宝', url: 'https://www.yunox.cn/icon/logo/支付宝1.png'},
                {name: '汉堡王', url: 'https://www.yunox.cn/icon/logo/汉堡王.png'},
                {name: '携程', url: 'https://www.yunox.cn/icon/logo/携程.png'},
                {name: '腾讯', url: 'https://www.yunox.cn/icon/logo/腾讯.png'},
                {name: '腾讯文档', url: 'https://www.yunox.cn/icon/logo/腾讯文档1.png'},
                {name: '信息', url: 'https://www.yunox.cn/icon/logo/信息.png'},
                {name: '信息', url: 'https://www.yunox.cn/icon/logo/信息1.png'},
                {name: '公文包', url: 'https://www.yunox.cn/icon/logo/公文包.png'},
                {name: '人员', url: 'https://www.yunox.cn/icon/logo/人员.png'},
                {name: '设置', url: 'https://www.yunox.cn/icon/logo/设置2.png'},
                {name: '信息', url: 'https://www.yunox.cn/icon/logo/信息2.png'},
                {name: '音频', url: 'https://www.yunox.cn/icon/logo/音频.png'},
                {name: '填表', url: 'https://www.yunox.cn/icon/logo/填表.png'},
                {name: '维修', url: 'https://www.yunox.cn/icon/logo/维修2.png'},
                {name: '警告', url: 'https://www.yunox.cn/icon/logo/警告1.png'},
                {name: '安全', url: 'https://www.yunox.cn/icon/logo/安全.png'},
                {name: '危险', url: 'https://www.yunox.cn/icon/logo/危险.png'},
                {name: '报修', url: 'https://www.yunox.cn/icon/logo/报修.png'},
                {name: '车辆', url: 'https://www.yunox.cn/icon/logo/车辆.png'},
                {name: '当心触电', url: 'https://www.yunox.cn/icon/logo/当心触电.png'},
                {name: '电池', url: 'https://www.yunox.cn/icon/logo/电池.png'},
                {name: '定位', url: 'https://www.yunox.cn/icon/logo/定位.png'},
                {name: '分享', url: 'https://www.yunox.cn/icon/logo/分享.png'},
                {name: '回收', url: 'https://www.yunox.cn/icon/logo/回收.png'},
                {name: '警告', url: 'https://www.yunox.cn/icon/logo/警告.png'},
                {name: '客服', url: 'https://www.yunox.cn/icon/logo/客服.png'},
                {name: '链接', url: 'https://www.yunox.cn/icon/logo/链接.png'},
                {name: '汽车', url: 'https://www.yunox.cn/icon/logo/汽车.png'},
                {name: '扫描', url: 'https://www.yunox.cn/icon/logo/扫描1.png'},
                {name: '设置', url: 'https://www.yunox.cn/icon/logo/设置.png'},
                {name: '施工人员', url: 'https://www.yunox.cn/icon/logo/施工人员.png'},
                {name: '视频', url: 'https://www.yunox.cn/icon/logo/视频.png'},
                {name: '书籍', url: 'https://www.yunox.cn/icon/logo/书籍.png'},
                {name: '图片', url: 'https://www.yunox.cn/icon/logo/图片.png'},
                {name: '维修', url: 'https://www.yunox.cn/icon/logo/维修.png'},
                {name: '位置', url: 'https://www.yunox.cn/icon/logo/位置.png'},
                {name: '文件', url: 'https://www.yunox.cn/icon/logo/文件.png'},
                {name: '文件', url: 'https://www.yunox.cn/icon/logo/文件1.png'},
                {name: '物料', url: 'https://www.yunox.cn/icon/logo/物料.png'},
                {name: '物资', url: 'https://www.yunox.cn/icon/logo/物资.png'},
                {name: '信封', url: 'https://www.yunox.cn/icon/logo/信封.png'},
                {name: '优惠券', url: 'https://www.yunox.cn/icon/logo/优惠券.png'},
                {name: '邮件', url: 'https://www.yunox.cn/icon/logo/邮件.png'},
                {name: '指纹', url: 'https://www.yunox.cn/icon/logo/指纹1.png'},
                {name: '注意安全', url: 'https://www.yunox.cn/icon/logo/注意安全.png'},
                {name: '注意安全', url: 'https://www.yunox.cn/icon/logo/注意安全1.png'},
                {name: '注意高温', url: 'https://www.yunox.cn/icon/logo/注意高温.png'},
                {name: '注意火焰', url: 'https://www.yunox.cn/icon/logo/注意火焰.png'},
                {name: '指纹', url: 'https://www.yunox.cn/icon/logo/指纹.png'},
                {name: '链接', url: 'https://www.yunox.cn/icon/logo/链接1.png'},
                {name: 'WiFi', url: 'https://www.yunox.cn/icon/logo/WiFi.png'}],
            isChoseIconIng: false,
            colorList: [
                // 冷色系颜色
                '#000000', // 黑色
                '#0000FF', // 蓝色
                '#4B0082', // 靛蓝色
                '#6A5ACD', // 石板蓝
                '#7B68EE', // 淡紫色
                '#8470FF', // 暗紫色
                '#000080', // 海军蓝
                '#00FFFF', // 水鸭蓝
                '#00CED1', // 浅绿松石色
                '#20B2AA', // 轻绿色
                '#3CB371', // 青绿色
                '#2E8B57', // 森林绿
                '#008000', // 绿色
                '#006400', // 暗绿色
                '#008B8B', // 青色
                '#00BFFF', // 深天蓝色
                '#1E90FF', // 鸽子蓝
                '#6495ED', // 皇室蓝
                '#4682B4', // 钢青色
                '#B0C4DE', // 亮钢蓝色
                '#778899', // 石板灰
                '#708090', // 石板蓝
                '#ffffff', // 白色
            ],
            marginList: [
                {name: '1个色块', value: 10},
                {name: '2个色块', value: 15},
                {name: '3个色块', value: 20},
                {name: '4个色块', value: 25}],
            faultList: [
                {name: '7%', value: 0},
                {name: '15%', value: 1},
                {name: '25%', value: 2},
                {name: '30%', value: 3}],
            sizeList: [
                {name: '30*30mm', value: 150},
                {name: '40*40mm', value: 200},
                {name: '65*65mm', value: 325},
                {name: '80*80mm', value: 400}],
            dotSizeList: [
                {name: '10', value: 1},
                {name: '9', value: 0.9},
                {name: '8', value: 0.8},
                {name: '7', value: 0.7},
                {name: '6', value: 0.6},
                {name: '5', value: 0.5},
                {name: '4', value: 0.4},
                {name: '3', value: 0.3},
                {name: '2', value: 0.2},
            ],
            fileList: []

        }
    },
    methods: {
        generateUrl() {
            const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/;
            if (regex.test(this.qrText)) {
                this.isShow = true
            } else {
                alert('检查网址是否正确')
            }
        },
        downLoad(item) {
            const link = document.createElement('a');
            link.href = 'https://www.yunox.cn/temp/' + this.sessionId + '/' + item.fileName;
            link.download = item.fileName; // 设置下载文件名
            // 解决某些浏览器在下载时不触发下载的情况
            document.body.appendChild(link); // 将链接添加到文档中
            link.click(); // 触发点击事件
            document.body.removeChild(link); // 下载后移除链接
        },
        updateSelectType(value) {
            this.clean()
            this.selectType = value
            if (value === 5) {
                this.sessionId = generateRandomCode2(10)
                this.connect()
            }
        },
        connect() {
            connectSocket(JSON.stringify({sessionId: 'receive' + this.sessionId}))
            this.getSocketData = e => {
                const data = e && e.detail.data
                // 如果不是连接成功的心跳数据，弹出提示
                if (data !== 'pong') {
                    this.handleNotifyMsg(data, e)  //处理消息的函数
                }
            }
            window.addEventListener('getMessage', this.getSocketData)
        },
        handleNotifyMsg(data, e) {
            if (e.detail.data === '刷新') {
                const info = {
                    path: '/temp/' + this.sessionId.replace('receive') + '/'
                }
                getFolderInfo(info).then(res => {
                    this.fileList = res
                })
            }
        },
        downloadQRCode() {
            const imgSrc = this.$refs.vueQr.imgUrl
            const link = document.createElement('a');
            link.href = imgSrc;
            link.download = 'qrcode.png';
            link.click();
        },
        updateDotSize(value) {
            this.dotScale = value
            this.adjustSettings = 0
        },
        updateSize(value) {
            this.qrSize = value
            this.adjustSettings = 0
        },
        updateCorrectLevel(value) {
            this.correctLevel = value
            this.adjustSettings = 0
        },
        updateMargin(value) {
            this.margin = value
            this.adjustSettings = 0
        },
        updateColorLight(color) {
            this.colorLight = color
            this.adjustSettings = 0
        },
        updateColorDark(color) {
            this.colorDark = color
            this.adjustSettings = 0
        },
        updateAdjustSettings(value) {
            this.adjustSettings = value
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                // 创建FileReader对象
                const reader = new FileReader();
                // 当文件读取成功时触发
                reader.onload = (e) => {
                    // 将读取到的DataURL保存到data属性
                    this.logoSrc = e.target.result;
                };
                // 以DataURL的形式读取文件
                reader.readAsDataURL(file);
            }
        },
        onFileChange2(event) {
            const file = event.target.files[0];
            uploadTempFile(file).then(res => {
                this.qrText = 'https://www.yunox.cn/temp/' + res
                this.isShow = true
            })
        },
        addLogo() {
            this.isAddLogo = true
        },
        closeChoseIcon() {
            this.isChoseIconIng = false
        },
        openChoseIcon() {
            this.isChoseIconIng = true
        },
        choseIcon(item) {
            this.closeChoseIcon()
            this.logoSrc = item.url
        },
        clean() {
            this.sessionId = null
            this.selectType = 1
            this.adjustSettings = 0
            this.isShow = false
            this.isAddLogo = false
            this.dotScale = 1
            this.selected = 1
            this.qrText = '' // 你的字符串内容
            this.qrSize = 200
            this.colorDark = '#000000'
            this.colorLight = '#ffffff'
            this.margin = 10
            this.correctLevel = 0
            this.logoSrc = '' // 路径根据你的项目结构调整
            this.isChoseIconIng = false
        }
    },
    mounted() {

    }
}

</script>

<style scoped>
@import "@/views/css/QRCode.css";
</style>