<template>
    <div>
        <back></back>
        <div class="createLineup-button" @click="createLineup()">保存阵容</div>
        <div class="createLineup_versionSelector">
            <div class="createLineup_version" @click="newChangeSeason(6)" :class="{ 'createLineup_bg-red': season !== 6 }">双城之战</div>
            <div class="createLineup_version" @click="newChangeSeason(11)" :class="{ 'createLineup_bg-red': season !== 11 }">画之灵</div>
            <div class="createLineup_version" @click="newChangeSeason(12)" :class="{ 'createLineup_bg-red': season !== 12 }">魔法乱斗</div>
        </div>
        <div style="padding-top: 80px">
            <div style="width: 400px;font-weight: bolder">
                阵容标题
                <input v-model="formationName" class="titleInput" placeholder="输入阵容名">
            </div>

            <!--            <div style="display: flex;margin-top: 30px;gap:20px;width: 400px">-->
            <!--                <div style="width: 14vh;font-weight: bolder">选择版本</div>-->
            <!--                <div style="width: 200px">-->
            <!--                    <el-switch-->
            <!--                            style="display: block"-->
            <!--                            v-model="season"-->
            <!--                            active-color="#13ce66"-->
            <!--                            inactive-color="#13ce66"-->
            <!--                            active-text="双城之战"-->
            <!--                            active-value=6-->
            <!--                            inactive-text="画之灵"-->
            <!--                            @change="changeSeason()"-->
            <!--                            inactive-value=11>-->
            <!--                    </el-switch>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="chessboard_create">
                <div v-for="row in 4" :key="'row_unique_key_' + row + '_' + formationInfo.length" class="row_create"
                     :class="{ 'offset': row % 2 === 0 }">
                    <div v-for="col in 7" :key="col" class="hexagon_create"
                         :style="{ backgroundColor: (row * 10 + col) === choseGrid ? 'rgba(217,169,169,0.48)':getBorderColor(row * 10 + col)  }">
                        <img :src="getLineupImageUrl(row * 10 + col)" alt="">
                    </div>
                </div>
                <div style="position: absolute;padding-top: 1vh">
                    <div v-for="row in 4" :key="row" class="row_create" :class="{ 'offset': row % 2 === 0 }">
                        <div v-for="col in 7" :key="col" class="hexagon3_create" @click="clickGrid(row * 10 + col)">
                            <div class="equipment_div">
                                <img :src="getEquipmentUrl(row * 10 + col)" alt="">
                                <img :src="getEquipment2Url(row * 10 + col)" alt="">
                                <img :src="getEquipment3Url(row * 10 + col)" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 5px;display: flex">
                <div style="font-size: larger;font-weight: bolder"> 费用：</div>
                <el-radio-group v-model="choseCost" size="small" fill="#9252a4">
                    <el-radio-button label=1></el-radio-button>
                    <el-radio-button label=2></el-radio-button>
                    <el-radio-button label=3></el-radio-button>
                    <el-radio-button label=4></el-radio-button>
                    <el-radio-button label=5></el-radio-button>
                </el-radio-group>
            </div>
            <div class="character_list">
                <div v-for="item in charactersByCost" :key="item.id" class="character_item" @click="putCharacter(item)">
                    <img
                            :src="item.imgUrl"
                            alt="Character"
                            class="character_image"
                            :style="{ borderColor: getBorderColor(item.cost) }"
                    >
                    <div class="character_name">{{ item.characterName }}</div>
                </div>
            </div>
            <div style="margin-top: 5px;display: flex">
                <div style="font-size: large;font-weight: bold">
                    <div style="min-width: 42px">装备:</div>
                </div>
                <el-radio-group v-model="choseLevel" size="mini" fill="#507773">
                    <el-radio-button label=0>基础装备</el-radio-button>
                    <el-radio-button label=1>合成装备</el-radio-button>
                    <el-radio-button label=2>转职纹章</el-radio-button>
                    <el-radio-button label=3>特殊装备</el-radio-button>
                    <el-radio-button label=4>辅助装备</el-radio-button>
                    <el-radio-button label=5>光明武器</el-radio-button>
                    <el-radio-button label=6>奥恩神器</el-radio-button>
                </el-radio-group>
            </div>
            <div class="character_list">
                <div v-for="item in equipmentsByLevel" :key="item.id" class="equipment_item"
                     @click="putEquipment(item)">
                    <img
                            :src="item.imgUrl"
                            alt="Character"
                            class="character_image"
                            :style="{ borderColor: getBorderColor(item.cost) }"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {Switch, RadioButton, RadioGroup, Notification} from "element-ui";
import {addLineup, getAllCharacter, getAllEquipment} from "@/api/tftApi";

export default {
    name: "createLineup",
    components: {Back, Switch, RadioButton, RadioGroup},
    data() {
        return {
            formationName: '',
            season: localStorage.getItem('season') ? parseInt(localStorage.getItem('season')) : 11,
            characterList: [], // 原始数据列表
            choseCost: 1,
            choseLevel: 1,
            charactersByCost: [], // 根据成本筛选后的角色列表
            choseGrid: 11,
            formationInfo: [],
            equipmentList: [],
            equipmentsByLevel: [],
            currentEquipmentIndex: 0
        }
    },
    methods: {
        newChangeSeason(season) {
            this.season = season
            localStorage.setItem('season', season)
            this.resetLineup();
            Promise.all([
                getAllCharacter(this.season),
                getAllEquipment(this.season)
            ]).then(([charRes, equipRes]) => {
                this.characterList = charRes;
                this.equipmentList = equipRes;
                this.setCharactersByCost();
                this.setEquipmentsByLevel();
            });
        },
        resetLineup() {
            this.formationName = ''
            this.choseCost = 1
            this.choseLevel = 1
            this.choseGrid = 11
            this.formationInfo = []
            this.currentEquipmentIndex = 0
        },
        createLineup() {
            if (this.formationName !== '' && this.formationInfo.length > 0) {
                let lineup = {
                    formationName: this.formationName,
                    season: Number(this.season),
                    formationInfo: this.formationInfo
                }
                addLineup(lineup)
            } else {
                this.$notify.error({
                    title: '提交失败',
                    message: '阵容名为空或没选择棋子。'
                });
                return;
            }
            this.resetLineup()
        },
        getLineupImageUrl(position) {
            // 遍历formationInfo列表
            for (let item of this.formationInfo) {
                // 检查当前对象的position属性是否与传入的position匹配
                if (item.position === position) {
                    return item.imgUrl;
                }
            }
        },
        putCharacter(characterInfo) {
            let existingPositionInfo = this.formationInfo.find(item => item.position === this.choseGrid);
            if (existingPositionInfo && existingPositionInfo.characterId === characterInfo.id) {
                const index = this.formationInfo.indexOf(existingPositionInfo);
                if (index > -1) {
                    this.formationInfo.splice(index, 1); // 删除元素
                }
                return;
            }
            // 创建新的positionInfo对象
            const positionInfo = {
                position: this.choseGrid,
                characterId: characterInfo.id,
                imgUrl: characterInfo.imgUrl
            };

            // 遍历formationInfo数组
            for (let i = 0; i < this.formationInfo.length; i++) {
                // 检查当前元素的position属性是否与this.choseGrid相等
                if (this.formationInfo[i].position === this.choseGrid) {
                    // 如果条件成立，使用Vue.set来替换数组中的元素
                    this.$set(this.formationInfo, i, positionInfo);
                    // 退出循环
                    break;
                }
            }
            // 如果没有找到匹配的元素，才添加新的positionInfo到数组中
            if (!this.formationInfo.some(item => item.position === this.choseGrid)) {
                this.formationInfo.push(positionInfo);
            }
        },
        putEquipment(equipment) {
            // 检查当前选中的格子是否已经存在人物信息
            const positionInfo = this.formationInfo.find(item => item.position === this.choseGrid && item.characterId);
            if (positionInfo) {
                // 定义装备槽的键名数组
                const equipmentKeys = ['equipmentOne', 'equipmentTwo', 'equipmentThree'];
                // 使用currentEquipmentIndex确定当前应该更新的装备槽
                let equipmentIndex = this.currentEquipmentIndex;

                // 更新选中格子的装备信息
                positionInfo[equipmentKeys[equipmentIndex]] = equipment.id;
                positionInfo[`${equipmentKeys[equipmentIndex]}Img`] = equipment.imgUrl;
                // 更新currentEquipmentIndex，使其指向下一个装备槽
                // 如果是最后一个装备槽，则重置为0
                this.currentEquipmentIndex = (equipmentIndex + 1) % equipmentKeys.length;
                // 确保Vue能够响应这个新状态
                this.$set(this.formationInfo, this.formationInfo.indexOf(positionInfo), positionInfo);
                console.log(this.formationInfo)
            }
        },
        clickGrid(position) {
            this.choseGrid = position
        },
        getBorderColor(cost) {
            switch (cost) {
                case 1:
                    return 'rgba(117, 117, 117, 0.35)';
                case 2:
                    return 'rgb(83,194,76)';
                case 3:
                    return 'rgb(16,149,220)';
                case 4:
                    return 'rgb(176,6,168)';
                case 5:
                    return 'rgb(248,198,73)';
                default:
                    return 'rgba(210,210,210,0.48)'; // 默认无边框
            }
        },
        getAllCharacter() {
            getAllCharacter(this.season).then(res => {
                this.characterList = res;
                // 初始化时根据 choseCost 筛选角色列表
                this.setCharactersByCost();
            });
        },
        getAllEquipment() {
            getAllEquipment(this.season).then(res => {
                this.equipmentList = res;
                // 初始化时根据 choseCost 筛选角色列表
                this.setEquipmentsByLevel();
            });
        },
        setCharactersByCost() {
            this.charactersByCost = this.characterList.filter(
                item => item.cost.toString() === this.choseCost.toString()
            );
        },
        setEquipmentsByLevel() {
            this.equipmentsByLevel = this.equipmentList.filter(
                item => item.level.toString() === this.choseLevel.toString()
            );
        },
        getEquipmentUrl(position) {
            const piece = this.formationInfo.find(piece => piece.position === position);
            return piece ? piece.equipmentOneImg : '';
        },
        getEquipment2Url(position) {
            const piece = this.formationInfo.find(piece => piece.position === position);
            return piece ? piece.equipmentTwoImg : '';
        },
        getEquipment3Url(position) {
            const piece = this.formationInfo.find(piece => piece.position === position);
            return piece ? piece.equipmentThreeImg : '';
        }
    },
    watch: {
        choseCost(newVal) {
            // 当 choseCost 发生变化时，更新角色列表
            this.setCharactersByCost();
        },
        choseLevel(newVal) {
            // 当 choseCost 发生变化时，更新角色列表
            this.setEquipmentsByLevel();
        },
        characterList(newVal) {
            // 当 characterList 发生变化时，也更新角色列表
            this.setCharactersByCost();
        },
        equipmentList(newVal) {
            // 当 characterList 发生变化时，也更新角色列表
            this.setEquipmentsByLevel();
        }
    }
    ,
    mounted() {
        this.getAllCharacter();
        this.getAllEquipment()
    }
}
</script>

<style>

.createTitle {
    color: slateblue;
    font-size: 35px;
    margin-left: 50px;
    font-weight: bolder;
    letter-spacing: 4vh; /* 增加字间距 */
}

.titleInput {
    font-size: 30px;
    height: 35px;
    width: 30vh;
}

.el-switch__label.is-active {
    color: #ff4d4d;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #c2c2c2;
    border-color: #9252a4;
}

.el-radio-button--small .el-radio-button__inner {
    padding: 6px 28px
}

.chessboard_create {
    max-width: 450px;
    border: 1px solid rgba(248, 236, 236, 0.96);
    border-radius: 8px;
    padding: 10px 10px 10px 1vh;
    display: flex;
    flex-direction: column;
}

.row_create {
    display: flex;
}

.row_create.offset {
    margin-left: 3vh; /* 每一行错开的距离 */
}

.hexagon_create {
    position: relative;
    width: 5.5vh;
    height: 6vh;
    margin-left: 6px;
    background-color: #c5c5c5;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagon_create img {
    max-height: 98%;
    object-fit: contain;
}

.hexagon3_create {
    position: relative;
    width: 5.5vh;
    height: 6vh;
    margin-left: 6px;
}

.character_list {
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    /*justify-content: center; !* 水平居中对齐 *!*/
    margin-left: auto; /* 自动向左对齐 */
    margin-right: auto; /* 自动向右对齐 */
}

/* 每个图片和名字的容器 */
.character_item {
    display: flex;
    flex-direction: column; /* 子元素垂直排列 */
    align-items: center; /* 水平居中对齐 */
    margin: 2px; /* 添加一些外边距 */
}

.equipment_item {
    display: flex;
    flex-direction: column; /* 子元素垂直排列 */
    align-items: center; /* 水平居中对齐 */
    margin: 2px; /* 添加一些外边距 */
}

/* 图片样式 */
.character_image {
    border: 2px solid; /* 使用自定义属性设置边框颜色 */
    width: 45px; /* 设置图片宽度 */
}

/* 名字样式 */
.character_name {
    font-size: 14px; /* 设置名字的字体大小 */
    text-align: center; /* 文本居中对齐 */
    margin-top: 5px; /* 名字与图片之间的间隔 */
}

.hexagon3_create img {
    height: 1.5vh;
}

.createLineup-button {
    background-color: #4cc53a;
    border-radius: 5px;
    padding: 6px;
    position: fixed; /* 使用 fixed 定位 */
    top: 5px; /* 距离顶部的距离 */
    right: 5px; /* 距离左侧的距离 */
    z-index: 999; /* 确保按钮在其他内容上方 */
}

.createLineup-button :hover {
    cursor: pointer;
}

.createLineup_versionSelector {
    display: flex;
    width: 95vw;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: absolute;
    gap: 10px;
    margin-top: 40px;
}

.createLineup_version {
    font-weight: bolder;
    border-radius: 10px;
    height: 30px;
    box-shadow: 1px 2px 2px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
    border: #2a738d 1px solid;
    width: 30vw; /* 每个元素占据视口宽度的30% */
    flex-grow: 1; /* 使元素等宽 */
    flex-basis: 0; /* 基础宽度为0，由flex-grow分配空间 */
    background-color: #ff8585;

}

.createLineup_bg-red {
    background-color: rgba(255, 255, 255, 0.2);
}
</style>