<template>
    <div>
        <back></back>

        <div class="roulette">

            <div class="pointer"></div>
            <div class="wheel" @click="spinWheel">


                <!-- 指针元素 -->
                <!-- 扇区 -->
                <div v-for="(item, index) in items" :key="index" class="sector"
                     :style="{ transform: 'rotate(' + (index * 5.63) + 'deg) translate(-50%, -50%)' }">
                    <div class="sector-content" :class="{ highlight: item === selectedItem }">
                        {{ item }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 结果显示 -->
        <div class="result" v-if="selectedItem !== null">
            <div style="font-weight: bolder;font-size: 16px">{{ selectedItem }}</div>
            <div>{{ selectedItem2 }}</div>
            <div style="margin-top: 5px">{{ selectedItem3 }}</div>
            <div style="white-space: pre-wrap;">{{ selectedItem4 }}</div>
        </div>
    </div>
</template>


<script>
import Back from "@/components/back.vue";

export default {
    name: "gossip",
    components: {Back},
    data() {
        return {
            items: [
                "乾为天", "坤为地", "水雷屯", "山水蒙", "水天需", "天水讼", "地水师", "水地比",
                "风天小畜", "天泽履", "地天泰", "天地否", "天火同人", "火天大有", "地山谦", "雷地豫",
                "泽雷随", "山风蛊", "地泽临", "风地观", "火雷噬嗑", "山火贲", "山地剥", "地雷复",
                "天雷无妄", "山天大畜", "山雷颐", "泽风大过", "坎为水", "离为火", "泽山咸", "雷风恒",
                "天山遁", "雷天大壮", "火地晋", "地火明夷", "风火家人", "火泽睽", "水山蹇", "雷水解",
                "山泽损", "风雷益", "泽天夬", "天风姤", "泽地萃", "地风升", "泽水困", "水风井",
                "泽火革", "火风鼎", "震为雷", "艮为山", "风山渐", "雷泽归妹", "雷火丰", "火山旅",
                "巽为风", "兑为泽", "风水涣", "水泽节", "风泽中孚", "雷山小过", "水火既济", "火水未济"
            ],
            gossipDetail: [
                "乾卦：自强不息，上上卦", "坤卦：厚德载物，上上卦", "屯卦：起始维艰，下下卦", "蒙卦：启蒙奋发，中下卦",
                "需卦：守正待机，中上卦", "讼卦：慎争戒讼，中下卦", "师卦：行险而顺，中上卦", "比卦：诚信团结，上上卦",
                "小畜卦：蓄养待进，下下卦", "履卦：脚踏实地，中上卦", "泰卦：应时而变，中中卦", "否卦：不交不通，中中卦",
                "同人卦：上下和同，中上卦，", "大有卦：顺天依时，上上卦", "谦卦：内高外低，中中卦", "豫卦：顺时依势，中中卦",
                "随卦：随时变通，中中卦", "蛊卦：振疲起衰，中中卦", "临卦：教民保民，中上卦", "观卦：观下瞻上，中上卦",
                "噬嗑卦：刚柔相济，上上卦", "贲卦：饰外扬质，中上卦", "剥卦：顺势而止，中下卦", "复卦：寓动于顺，中中卦",
                "无妄卦：无妄而得，下下卦", "大畜卦：止而不止，中上卦", "颐卦：纯正以养，上上卦", "大过卦：非常行动，中下卦",
                "坎卦：行险用险，下下卦", "离卦：附和依托，中上卦", "咸卦：相互感应，中上卦", "恒卦：恒心有成，中上卦",
                "遁卦：遁世救世，下下卦", "大壮卦：壮勿妄动，中上卦", "晋卦：求进发展，中上卦", "明夷卦：晦而转明，中下卦",
                "家人卦：诚威治业，下下卦", "睽卦：异中求同，下下卦", "蹇卦：险阻在前，下下卦", "解卦：柔道致治，中上卦",
                "损卦：损益制衡，下下卦", "益卦：损上益下，上上卦", "夬卦：决而能和，上上卦", "姤卦：天下有风，上卦",
                "萃卦：荟萃聚集，中上卦", "升卦：柔顺谦虚，上上卦", "困卦：困境求通，中上卦", "井卦：求贤若渴，上上卦",
                "革卦：顺天应人，上上卦", "鼎卦：稳重图变，中下卦", "震卦：临危不乱，中上卦", "艮卦：动静适时，中下卦",
                "渐卦：渐进蓄德，上上卦", "归妹卦：立家兴业，下下卦", "丰卦：日中则斜，上上卦", "旅卦：依义顺时，下下卦",
                "巽卦：谦逊受益，中上卦", "兑卦：刚内柔外，上上卦", "涣卦：拯救涣散，下下卦", "节卦：万物有节，上上卦",
                "中孚卦：诚信立身，下下卦", "小过卦：行动有度，中上卦", "既济卦：盛极将衰，中上卦", "未济卦：事业未竟，中下卦"
            ],
            gossipExplain: [
                "同卦（下乾上乾）相叠。象征天，喻龙（德才的君子），又象征纯粹的阳和健，表明兴盛强健。乾卦是根据万物变通的道理，以“元、亨、利、贞”为卦辞，示吉祥如意，教导人遵守天道的德行。",
                "同卦（下坤上坤）相叠，阴性。象征地（与乾卦相反），顺从天。承载万物，伸展无穷无尽。坤卦以雌马为象征，表明地道生育抚养万物，而又依天顺时，性情温顺。它以“先迷后得”证明“坤”顺从“乾”，依随“乾”，才能把握正确方向，遵循正道，获取吉利。",
                "异卦（下震上坎）相叠，震为雷，喻动；坎为雨，喻险。雷雨交加，险象丛生，环境恶劣。“屯”原指植物萌生大地。万物始生，充满艰难险阻，然而顺时应运，必欣欣向荣。",
                "异卦（下坎上艮）相叠，艮是山的形象，喻止；坎是水的形象，喻险。卦形为山下有险，仍不停止前进，是为蒙昧，故称蒙卦。但因把握时机，行动切合时宜，因此，具有启蒙和通达的卦象。",
                "异卦（下乾上坎）相叠，下卦是乾，刚健之意；上卦是坎，险陷之意。以刚逢险，宜稳健之妥，不可冒失行动，观时待变，所往一定成功。",
                "异卦（下坎上乾）相叠。同需卦相反，互为“综卦”。乾为刚健，坎为险陷。刚与险，健与险，彼此反对，定生争讼。争讼非善事，务必慎重戒惧。",
                "异卦（下坎上坤）相叠。“师”指军队。坎为水、为险；坤为地、为顺，喻寓兵于农。兵凶战危，用兵乃圣人不得已而为之，但它可以顺利无阻碍地解决矛盾，因为顺乎形势，师出有名，故能化凶为吉。",
                "异卦（下坤上坎）相叠，坤为地；坎为水。水附大地，地纳河海，相互依赖，亲密无间。此卦与师卦完全相反，互为综卦。它阐述的是相亲相辅，宽宏无私，精诚团结的道理。",
                "异卦（下乾上巽）相叠，乾为天；巽为风。喻风调雨顺，谷物滋长，故卦名小畜（蓄）。力量有限，须待发展到一定程度，才可大有作为。",
                "异卦（下兑上乾）相叠，乾为天；兑为泽，以天喻君，以泽喻民，原文：“履虎尾、不咥人。”因此，结果吉利。君上民下，各得其位。兑柔遇乾刚，所履危。履意为实践，卦义是脚踏实地的向前进取的意思。",
                "异卦（下乾上坤）相叠，乾为天，为阳；坤为地，为阴，阴阳交感，上下互通，天地相交，万物纷纭。反之则凶。万事万物，皆对立，转化，盛极必衰，衰而转盛，故应时而变者泰（通）。",
                "异卦（下坤上乾）相叠，其结构同泰卦相反，系阳气上升，阴气下降，天地不交，万物不通。它们彼此为“综卦”，表明泰极而否，否极泰来，互为因果。",
                "异卦（下离上乾）相叠，乾为天，为君；离为火，为臣民百姓，上天下火，火性上升，同于天，上下和同，同舟共济，人际关系和谐，天下大同。",
                "异卦（下乾上离）相叠。上卦为离，为火；下卦为乾，为天。火在天上，普照万物，万民归顺，顺天依时，大有所成。",
                "异卦（下艮上坤）相叠，艮为山，坤为地。地面有山，地卑而山高，是为内高外低，比喻功高不自居，名高不自誉，位高不自傲。这就是谦。",
                "异卦（下坤上震）相叠，坤为地，å为顺；震为雷，为动。雷依时出，预示大地回春。因顺而动，和乐之源。此卦与谦卦互为综卦，交互作用。",
                "异卦（下震上兑）相叠，震为雷、为动；兑为悦。动而悦就是“随”。随指相互顺从，己有随物，物能随己，彼此沟通。随必依时顺势，有原则和条件，以坚贞为前提。",
                "异卦（下巽上艮）相叠，与随卦互为综卦。蛊本意为事，引申为多事、混乱。器皿久不用而生虫称“蛊”，喻天下久安而因循、腐败，必须革新创造，治理整顿，挽救危机，重振事业。",
                "异卦（下兑上坤）相叠。坤为地；兑为泽，地高于泽，泽容于地。喻君主亲临天下，治国安邦，上下融洽。",
                "异卦（下坤上巽）相叠，风行地上，喻德教遍施。观卦与临卦互为综卦，交相使用。在上者以道义观天下；在下者以敬仰瞻上，人心顺服归从。",
                "异卦（下震上离）相叠。离为阴卦；震为阳卦。阴阳相交，咬碎硬物，喻恩威并施，宽严结合，刚柔相济。",
                "异卦（下离上艮）相叠。离为火为明；艮为山为止。文明而有节制。贲卦论述文与质的关系，以质为主，以文调节。",
                "异卦（下坤上艮）相叠。五阴在下，一阳在上，阴盛而阳孤；高山附于地。二者都是剥落象，故为“剥卦”。此卦阴盛阳衰，喻小人得势，君子困顿，事业败坏。",
                "异卦（下震上坤）相叠。震为雷、为动；坤为地、为顺，动则顺，顺其自然。动在顺中，内阳外阴，循序运动，进退自如，利于前进。",
                "异卦（下震上乾）相叠。乾为天为刚为健；震为雷为刚为动。动而健，刚阳盛，人心振奋，必有所得，但唯循纯正，不可妄行。无妄必有获，必可致福。",
                "异卦（下乾上艮）相叠。乾为天，刚健；艮为山，笃实。畜者积聚，大畜意为大积蓄。为此不畏严重的艰难险阻，努力修身养性以丰富德业。",
                "异卦（下震上艮）相叠。震为雷，艮为山。山在上而雷在下，外实内虚。春暖万物养育，依时养贤育民。阳实阴虚，实者养人，虚者为人养。自食其力。",
                "异卦（下巽上兑）相叠。兑为泽、为悦，巽为木、为顺，泽水淹舟，遂成大错。阴阳爻相反，阳大阴小，行动非常，有过度形象，内刚外柔。",
                "同卦（下坎上坎）相叠。坎为水、为险，两坎相重，险上加险，险阻重重。一阳陷二阴。所幸阴虚阳实，诚信可豁然贯通。虽险难重重，却方能显人性光彩。",
                "同卦（下离上离）相叠。离者丽也，附着之意，一阴附丽，上下二阳，该卦象征火，内空外明。离为火、为明、太阳反复升落，运行不息，柔顺为心。",
                "异卦（下艮上兑）相叠。艮为山；泽为水。兑柔在上，艮刚在下，水向下渗，柔上而刚下，交相感应。感则成。",
                "异卦（下巽上震）相叠。震为男、为雷；巽为女、为风。震刚在上，巽柔在下。刚上柔下，造化有常，相互助长。阴阳相应，常情，故称为恒。",
                "异卦（下艮上乾）相叠。乾为天，艮为山。天下有山，山高天退。阴长阳消，小人得势，君子退隐，明哲保身，伺机救天下。",
                "异卦（下乾上震）相叠。震为雷；乾为天。乾刚震动。天鸣雷，云雷滚，声势宏大，阳气盛壮，万物生长。刚壮有力故曰壮。大而且壮，故名大壮。四阳壮盛，积极而有所作为，上正下正，标正影直。",
                "异卦（下坤上离）相叠。离为日，为光明；坤为地。太阳高悬，普照大地，大地卑顺，万物生长，光明磊落，柔进上行，喻事业蒸蒸日上。",
                "异卦（下离上坤）相叠。离为明，坤为顺；离为日；坤为地。日没入地，光明受损，前途不明，环境困难，宜遵时养晦，坚守正道，外愚内慧，韬光养晦。",
                "异卦（下离上巽）相叠。离为火；巽为风。火使热气上升，成为风。一切事物皆应以内在为本，然后伸延到外。发生于内，形成于外。喻先治家而后治天下，家道正，天下安乐。",
                "异卦（下兑上离）相叠。离为火；兑为泽。上火下泽，相违不相济。克则生，往复无空。万物有所不同，必有所异，相互矛盾。睽即矛盾。",
                "异卦（下艮上坎）相叠。坎为水；艮为山。山高水深，困难重重，人生险阻，见险而止，明哲保身，可谓智慧。蹇，跋行艰难。",
                "异卦（下坎上震）相叠。震为雷、为动；坎为水、为险。险在内，动在外。严冬天地闭塞，静极而动。万象更新，冬去春来，一切消除，是为解。",
                "异卦（下兑上艮）相叠。艮为山；兑为泽。上山下泽，大泽浸蚀山根。损益相间，损中有益，益中有损。二者之间，不可不慎重对待。损下益上，治理国家，过度会损伤国基。应损则损，但必量力、适度。少损而益最佳。",
                "异卦（下震上巽）相叠。巽为风；震为雷。风雷激荡，其势愈强，雷愈响，风雷相助互长，交相助益。此卦与损卦相反。它是损上以益下，后者是损下以益上。二卦阐述的是损益的原则。",
                "异卦（下乾上兑）相叠。乾为天为健；兑为泽为悦。泽气上升，决注成雨，雨施大地，滋润万物。五阳去一阴，去之不难，决即可",
                "异卦（下巽上乾）相叠。乾为天；巽为风。天下有风，吹遍大地，阴阳交合，万物茂盛。姤卦与夬卦相反，互为“综卦”。姤即遘，阴阳相遇。但五阳一阴，不能长久相处。",
                "异卦（下坤上兑）相叠。坤为地、为顺；兑为泽、为水。泽泛滥淹没大地，人众多相互斗争，危机必四伏，务必顺天任贤，未雨绸缪，柔顺而又和悦，彼此相得益彰，安居乐业。",
                "异卦（下巽上坤）相叠。坤为地、为顺；巽为木、为逊。大地生长树木，逐步的成长，日渐高大成材，喻事业步步高升，前程远大，故名“升”。",
                "异卦（下坎上兑）相叠。兑为阴为泽喻悦；坎为阳为水喻险。泽水困，陷入困境，才智难以施展，仍坚守正道，自得其乐，必可成事，摆脱困境。",
                "异卦（下巽上坎）相叠。坎为水；巽为木。树木得水而蓬勃生长。人靠水井生活，水井由人挖掘而成。相互为养，井以水养人，经久不竭，人应取此德而勤劳自勉。",
                "异卦（下离上兑）相叠。离为火；兑为泽，泽内有水。水在上而下浇，火在下而上升。火旺水干；水大火熄。二者相生亦相克，必然出现变革。变革是宇宙的基本规律。",
                "异卦（下巽上离）相叠。燃木煮食，化生为熟，除旧布新的意思。鼎为重宝大器，三足稳重之象。煮食，喻食物充足，不再有困难和困扰。在此基础上宜变革，发展事业。",
                "同卦（下震上震）相叠。震为雷，两震相叠，反响巨大，可消除沉闷之气，亨通畅达。平日应居安思危，怀恐惧心理，不敢有所怠慢，遇到突发事变，也能安然自若，谈笑如常。",
                "同卦（下艮上艮）相叠。艮为山，二山相重，喻静止。它和震卦相反。高潮过后，必然出现低潮，进入事物的相对静止阶段。静止如山，宜止则止，宜行则行。行止即动和静，都不可失机，应恰到好处，动静得宜，适可而止。",
                "异卦（下艮上巽）相叠。艮为山；巽为木。山上有木，逐渐成长，山也随着增高。这是逐渐进步的过程，所以称渐，渐即进，渐渐前进而不急速。",
                "异卦（下兑上震）相叠。震为动、为长男；兑为悦、为少女。以少女从长男，产生爱慕之情，有婚姻之动，有嫁女之象，故称归妹。男婚女嫁，天地大义，人的开始和终结。上卦与渐卦为综卦，交互为用。",
                "异卦（下离上震）相叠，电闪雷鸣，成就巨大，喻达到顶峰，如日中天。告诫；务必注意事物向相反方面发展。治乱相因，盛衰无常，不可不警惕。",
                "异卦（下艮上离）相叠。此卦与丰卦相反，互为“综卦”。山中燃火，烧而不止，火势不停地向前蔓延，如同途中行人，急于赶路。因而称旅卦。",
                "同卦（下巽上巽）相叠，巽为风，两风相重，长风不绝，无孔不入，巽义为顺。谦逊的态度和行为可无往不利。",
                "同卦（下泽上泽）相叠。泽为水。两泽相连，两水交流，上下相和，团结一致，朋友相助，欢欣喜悦。兑为悦也。同秉刚健之德，外抱柔和之姿，坚行正道，导民向上。",
                "异卦（下坎上巽）相叠。风在水上行，推波助澜，四方流溢。涣，水流流散之意。象征组织和人心涣散，必用积极的手段和方法克服，战胜弊端，挽救涣散，转危为安。",
                "异卦（下兑上坎）相叠。兑为泽；坎为水。泽有水而流有限，多必溢于泽外。因此要有节度，故称节。节卦与涣卦相反，互为综卦，交相使用。天地有节度才能常新，国家有节度才能安稳，个人有节度才能完美。",
                "异卦（下兑上巽）相叠。孚本义孵，孵卵出壳的日期非常准确，有信的意义。卦形外实内虚，喻心中诚信，所以称中孚卦。这是立身处世的根本。",
                "异卦（下艮上震）相叠。艮为山；震为雷。过山雷鸣，不可不畏惧。阳为大，阴为小，卦外四阴超过中二阳，故称“小过”，小有越过。",
                "异卦（下离上坎）相叠。坎为水；离为火。水火相交，水在火上，水势压倒火势，救火大功告成。既，已经；济，成也。既济就是事情已经成功，但终将发生变故。",
                "异卦（下坎上离）相叠。离为火；坎为水。火上水下，火势压倒水势，救火大功未成，故称未济。《周易》以乾坤二卦为始，以既济、未济二卦为终，充分反映了变化发展的思想。",
            ],
            detailExplain: [
                '\n' + '【事业】\n' +
                '大吉大利，万事如意，心想事成，自有天佑，春风得意，事业如日中天。但阳气已达顶点，盛极必衰，务须提高警惕，小心谨慎。力戒骄傲，冷静处世，心境平和，如是则能充分发挥才智，保证事业成功。\n' +
                '\n' +
                '【经商】\n' +
                '十分顺利，有发展向上的大好机会。但切勿操之过急，宜冷静分析形势，把握时机，坚持商业道德，冷静对待中途出现的困难，定会有满意的结果。\n' +
                '\n' +
                '【求名】\n' +
                '潜在能力尚未充分发挥，只要进一步努力，克服骄傲自满情绪，进业修德，以渊博学识和高尚品质，成君子之名。\n' +
                '\n' +
                '\n' + '【外出】\n' +
                '利于出行，结伴而行更佳。\n' +
                '\n' +
                '【婚恋】\n' +
                '阳盛阴衰，但刚柔可相济，形成美满结果。女性温柔者更佳。\n' +
                '\n' +
                '【决策】\n' +
                '可成就大的事业。坚持此卦的刚健、正直、公允的实质，修养德行，积累知识，坚定信念，自强不息，必能克服困难，消除灾难。\n' +
                '\n' +
                '\n' + '【解卦】\n' +
                '天：代表极阳之象，为纯卦、六冲卦，是大好、大坏的卦象。对事一般为吉象，为主管、负责任，可以任用担当大事，或开业当老板，得此卦都属吉象，只是必须有一个好的助理更佳，升官、考试、升迁都较有利。\n' +
                '\n' +
                '\n' + '【解释】\n' +
                '刚健稳固。\n' +
                '\n' + '【周转】\n' +
                '虽不成问题，尚宜心平气和来处理。\n' +
                '\n' + '【买卖】\n' +
                '小利可得，大则勿取，勿太张扬为吉。\n' +
                '\n' + '【等人】\n' +
                '一定会来。\n' +
                '\n' + '【寻人】\n' +
                '已远走高飞，不易找到，在西北方向。\n' +
                '\n' + '【失物】\n' +
                '可望寻回。\n' +
                '\n' + '【外出】\n' +
                '利于出行，结伴而行更佳。\n' +
                '\n' + '【考试】\n' +
                '有望取得好成绩。\n' +
                '\n' + '【诉讼】\n' +
                '宜据理力争。\n' +
                '\n' + '【求事】\n' +
                '有贵人提拔，可发展，称心。\n' +
                '\n' + '【改行】\n' +
                '占得此卦不宜改行。\n' +
                '\n' + '【开业】\n' +
                '开业者需要耐心观望。\n' +
                '\n' + '【特性】\n' +
                '积极，刚毅，努力，认真，有耐性，坚忍，正派，主动，慷慨，严律，重名誉。\n' +
                '\n' + '【运势】\n' +
                '有地位擢升、名利双收之象，功名荣显得贵子。六亲和睦，凡事谦和为贵，可吉。\n' +
                '\n' + '【家运】\n' +
                '繁昌兴隆，须小心口舌之争。\n' +
                '\n' + '【疾病】\n' +
                '病情恶化，宜细心调养，注意头、脑、骨髓等病变。\n' +
                '\n' + '【胎孕】\n' +
                '有不安之兆，宜小心保养。\n' +
                '\n' + '【子女】\n' +
                '儿女健康，幸福有为。',

                '\n' + '【事业】\n' +
                '诸项事业可以成功，得到预想的结果，但开始出师不利，为困境所扰。切莫冒险急进，须小心谨言慎行，尤其不可单枪匹马，独断专行。取得朋友的关心和支持最为重要，在他人的合作下，共同完成事业。因此，应注重内心修养，积蓄养德，效法大地，容忍负重，宽厚大度，以直率、方正、含蓄为原则，不得贪功自傲，持之以恒，谋求事业的成功。\n' +
                '\n' +
                '【经商】\n' +
                '机遇不很好，切莫冒险，以稳健为妥，遇到挫折，务必即时总结经验。注意储存货物，待价而沽，处处小心为是。\n' +
                '\n' +
                '【求名】\n' +
                '比较顺利，具备基本条件，踏踏实实，埋头苦干，不追求身外之物，即可吉祥。\n' +

                '\n' + '【外出】\n' +
                '宜结伴而行。可去陌生的地方。\n' +
                '\n' +
                '【婚恋】\n' +
                '阴盛。以柔克刚，女方柔顺，美好姻缘，白头到老。\n' +
                '\n' +
                '【决策】\n' +
                '忠厚、温和，待人真诚，热心助人，因此也能得到他人的帮助，可往往因不提防小人而受到伤害，但无大碍。性格灵活，工作方法多样，可以左右逢源，得到赞许。难，消除灾难。\n' +
                '\n' + '【解卦】\n' +
                '地：代表大地为母，天生之母性也。六冲纯卦，主大好大坏之卦象。优点：如母亲般地柔顺、牺牲、付出，有事事和顺之意。缺点：过于委缺求全，没有了自己的想法和观念。此卦论感情，有前世因缘的影响，自己就有如母亲大姊般地照顾对方，无怨无悔，会很辛苦、多劳累。事业，适合当任副手之职位，忌任主事者，容易软弱难成事。\n' +
                '\n' + '【解释】\n' +
                '在母胎里孕育中，不宜创业。\n' +
                '\n' + '【特性】\n' +
                '为人较为保守，善于计划与研究工作，适合任辅佐幕僚之职，做人朴实，人缘佳，大好人型，男性桃花多，女性则爱情专一。\n' +
                '\n' + '【运势】\n' +
                '诸事不宜急进，退守以静制动者吉。坤者大地平静，任其他物所摧而不为所动者大利也。若醉心私欲，违背常理，则有无妄之灾。\n' +
                '\n' + '【寻人】\n' +
                '此人为色情之事出走，于西南方附近之地也。\n' +
                '\n' + '【失物】\n' +
                '难以寻回，大部份均不知下落。\n' +
                '\n' + '【外出】\n' +
                '宜结伴而行。可去陌生的地方。\n' +
                '\n' + '【考试】\n' +
                '希望不大。\n' +
                '\n' + '【诉讼】\n' +
                '防房地产之争，宜和解，有始无终之兆。\n' +
                '\n' + '【求事】\n' +
                '不称意亦不理想，另寻。\n' +
                '\n' + '【改行】\n' +
                '改行不利，宜固守本份。\n' +
                '\n' + '【开业】\n' +
                '开业者再观望，目前尚未筹备妥当，不宜。\n' +
                '\n' + '【家运】\n' +
                '幸福平静之象。\n' +
                '\n' + '【疾病】\n' +
                '病情严重，为腹、肠胃、肌肉等慢性病（腹疾、脾胃之疾、饮食停滞，从而食不化）。\n' +
                '\n' + '【胎孕】\n' +
                '生贵子，女儿亦福。\n' +
                '\n' + '【子女】\n' +
                '儿女众多，和睦幸福也。\n' +
                '\n' + '【周转】\n' +
                '须耐心去求，否则难成。\n' +
                '\n' + '【买卖】\n' +
                '勿急，交易可成。\n' +
                '\n' + '【等人】\n' +
                '不会来，须再待些时日。',

                '\n' + '【事业】\n' +
                '起初多不利，必知难而进，小心翼翼，勇往直前，灵活机动，可望获得大的成功，时机到来时一定要抓住，却也不得操之太急，且仍有困难，务必有他人相助，故平时应多施恩惠。\n' +
                '\n' +
                '【经商】\n' +
                '创业初期步履艰难，多有挫折。坚定信念最重要，不要为表面现象所迷惑，应积极进取，行动果断，打开出路。若仍无法摆脱困境，则应退守保全，等待机会，再展宏图。\n' +
                '\n' +
                '【求名】\n' +
                '积极争取，主动追求，可以成功。\n' +

                '\n' + '【外出】\n' +
                '慎重为好，有危险，宜改日动身。\n' +
                '\n' +
                '【婚恋】\n' +
                '好事多磨，忠贞纯洁，大胆追求，能够成功，婚姻美满。\n' +
                '\n' +
                '【决策】\n' +
                '初始困难，但若具有坚忍不拔的毅力和锲而不舍的奋斗精神，前途不可估量，但往往不为他人理解而陷于孤独苦闷，事业会因此处于困难状态，需要得到贤德之人的帮助才能摆脱。如能以乐观主义精神处世，能取得成就。\n' +

                '\n' + '【解卦】\n' +
                '屯：代表刚出生的小草，主凶象，四大难卦第一卦。因为刚出生的小草，脆弱易折，代表凡事都会很困难，很容易就会夭折、失败。但是只要有心小心地护持，小草还是有机会长成大树的！\n' +

                '\n' + '【解释】\n' +
                '不明不白。\n' +
                '\n' + '【特性】\n' +
                '理想远大，奋斗不懈，苦尽甘来，最后能达成目标，获得他人的肯定。一生困境多，烦恼厌烦多，朋友少助。\n' +
                '\n' + '【运势】\n' +
                '逆势，多有困惑，但不可妄进，宜守不宜进。坚定意志，凡事不可为一己之利而执意强行，皆有不成损破之象。宜步步为营，排除困难，始可通达，有初难后解之象。\n' +
                '\n' + '【家运】\n' +
                '多波折，若能努力排除困难，可得通顺。\n' +
                '\n' + '【疾病】\n' +
                '病在肠胃，足部或泌尿系统，不宜掉以轻心。\n' +
                '\n' + '【胎孕】\n' +
                '不安，产母有虚惊，但可无虑。\n' +
                '\n' + '【子女】\n' +
                '有辛苦，但以后可得安逸。\n' +
                '\n' + '【周转】\n' +
                '困难重重，须用心忍耐奔波。\n' +
                '\n' + '【买卖】\n' +
                '不利，交易有损。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '出走者不知去向，难寻。东或北方去矣。\n' +
                '\n' + '【失物】\n' +
                '难以寻回。\n' +
                '\n' + '【外出】\n' +
                '慎重为好，有危险，宜改日动身。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '小事粘滞纠缠，大事反无大害，宜和解。\n' +
                '\n' + '【求事】\n' +
                '不利。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '不宜，有困难亦难成也。',

                '\n' + '【事业】\n' +
                '事业开始，混乱无序，危机四伏，以勇敢坚毅的行动可以扭转局面。然而必须接受严格教育，培养这种奋发图强的精神。务必脚踏实地，最忌好高骛远，否则会陷入孤立无援的境地。\n' +
                '\n' +
                '【经商】\n' +
                '务必小心谨慎，不得急功近利，尤其应树立高尚的商业道德，以良好的信誉提高竞争力而取胜。\n' +
                '\n' +
                '【求名】\n' +
                '必须接受良好的基础教育，陶冶情操。且动机纯正，可以达到目的。\n' +

                '\n' + '【外出】\n' +
                '等候有利时机再行动，等待期间应积极做准备。\n' +
                '\n' +
                '【婚恋】\n' +
                '注意考察对方品德，不可以金钱为诱铒。夫妻需相互宽容、理解。\n' +
                '\n' +
                '【决策】\n' +
                '有时会陷入迷惘困顿的境地，加上胆小、不果断，往往误事。如能接受长辈的教诲，甚至严酷的考验，抛弃疑惧的心理，等待适当时机，必然一帆风顺。\n' +
                '\n' +
                '【小凶】\n' +
                '得此卦者，智慧犹如童蒙，不辨是非，迷失方向；若能顺贤师良友之教，启其聪明则亨通。\n' +

                '\n' + '【解卦】\n' +
                '蒙：代表被蒙蔽不明之意。主小凶象。事情迷蒙不明，不宜轻信于人，此卦易犯小人，需诚心求教于有智慧经验的友人长者，受其启蒙开智慧，方能转危而安。若是一意而行必现大凶象。\n' +

                '\n' + '【解释】\n' +
                '被蒙蔽、被欺瞒。\n' +
                '\n' + '【特性】\n' +
                '喜欢追求新的知识，过平稳生活，做事谨慎，对下属要求较严，领导统御能力略差。宜防受朋友之拖累蒙蔽。\n' +
                '\n' + '【运势】\n' +
                '气运不发，多迷惑。初时不顺，须忍耐待机，渐可佳。切勿贸然行事或为人认保、作保，否则必有损失。凡事宜从上辈教诲，则运可通也。\n' +
                '\n' + '【家运】\n' +
                '初时辛苦多端，上下无法沟通，只要以诚相待，必有开运之时。\n' +
                '\n' + '【疾病】\n' +
                '病情不明，反覆难治。为肠胃、腹水之疾及传染病。\n' +
                '\n' + '【胎孕】\n' +
                '胎孕无碍。\n' +
                '\n' + '【子女】\n' +
                '应注重子女教养，将来方得幸福之象。\n' +
                '\n' + '【周转】\n' +
                '困难，防止上当受骗。\n' +
                '\n' + '【买卖】\n' +
                '不利，有损失。\n' +
                '\n' + '【等人】\n' +
                '可能走错路，会迟到。\n' +
                '\n' + '【寻人】\n' +
                '受诱惑而出走，因本身蒙昧无知而受骗。在东北或北方，日前难寻。\n' +
                '\n' + '【失物】\n' +
                '遍寻不获。似乎在大门下陷之处遗失，忘记带走，可试问人。\n' +
                '\n' + '【外出】\n' +
                '等候有利时机再行动，等待期间应积极做准备。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '因外人引起事端，目下未明，有理讲不清。\n' +
                '\n' + '【求事】\n' +
                '未能如愿，再等时机。\n' +
                '\n' + '【改行】\n' +
                '改行不宜\n' +
                '\n' + '【开业】\n' +
                '开业者不宜。再等一段时间，筹备好资金再做打算。',

                '\n' + '【事业】\n' +
                '关键在于审时度势，耐心等待，事成于安祥，切勿冒险，欲速不达。自己要充满自信，临危不惧，坚守中正，必可化险为夷。情况有利时，仍得居安思危。\n' +
                '\n' +
                '【经商】\n' +
                '行动之初，情况困难，必须以极大的耐心，创造条件和机会，行事光明磊落，观时待变，实现愿望。事情接近成功时，更应小心谨慎，切莫功亏一篑。\n' +
                '\n' +
                '【求名】\n' +
                '时机尚不成熟，应耐心等待。这时应坚定信念，不为闲言流语所动摇，努力丰富自己，再求助可靠的人便可成功。\n' +

                '\n' + '【外出】\n' +
                '充分准备齐全后，可平安无事。\n' +
                '\n' +
                '【婚恋】\n' +
                '慎重，切不可草率行事，逐渐培养感情，以诚实、热情相待，会发生变故，仍可以有良好的结局。双方都应懂得以柔克刚的道理。\n' +
                '\n' +
                '【决策】\n' +
                '前途光明，有雄心大志，且可实现。为此需要积蓄实力，等待时机，大器晚成。本人具有坚强的意志，冷静静的头脑。前进途中会遇到困难和险阻，必须十分谨慎，坦然对待小人的中伤，在灾祸在面前能镇静自若。不轻举妄动，冷静选择方向。为人谦和、坦率，多有他人相助，促使事业成功。当时机，必然一帆风顺。\n' +

                '\n' + '【解卦】\n' +
                '需：代表需要、需求，但却又是时机未到。此卦凶中带吉，需耐心等待，则事情可成。健康方面，要特别注意。\n' +

                '\n' + '【解释】\n' +
                '需要等待时机。\n' +
                '\n' + '【特性】\n' +
                '谦恭有礼，性格保守，稍被动，但为人诚恳，欲求不高，中晚年才渐入佳境。不利早婚，有宗教艺术兴趣。\n' +
                '\n' + '【运势】\n' +
                '宜退守正道，不宜冒险前进，投机急取反而失利。须知贪小失大，智者必须待时也。\n' +
                '\n' + '【家运】\n' +
                '初为多事之秋，须凭智慧耐心挽救颓势。\n' +
                '\n' + '【疾病】\n' +
                '病情拖延，须留心治疗，为头、胸、肺、泌尿系统等病。\n' +
                '\n' + '【胎孕】\n' +
                '临产之时才占卜，代表会有障碍。防克产母。\n' +
                '\n' + '【子女】\n' +
                '得子迟。\n' +
                '\n' + '【周转】\n' +
                '不能预期而得，有延滞。\n' +
                '\n' + '【买卖】\n' +
                '有口舌不可成就，亦勿贪小而失大。\n' +
                '\n' + '【等人】\n' +
                '迟到。\n' +
                '\n' + '【寻人】\n' +
                '此出走之人因感情之事，于西北或北方。\n' +
                '\n' + '【失物】\n' +
                '即时不能寻回，需经过一段时间将会出现。\n' +
                '\n' + '【外出】\n' +
                '充分准备齐全后，可平安无事。\n' +
                '\n' + '【考试】\n' +
                '要多用功。\n' +
                '\n' + '【诉讼】\n' +
                '暂时未能了结，以和为贵。\n' +
                '\n' + '【求事】\n' +
                '不宜急取，急亦不得，再等待，再寻求。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '不宜。',

                '\n' + '【事业】\n' +
                '起初顺利，有利可图，继而受挫，务必警惕，慎之又慎，不得固执已见，极力避免介入诉讼纠纷的争执之中。与其这样，不如退而让人，求得化解，安于正理，可免除意外之灾。陷入争讼，即使获胜，最后还得失去，得不偿失。\n' +
                '\n' +
                '【经商】\n' +
                '和气生财，吃亏是福。切勿追求不义之财。商业谈判坚持公正公平、互利的原则，尽量避免发生冲突。这样，会有好结果。\n' +
                '\n' +
                '【求名】\n' +
                '不利。自己尚缺乏竞争实力，应坚守纯正，隐忍自励，自强自勉，切莫逞强。依靠有地位的人的帮助，及早渡过难关。\n' +

                '\n' + '【外出】\n' +
                '途中多有变故，宜改日出行。\n' +
                '\n' +
                '【婚恋】\n' +
                '虽不尽人意，倒也般配，彼此理解，未尝不可。双方应以温和的方式处理生活。\n' +
                '\n' +
                '【决策】\n' +
                '争强好胜，不安于现状，为改变命运和超越他人而奋斗。头脑聪颖，反应敏捷，有贵人相助。但缺乏持之以恒的毅力，容易露出锋芒，得罪他人，带来诉讼之灾。宜承认现实，顺其自然，知足，适可而止。接受教训，引以为戒，可功成名就。\n' +

                '\n' + '【解卦】\n' +
                '讼：代表诉讼、争端之象。主大凶象。事情冲突到必须见官上法院，可能必须以法律途径来解决了！凡事皆不利，而且已引起很大口舌是非争持，而必须摊牌互相攻击的程度了！\n' +

                '\n' + '【解释】\n' +
                '官司缠讼。\n' +
                '\n' + '【特性】\n' +
                '不服输，好竞争辩论，反应快，口才佳，易得罪他人，是自立自强之人。\n' +
                '\n' + '【运势】\n' +
                '百事闭塞不通，伤害频繁，受中伤而又易陷于奸计，不如意之运也。得饶人处且饶人，得过且过，莫再争讼，否则皆不利也。\n' +
                '\n' + '【家运】\n' +
                '家庭内部不和，有口舌争端之害，并有散离之象。\n' +
                '\n' + '【疾病】\n' +
                '病情严重，为腰、血液、泌尿系统、头部疾患。\n' +
                '\n' + '【胎孕】\n' +
                '生产无惊阻，安也。\n' +
                '\n' + '【子女】\n' +
                '父子之间存有歧见，无法沟通之象。子女生来劳碌，与父母缘薄，多辛劳忧苦也。\n' +
                '\n' + '【周转】\n' +
                '不得要领，难成。\n' +
                '\n' + '【买卖】\n' +
                '因处理不当，有冲突争端，常招损失，不利也。\n' +
                '\n' + '【等人】\n' +
                '不会来。就算会来，双方必有争执情事发生。\n' +
                '\n' + '【寻人】\n' +
                '此人因争执事由负气出走，并有生命危险，又寻找不易，尽量往西北与北方寻找。\n' +
                '\n' + '【失物】\n' +
                '不会出现。\n' +
                '\n' + '【外出】\n' +
                '途中多有变故，宜改日出行。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '两败俱伤，宜找中间人调解，久争无益。\n' +
                '\n' + '【求事】\n' +
                '难成。\n' +
                '\n' + '【改行】\n' +
                '不利。\n' +
                '\n' + '【开业】\n' +
                '不宜。',

                '\n' + '【事业】\n' +
                '阻力很大，困难很多，处于激烈的竞争状态，必与他人密切合作，谨小慎微，行为果断，切忌盲目妄动，适度即可，注意保全自己。机动灵活，严于律已。从容沉着对付一切，必能成功。\n' +
                '\n' +
                '【经商】\n' +
                '已有一定的积蓄，可以从事大的营销活动，但必卷入激烈商战，以刚毅顽强的精神和高尚的商业道德，辅以灵活的方法，勿贪图小利，勿掉以轻心，加强与他人的沟通，必可摆脱困境，化险为夷。\n' +
                '\n' +
                '【求名】\n' +
                '具备很好的条件，但须有正确的引导，务必严格要求自己，克服不利因素的干扰，经过扎实努力，必可名利双全。\n' +

                '\n' + '【外出】\n' +
                '“行险而顺。”虽有可能遇到麻烦，但结果一定十分顺利，可放心出行。\n' +
                '\n' +
                '【婚恋】\n' +
                '慎重、专注，否则会陷入“三角”纠纷。痴情追求可以达到目的。\n' +
                '\n' +
                '【决策】\n' +
                '天资聪颖，性格灵活，具有坚强的意志，对事业执着追求，迎难而进。可成就大事业。喜竞争，善争辩，富有冒险精神，不免带来麻烦，务老成持重，不贪功，以中正为要。\n' +

                '\n' + '【解卦】\n' +
                '师：代表军队打仗强烈抗争之意。师亦为「师长、老师」，是为强烈教训的现象，若在事、在人，均代表会受到很大教训、挫折。或是与人结怨甚深而遭到其强力的打击、迫害。\n' +

                '\n' + '【解释】\n' +
                '要师出有名。\n' +
                '\n' + '【特性】\n' +
                '讲求实力，具有毅力，不怕困难，挑战艰巨任务，虽然时常会遇到逆境，都能一一化解克服，赢得他人的赞许。\n' +
                '\n' + '【运势】\n' +
                '事虽有不顺、但要引以为镜，记取教训。不要图私利而投机取巧。凡事应以正规而行，事物多变动，切忌独立而行。困难多端，靠自己努力，摒除一切困难为上策。\n' +
                '\n' + '【家运】\n' +
                '外柔顺而内险恶，故家内常生不和，需慎重处理。行正道可化险为安。\n' +
                '\n' + '【疾病】\n' +
                '病况严重，提防心脏或腹腔肿瘤等。\n' +
                '\n' + '【胎孕】\n' +
                '喜中有忧。或有不安或不孕。\n' +
                '\n' + '【子女】\n' +
                '大出息之象。\n' +
                '\n' + '【周转】\n' +
                '无望，另觅别处。\n' +
                '\n' + '【买卖】\n' +
                '有阻，不太顺意，寅午戌日交易可成。\n' +
                '\n' + '【等人】\n' +
                '来者不善。\n' +
                '\n' + '【寻人】\n' +
                '此人因家内失和而出走，速往北方或西南找，否则有危险。\n' +
                '\n' + '【失物】\n' +
                '不会出现，即或寻回，也已损坏。\n' +
                '\n' + '【外出】\n' +
                '“行险而顺”。虽有可能遇到麻烦，但结果一定十分顺利，可放心出行。\n' +
                '\n' + '【考试】\n' +
                '很认真，但不理想，继续努力终有成就。\n' +
                '\n' + '【诉讼】\n' +
                '宜进不宜退，内心虽忧，但得贵人之助。\n' +
                '\n' + '【求事】\n' +
                '独立无望，有人扶持则可。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '须待时机行事，勿过急。',

                '\n' + '【事业】\n' +
                '顺利能够成功，向前发展，可以得到他人的帮助和辅佐，以诚实、信任的态度去做事。待人宽厚、正直，主动热情，向才德高尚的人士学习，听取建议。\n' +
                '\n' +
                '【经商】\n' +
                '愿望能够实现，且有较丰厚的利润，但需要与他人密切合作，真诚交往，讲究商业道德，遵守信义，如唯利是图，贪心不足，或自以为是，会导致严重损失。\n' +
                '\n' +
                '【求名】\n' +
                '有成功的希望，不仅要靠个人的努力，更为重要的是他人的赏识和栽培。\n' +

                '\n' + '【外出】\n' +
                '旅途平安，一路顺风。\n' +
                '\n' +
                '【婚恋】\n' +
                '美好姻缘，相亲相爱，彼此忠诚，白头到老。\n' +
                '\n' +
                '【决策】\n' +
                '心地善良，待人忠诚、厚道，乐于帮助他人，也能得到回报。工作勤恳，对自己要求严格，可以实现自己的理想，但要多动脑筋，多思考，善于判断是非，尤其要注意选择朋友，一旦结上品行不端的人，会成为自己的祸患。倘若与比自己高明的人交朋友，并取得帮助，会终身受益。\n' +

                '\n' + '【解卦】\n' +
                '比：代表兄友比肩而站，一片融合互持、比和之意。主吉象。水溶入土中，胶和在一起，是个很亲密的卦象，尤其是论男女之情。事业投资有亲密的伙伴相助，共同扶持打拼，事事当然顺利进行。\n' +

                '\n' + '【解释】\n' +
                '平等、比合。\n' +
                '\n' + '【特性】\n' +
                '勤劳，认真，善良，谦恭，务实，踏实，重生活享受。异性缘多，感情烦恼也多。\n' +
                '\n' + '【运势】\n' +
                '平顺可得贵人提拔，凡事宜速战速决，不可过份迟疑。\n' +
                '\n' + '【家运】\n' +
                '合家和乐，家运亨通。\n' +
                '\n' + '【疾病】\n' +
                '新病宜速就医，久病则危，为腹、肾、耳等疾。\n' +
                '\n' + '【胎孕】\n' +
                '孕安。\n' +
                '\n' + '【子女】\n' +
                '儿女温顺且侍亲至孝，亲情融合之象。\n' +
                '\n' + '【周转】\n' +
                '必成、找同年之兄弟、朋友可成。\n' +
                '\n' + '【买卖】\n' +
                '莫太贪心。交易反覆难成，勿贪则有利可成。\n' +
                '\n' + '【等人】\n' +
                '会来。\n' +
                '\n' + '【寻人】\n' +
                '不在杏花处，则是有妇人相留，不可寻，恐生不测之祸。此人会自己回来。\n' +
                '\n' + '【失物】\n' +
                '不会再现。通常均已遭损毁。\n' +
                '\n' + '【外出】\n' +
                '旅途平安，一路顺风。\n' +
                '\n' + '【考试】\n' +
                '金榜题名。\n' +
                '\n' + '【诉讼】\n' +
                '宜以和解态度面对，虽小人多，但可胜诉。\n' +
                '\n' + '【求事】\n' +
                '有贵人帮助，可成。\n' +
                '\n' + '【改行】\n' +
                '有利，可得友人支持。\n' +
                '\n' + '【开业】\n' +
                '可大发利市，但须注意有人背叛。',

                '\n' + '【事业】\n' +
                '时机尚不成熟，应当耐心而积极地积存力量，切不可冒险行动。遇到挫折不可灰心，鼓起勇气战胜困难，坚持原则，加强团结合作，提前做好各项准备，会有所成。\n' +
                '\n' +
                '【经商】\n' +
                '已有一定的基础，但有失去危险，一定要特别慎重，争取与他人真诚合作，共同获利，决不贪心，适可而止，但更不能满足现状，不图进取。\n' +
                '\n' +
                '【求名】\n' +
                '树立远大目标，尽最大努力去争取，虚心求教他人，取得真诚帮助，实现自己的理想，警惕小人的破坏。\n' +

                '\n' + '【外出】\n' +
                '准备尚未充分，不得仓促出行，尤其忌长途旅行，会有意外的灾害。应另择他日外出。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方必须十分忠诚，第三者插足的现象极有可能发生。\n' +
                '\n' +
                '【决策】\n' +
                '不很顺利，前进途中会遇到一些意想不到的困难。但由于本人性格坚强，具有战胜不利条件的意志。因此，可以实理自己的理想。但极有可能遭到小人的伤害，必须得到志同道合者的真诚相助，才能可能排除障碍。对所得应满足，适可而止，切不可过度追求，以免满招损。\n' +

                '\n' + '【解卦】\n' +
                '小畜：代表小有积蓄。是个小吉卦，事事不可贪大，投资理财均需保守为佳。健康无碍，平顺。\n' +

                '\n' + '【解释】\n' +
                '稍有积蓄。\n' +
                '\n' + '【特性】\n' +
                '上进心，求知欲强，思想灵敏，察言观色，善解人意，追求精神层次，美好事物。有艺术美学方面天分。\n' +
                '\n' + '【运势】\n' +
                '有蓄聚、阻止之意。一阴畜五阳，虽有云雨而不下，雨之不下，大地不能得到滋润，故此运势有反覆、有口舌、又难以急调，诸事宜忍耐，等待时机。尤其女人得此卦更应在行为上，对事或对人有所节制。\n' +
                '\n' + '【家运】\n' +
                '不和且多是非，衰而不振，多劳，要耐心应对，以待时机。雨过天必晴。\n' +
                '\n' + '【疾病】\n' +
                '危，重病，注意肝胆，头部病变。\n' +
                '\n' + '【胎孕】\n' +
                '宜防流产。\n' +
                '\n' + '【子女】\n' +
                '因子女而劳碌之象，但晚年有福也。\n' +
                '\n' + '【周转】\n' +
                '有女人从中阻碍，不成也。\n' +
                '\n' + '【买卖】\n' +
                '景气不好，买卖难成或利薄。\n' +
                '\n' + '【等人】\n' +
                '不会来，临时变卦。\n' +
                '\n' + '【寻人】\n' +
                '因感情或家庭不和出走，只在原处。东南或西北之向。\n' +
                '\n' + '【失物】\n' +
                '似乎遗失的是金器或现金支票等物，有被硬物夹住，或者第三格的地方。\n' +
                '\n' + '【外出】\n' +
                '准备尚未充分，不得仓促出行，尤其忌长途旅行，会有意外的灾害。应另择他日外出。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '会因证据不足而败诉。\n' +
                '\n' + '【求事】\n' +
                '希望渺小。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '不宜，已开业者则困难、口舌多。',

                '\n' + '【事业】\n' +
                '起初很不顺利，受到种种威胁，若能提高警惕，谨小慎微，脚踏实地，逐个地去克服困难，不改变自己的决心，一定可以达到目的。办事有自知之明，务必量力而行，不可不顾实际，尤其不得逞强，急于求成。\n' +
                '\n' +
                '【经商】\n' +
                '宜待观动静，勿为小利而动摇自己计划，尤其不得贪得无厌，遵循商业道德，了解全面信息。\n' +
                '\n' +
                '【求名】\n' +
                '务必有恒心，孜孜以求，不为财富利诱，不为世俗干扰，坚持自己的志向和理想，可以取得成功。\n' +

                '\n' + '【外出】\n' +
                '有惊无险，可以外出，但非紧急情况可以缓行。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方安贫乐道，可和睦相处，若一方为富贵所动，会发生婚变。\n' +
                '\n' +
                '【决策】\n' +
                '诸事必须小心慎重，不利的情况经常会出现，但若能处理得好，总会是有惊无险，化险为夷。为此，办事必须认认真真，遵循正道，虚心听取别人的建议，量力而行，切莫自负逞强，一定会有好的结果。\n' +

                '\n' + '【解卦】\n' +
                '履：代表战战兢兢、如履薄冰而行，却是有惊无险。为小凶带吉之象。履又为「礼」也。得此卦者，需尽快反省自己的态度，对人、对事、对长辈是否有轻忽、不敬之意。虽有危急之事幸好能平安度过。\n' +

                '\n' + '【解释】\n' +
                '只要履行契约承诺则吉。\n' +
                '\n' + '【特性】\n' +
                '竞争，积极，冒险，热枕，独立，自立自强，有骨气。\n' +
                '\n' + '【运势】\n' +
                '先劳而后逸，始惊而后安，百事不宜急进，须以和待人，有惊无险。\n' +
                '\n' + '【家运】\n' +
                '新婚而刚建立家庭者，多碍、劳苦。但此卦有先苦后甘之象，和气可生财，过些时日即可雨过天晴也。\n' +
                '\n' + '【疾病】\n' +
                '病虽重，但注意调理亦可愈，防头、口、肺等疾。\n' +
                '\n' + '【胎孕】\n' +
                '临盆产妇占得则吉，否则有受惊吓之象，需安胎。\n' +
                '\n' + '【子女】\n' +
                '初运有劳苦，障碍、困难之象，但以后能获得幸福也。\n' +
                '\n' + '【周转】\n' +
                '虽有些困难，但不必急躁，谦恭而施则可成也。\n' +
                '\n' + '【买卖】\n' +
                '虽有是非但终可成，牛马猴之月或日方成。\n' +
                '\n' + '【等人】\n' +
                '迟来。\n' +
                '\n' + '【寻人】\n' +
                '走失之人有生命危险，在西方或西北方向，难寻，须争取时效。申未日可见。\n' +
                '\n' + '【失物】\n' +
                '赶快去找有可能寻回，留意行过的路上，或者有鞋的地方。\n' +
                '\n' + '【外出】\n' +
                '有惊无险，可以外出，但非紧急情况可以缓行。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '敌强而我弱之象，宜步步为营。\n' +
                '\n' + '【求事】\n' +
                '有贵人提拔，可发展，称心。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '不宜。',

                '\n' + '【事业】\n' +
                '坚持由小而大，循序渐进的原则，事业已达到顺利的境地，更应小心从事，居安思危，积极寻求、开拓新的事业，方可继续前进，若因循守旧，不思进取，必遭失败。\n' +
                '\n' +
                '【经商】\n' +
                '顺利。务必注意市场动向，开拓新的领域，展开新的竞争。为此，密切与他人的合作，争取各方面的帮助，但需谦虚谨慎，警惕巧言令色的小人。\n' +
                '\n' +
                '【求名】\n' +
                '具有成功的条件，但一定得更加努力，刻苦追求，最忌聪明反被聪明误。\n' +

                '\n' + '【外出】\n' +
                '一帆风顺。\n' +
                '\n' +
                '【婚恋】\n' +
                '美满姻缘。谈恋爱却不可不认真对待。\n' +
                '\n' +
                '【决策】\n' +
                '吉祥如意。适应能力强，各项事业能成功。能谋善断，长于人际关系，能团结他人共创业绩，灵活机动，很能适应形势的变化，不断向新的领域迈进。但在人生途中必须兢兢业业，尤其不可麻痹大意，在择朋选友方面更应慎重，切防小人倒运。\n' +

                '\n' + '【解卦】\n' +
                '泰：代表三阳开泰，万事亨通。吉卦之象，诸事皆顺。\n' +

                '\n' + '【解释】\n' +
                '否极泰来，鸿运当头。\n' +
                '\n' + '【特性】\n' +
                '交游广泛，包容性大，有雅量，个性开朗，乐观，积极，主动。又能接受他人意见，心胸开阔。\n' +
                '\n' + '【运势】\n' +
                '诸事如意吉祥，前途事业均顺利。切不可骄傲或任意从事，亦宜自惕勿太活跃，始能免于灾难。凡事宜求内在之实，不求外在之虚，否则有破。\n' +
                '\n' + '【家运】\n' +
                '家庭和合，有通亨之象，凡事宜检点得失，不可胡为，否则招灾不利。\n' +
                '\n' + '【疾病】\n' +
                '久病不利，注意肠肺及头部之症。\n' +
                '\n' + '【胎孕】\n' +
                '生贵子。安而无灾。生女亦均无碍。\n' +
                '\n' + '【子女】\n' +
                '亲子和睦，幸福圆满。留心教养，勿使太任性，否则沦于不幸之破运。\n' +
                '\n' + '【周转】\n' +
                '可成。\n' +
                '\n' + '【买卖】\n' +
                '交易有益，得利。\n' +
                '\n' + '【等人】\n' +
                '会来。\n' +
                '\n' + '【寻人】\n' +
                '在朋友或亲戚家里，有信息也。可寻。\n' +
                '\n' + '【失物】\n' +
                '不会出现。\n' +
                '\n' + '【外出】\n' +
                '一帆风顺。\n' +
                '\n' + '【考试】\n' +
                '上榜有望，宜再努力勿懈。\n' +
                '\n' + '【诉讼】\n' +
                '是非由小事化大，终可和解。\n' +
                '\n' + '【求事】\n' +
                '吉利亨通。\n' +
                '\n' + '【改行】\n' +
                '吉。\n' +
                '\n' + '【开业】\n' +
                '开业者吉利之象。',

                '\n' + '【事业】\n' +
                '陷入逆境，事业处于衰退阶段，多有不顺利。受小人干扰而不得志，爱挫折。应坚持正道，勿与小人合流，团结志同道合者，自保以等待时机。保持自信心，戒慎恐惧，形势定会好转，事业终能成功。\n' +
                '\n' +
                '【经商】\n' +
                '遇到强大的竞争对手，处于不利地位。信息不灵造成重大损失。应格外小心谨慎，坚持守势，等待有利时机到来，再发展自己的营销事业。\n' +
                '\n' +
                '【求名】\n' +
                '条件还不成熟，再努力一阵子，并得到他人的帮助，理想可以实现。\n' +

                '\n' + '【外出】\n' +
                '会遇到不少麻烦和困难，宜推迟或提前****。\n' +
                '\n' +
                '【婚恋】\n' +
                '容易出现节外生枝的事情，甚至发生挫折和变故，双方若都充满信心，好的结果会出现。\n' +
                '\n' +
                '【决策】\n' +
                '本人聪慧，精明，勤奋努力，但不注意人际关系，加上小人的排斥，命运或遭厄运。意志坚强，具有奋斗精神，且克服了不足，尽管付出的代价很高，必能成全事业。\n' +

                '\n' + '【解卦】\n' +
                '否：代表不好、坏掉了！主凶象。否显现出阴阳之气不协调，在人及事方面诸多不被认同，有内外不合、众说纷纭的现象，因此事事难以进行，最好此时能彼此互相退让静下心来理性的沟通，方能转吉。婚姻、感情，已有个性、意见的冲突产生，若不能好好退让协商，恐有离异分手的结局。\n' +

                '\n' + '【解释】\n' +
                '处在困境中。\n' +
                '\n' + '【特性】\n' +
                '自尊心强，个性刚烈，不服输，反应快，学习能力强，高傲不亦亲近。财运佳，有领导才能，与上司不易相处。\n' +
                '\n' + '【运势】\n' +
                '凶且衰，君子以险德避难，不可荣以禄也。运势不通达，诸事不顺，有损失且波折多。故宜守固正道，若稍偏差，则灾害至也。\n' +
                '\n' + '【家运】\n' +
                '夫妻彼此不能协调，是非很多，劫财又带衰，慎之。\n' +
                '\n' + '【疾病】\n' +
                '占病为凶兆，病在脑、肺及肠胃等，慎防癌症。\n' +
                '\n' + '【胎孕】\n' +
                '不安。\n' +
                '\n' + '【子女】\n' +
                '与双亲感情不睦，任其自然发展吧。\n' +
                '\n' + '【周转】\n' +
                '告贷无门。\n' +
                '\n' + '【买卖】\n' +
                '多有损失，但有贵人应在午未申日。\n' +
                '\n' + '【等人】\n' +
                '不会来，因别的事情而违约。\n' +
                '\n' + '【寻人】\n' +
                '因感情不睦而出走，去向不明，难寻。东南方。\n' +
                '\n' + '【失物】\n' +
                '寻不回。\n' +
                '\n' + '【外出】\n' +
                '会遇到不少麻烦和困难，宜推迟或提前行动。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '各走极端，有口难言，宜及时求和。\n' +
                '\n' + '【求事】\n' +
                '先难后易，须有耐心去争取胜利，否则凶。\n' +
                '\n' + '【改行】\n' +
                '难如愿。\n' +
                '\n' + '【开业】\n' +
                '最好另择吉日。然此卦有先难之困，若能舒之则利。',
                '\n' + '【事业】\n' +
                '顺利、平安、尤其是在与他人的合作方面会十分成功，宜广泛开展人际活动，建立广泛的联系，克服狭隘的门户之见，照顾各方面的利益，求大同，存小异，坚持正确的原则，必能成就大事业。\n' +
                '\n' +
                '【经商】\n' +
                '以真诚合作、精诚团结的态度与同行共事，公平竞争，必可获得自己应得的利益。\n' +
                '\n' +
                '【求名】\n' +
                '自己必须刻苦努力，尤其必要取行师长和志同道全的朋友的指教、帮助，自己虚心进取，完全能够达到目的。\n' +

                '\n' + '【外出】\n' +
                '很平安、顺利，并可以结交新朋友，获得帮助。\n' +
                '\n' +
                '【婚恋】\n' +
                '多情、善交际，认识的异性朋友多，故需慎重选择，注意维护双方关系和家庭和睦。\n' +
                '\n' +
                '【决策】\n' +
                '性格开朗、乐观，为人热情，人际关系好，社交能力强，要善于同他人合作，尤其要协调多方面的关系，要有原则性，与不良风气斗争，有魄力，敢作敢为，富有自我奉献精神，并具有统御能力，适于担任一定的负责。\n' +

                '\n' + '【解卦】\n' +
                '同人：代表有志一同，相和而成。主吉象。理念相同共处愉快。事业投资、感情皆是和睦融洽。最适合找人合伙事业或寻求认同自己理念的吉卦。\n' +

                '\n' + '【解释】\n' +
                '结合志同道合的人集思广益。\n' +
                '\n' + '【特性】\n' +
                '喜团体生活户外活动，有领导才能，善交际，人际关系佳。有幽默感。\n' +
                '\n' + '【运势】\n' +
                '得此卦为诸事开通、平安、吉祥、如意之象，有同情心，互爱、亨通之象，故宜好好掌握时机，维持盛泰。若有稍涉偏私或为私情之爱则有破兆，应以事业为重。\n' +
                '\n' + '【家运】\n' +
                '上下和乐，运势亨通，可得意外之财，宜把握良机，努力求取。\n' +
                '\n' + '【疾病】\n' +
                '病轻，不久可愈，注意心脏，头肺等疾。\n' +
                '\n' + '【胎孕】\n' +
                '无碍。与六亲和睦，得缘之吉卦。\n' +
                '\n' + '【子女】\n' +
                '上下皆能和睦之象。\n' +
                '\n' + '【周转】\n' +
                '顺利，并可进取大业，利也。\n' +
                '\n' + '【买卖】\n' +
                '利益大，与人共事吉。\n' +
                '\n' + '【等人】\n' +
                '一定会来，且会带来好消息。\n' +
                '\n' + '【寻人】\n' +
                '此人非故意出走，自己会回来。\n' +
                '\n' + '【失物】\n' +
                '遗失似文件眼镜等物，可能被压在底层，尽快找寻，则可取回。\n' +
                '\n' + '【外出】\n' +
                '很平安、顺利，并可以结交新朋友，获得帮助。\n' +
                '\n' + '【考试】\n' +
                '成绩优良。\n' +
                '\n' + '【诉讼】\n' +
                '凡事以和为贵。\n' +
                '\n' + '【求事】\n' +
                '吉利。\n' +
                '\n' + '【改行】\n' +
                '吉利亨通。\n' +
                '\n' + '【开业】\n' +
                '吉利亨通。',
                '\n' + '【事业】\n' +
                '事业已经取得一定的成就，最忌得意忘形，胡作非为。务必止恶扬善，坚守中止，交往正直的朋友，戒惧谨慎，兢兢业业，真正做到大而不盈，满而不溢，事业可望迈上新的台阶。\n' +
                '\n' +
                '【经商】\n' +
                '兴隆昌盛，已摆脱困难状态，进入兴旺发达的阶段，如能克制贪财的心理，确守诚信原则，公平待人，克勤克俭，尤其应时刻注意市场动向，适时转轨，可望长久。\n' +
                '\n' +
                '【求名】\n' +
                '已经小有名气，切勿就此满足，停顿不前，尤其不可陷入世俗圈内，应继续努力，在真正朋友的帮助下，更上一层楼。\n' +

                '\n' + '【外出】\n' +
                '平安，可动身。\n' +
                '\n' +
                '【婚恋】\n' +
                '条件优越，可供选择者多，以慎重为好。对于家庭双方应尽力维护。\n' +
                '\n' +
                '【决策】\n' +
                '性格坚强，勇于战胜困难，奋斗向上，前进途中困难和厄运很多，唯有认真对待才能不断取得成功，如放松警惕，骄傲自满，将带来惨重失败。朋友很多，交际广泛，对事业很有帮助，但其中也有拆台的小人。\n' +

                '\n' + '【解卦】\n' +
                '大有：代表有很多的收获。是个吉卦。易经解释大有卦为：春耕、夏耘、秋收、冬藏，是个努力坚持之后的愿望期待实现。很利于投资、合伙、感情、婚姻。\n' +

                '\n' + '【解释】\n' +
                '旺盛大有收获。\n' +
                '\n' + '【特性】\n' +
                '具有组织能力，能扩展大事业，吸收各方专才，克服困难，有努力完成大目标勇气，荣誉心重。\n' +
                '\n' + '【运势】\n' +
                '得时得运，隆盛昌荣也。然卦象藏有盛极必衰的预兆，不得不慎，以期保持其全盛之枢纽，可众望所归。有走红、赚钱之势，须谨慎不可骄纵。\n' +
                '\n' + '【家运】\n' +
                '生平富贵吉利。切勿因富而凌贫，过于傲气凌人者不利也。\n' +
                '\n' + '【疾病】\n' +
                '治疗可愈，防高血压、心脏、头肺病患。\n' +
                '\n' + '【胎孕】\n' +
                '生贵子女，宜往大医院。\n' +
                '\n' + '【子女】\n' +
                '儿女有成，父母欢欣之象。\n' +
                '\n' + '【周转】\n' +
                '大有所得。\n' +
                '\n' + '【买卖】\n' +
                '交易有获大利之象，但亦宜审慎行事。\n' +
                '\n' + '【等人】\n' +
                '会带来好消息。\n' +
                '\n' + '【寻人】\n' +
                '此人为感情或金钱之事出走，宜速找寻，否则恐有走极端之险。以东南再南方再西北三向寻之。\n' +
                '\n' + '【失物】\n' +
                '找寻费时，惟可失而得。\n' +
                '\n' + '【外出】\n' +
                '平安，可动身。\n' +
                '\n' + '【考试】\n' +
                '会取得好成绩\n' +
                '\n' + '【诉讼】\n' +
                '可圆满解决。\n' +
                '\n' + '【求事】\n' +
                '顺利可行。\n' +
                '\n' + '【改行】\n' +
                '可以，依计划进行。\n' +
                '\n' + '【开业】\n' +
                '开业吉利。',
                '\n' + '【事业】\n' +
                '尚未被人器重，但因品德高尚，终会为人发现。自己不必有意表现，尤其不可放弃谦虚的美德，埋头苦干，一定会得到他人的帮助，在事业上大有作为。\n' +
                '\n' +
                '【经商】\n' +
                '较为顺利，但不会获暴利，须逐步积累，故应极其小心谨慎，诚心与他人合作，遵守商业道德，通过自已的勤劳努力，取得商业的发展。\n' +
                '\n' +
                '【求名】\n' +
                '天资聪明，但因性格不善于表现自己，起初往往被埋没，自己只要不灰心，继续努力钻研业务，保持谦逊好学精神，更大的成功正在未来。\n' +

                '\n' + '【外出】\n' +
                '顺利，但应准备充分，途中小心，渐可忽视安全。\n' +
                '\n' +
                '【婚恋】\n' +
                '美满的婚姻应由双方共同去努力争取。否则，恋爱不成功，家庭不能和睦。\n' +
                '\n' +
                '【决策】\n' +
                '具有内高外低的谦虚美德，善于团结他人，也能得到别人的帮助，事业很顺利，遇到困难，很快可以化险为夷，有惊无险。由于能够认真吸取别人的长处，进步很快，会畅通无阻，但需要特别注意学业、德业和修养。\n' +

                '\n' + '【解卦】\n' +
                '谦：代表谦虚不出风头之意。主吉中带小凶。谦卦显示除了谦虚不与人争之外，还有一个很重要的意义，就是被「压抑」！在目前到处都充满竞争的时代里，彼此竞争、排斥，是很正常的一种现象，所以，谦卦就是告诉你，虽然一时被压制了，但是忍辱负重，很快就会再意气风发。因此不要气馁、灰心。另外，也显示，事事不可强出头，目前局势下，谦让不意气用事，才可得吉象。\n' +

                '\n' + '【解释】\n' +
                '要谦虚才可得到助力。\n' +
                '\n' + '【特性】\n' +
                '待人谦恭，随和，交游广泛，喜休闲活动，重视生活安排及财物的规划，并不断追求新知。\n' +
                '\n' + '【运势】\n' +
                '吉利平安，欣欣向荣之象，谦者前途大利，骄者横行招败。故以君子谦德，纵涉大川而无险也，卦利君子。正所谓谦受益满招损也。\n' +
                '\n' + '【家运】\n' +
                '幸福之吉运。\n' +
                '\n' + '【疾病】\n' +
                '防旧病发，腹部或背部之疾，但仍属吉象。\n' +
                '\n' + '【胎孕】\n' +
                '生男儿无灾。生女儿亦无灾。\n' +
                '\n' + '【子女】\n' +
                '子女多温顺，事亲孝，可贺也。\n' +
                '\n' + '【周转】\n' +
                '诚实谦恭，可成也。\n' +
                '\n' + '【买卖】\n' +
                '终成，有是非。获薄利，益也。\n' +
                '\n' + '【等人】\n' +
                '准时到达。\n' +
                '\n' + '【寻人】\n' +
                '未见，待一段时间自回。\n' +
                '\n' + '【失物】\n' +
                '难以发现，有失窃的可能。\n' +
                '\n' + '【外出】\n' +
                '顺利，但应准备充分，途中小心，渐可忽视安全。\n' +
                '\n' + '【考试】\n' +
                '金榜题名。\n' +
                '\n' + '【诉讼】\n' +
                '宜速和解，拖延不利。\n' +
                '\n' + '【求事】\n' +
                '吉利，可成。\n' +
                '\n' + '【改行】\n' +
                '可行。\n' +
                '\n' + '【开业】\n' +
                '开业者吉利，但诸事勿太急，以谦则受益。',
                '\n' + '【事业】\n' +
                '十分顺利，事业可以获得成功，但必须符合实际，顺应潮流，且得自己努力奋斗，树立远大目光，尤其不可因事业的顺利而放松谨慎小心的态度，陷于懒散享乐。否则，必将后悔莫及。\n' +
                '\n' +
                '【经商】\n' +
                '时运已到，应大胆行动，即使发生挫折，也可以很快化险为夷。应加强同他人的合作，开诚布公，共同开发事业。\n' +
                '\n' +
                '【求名】\n' +
                '天资聪颖，如能坚守正直的品德，严格要求自己，努力上进，不因有成绩骄傲满足，停止前进，而是兢兢业业，不惰不怠，必定可以有重大成功。\n' +

                '\n' + '【外出】\n' +
                '充分准备，从容出发，会十分顺利和平安。\n' +
                '\n' +
                '【婚恋】\n' +
                '十分顺利，一生顺利和吉祥，但万不可掉以轻心，更不可玩弄感情，否则将会出现悲剧。\n' +
                '\n' +
                '【决策】\n' +
                '一生顺利，不会出现大的波折，容易比较顺利得到自己应该得到的。但关键在于自己的努力和奋斗，不得耽于安乐，不可玩物丧志。豁达、大度更有利于健康和长寿。一旦遇到意外的灾害，只要时时严格要求自己，坚守纯正，乐不忘忧，居安思危，必可转危为安。\n' +

                '\n' + '【解卦】\n' +
                '豫：代表高兴悦乐之意，主小吉象。此卦显示做事积极、有力，性情柔和、圆融，充满令人喜乐之气象。凡事可尽力去做，无大碍。婚姻感情，可得良缘。\n' +

                '\n' + '【解释】\n' +
                '顺应天命。\n' +
                '\n' + '【特性】\n' +
                '稳重，有组织领导的能力，心地善良乐于助人，做事积极务实有效率。精于计划，有金钱观念。\n' +
                '\n' + '【运势】\n' +
                '此卦象征万物欣畅，如意安泰，可得上辈之助。切不可因繁华而怠惰，或沉醉于声色欢场之中。好运不常有，当好好把握。\n' +
                '\n' + '【家运】\n' +
                '吉象。切不可迷于情而招破相。\n' +
                '\n' + '【疾病】\n' +
                '凶险之卦，注意腹腔、肝胆之疾。\n' +
                '\n' + '【胎孕】\n' +
                '安康，会喜得贵子。\n' +
                '\n' + '【子女】\n' +
                '儿女均能相亲相爱，前程美好。\n' +
                '\n' + '【周转】\n' +
                '稍有阻，过后顺利。\n' +
                '\n' + '【买卖】\n' +
                '好时机。秋更吉，有贵人。\n' +
                '\n' + '【等人】\n' +
                '途中有碍，一定会来。\n' +
                '\n' + '【寻人】\n' +
                '东方或西南方可寻，或他人自来相寻。\n' +
                '\n' + '【失物】\n' +
                '难以寻回。\n' +
                '\n' + '【外出】\n' +
                '充分准备，从容出发，会十分顺利和平安。\n' +
                '\n' + '【考试】\n' +
                '金榜题名。\n' +
                '\n' + '【诉讼】\n' +
                '有始而无终，易被多人连累，破财、受惊。\n' +
                '\n' + '【求事】\n' +
                '可得上辈提拔，有希望。\n' +
                '\n' + '【改行】\n' +
                '吉，大有可为。\n' +
                '\n' + '【开业】\n' +
                '开业者大吉大利之象。',
                '\n' + '【事业】\n' +
                '对社会和人生有正确的估价，重视人际关系，善于同他人合作，事业会很顺利。为了保证取得更进一步的成功，不得贪图小利，向比自己优秀的人学习，择善而从，心存诚信、努力开拓事业。\n' +
                '\n' +
                '【经商】\n' +
                '顺利。在与他人的真诚合作下，能够达到预期的目的。在竞争中应保持清醒头脑，从大处、长远考虑，遵守商业道德，保持至诚的态度对待顾客和同行，会保持竞争优势。\n' +
                '\n' +
                '【求名】\n' +
                '在自己刻苦钻研的基础上，虚心向有德有才的师长学习，借助外界的力量，促使自己更快的进步，实现个人的理想。\n' +

                '\n' + '【外出】\n' +
                '吉利。可以改变处境。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方情投意合可以成功，家庭和睦，但若迷恋于情爱，会影响双方的事业，务必注意。\n' +
                '\n' +
                '【决策】\n' +
                '个人成长道路并不十分顺利，但由于谦虚、随和，人际关系好，尤其能够得到长辈的器重，各种事都能在他人的帮助和关心下成功。但务必注意追随他人应有的立场和原则，不可趋炎附势，招致祸凶。\n' +

                '\n' + '【解卦】\n' +
                '随：代表随遇而安，一切随缘、随和。主吉象。事事均可依随着自己的心思计划来执行，会顺利地达成。事业、投资、理财、感情，均会有令人满意的发展和收获。\n' +

                '\n' + '【解释】\n' +
                '跟随上司主意行事。\n' +
                '\n' + '【特性】\n' +
                '交际佳，贵人运多，善谋略，为辅佐良才，保守，被动。\n' +
                '\n' + '【运势】\n' +
                '物事均有去旧迎新之吉象，凡事与他人互相通达、协商，可名利双收。倘若三心二意，或独立单行，不听人言劝，有自招灾祸之虞。\n' +
                '\n' + '【家运】\n' +
                '本身有相当不错的收入，富有之象。但不可过于放纵，守操节为宜。\n' +
                '\n' + '【疾病】\n' +
                '病重，须长期治疗，注意口腔、肝、足患等症。\n' +
                '\n' + '【胎孕】\n' +
                '无碍，无惊险。\n' +
                '\n' + '【子女】\n' +
                '亲情融洽，与六亲和睦，幸福之象。\n' +
                '\n' + '【周转】\n' +
                '和气相商有利，意气用事则难成。\n' +
                '\n' + '【买卖】\n' +
                '勿顽强固执，则交易有利。否则失之，有阻。\n' +
                '\n' + '【等人】\n' +
                '会迟到。\n' +
                '\n' + '【寻人】\n' +
                '不必去找了，过些时日会自己回来，勿急。\n' +
                '\n' + '【失物】\n' +
                '即将疏忽之时，会突然察觉。\n' +
                '\n' + '【外出】\n' +
                '吉利。可以改变处境。\n' +
                '\n' + '【考试】\n' +
                '成绩理想。\n' +
                '\n' + '【诉讼】\n' +
                '宜决意和解，目前无忧，但不久须防有祸。\n' +
                '\n' + '【求事】\n' +
                '虽吉，然若能谦逊待人，多接受别人意见，会得人提拔。\n' +
                '\n' + '【改行】\n' +
                '有充份计划者可行。\n' +
                '\n' + '【开业】\n' +
                '吉利。',
                '\n' + '【事业】\n' +
                '运气处于不佳状态，或因外界条件，或因个人因素所致。唯有谨慎分析原因，找出弊端，坚决，大胆，不惜冒险，战胜阻力。这时一要头脑冷静，二要向高明的人请教，必能创造业绩。\n' +
                '\n' +
                '【经商】\n' +
                '诸多不利困扰着自己，不宜继续走老路。应全面分析市场动向，当机立断，另外找寻出路。\n' +
                '\n' +
                '【求名】\n' +
                '会遇到许多困难和不利，应看作是对自己的考验，要以百倍的勇气，继续奋斗。同时，虚心寻找自己的不足，加以补救。\n' +

                '\n' + '【外出】\n' +
                '宜推迟或提前。否则会出现危险，务必小心谨慎。\n' +
                '\n' +
                '【婚恋】\n' +
                '不顺利。双方如情投意合，则应全力以赴促成此事，夫妇应共同努力维系家庭。\n' +
                '\n' +
                '【决策】\n' +
                '生不逢时，家道或事业有中落现象，个人陷入多事之秋。唯有正视现实，勤奋勉励，不屈不挠，有所作为，甚至不惜冒险，挽救败落状况。必要时还需耐心等待时机。\n' +

                '\n' + '【解卦】\n' +
                '蛊：代表受到蛊惑、毒害。主大凶象。蛊毒是因内在腐朽而生虫化毒，显示问题的根因存在已久，却未诚实面对处理而导致愈来愈严重。易做出错误的决策，慨大都被人所迷惑、而一时心神错乱引致祸事。\n' +

                '\n' + '【解释】\n' +
                '中蛊毒，无可救药。\n' +
                '\n' + '【特性】\n' +
                '内心犹豫，猜疑心重，多事之秋，宜大胆革新。\n' +
                '\n' + '【运势】\n' +
                '诸事不如意、积弊已深，未能进展，气运杂乱。逢此宜鼓起勇气，大胆加以革新，有内忧外患之象。\n' +
                '\n' + '【家运】\n' +
                '家庭易生烦心之事，不彻底改革有破家、损财之象。\n' +
                '\n' + '【疾病】\n' +
                '宜把握治疗时机，冬季占得险象，为内脏恶性疾病。\n' +
                '\n' + '【胎孕】\n' +
                '胎有惊。\n' +
                '\n' + '【子女】\n' +
                '双亲不堪其忧之苦象耳。\n' +
                '\n' + '【周转】\n' +
                '信用不好，周转无望。\n' +
                '\n' + '【买卖】\n' +
                '交易不成，另谋生计为要。\n' +
                '\n' + '【等人】\n' +
                '中途有变，不会来。\n' +
                '\n' + '【寻人】\n' +
                '因家庭感情失和而出走，或与人私奔，于东南或东北。\n' +
                '\n' + '【失物】\n' +
                '找不回来，遗失似是木制工艺品，在门口或旅馆遗失，或者是寄失了。\n' +
                '\n' + '【外出】\n' +
                '宜推迟或提前。否则会出现危险，务必小心谨慎。\n' +
                '\n' + '【考试】\n' +
                '落榜。\n' +
                '\n' + '【诉讼】\n' +
                '慎防桃色纠纷，会拖一段时间，不易解决。\n' +
                '\n' + '【求事】\n' +
                '不利。\n' +
                '\n' + '【改行】\n' +
                '弃旧迎新，或革新内部人事。\n' +
                '\n' + '【开业】\n' +
                '开业者不宜，有损财或官符之灾。',
                '\n' + '【事业】\n' +
                '正是成功的极好时机，务必抓紧，不可失去良机。但却不可就此满足，时运会很快消失，一定要从长计议，注意总结经验，团结他人，共同开拓新领域。\n' +
                '\n' +
                '【经商】\n' +
                '会十分顺利，并有收获，但须随时注意市场动向，兢兢业业，防止出现意外的事故，尤其应妥善处理人际关系。\n' +
                '\n' +
                '【求名】\n' +
                '自己的努力已到了收获的季节，应更加虚心，严格要求自己，谨慎小心地实现自己的目标。很可能出现意外，应以刚强的意志去从容对付，过于软弱将失去机会。\n' +

                '\n' + '【外出】\n' +
                '在准备充分的情况下，立即行动，不必再犹豫。\n' +
                '\n' +
                '【婚恋】\n' +
                '加紧进行，全力以赴，双方以诚信相待，美满、和谐。\n' +
                '\n' +
                '【决策】\n' +
                '头脑灵活，善于同他人交往，并有主见，具有领导才能。务必加强个人的全面修养，诚信待人，树立良好的个人威望，尤其要有远大的眼光，长久的计划，可以保证事业的发达和前程的光明。所作为，甚至不惜冒险，挽救败落状况。必要时还需耐心等待时机。\n' +

                '\n' + '【解卦】\n' +
                '临：代表大驾光临，贵人来到之意。主吉象。临又为「君王临幸」之意，是上者对下者之象，在事业投资，为贵人相助之意。在感情则有期待而至的欢喜感。\n' +

                '\n' + '【解释】\n' +
                '好事即将来临。\n' +
                '\n' + '【特性】\n' +
                '待人热心，心胸开阔，乐于助人，尊重他人，体会别人心意，谦恭有礼。自然贵人多来帮助，财运丰厚。\n' +
                '\n' + '【运势】\n' +
                '运势渐增，诸事亨通如意，前途有望，仕途有成，可得贵人相助，上下安合。以和顺可奏大功，宜诚心待人，可诸事顺畅，急进或忘形者，有破财之象。\n' +
                '\n' + '【家运】\n' +
                '幸福圆满，切勿意气用事，否则有反。\n' +
                '\n' + '【疾病】\n' +
                '病情较轻，为肠胃，口腔之疾，并防泌尿生殖系统病变。\n' +
                '\n' + '【胎孕】\n' +
                '生贵子。求佛及家神则安。产妇慎饮食。\n' +
                '\n' + '【子女】\n' +
                '得孝顺之儿女。\n' +
                '\n' + '【周转】\n' +
                '可成。\n' +
                '\n' + '【买卖】\n' +
                '防口舌，勿过急，和顺交易有利。\n' +
                '\n' + '【等人】\n' +
                '有好消息且准时到。\n' +
                '\n' + '【寻人】\n' +
                '西方或西南方，不必急，目下其人已动，自回。\n' +
                '\n' + '【失物】\n' +
                '不能立即发现，很久才能找回。可能被埋在地下。是件会发声或震动的物件。\n' +
                '\n' + '【外出】\n' +
                '在准备充分的情况下，立即行动，不必再犹豫。\n' +
                '\n' + '【考试】\n' +
                '金榜题名。\n' +
                '\n' + '【诉讼】\n' +
                '宜柔不宜刚，因本欲欺他人，反伤自己。\n' +
                '\n' + '【求事】\n' +
                '顺利可成。\n' +
                '\n' + '【改行】\n' +
                '吉利。\n' +
                '\n' + '【开业】\n' +
                '开业正得其时，大吉大利。',
                '\n' + '【事业】\n' +
                '事业已出现不顺利的迹象，务必谦虚慎重，高瞻远瞩，尤忌短期行为，心胸开阔，注意搞好人际关系，团结他人，不宜轻率行动。必要时不妨投靠德行高的人，以图再度发展。\n' +
                '\n' +
                '【经商】\n' +
                '市场形势不稳定，正处于变动，应谨慎小心，随时观察动向，从长计议，做好退却的准备。\n' +
                '\n' +
                '【求名】\n' +
                '贵在自知之明，虚心向他人求教，端正处世态度，尤其不可自我满足，更须守中持正，树立远大理想，勿丧失个人追求的目标，勿偏离正道。\n' +

                '\n' + '【外出】\n' +
                '有风险，择日而行。\n' +
                '\n' +
                '【婚恋】\n' +
                '不顺利，双方应经受住考验，从长计议，会有理想的结果。\n' +
                '\n' +
                '【决策】\n' +
                '具有组织能力，适宜担任负责工作，但人生旅途多曲折，必须注意培养自己的预见性和观察力，办事务必公正，实事求是，注意人心动向，关心他人。尤其不可以陷入小人之道和近视行为之中。应有决断精神，不可盲从，必要时可进行策略依附。\n' +

                '\n' + '【解卦】\n' +
                '观：代表观察、观看，是个最标准的状况卦，吉凶未定。凡事不可轻下决定，需要再观察一下局势再做打算。尤其是对长官、上位者的态度更是要察言观色，不可轻忽大意。\n' +

                '\n' + '【解释】\n' +
                '尚在观察中。\n' +
                '\n' + '【特性】\n' +
                '有创意，喜欢开发新事物，见解新颖，爱旅行，观摩他人，研究比较，不断修正反省自己。\n' +
                '\n' + '【运势】\n' +
                '处在有危险不稳的时运，耍多观望时势之利弊，待机行事，于人事物，须以诚待之，才能感化而幸免陷入困难也。\n' +
                '\n' + '【家运】\n' +
                '正处于下坡时段，要知固守进退之机，听取长辈意见有助也。\n' +
                '\n' + '【疾病】\n' +
                '有渐恶化之象，病情变化多，为腹、神经系统病症。\n' +
                '\n' + '【胎孕】\n' +
                '孕有不安之象。\n' +
                '\n' + '【子女】\n' +
                '儿女将来有成就幸福耳。\n' +
                '\n' + '【周转】\n' +
                '虽有好言，实际内在空虚。无指望居多。\n' +
                '\n' + '【买卖】\n' +
                '交易有碍。应在戌亥日可成，否则难成。\n' +
                '\n' + '【等人】\n' +
                '观望必有等待，而来者有随他人去之象。\n' +
                '\n' + '【寻人】\n' +
                '此人到处漂泊，行踪不定，有险象。目前在西南方，宜丑未日见。\n' +
                '\n' + '【失物】\n' +
                '若能细心四察，可以找回来。\n' +
                '\n' + '【外出】\n' +
                '有风险，择日而行。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '小人当道，愈争论惹起纷争愈多，但终会和解。\n' +
                '\n' + '【求事】\n' +
                '不易。\n' +
                '\n' + '【改行】\n' +
                '擅自主张不宜，若由他人提拔则可。\n' +
                '\n' + '【开业】\n' +
                '开业者不宜。',
                '\n' + '【事业】\n' +
                '困难与阻力十分大，应以坚强的意志，果敢的行为，公正无私的态度去战胜种种厄运，争取事态好转。为了早日化险为夷，必要时可采取强硬手段，甚至诉诸法律。\n' +
                '\n' +
                '【经商】\n' +
                '处于不利的时候，头脑冷静，明察形势，寻求机遇，不为眼前小利所诱，不发非分之财。认真听取忠告，遵守法纪，秉公办事，不得徇私情，更警惕不得触犯刑律。\n' +
                '\n' +
                '【求名】\n' +
                '自己的努力尚不为人所知，不可急于求成，受到挫折应看作是对自己的考验，持之以恒，必能成功。\n' +

                '\n' + '【外出】\n' +
                '另择他日为佳。若非出门不可，务必充分准备，小心为是。\n' +
                '\n' +
                '【婚恋】\n' +
                '初不顺利，须有顽强精神可以取得满意的结果，不可以个的情绪左右家庭事务。\n' +
                '\n' +
                '【决策】\n' +
                '一生不平坦，会遇到挫折和磨难，但应看作是对个人的考验，应认真总结经验教训，以更为坚强的意志，不屈不挠，继续前进。经过锻炼，各方面都会有较大的进展，终将进入光明境地，取得重大成就。\n' +

                '\n' + '【解卦】\n' +
                '噬嗑：代表如鲠在喉、难以决策。主吉凶未定，是个状况卦，有点偏小凶。也如同「鸡肋」一般，食之无味、弃之又可惜！是需要坚决下个决心的时候了！\n' +

                '\n' + '【解释】\n' +
                '被吞没掉。\n' +
                '\n' + '【特性】\n' +
                '生命力强，坚守奋斗，百折不挠，对自己要求很高，不断充实自己，突破超越提升。克服困难，宜往大企业大单位任职，较能有所表现。\n' +
                '\n' + '【运势】\n' +
                '诸事不遂心，多受阻害。有纷争，无法前进之时，宜守原则，不为利诱，以免被人中伤。然而一般人多有无法逃避利诱者，故必受祸，若能和气处事为安，更须突破困难方可通达。\n' +
                '\n' + '【家运】\n' +
                '家庭有不和现象，亦常有无法沟通之阻碍。除非彼此能够排除成见，否则无法融洽相处。\n' +
                '\n' + '【疾病】\n' +
                '病情严重，注意足部，心脏、神经系统病变。\n' +
                '\n' + '【胎孕】\n' +
                '胎有惊。\n' +
                '\n' + '【子女】\n' +
                '儿女倔强、反抗。\n' +
                '\n' + '【周转】\n' +
                '卦已明示有困难，但诚心或可圆满。\n' +
                '\n' + '【买卖】\n' +
                '多阻挠且多是非。但若积极推进，不要放弃，则有利，可成。\n' +
                '\n' + '【等人】\n' +
                '受到阻碍，不能来。\n' +
                '\n' + '【寻人】\n' +
                '必因发生争端或涉嫌重大事件而出走。去庙观市井求之，东、南方。\n' +
                '\n' + '【失物】\n' +
                '有被人捷足捡走的可能，寻回的机会不大。\n' +
                '\n' + '【外出】\n' +
                '另择他日为佳。若非出门不可，务必充分准备，小心为是。\n' +
                '\n' + '【考试】\n' +
                '不佳。\n' +
                '\n' + '【诉讼】\n' +
                '宜采取主动，据理力争。\n' +
                '\n' + '【求事】\n' +
                '多生枝节，不利。唯有积极去谋求方有所成就。\n' +
                '\n' + '【改行】\n' +
                '有碍，但耐心排除可成。\n' +
                '\n' + '【开业】\n' +
                '开业吉，中途有是非，宜耐心解决。',
                '\n' + '【事业】\n' +
                '顺利，小有成绩，宜即时总结经验，图谋更大的发展。树立信心，不计较一时的得失。追求实质性的内容，慎重诸事，不得随波逐流，寻求有实力的人物提携自己。\n' +
                '\n' +
                '【经商】\n' +
                '开初会遇到困难，不应灰心，宜全面分析形势，大胆开拓，勇敢地投入，联合他人，共同合作，根据市场，运转业务。\n' +
                '\n' +
                '【求名】\n' +
                '不可过多地注重外表，而应着重于内在的素质。大胆地推荐自己，但应坚持自己的理想。\n' +

                '\n' + '【外出】\n' +
                '慎远行。但只要准备充分，注意安全，不会出麻烦。\n' +
                '\n' +
                '【婚恋】\n' +
                '慎重选择，有合适者，则应迫切追求，不可三心二意，务必专一。注重对方的内在气质，勿为表面现象所欺骗。\n' +
                '\n' +
                '【决策】\n' +
                '喜欢注重外表，缺乏追求内在气质的恒心。生性较为浮躁，好务虚名，往往因此而碰壁，或半途而废。在事实的教育下，改变不切实际的做法，明察社会和人生，会有所收敛，变得自持，注重自身修养，成全事业，中年以后会大有作为，老年仍可发挥余热。\n' +

                '\n' + '【解卦】\n' +
                '贲：代表美丽得外表装饰，主小凶象。「金玉其外、败絮其中」，经过有心修饰、伪装的外表或事情，事业投资、感情皆不宜。慎防有心的小人、伪君子。\n' +

                '\n' + '【解释】\n' +
                '表面好看，里表不一。\n' +
                '\n' + '【特性】\n' +
                '重精神，轻物质，有艺术文学专才，感情敏锐，追求理想。在情爱上，易留下刻骨的回忆。\n' +
                '\n' + '【运势】\n' +
                '卦象是象征其人外表好看，内在空虚，因此，必须充实自己，凡事深思远虑，与人和睦相处，能获得意外利益。切忌因小失大，更无须为了掩饰外观而造成不必要的损失。凡事踏实，按部就班为是。\n' +
                '\n' + '【家运】\n' +
                '外美内虚而不为外人所知，应即时整顿家庭经济，使其安稳、充实，更须安份自持，莫以虚浮来掩饰空虚。踏实为上策。\n' +
                '\n' + '【疾病】\n' +
                '病情危重，为心脏、胸腹等病。\n' +
                '\n' + '【胎孕】\n' +
                '孕有不安之象。\n' +
                '\n' + '【子女】\n' +
                '子女身体虚弱，美丽而得人缘。\n' +
                '\n' + '【周转】\n' +
                '不宜大，小调尚可。\n' +
                '\n' + '【买卖】\n' +
                '有贵人，速决有利。\n' +
                '\n' + '【等人】\n' +
                '会来。但远方者不来。\n' +
                '\n' + '【寻人】\n' +
                '已在东北或南方亲友家，可寻。\n' +
                '\n' + '【失物】\n' +
                '在家中遗失，将可寻回。不妨试试注意门脚的地方。\n' +
                '\n' + '【外出】\n' +
                '慎远行。但只要准备充分，注意安全，不会出麻烦。\n' +
                '\n' + '【考试】\n' +
                '不理想。但人家以为你读得不错。\n' +
                '\n' + '【诉讼】\n' +
                '宜速求解决，不可拖延，可得贵人调解。\n' +
                '\n' + '【求事】\n' +
                '条件勿太苛求，可成。\n' +
                '\n' + '【改行】\n' +
                '改行宜，但勿夸大或太过。\n' +
                '\n' + '【开业】\n' +
                '开业者吉利之象。',
                '\n' + '【事业】\n' +
                '时运不佳，乃大势所趋，个人只能顺应时势而暂停行动，静观待变，不可冒险，积极创造条件，增强实力，谨慎隐忍，勿与小人同流。时来运转，成就事业，为期不远。\n' +
                '\n' +
                '【经商】\n' +
                '已从高峰转入低落，进入不景气时期，需要认真总结经验教训，进行整顿，尤其不可孤注一掷。景气形势一旦到来，可顺势从容再起。\n' +
                '\n' +
                '【求名】\n' +
                '成功的可能性不大，不宜执意强求，应继续创造条件，努力提高自己，充实处世的本领，增强实力，待机东山再起。\n' +

                '\n' + '【外出】\n' +
                '暂缓为好。可在充分准备之后动身。\n' +
                '\n' +
                '【婚恋】\n' +
                '务须慎重考虑，除非有十分般配者，不要随便进行。双方应注意维护家庭的和睦。\n' +
                '\n' +
                '【决策】\n' +
                '一生曲折较多，往往隐入不顺利的境况，甚至绝境。但可绝处逢生。只要冷静分析形势，顽强拼搏，一定会彻底改变命运，获得美好的结果，为他人惊羡。关键是不可急于求成，尤其不可冒险。须顺应时势，并防小人陷害。\n' +

                '\n' + '【解卦】\n' +
                '剥：代表剥落之意，主大凶象。地动山崩，一时群山崩落为平地，代表很严重的动荡、变化，而致使重大的伤害、不幸的产生。最易有急症、意外的血光之灾。事业、感情、婚姻，得此卦需有心理准备，所有之前的努力可能要重新再来了。唯此卦利于重新到外地发展，或另寻对象也可。\n' +

                '\n' + '【解释】\n' +
                '剥削，剥落。\n' +
                '\n' + '【特性】\n' +
                '反应灵敏，思想新颖，走在时代尖端，有研发长才，有意料之外的机会和柳暗花明之遭遇。\n' +
                '\n' + '【运势】\n' +
                '运带破、带衰，有不利之灾祸，不可抱持野心，自作聪明，否则自掘坟坑。得此卦者，必有与欢场女子耗费钱财而又被陷害之苦恼，且有受部下连累而损财，为不得志之时运也。\n' +
                '\n' + '【家运】\n' +
                '正处于没落之时，辛苦劳心，有心挽回者可得安，否则有别离之险象。\n' +
                '\n' + '【疾病】\n' +
                '病情恶化之象，防头痛、肠胃、性病等疾。\n' +
                '\n' + '【胎孕】\n' +
                '虚惊之凶象。\n' +
                '\n' + '【子女】\n' +
                '缘薄，不和，体弱。\n' +
                '\n' + '【周转】\n' +
                '无望。\n' +
                '\n' + '【买卖】\n' +
                '难成。若成亦有损。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '在东北方的山上或水边寻之。\n' +
                '\n' + '【失物】\n' +
                '目前还隐藏在家中地上，可惜无法发现，不久会被扫走。\n' +
                '\n' + '【外出】\n' +
                '暂缓为好。可在充分准备之后动身。\n' +
                '\n' + '【考试】\n' +
                '落榜。\n' +
                '\n' + '【诉讼】\n' +
                '因财而起纷争，小人当道，宜避之则吉。\n' +
                '\n' + '【求事】\n' +
                '顺而止，要谨慎行事，不可粗心大意。\n' +
                '\n' + '【改行】\n' +
                '不利，必损财。\n' +
                '\n' + '【开业】\n' +
                '开业者有阻碍，切勿意气用事，否则有害，宜再待时。',
                '\n' + '【事业】\n' +
                '已经渡过了困难时期，开始进入积极行动的阶段。但务必抓住时机，当机立断，却不可急躁，且应时常反省个人行动，严于修身，勇往直前，可望成功。\n' +
                '\n' +
                '【经商】\n' +
                '可大胆投资，与志同道合的伙伴坦诚合作，打开市场，勿因挫折而退缩。更可以打入外地，坚持商业道德，循序渐进，一定会有所赢利。\n' +
                '\n' +
                '【求名】\n' +
                '时机正在成熟，不要急于求成，继续奠定基础，机遇一旦到来，务必要抓住，必将转运。最忌自伤意志。\n' +

                '\n' + '【外出】\n' +
                '无灾无祸，且有收获。\n' +
                '\n' +
                '【婚恋】\n' +
                '顺利。但切不可性急，应冷静考虑。家庭的美满幸福，靠严于律己维持。\n' +
                '\n' +
                '【决策】\n' +
                '性格活泼，精力旺盛，但因急躁情绪会造成事业损失。一定要冷静思考，勇于改正错误，必能重振事业。此时应抓住时机，明确方向，灵活机敏，脚踏实地改变处境。一旦出现意外，宜当机立断，暂行退让。如能准确驾驶自己，努力奋斗，成就事业十分顺利。\n' +

                '\n' + '【解卦】\n' +
                '复：代表重复再来、周而复始之意。是个状况卦，吉凶未定。好事会重复，但坏事也会重复再来的。此卦显现一种循环的状态，可能是好、也可能是坏。\n' +

                '\n' + '【解释】\n' +
                '一年复始、万象更新。\n' +
                '\n' + '【特性】\n' +
                '为最佳辅佐人才，做事谨慎，调理分明，精企划，组织，一生财运丰足，乐于助人，排解别人困难，人缘佳。\n' +
                '\n' + '【运势】\n' +
                '一阳来复，万事苏伸，不久就会好转。初时不宜急进，脚踏实地则有一本万利、开运亨通之象也。\n' +
                '\n' + '【家运】\n' +
                '昌隆茂盛，渐曙光明之象。\n' +
                '\n' + '【疾病】\n' +
                '病防发，但可治，为肝、肠胃、神经系统病变。\n' +
                '\n' + '【胎孕】\n' +
                '主产母有惊惧之灾。\n' +
                '\n' + '【子女】\n' +
                '辛劳之后必得享福，儿女将来会出人头地。\n' +
                '\n' + '【周转】\n' +
                '难求，反覆终可入手。\n' +
                '\n' + '【买卖】\n' +
                '有是非，故勿太急，可有利。\n' +
                '\n' + '【等人】\n' +
                '会迟到。\n' +
                '\n' + '【寻人】\n' +
                '自身反覆终人不动，但七日内可回。\n' +
                '\n' + '【失物】\n' +
                '有失而得之可能。\n' +
                '\n' + '【外出】\n' +
                '无灾无祸，且有收获。\n' +
                '\n' + '【考试】\n' +
                '愈考愈好。\n' +
                '\n' + '【诉讼】\n' +
                '多人之事，但经多次扰攘才可解决。\n' +
                '\n' + '【求事】\n' +
                '勿太急，可成。\n' +
                '\n' + '【改行】\n' +
                '可行，但宜沉着，勿急躁。\n' +
                '\n' + '【开业】\n' +
                '开业者可行，但宜沉着，勿急躁。',
                '\n' + '【事业】\n' +
                '贵自知之明，从个人实际出发，不抱非分之想，脚踏实地，勤奋努力，检点行为，防意外灾祸。不计较得失，诚心追求，待机而动，事业必成。\n' +
                '\n' +
                '【经商】\n' +
                '切勿勉为其难，遵循市场规律，讲究商业道德，屏弃投机心理，勿贪暴利，经过辛勤努力，日积月累，必将成功。\n' +
                '\n' +
                '【求名】\n' +
                '以良好的动机，刻苦努力，丢弃幻想，但问耕耘，不问收获，终将有所获。\n' +

                '\n' + '【外出】\n' +
                '不存妄想则平安无事。为谋私利或邪念而行则大为不利，不宜行。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方务以诚相待，绝不可抱以轻率的态度，亦不宜急于求成、顺其发展，水到渠成。\n' +
                '\n' +
                '【决策】\n' +
                '精力充沛，富有上进心，可有作为。但务必勤勉努力，诚心诚恳，奋发向上，刻苦钻研。不可因小有成就而得意忘形。不必追求所得，而应追求实干。遵循规律办事，等待时机再行动。尤其应清除非份的念头。这样，可实现自己的理想。\n' +

                '\n' + '【解卦】\n' +
                '无妄：代表不要有一些空幻的想法，或是你的想法可能有点虚妄不切实际，主小凶。建议凡事要务实、踏实一点。也代表目前所处的状况，是有一厢情愿的心态，或是有点「妄想症」的不切实际心理。\n' +

                '\n' + '【解释】\n' +
                '无妄之灾。\n' +
                '\n' + '【特性】\n' +
                '言词犀利，易生是非，愤世嫉俗，不流俗，求知欲强，重精神层面。\n' +
                '\n' + '【运势】\n' +
                '无妄的道理是提醒要真实无虚，踏实。故若做事操守而能坚贞固执者吉。若不行正道，行为不检点者，必然有灾祸至也。切忌沉醉于利、欲之中，慎之。\n' +
                '\n' + '【家运】\n' +
                '有不和之情况，因受外界诱惑而心乱，须谨慎，否则导致破运。\n' +
                '\n' + '【疾病】\n' +
                '不宜意气用事，应尽力疗养为宜，属呼吸系统，头、足之疾病。\n' +
                '\n' + '【胎孕】\n' +
                '或有惊，终无碍。\n' +
                '\n' + '【子女】\n' +
                '注意与其父不和而离家出走。\n' +
                '\n' + '【周转】\n' +
                '诚心有望，不诚免谈。\n' +
                '\n' + '【买卖】\n' +
                '若不要过份苛求，有利。\n' +
                '\n' + '【等人】\n' +
                '一定会来。但有不欢而散之象，宜以和为贵。\n' +
                '\n' + '【寻人】\n' +
                '已走远，不易寻找，东北或西北之向。\n' +
                '\n' + '【失物】\n' +
                '找不到，似已遗失。\n' +
                '\n' + '【外出】\n' +
                '不存妄想则平安无事。为谋私利或邪念而行则大为不利，不宜行。\n' +
                '\n' + '【考试】\n' +
                '笔试有利，口试不利。\n' +
                '\n' + '【诉讼】\n' +
                '因女人或死亡之事起纷争，宜和解，否则招凶。\n' +
                '\n' + '【求事】\n' +
                '毫无头绪，勿过于草率为宜。\n' +
                '\n' + '【改行】\n' +
                '不宜勉强。\n' +
                '\n' + '【开业】\n' +
                '开业者可行。',
                '\n' + '【事业】\n' +
                '务必端正行为，滋养德行。行为应大胆，果断，但需适可而止，注意休整，决不可冒险闯进。宜注意吸收前人的经验教训，有所畏而不行，否则必有危害。再取得成就之后，应适可而止，不可贪图眼前小利而走向反面。\n' +
                '\n' +
                '【经商】\n' +
                '本着循序渐进的原则，把握正当的商业道德，会有重大收获。此时，切忌贪心不足，而应见机而退，保守事业，深思熟虑，再图发展，一味自信冒进，决不可取。\n' +
                '\n' +
                '【求名】\n' +
                '注意积累学问，提高德行，勿三天打鱼两天晒网，持之以恒，且以学问、知识报效国家，抱负终将实现。\n' +

                '\n' + '【外出】\n' +
                '不宜守于家中，出行必得，且畅通无阻。不过应处处小心谨慎。\n' +
                '\n' +
                '【婚恋】\n' +
                '顺利。建立在共同勤俭持家的基础上，相互坦诚而待，美满幸福。\n' +
                '\n' +
                '【决策】\n' +
                '蓄财的人，但决不可抱投机取巧心理，得靠个人诚实工作，多动心机，真诚与他人合作，共创事业，困难时仍应持正道，尤其不可粗心大意，牢记成就大阻力也大的道理。\n' +

                '\n' + '【解卦】\n' +
                '大畜：代表会有很多的积蓄、不动产的来到，吉相之卦。可以考虑较大的投资案，只是现金周转可能会较不利。蓄也有守财小气之象。\n' +

                '\n' + '【解释】\n' +
                '大有积蓄。\n' +
                '\n' + '【特性】\n' +
                '反应快，追求新知，不断创新，研究新事物，待人谦虚，修养好。贵人多助，容易少年发达，年轻就会有所成就。\n' +
                '\n' + '【运势】\n' +
                '大凡诸事不可好高鹜远，脚踏实地、务实行事，始能成就大业。以蓄德为主可吉，若因得势而气盛凌人，目空一切者，终招失败之象。\n' +
                '\n' + '【家运】\n' +
                '须知蓄者以备急需也。明此道理，持之力行，则可亨通。\n' +
                '\n' + '【疾病】\n' +
                '病虽重，但可治，防胸肺，腹或手部之病，易积劳成疾。\n' +
                '\n' + '【胎孕】\n' +
                '占得此卦主生贵子。\n' +
                '\n' + '【子女】\n' +
                '儿女必声扬家风，可得幸福之象。\n' +
                '\n' + '【周转】\n' +
                '可成。\n' +
                '\n' + '【买卖】\n' +
                '再谈可成交，且有获大利之象也。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '途中虚险，若一人等待必有凶。若是出走者，不必寻，会自回。\n' +
                '\n' + '【失物】\n' +
                '有机会寻回。似是在圆形有盖盛存之物中。\n' +
                '\n' + '【外出】\n' +
                '不宜守于家中，出行必得，且畅通无阻。不过应处处小心谨慎。\n' +
                '\n' + '【考试】\n' +
                '上榜。\n' +
                '\n' + '【诉讼】\n' +
                '因房地产引起，有破财之象，但纠纷可解。\n' +
                '\n' + '【求事】\n' +
                '不利，宜再待时。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '开业者要有不怕挫折之决心全力以赴，有利可得。',
                '\n' + '【事业】\n' +
                '凡事得靠个人追求，自己奋斗，而不能依赖他人。更不得用不光彩的手段谋求财物。只要遵循正道，自食其力，慎言敏行，一定会有好的机遇。这时，经过一番艰苦奋斗，能够成功。\n' +
                '\n' +
                '【经商】\n' +
                '不得急于求成，初仅以勉强维持生活为限。谨慎小心，循序渐进，诚以待人，真诚与他人合作，因时制宜，不断追求新的开拓方向，必要时可适当冒险。\n' +
                '\n' +
                '【求名】\n' +
                '以丰富自身知识为目的，知足安贫，刻苦努力，成功的希望极大。\n' +

                '\n' + '【外出】\n' +
                '应慎重，不宜过多外出。如无充分准备，仓促出行，将可能会遇到凶险。\n' +
                '\n' +
                '【婚恋】\n' +
                '不可急于求成。关键在于加强自己的修养。一旦选中应全力追求，尤其不可三心二意。\n' +
                '\n' +
                '【决策】\n' +
                '注意道德修养，乐于尽力而为地去帮助他人，救人急难，不贪求不应得到的财物。因此，能受到他人的尊重，也能不断得到外来的援助，个人事业十分顺利，成功的可能性很大。尤其不可粗心大意，牢记成就大阻力也大的道理。\n' +

                '\n' + '【解卦】\n' +
                '颐：代表养也，主小吉带一点凶。养跟饮食有关，也代表养育、教育的意思，对情势需要充分了解，不要贸然的就下任何的决定。颐，又与口有关，故需慎防小人口舌之灾。\n' +

                '\n' + '【解释】\n' +
                '静卧，不向前冲。\n' +
                '\n' + '【特性】\n' +
                '有欠缺考虑、鲁莽妄动之害。若有怀阴谋，与人互相猜疑或争端者更不利。要守正道而善行之，凡事切忌任性非为。\n' +
                '\n' + '【运势】\n' +
                '先苦后甜，失败后成功型，物质金钱运佳，乐善好施，贵人多助。不断提升修养自己，容易得到社会好的名声。\n' +
                '\n' + '【家运】\n' +
                '陷于痛苦深渊，有身败名裂之象，宜改进言行，冷静反省，力求幸福才是。\n' +
                '\n' + '【疾病】\n' +
                '占病为凶，久病者险，为肠胃、咽喉、手足之疾。\n' +
                '\n' + '【胎孕】\n' +
                '宜求香火保佑。\n' +
                '\n' + '【子女】\n' +
                '过份溺爱反有害。尤其须注意儿女健康，以免日后因身体虚弱而不能获福。\n' +
                '\n' + '【周转】\n' +
                '是否成功，要看自己是否有诚心。\n' +
                '\n' + '【买卖】\n' +
                '守旧为宜，不宜新事所为，否则不利。\n' +
                '\n' + '【等人】\n' +
                '难来，中途有碍。\n' +
                '\n' + '【寻人】\n' +
                '不久相逢。东北或东之向。\n' +
                '\n' + '【失物】\n' +
                '尽快去找，似被物件上下夹着。\n' +
                '\n' + '【外出】\n' +
                '应慎重，不宜过多外出。如无充分准备，仓促出行，将可能会遇到凶险。\n' +
                '\n' + '【考试】\n' +
                '尚可。\n' +
                '\n' + '【诉讼】\n' +
                '我告他人终不能成，宜速和解。\n' +
                '\n' + '【求事】\n' +
                '不成。\n' +
                '\n' + '【改行】\n' +
                '不宜，勿勉强。\n' +
                '\n' + '【开业】\n' +
                '开业者不利，须再待时。',
                '\n' + '【事业】\n' +
                '事业出现隐患，甚至危机四伏，务必十分小心谨慎，唯有坚守中道，以谦虚和悦的手段，胆大心细，求助他人相助。必要时可采取十分手段，不拘常规，予以冒险。\n' +
                '\n' +
                '【经商】\n' +
                '操之过急，发展过快，宜收缩调整。竞争处于不利地步，切勿过度自信，更不得刚愎自用，宜随时观测风向，待机行动。在有确切把握之下，不妨冒险，争取时机的好转。\n' +
                '\n' +
                '【求名】\n' +
                '最忌不务实际，追求不切实际的虚名，以致盛名不符。唯以谦逊态度，谨慎行动，潜心努力，不怕世人指现，一往直前，追求最初的目标。\n' +

                '\n' + '【外出】\n' +
                '行动务必慎重，但不可过于拘泥，必要时不妨采取特殊行动。\n' +
                '\n' +
                '【婚恋】\n' +
                '自知之明最为重要，不可急于求成，应慎重考虑，并以正当手段，促成事情的成功。但也不排除必要的精明手段。\n' +
                '\n' +
                '【决策】\n' +
                '处在十分时期，若不能妥善处置，则极为不利，针对实际情况，既不可轻举妄动，也不可优柔寡断，而应刚柔相济，虚心征求他人意见，慎重行动，大胆追求，却又不怕流言，争取时局好转。\n' +

                '\n' + '【解卦】\n' +
                '大过：代表犯了一个很大的过错，主大凶。诸事不顺，切忌此时作任何的决策。需努力找出问题之所在，尽快解决才能扭转劣势。\n' +

                '\n' + '【解释】\n' +
                '极大过失。\n' +
                '\n' + '【特性】\n' +
                '心直口快，性急，易得罪他人，是非小人多，但交际层面广，朋友多，但也易受朋友之拖累。\n' +
                '\n' + '【运势】\n' +
                '诸事衰退，多烦恼，防官非及水险。有力而不从心，负担过重之象。逢挫折而将坠落之时运，故得此卦者，凡事切忌轻举妄动，否则有过。\n' +
                '\n' + '【家运】\n' +
                '困难重重，摇摇欲坠之衰运。夫妻感情不睦，且均有向外发展之趋势，若想挽回此破象，双方宜改正自己的缺点，否则破裂也。\n' +
                '\n' + '【疾病】\n' +
                '严重，须耐心治疗，为肺、神经系统，女占得妇科经带之疾。\n' +
                '\n' + '【胎孕】\n' +
                '女儿或男儿均与六亲缘薄，育养困难。\n' +
                '\n' + '【子女】\n' +
                '子女与双亲间感情薄弱，又缺乏谅解，有症结耳。\n' +
                '\n' + '【周转】\n' +
                '无啥希望。\n' +
                '\n' + '【买卖】\n' +
                '不可做超过自己本身财力之生意交易，否则定有折损，亦不成。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '不易找到，可向东南方或西方，难寻。但若动用人手共寻找，可见。\n' +
                '\n' + '【失物】\n' +
                '无法发现，不能物归原主。\n' +
                '\n' + '【外出】\n' +
                '行动务必慎重，但不可过于拘泥，必要时不妨采取特殊行动。\n' +
                '\n' + '【考试】\n' +
                '苦恼也，不佳。\n' +
                '\n' + '【诉讼】\n' +
                '有文书之烦，即使求和也未能如愿。\n' +
                '\n' + '【求事】\n' +
                '困难很多。\n' +
                '\n' + '【改行】\n' +
                '不宜图不相应之事。\n' +
                '\n' + '【开业】\n' +
                '开业尚属虚，且待时候。',
                '\n' + '【事业】\n' +
                '陷入重重艰难险阻之中，险况丛生。既不得冒险，也不可束手待毙，应以积极态度，努力创造条件，改变处境，化险为夷。务必实心实意，充满信心，不图侥幸，不辞艰险，宜静观待变，运用智慧，突破险境，转危为安。\n' +
                '\n' +
                '【经商】\n' +
                '十分不利，甚至陷入面临破产的境地，但内心应沉着踏实，保持头脑冷静，身陷险境而心不陷，勿轻举妄动，先求自保以观变，把握时机以脱险。\n' +
                '\n' +
                '【求名】\n' +
                '不为他人理解，怀才不遇。不可自暴自弃。不改初衷，泰然自若，循序渐进，终可为人理解和发现。\n' +

                '\n' + '【外出】\n' +
                '大胆外出，另辟新路，必有所成，且得到他人相助。\n' +
                '\n' +
                '【婚恋】\n' +
                '多遭不利，应冷静分析原因，积极改变自身条件。双方同舟共济，共渡难关。\n' +
                '\n' +
                '【决策】\n' +
                '不幸。然而是转运前的考验。只要能不因困境而失常，且不图侥幸，保持信心，勿自陷险境。镇定自若，不轻举妄动，洞察时机，必可转危为安，成出险之功。\n' +

                '\n' + '【解卦】\n' +
                '水：代表陷溺被水淹灭之意，主凶象，四大难卦第二卦。困难已到来了，此时正在挣扎中，随时会被淹灭而破散。若能破釜沉舟、奋力一击，或许尚有转机，需积极找求贵人，才会有生机。\n' +

                '\n' + '【解释】\n' +
                '漂浮，被情所困。\n' +
                '\n' + '【特性】\n' +
                '不安定感，好冒险，投机，变换工作环境，爱情多困扰不顺。\n' +
                '\n' + '【运势】\n' +
                '危机重重，宜容忍自重，保持心境开朗，沉着应付，则可有脱险之机。吃得苦中苦，方为人上人，凡事莫与人争长短，否则有不幸灾害。\n' +
                '\n' + '【家运】\n' +
                '时运不济，陷于困境之中，不和，且有分裂之忧，凡事宜慎重行事。\n' +
                '\n' + '【疾病】\n' +
                '病重宜速治，否则难救，注意肾脏，膀胱生殖器病。\n' +
                '\n' + '【胎孕】\n' +
                '临产有虚惊，难产之象。\n' +
                '\n' + '【子女】\n' +
                '子女多劳苦，宜忍辱负重，会有出人头地的一天。子女宜防水厄之灾。\n' +
                '\n' + '【周转】\n' +
                '难成。\n' +
                '\n' + '【买卖】\n' +
                '亏损。\n' +
                '\n' + '【等人】\n' +
                '不来。\n' +
                '\n' + '【寻人】\n' +
                '此人因家庭不和或不得志而出走，速寻北方水边。\n' +
                '\n' + '【失物】\n' +
                '找不回来。有失窃可能，或沉在水底。因坎水有困难之意。\n' +
                '\n' + '【外出】\n' +
                '大胆外出，另辟新路，必有所成，且得到他人相助。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '有盗贼之讼，或田土之争，有理说不清，凶象。\n' +
                '\n' + '【求事】\n' +
                '不利。\n' +
                '\n' + '【改行】\n' +
                '不宜，应暂时守住本行。\n' +
                '\n' + '【开业】\n' +
                '开业者不利，有陷入困难之象。',
                '\n' + '【事业】\n' +
                '已快进入顶点，盛极而衰，务必总结经验教训，趋善避邪，以顺自养，居危知危，激励志气，切勿妄动。尤应求助中正的人援助，以期重振事业。\n' +
                '\n' +
                '【经商】\n' +
                '戒急于求成，宜兢兢业业，忧深虑远，考察市场行情，公平竞争，不可投机取巧。争取与他人密切合作。\n' +
                '\n' +
                '【求名】\n' +
                '方向未确定之前，不可到处乱撞，应持之以恒，执意追求，虚心向有才德的长者请教。\n' +

                '\n' + '【外出】\n' +
                '可行动，事先应准备充分，不可仓促上路。\n' +
                '\n' +
                '【婚恋】\n' +
                '自己寻找对象恐怕有困难，最好请可靠的朋友、长辈帮忙，不得急躁。双方应相互尊敬，最忌生邪念。\n' +
                '\n' +
                '【决策】\n' +
                '乐天知命，顺应自然，年轻时急于上进，未能实现理想，但坚持中正、谦和，可无灾祸，时常警觉，更可化险为夷。在危难时要寻求依托，但要慎重选择对象。晚年应知天命，尤不可不顾时势而轻举妄图动。\n' +

                '\n' + '【解卦】\n' +
                '火：代表智慧、明亮、温暖。六冲卦、纯卦，主大吉大凶、大好大坏之象，也表虚象不实之意。\n' +

                '\n' + '【解释】\n' +
                '变动，突发火光。\n' +
                '\n' + '【特性】\n' +
                '喜欢与人竞争，冲动，自尊心强，不服输，容易遭人排斥反对。依赖自己努力，他人助力较少。\n' +
                '\n' + '【运势】\n' +
                '虽然目前外观极盛一切顺利，可能为假象、虚象，而且双火之燃，其内部、内情已有所损，又双火之烈，如烈日中天，故凡事宜守之，勿太急进，待人以谦和、理性，否则必有所损失。宜顺从长辈，勿因急躁而妄动或意气用事，则可欣欣向荣也。\n' +
                '\n' + '【家运】\n' +
                '富有幸福之运，必须保持仁和谦恭，骄傲自大者易招致失败，宜谦逊处世。\n' +
                '\n' + '【疾病】\n' +
                '急病可解，久病则凶，宜耐心调养、为心脏眼科血疾。\n' +
                '\n' + '【胎孕】\n' +
                '胎有不安。产母不宜出外游。\n' +
                '\n' + '【子女】\n' +
                '子女幸福，但有娇生惯养之嫌，勿疏于教导为上。\n' +
                '\n' + '【周转】\n' +
                '可得到上辈的帮助渡过难关。\n' +
                '\n' + '【买卖】\n' +
                '交易必得利，忌用不法手段。\n' +
                '\n' + '【等人】\n' +
                '一定会来。\n' +
                '\n' + '【寻人】\n' +
                '此人因受到诱惑或煽动而出走。南方可寻，且小有是非。\n' +
                '\n' + '【失物】\n' +
                '动作快，可寻回。\n' +
                '\n' + '【外出】\n' +
                '可行动，事先应准备充分，不可仓促上路。\n' +
                '\n' + '【考试】\n' +
                '成绩不错。\n' +
                '\n' + '【诉讼】\n' +
                '只宜人侵己，不宜己侵人，主动者不利。\n' +
                '\n' + '【求事】\n' +
                '尊重长辈安排。待时机可获良好事业。\n' +
                '\n' + '【改行】\n' +
                '可极力推展。\n' +
                '\n' + '【开业】\n' +
                '开业者吉象。',
                '\n' + '【事业】\n' +
                '和为贵，和则万事兴，务以诚感人，以诚待人。广泛交往朋友，谦虚礼让。树立大志向，坚持主见，不可盲目随大流。不利时应安居待机，不可妄动强求。\n' +
                '\n' +
                '【经商】\n' +
                '有利可图。但应以纯正之心，以诚待人，加强合作。市场若不景气，决不可妄动，不可强求，而应静待发展。虚心听取不同意见。\n' +
                '\n' +
                '【求名】\n' +
                '志向应宏大，脚踏实地，虚心请教他人，使人悦而应，帮助、重用自己。切莫自我封闭，更不得冷漠孤僻，以广阔的胸怀发展自身。\n' +

                '\n' + '【外出】\n' +
                '虽希望外出，但暂时不宜远行。待时机成熟时再出行。\n' +
                '\n' +
                '【婚恋】\n' +
                '成功的可能性极大。双方很有感情，但动机务要纯正，婚姻方可幸福、永久。\n' +
                '\n' +
                '【决策】\n' +
                '吉祥如意。人际关系好，善于交际。以真诚的态度，无私的心去处世，不会有不利的地方。与他人合作应坚持原则。办事不可优柔寡断。不顺利时，应停止行动，总结经验，以图发展。待人诚恳，但不可与玩弄口舌的小人交往，防止受骗。\n' +

                '\n' + '【解卦】\n' +
                '咸：代表感动也。主吉象。感，有如青春男女，清纯无暇的感情，心心相印有感而发的情感，得此卦，与感情有十分大的关系，也可引伸为对一种理念的认同和欣赏。咸，并无利欲的情色纠葛，是属于比较「感性的」一种感觉。对男女之间的感情，是最大的吉象。\n' +

                '\n' + '【解释】\n' +
                '合和。\n' +
                '\n' + '【特性】\n' +
                '罗曼蒂克型，异性缘佳，感情丰富，六亲缘浓，口才佳，贵人多助。\n' +
                '\n' + '【运势】\n' +
                '吉祥如意。但勿为不正当的感情而意乱迷惑，或过于冲动。然得此卦时，会有男贪女爱之筝，非名正者必遭损，宜慎之。\n' +
                '\n' + '【家运】\n' +
                '目前十分融洽，而后亦然。如夫妇间另有不正当之交往者，宜明哲保家，避免有越轨情事为宜。\n' +
                '\n' + '【疾病】\n' +
                '防泌尿系统病，肠胃病及性病等，须疗养得法，否则不利。\n' +
                '\n' + '【胎孕】\n' +
                '有兴家和泰之运。\n' +
                '\n' + '【子女】\n' +
                '感情极融洽，幸福。\n' +
                '\n' + '【周转】\n' +
                '可成。\n' +
                '\n' + '【买卖】\n' +
                '交易可成有利。\n' +
                '\n' + '【等人】\n' +
                '会带来好消息。\n' +
                '\n' + '【寻人】\n' +
                '因色情之事出走，不久会出现。其人有在住处动身不得之忧，恐有些是非。\n' +
                '\n' + '【失物】\n' +
                '尽速找寻，可以取回，延误时机，则找不回来。\n' +
                '\n' + '【外出】\n' +
                '虽希望外出，但暂时不宜远行。待时机成熟时再出行。\n' +
                '\n' + '【考试】\n' +
                '成绩佳。\n' +
                '\n' + '【诉讼】\n' +
                '提防桃色纠纷，诸事宜和解，有贵人相助。\n' +
                '\n' + '【求事】\n' +
                '皆能如偿所愿。\n' +
                '\n' + '【改行】\n' +
                '吉利，可进行。\n' +
                '\n' + '【开业】\n' +
                '大吉大利。',
                '\n' + '【事业】\n' +
                '诸事无不成于恒，持之以恒，必有成效。恒乃成功之本。为此，务必动机纯正，守静而勿躁动，不可急于求成，也不可固守死道，应从个人实际出发。最忌人云亦云，最宜随机应变。如此，诸事顺而成。\n' +
                '\n' +
                '【经商】\n' +
                '大胆行动，有所往必有所利。注意市场行情和周围的情况。随时变化经营策略，但不得改变常理常道，坚持慎重选择的方向，勿追随潮流。忌不自量而冒险强求。\n' +
                '\n' +
                '【求名】\n' +
                '有始有终，善始善终，立于正道，坚持不懈，学习方面尤应提高涵养，必可成。\n' +

                '\n' + '【外出】\n' +
                '果敢行动，有利。\n' +
                '\n' +
                '【婚恋】\n' +
                '好事多磨，有曲折，唯不可见异思迁，和睦相处，勿固执己见。\n' +
                '\n' +
                '【决策】\n' +
                '循序渐进，不得在一开始期望过高，但不得因此放弃追求，应脚踏实地，从实际也发，不要好高骛远，更不可不自量，勿冒险激进，勿反复无常。坚信宇宙常新，不断修正自己的见解，补充新内容。\n' +

                '\n' + '【解卦】\n' +
                '恒：代表维持不变的迹象。是个状况卦，吉凶未定。显示一种会持续很长久的一种状况。好的或坏的情形，都将会再持续下去。事业投资、感情婚姻都不宜在此时做任何的计划或变动。\n' +

                '\n' + '【解释】\n' +
                '要有恒心。\n' +
                '\n' + '【特性】\n' +
                '主动，积极，坚忍，百折不挠，律己严，反应快，追求成功，理财佳，组织能力好。\n' +
                '\n' + '【运势】\n' +
                '诸事亨通而没有犯错方能恒久。其象利于正常固守，并须有毅力持行。雷风者，知守常则吉，妄动者则不能亨通。\n' +
                '\n' + '【家运】\n' +
                '全家上下均各循正轨而行，幸福家庭也。\n' +
                '\n' + '【疾病】\n' +
                '为慢性及须长期治疗之疾。注意肝之疾。\n' +
                '\n' + '【胎孕】\n' +
                '胎有烦恼，但无碍。\n' +
                '\n' + '【子女】\n' +
                '与双亲感情融治，幸福之至。\n' +
                '\n' + '【周转】\n' +
                '不成问题。\n' +
                '\n' + '【买卖】\n' +
                '有贵人，得力终成。利益双收。\n' +
                '\n' + '【等人】\n' +
                '迟到。\n' +
                '\n' + '【寻人】\n' +
                '外出之人平安无事，会自己回来。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '果敢行动，有利。\n' +
                '\n' + '【考试】\n' +
                '会得高分。\n' +
                '\n' + '【诉讼】\n' +
                '小人招祸，但宜速战速决，不利拖延，宜和解。\n' +
                '\n' + '【求事】\n' +
                '向东南有利。\n' +
                '\n' + '【改行】\n' +
                '忌做无妄之事，需注意风险。\n' +
                '\n' + '【开业】\n' +
                '吉利可行。',
                '\n' + '【事业】\n' +
                '时运不佳，应考虑退隐，勿与不正派的人同流合污。但不是消极逃避，而是为保存实力，总结经验，把握时机，待机而出。切忌贸然行事，造成不必要的损失。同时勿为眼前小利禄所诱惑。\n' +
                '\n' +
                '【经商】\n' +
                '市场进入衰落的状况，行情不佳。决不可执意追求，盲目投入，而应沉着冷静，坚持商业道德，远离小人、奸商，保存自己的实力，等待市场行情好转，再图发展。\n' +
                '\n' +
                '【求名】\n' +
                '宜暂名哲保身，甘居退隐。全力总结提高，为复出做准备。\n' +

                '\n' + '【外出】\n' +
                '不宜积极行动，而应等待时机，创造条件，争取早日而行。\n' +
                '\n' +
                '【婚恋】\n' +
                '勿急于解决，更不可妄动强求。时机成熟时，须以纯正的态度去正确对待。\n' +
                '\n' +
                '【决策】\n' +
                '处于衰败时期，小人得志。盲目行动必遭不利。虽不必以恶报恶，但更不可同流合污，因小失大，因权宜轻重，韬光养晦，保存实力，断然离去，不可犹豫不决。潜心发展个人实力，隐忍待机。只要能坚定正道，坚持中正，必可在时机成熟时复出，成就大业。\n' +

                '\n' + '【解卦】\n' +
                '遁：代表退守、退步之意，主凶象。一切事项均需停止下来，因其中可能有小人或障碍、阴谋在破坏中。退另有代表退步之意，功课、事业，均不再如同往常般的成绩了！\n' +

                '\n' + '【解释】\n' +
                '隐居退守。\n' +
                '\n' + '【特性】\n' +
                '外华内虚，内心不易满足空虚，自我要求高，不善人际关系，但有贵人运。宜追求心灵宗教寄托。\n' +
                '\n' + '【运势】\n' +
                '小人道长，君子须远离小人，诸事宜守。艮者，止也，言行物事要谨慎，才能得安而运泰。若遇小人之害，见陷于是非之地，勿管闲事，识时务为俊杰。\n' +
                '\n' + '【家运】\n' +
                '须多积德行善，可得安祥。若常争端则衰运，且有别离之象、离婚之象。若曾数经波折之君子，历尽沧桑，则有先恶后吉之运。\n' +
                '\n' + '【疾病】\n' +
                '病重，注意血气不调、头、背、筋骨不良之疾。\n' +
                '\n' + '【胎孕】\n' +
                '母子不安，慎之。\n' +
                '\n' + '【子女】\n' +
                '儿女体质虚弱，有亲子不和之象。\n' +
                '\n' + '【周转】\n' +
                '不成，有碍。\n' +
                '\n' + '【买卖】\n' +
                '宜守，不能大事进取，否则失败。\n' +
                '\n' + '【等人】\n' +
                '遁，藏也。不能来。\n' +
                '\n' + '【寻人】\n' +
                '下落不明，为家庭之事而离乡背井，寻亦难觅。\n' +
                '\n' + '【失物】\n' +
                '失落远处，不能寻回。\n' +
                '\n' + '【外出】\n' +
                '不宜积极行动，而应等待时机，创造条件，争取早日而行。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '强行争取亦不利，宜罢手逃避反吉。\n' +
                '\n' + '【求事】\n' +
                '须待时机。\n' +
                '\n' + '【改行】\n' +
                '不得时。\n' +
                '\n' + '【开业】\n' +
                '开业不利。',
                '\n' + '【事业】\n' +
                '处世凭智不凭力，有勇更有谋，切忌蛮干，该守不守，自取其凶。不可逞强，否则自取凶险。对小人应有防犯，尤其不得忽视小人的奸诈。对事业应勇往直前，但切忌冒进。\n' +
                '\n' +
                '【经商】\n' +
                '处在高涨阶段，形势很好。但决不可逞强，更不可妄动，应主动增强与他人的合作。提高警惕，认识物极必反的道理，做事业衰退的准备。\n' +
                '\n' +
                '【求名】\n' +
                '务必注意妥善地运用自己的力量，不可自恃刚健强壮而冒进。尤其不可自负。适当发挥自己的才干，加上外力的援助，必有所作为。\n' +

                '\n' + '【外出】\n' +
                '慎重外出，不可莽撞，否则不吉利。\n' +
                '\n' +
                '【婚恋】\n' +
                '决不可因自己条件好而高傲。切勿错过好姻缘。\n' +
                '\n' +
                '【决策】\n' +
                '强健适中，不可过分消耗自己的力量。在事业顺利的时刻，尤其不可妄动。更应注意，很可能已经处于进退维谷的状态，务必自保待机，及早有所准备。\n' +

                '\n' + '【解卦】\n' +
                '大壮：代表天上打雷、声势浩大，行动迅速、积极之象。此卦一般以吉处藏凶来论。因为对人、对事最忌冲动。此卦有过于不理性冲动之象，容易犯错。尤其有血光之祸，被人殴打、伤害之意。得此卦，凡事再三思为吉。\n' +

                '\n' + '【解释】\n' +
                '声势浩荡。\n' +
                '\n' + '【特性】\n' +
                '爱冒险，领导欲强，喜怒易形于色，不喜欢太平淡安定的工作生活。金钱支出大方，朋友人缘不错。\n' +
                '\n' + '【运势】\n' +
                '刚强过盛者，若不思正与顺，则有躁动之害耳。虽运势强大，时至当盛之期，但忌血气方刚。处事欠思虑而遭破运，宜力持和平、顺气，勿得罪人，否则会因此而招来失败，切勿贪妄。\n' +
                '\n' + '【家运】\n' +
                '已壮大且富有，但亦有似成而空之感。\n' +
                '\n' + '【疾病】\n' +
                '生平少病者遇此卦则不利，注意急性呼吸道感染和脑疾等。\n' +
                '\n' + '【胎孕】\n' +
                '子母有难非喜。\n' +
                '\n' + '【子女】\n' +
                '儿女有自视太高，目中无人之情。\n' +
                '\n' + '【周转】\n' +
                '勿再三借贷，如此反而不利。\n' +
                '\n' + '【买卖】\n' +
                '可成功与获大利，但往后要小心，有反遭不利之情况。\n' +
                '\n' + '【等人】\n' +
                '迟来。\n' +
                '\n' + '【寻人】\n' +
                '为斗气而出走，若寻找途中不见，则难查下落，东方或西北方。\n' +
                '\n' + '【失物】\n' +
                '不能取回。\n' +
                '\n' + '【外出】\n' +
                '慎重外出，不可莽撞，否则不吉利。\n' +
                '\n' + '【考试】\n' +
                '有好成绩，但勿太骄傲。\n' +
                '\n' + '【诉讼】\n' +
                '忌与人斗气，宜和解收场否则两败俱伤。\n' +
                '\n' + '【求事】\n' +
                '可顺利，勿太刚强行事。\n' +
                '\n' + '【改行】\n' +
                '可，不过勿太勉强。\n' +
                '\n' + '【开业】\n' +
                '吉利。',
                '\n' + '【事业】\n' +
                '顺利。应遵守正道，迎难而上，克敌制胜，因势利导。树立良好的人际关系，深得人心。全力以赴，不得有丝毫犹豫不决，更忌优柔寡断，而应败不馁，勇往直前。注意和衷共济，共同前进。\n' +
                '\n' +
                '【经商】\n' +
                '行情好。市场竞争顺利。但也会遇到一些困难，必迎难而上，因势利导，克敌制胜。争取众人支持。前进中的挫折不可免，只要动机纯正，必可转危为安。\n' +
                '\n' +
                '【求名】\n' +
                '经刻苦努力与奋斗，已具备开拓事业的基础，却因无人引荐，暂时不得志，决不可因此自暴自弃，须耐心等待时机。同时，更加积极地创造条件。\n' +

                '\n' + '【外出】\n' +
                '克服犹豫心理，大胆前进，可无往而不顺。\n' +
                '\n' +
                '【婚恋】\n' +
                '吉星高照。会有理想的结果，但决不可自恃条件优越而抱无所谓的态度或过于挑剔。\n' +
                '\n' +
                '【决策】\n' +
                '处于不断上升的形势，不会有过大的阻力。但务必争取众人信任，获取人心，再接再厉，只要动机纯正，克服侥幸心理，必有喜从天降。\n' +

                '\n' + '【解卦】\n' +
                '晋：代表进取也，主吉象。火为太阳，所以是「日出于大地、光明乍现」，阳光准备要照耀大地，一片欣欣向荣之象。事业投资、升迁、婚姻、感情均是有雨过天晴，奋发向上的吉象。\n' +

                '\n' + '【解释】\n' +
                '很明白，很明亮。\n' +
                '\n' + '【特性】\n' +
                '荣誉心重，固执，爱面子，外华内虚，喜领导他人。\n' +
                '\n' + '【运势】\n' +
                '事业繁荣，声誉渐高，于人、事、物均获宏利之际，与人共事者吉。但要知进德虚怀，即是诸凡对人、事宜谨守德操，不可傲溢心怀，否则有破象也。但财运旺。\n' +
                '\n' + '【家运】\n' +
                '虽如日中天，切不可过于骄纵、蛮横，若为人不行正道，虽然光明就在眼前，且兴隆幸福，又难免夫妇失和，家运不振耳。\n' +
                '\n' + '【疾病】\n' +
                '久病者凶，近病无妨，注意胃肠、心脏之疾。\n' +
                '\n' + '【胎孕】\n' +
                '平。\n' +
                '\n' + '【子女】\n' +
                '多才智，聪明而贤孝。\n' +
                '\n' + '【周转】\n' +
                '可获得强大资金。\n' +
                '\n' + '【买卖】\n' +
                '进取得利，并获得巨财。\n' +
                '\n' + '【等人】\n' +
                '女者会来，男者不一定会来。\n' +
                '\n' + '【寻人】\n' +
                '见于西南方或南方。\n' +
                '\n' + '【失物】\n' +
                '动作快，可以寻回来。似是在地上的箱子之类藏着。\n' +
                '\n' + '【外出】\n' +
                '克服犹豫心理，大胆前进，可无往而不顺。\n' +
                '\n' + '【考试】\n' +
                '科甲登榜。\n' +
                '\n' + '【诉讼】\n' +
                '最终能圆满解决。\n' +
                '\n' + '【求事】\n' +
                '受人提拔，可有成就。\n' +
                '\n' + '【改行】\n' +
                '可行。\n' +
                '\n' + '【开业】\n' +
                '吉利。',
                '\n' + '【事业】\n' +
                '处于不利的环境，宜心境坦然地处置险恶的条件，增强心理素质，承受各种压力。表面柔顺小心，内心洞察事理，当离去则离去，避免灾祸，脱离危险。防患于未然，坚持逆境中奋斗。\n' +
                '\n' +
                '【经商】\n' +
                '认真观察言市场动态，待机行动，但须坚持商业道德，即使处于不利情况下，也要冒险向前，特别是要向南方发展，会大有收获。\n' +
                '\n' +
                '【求名】\n' +
                '内心保持大志，行动刻苦忍耐，奋斗于逆境，自守其志，收敛聪明而不外露，于混乱中更应坚持正义，藏拙守愚。\n' +

                '\n' + '【外出】\n' +
                '最好不要马上行动，再等待一个时期，做好充分准备。\n' +
                '\n' +
                '【婚恋】\n' +
                '必须全面分析对方情况，不能因小失大。注意大节。\n' +
                '\n' +
                '【决策】\n' +
                '处在不佳的状况下，环境困难，前途不明。但若能坦处厄运，能伸能屈，前景会变光明。尤其应加强修养，隐忍行志，外愚内慧，可以遇难呈祥。如能谨慎行事，处处小心，更能有所作为。\n' +

                '\n' + '【解卦】\n' +
                '明夷：代表火入坑中，阳气被阴气所伤害，被小人所重伤，主大凶象。诸事不宜，运背之时。\n' +

                '\n' + '【解释】\n' +
                '浑沌不明。\n' +
                '\n' + '【特性】\n' +
                '朋友多，心慈，重友谊，喜追求高层次的境界，理智和感情常矛盾。有时会牺牲一切，去完成自己的理想。\n' +
                '\n' + '【运势】\n' +
                '逆势下降，时运未济，万事劳苦，逢小人加害，光明受到掩蔽而失光明，所以多艰难之运也。有万事阻滞，遇事迷惑、受灾。故宜守贞固之道，忍耐自重，等待时机。\n' +
                '\n' + '【家运】\n' +
                '衰弱、多劳，家人受累，恐有诈欺、官符。须知邪恶之人定有因果，凡事心地坦荡可渡难关也。\n' +
                '\n' + '【疾病】\n' +
                '凶象。为潜伏已久之疾，注意心脏、腹部之疾或神经病。\n' +
                '\n' + '【胎孕】\n' +
                '产母有惊。\n' +
                '\n' + '【子女】\n' +
                '受子女牵连，陷于痛苦深渊之中。\n' +
                '\n' + '【周转】\n' +
                '不成。\n' +
                '\n' + '【买卖】\n' +
                '难成，用谋方就。\n' +
                '\n' + '【等人】\n' +
                '不来。\n' +
                '\n' + '【寻人】\n' +
                '西南或南方，虽在附近但难寻。\n' +
                '\n' + '【失物】\n' +
                '不能立即寻回。正当要遗忘这件事时，会意外地有所发现。\n' +
                '\n' + '【外出】\n' +
                '最好不要马上行动，再等待一个时期，做好充分准备。\n' +
                '\n' + '【考试】\n' +
                '无望。\n' +
                '\n' + '【诉讼】\n' +
                '败象，宜和解了事，愈争愈苦。\n' +
                '\n' + '【求事】\n' +
                '日下无希望，须再待时，需防被骗、失财、失身。\n' +
                '\n' + '【改行】\n' +
                '不利。\n' +
                '\n' + '【开业】\n' +
                '不宜，亦不成事。',
                '\n' + '【事业】\n' +
                '成功与否取决于家庭的情况。严格治家，防止“后院”起火出现意外事故，这是事业成功的先决条件。夫妇和睦，共同合作，必可脱贫致富。事业应由内而外，循序渐进，持之以恒，而后必有所成。\n' +
                '\n' +
                '【经商】\n' +
                '以治家方式用于商业活动。对待公司职工应严宽结合，以严为主。宜集中领导，主事一人，共同合作，日积月累。\n' +
                '\n' +
                '【求名】\n' +
                '以勤苦努力和安贫乐道为主，更应有家庭的支持。家道兴盛的同时，个人的事业也就容易成功。\n' +

                '\n' + '【外出】\n' +
                '在家千日好，外出一时难，准备充分后再行动。\n' +
                '\n' +
                '【婚恋】\n' +
                '和睦的家庭，是一切的基础，而妻子又是家道的根本。择妻应慎重，和乐兴家。\n' +
                '\n' +
                '【决策】\n' +
                '因好的家庭和妻子的支持而顺利成功，务必保持这种优势，否则，由盛转衰在顷刻之间。尤其应预防家庭变故。持家重在谦和待人，勤俭积蓄以致富。亲人相互和睦、友好。\n' +

                '\n' + '【解卦】\n' +
                '家人：代表同为一家人，主小吉之象。事事以家人为重之意，如事业投资均需一家人合作共事为佳。感情，为成家之吉象。\n' +

                '\n' + '【解释】\n' +
                '家人离散，吉中带凶。\n' +
                '\n' + '【特性】\n' +
                '为人热忱，待人谦恭有礼貌，善交际，外表积极内心保守。喜家庭生活，爱小孩小动物。\n' +
                '\n' + '【运势】\n' +
                '平安无事，且有喜事之象，与家人共事者大利。\n' +
                '\n' + '【家运】\n' +
                '万事畅达如意，和乐之象。\n' +
                '\n' + '【疾病】\n' +
                '注意心脏或胆病，必须小心调理。\n' +
                '\n' + '【胎孕】\n' +
                '不碍。\n' +
                '\n' + '【子女】\n' +
                '儿女孝顺。\n' +
                '\n' + '【周转】\n' +
                '难调，但可成，宜找自家亲人为佳。谨守诚信可无阻。\n' +
                '\n' + '【买卖】\n' +
                '有超值的利益可得，交易可成。\n' +
                '\n' + '【等人】\n' +
                '自来，且有吉事相告。\n' +
                '\n' + '【寻人】\n' +
                '因赌气出走，不久便见。\n' +
                '\n' + '【失物】\n' +
                '出现。隐藏在家中。可能是被家人收起来了。\n' +
                '\n' + '【外出】\n' +
                '在家千日好，外出一时难，准备充分后再行动。\n' +
                '\n' + '【考试】\n' +
                '上榜有名。\n' +
                '\n' + '【诉讼】\n' +
                '虽产生大疑虑，若行事合理则无碍。\n' +
                '\n' + '【求事】\n' +
                '有良好机会，勿失。\n' +
                '\n' + '【改行】\n' +
                '勿急。\n' +
                '\n' + '【开业】\n' +
                '开业者可照计划而行，吉利也。',
                '\n' + '【事业】\n' +
                '事业开创困难，处境艰辛。如能志同道合，相互信任，目的可以达到。但起初成功的多是小事。小事积累可成大事。事成于协力合作，败于众志相异。应加强团结，委曲以求相通。最忌内部相互猜疑。\n' +
                '\n' +
                '【经商】\n' +
                '应以心平气和的态度投入市场竞争，求大同而存小异。对竞争对手不应敌视，可和而不流，群而不党。尤其不能惹起纠纷，极力避免灾祸。\n' +
                '\n' +
                '【求名】\n' +
                '以真诚的心努力上进。对于恶人必要时亦不可完全不交往，应采取宽大包容的态度，适度往来。对志同道合者也不必完全一致，可异中求同。\n' +

                '\n' + '【外出】\n' +
                '顺利。途中可以得到善人相助。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方必真诚相待。否则，第三者极易介入，夫妻相疑则家破。\n' +
                '\n' +
                '【决策】\n' +
                '处于矛盾、对应状态。必须注意以宽宏大度、平和、柔顺的态度对待人生。这样才能心平气和，柔而上进。认清合必有离，离必有合；同中有异，异中有同的道理。否则，刚极暴躁，明极生疑。暴疑皆可致命。\n' +

                '\n' + '【解卦】\n' +
                '睽：代表乖危、叛逆、背离之象，主凶象。意见不合，彼此争斗、任性、不协调。事事均不顺，且有被出卖的危险，注意犯小人。\n' +

                '\n' + '【解释】\n' +
                '防小人。\n' +
                '\n' + '【特性】\n' +
                '经常事与愿违，个性敏锐，理想化，感情与理智容易矛盾冲突，易生孤独感，宜往专技艺术发展。\n' +
                '\n' + '【运势】\n' +
                '水火不相容，则气运不通，势行低落，诸事难成。凡事有违叛之时，若能处变不惊，不逞强出头，尚可转危为安。有家内不和，亲友疏散之情。宜力持温和，忍耐去克服困难，始可渡危。\n' +
                '\n' + '【家运】\n' +
                '困苦离亲之象，不和睦之情。家运衰颓，虽可得小利，但也济不敷出。\n' +
                '\n' + '【疾病】\n' +
                '轻症宜速就医，重症则危，更防断症错误，注意心脏，寒热失调之症。\n' +
                '\n' + '【胎孕】\n' +
                '无碍。\n' +
                '\n' + '【子女】\n' +
                '子女不和，有骨肉无情之不幸，且其子女六亲缘薄无靠。\n' +
                '\n' + '【周转】\n' +
                '难以如愿。\n' +
                '\n' + '【买卖】\n' +
                '多阻碍，难成，若成者亦必损。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '因口角负气出走，其志不同故不易寻。\n' +
                '\n' + '【失物】\n' +
                '不能找回。有失窃的可能。\n' +
                '\n' + '【外出】\n' +
                '顺利。途中可以得到善人相助。\n' +
                '\n' + '【考试】\n' +
                '落榜。\n' +
                '\n' + '【诉讼】\n' +
                '宜速求和解，迟则理屈辞穷。\n' +
                '\n' + '【求事】\n' +
                '无希望。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '不利。',
                '\n' + '【事业】\n' +
                '诸事开头难，坚持下去，会有好的结果。人生不易，首先得求诸自己，修德养性。志高自信，奋勇进取。逢到困难，得求友人相助，行动不可冒险，必以柔克刚，待机候时，量力而行。\n' +
                '\n' +
                '【经商】\n' +
                '逐步起业，冒险行事，自寻苦恼。宜加强商业道德，真诚同他人合作，陷入困境时，不得见死不救，而应奋不顾身。联合行动，可活跃于市场竞争之中。\n' +
                '\n' +
                '【求名】\n' +
                '循序渐进，加强自身修道。居静观动，待变，俟机而动，能进则进。内心坦然，不可为追逐名利而冒险。与贤能结合，追随高人，必能转危为安。\n' +

                '\n' + '【外出】\n' +
                '出门可能有难，利于西南行。如外出遇难，可得救助，转危为安。\n' +
                '\n' +
                '【婚恋】\n' +
                '不很顺利，不得急，宜慎重选择。但应有自知之明。\n' +
                '\n' +
                '【决策】\n' +
                '艰难不利。但可视为考验。奋进向前，充满信心，切勿自寻苦恼，交结贤人，相互帮助，坚守中正之道，可遇难呈祥，结得善果。\n' +

                '\n' + '【解卦】\n' +
                '蹇：代表寒足之意，主凶象，四大难卦第三卦。冰天雪地中赤足而行，代表现在处境多么的艰辛困苦，却又不能放守不管，只能硬撑到底。此时虽是无奈，但也总有苦尽甘来的时刻。人事均很为难之时，事业、感情均有骑虎难下的困境。\n' +

                '\n' + '【解释】\n' +
                '跛足，执行困难。\n' +
                '\n' + '【特性】\n' +
                '幻想多，重情调，喜新厌旧，有设计研发才能，追求理想化的生活，不切实际，故内心易有挫折感。\n' +
                '\n' + '【运势】\n' +
                '衰运，多灾难，进退两难之处境，要自重而不可妄动，此时应守正道待时也。凡事杂乱，更防小人之害，绝不可涉险境，否则灾害必至。\n' +
                '\n' + '【家运】\n' +
                '六亲无情，有贫困、不幸之象，运差者，其险难脱，善者宜致力克服挽救。宜注意家人有重病、血光之厄运。\n' +
                '\n' + '【疾病】\n' +
                '病重，不可疏忽医治，注意肾、胃、手部四肢损伤。\n' +
                '\n' + '【胎孕】\n' +
                '胎不安，孕有灾。\n' +
                '\n' + '【子女】\n' +
                '子女手足情薄，六亲少靠，劳碌之命也。\n' +
                '\n' + '【周转】\n' +
                '难调，多不成也。\n' +
                '\n' + '【买卖】\n' +
                '失利，交易不成。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '速报警帮寻，得贵人则可回。\n' +
                '\n' + '【失物】\n' +
                '搜索有困难阻滞而停止，不能寻回。\n' +
                '\n' + '【外出】\n' +
                '出门可能有难，利于西南行。如外出遇难，可得救助，转危为安。\n' +
                '\n' + '【考试】\n' +
                '落榜。\n' +
                '\n' + '【诉讼】\n' +
                '终必有险，更防血光。不宜起讼，动则招咎。\n' +
                '\n' + '【求事】\n' +
                '眼前毫无希望。\n' +
                '\n' + '【改行】\n' +
                '不宜。借贷强行并非良策。\n' +
                '\n' + '【开业】\n' +
                '开业者不宜，有是非、损耗之象。',
                '\n' + '【事业】\n' +
                '元气初复，宜休养生息，安静平易为佳，宜宽宜和。当准备就绪则应立即行动，争取主动，不可拖廷，所往必有功利。但切勿贪得无厌。克勤克俭，刚柔相济，以柔为主。远辟小人，团结君子，相互依托，共成事业。\n' +
                '\n' +
                '【经商】\n' +
                '行动贵神速，积极而为，方可摆脱险境。行商宜循正道，切勿招摇过市。此乃愚蠢行动，必自招灾祸。\n' +
                '\n' +
                '【求名】\n' +
                '初出茅庐，应继续充实自己。待机行动。尤应远小人近君子和有才德的人，以期迅速提高自己。\n' +

                '\n' + '【外出】\n' +
                '一旦准备充分，立即行动，捷足先登必有所获。\n' +
                '\n' +
                '【婚恋】\n' +
                '应适时进行。夫妻和衷共济，可家庭和睦，免除灾难。\n' +
                '\n' +
                '【决策】\n' +
                '稳妥为宜，亦不应冒险，因一切尚在元气初复阶段。为争取大发展，务必宽以待人，以柔致治，广交贤者，遣散小人，以中庸、正直的方式，张驰结合的手段，开拓方兴未艾的事业。\n' +

                '\n' + '【解卦】\n' +
                '解：代表解决之象，先苦后甜之象，冬雪春化之意。冰冻三尺非一日之寒，事出有因、但已是到该解决的尽头了！只是化解之日还很长，要抓住重点方向，好好努力坚持下去，还是有可能再成功的。\n' +

                '\n' + '【解释】\n' +
                '解冻。\n' +
                '\n' + '【特性】\n' +
                '耐性差，爱变化，喜热闹场合，多学不精，思想兴趣不稳定，易受外界影响。一生多为他人排解事务，自己却少有他人之助。\n' +
                '\n' + '【运势】\n' +
                '顺势而行，速把握良机、坚持努力，快速处理可成。贵人在远方，有助，宜出外营谋。大运在西南。\n' +
                '\n' + '【家运】\n' +
                '初有困难，但难关已过。开花结实之兆。\n' +
                '\n' + '【疾病】\n' +
                '久病可治愈，注意肝、肾、足疾。\n' +
                '\n' + '【胎孕】\n' +
                '孕安。\n' +
                '\n' + '【子女】\n' +
                '初时因子女牵累而劳苦，但其子女越长运越好，故苦后得甘，已可得到幸福。\n' +
                '\n' + '【周转】\n' +
                '速进行可成，延迟不利。\n' +
                '\n' + '【买卖】\n' +
                '即速交易，可名利双收。\n' +
                '\n' + '【等人】\n' +
                '一定来。\n' +
                '\n' + '【寻人】\n' +
                '北方寻之可见。\n' +
                '\n' + '【失物】\n' +
                '遗失之物似有被支解破坏的可能，故寻回的机会不大。\n' +
                '\n' + '【外出】\n' +
                '一旦准备充分，立即行动，捷足先登必有所获。\n' +
                '\n' + '【考试】\n' +
                '题榜在望。\n' +
                '\n' + '【诉讼】\n' +
                '一般纠纷皆可解决，宜当机立断，不可拖延。\n' +
                '\n' + '【求事】\n' +
                '有贵人相助。\n' +
                '\n' + '【改行】\n' +
                '速改有利。\n' +
                '\n' + '【开业】\n' +
                '开业者有很大的展望。',
                '\n' + '【事业】\n' +
                '开拓事业必有投入。投入多少应事先精心算计，力求损益得当。诸事皆应有节度，切忌欺诈、贪婪。天下事克已最难，务严格要求自己，刻苦奋斗，手段灵活，争取事业成功。\n' +
                '\n' +
                '【经商】\n' +
                '全面调查市场行情，计算投入和收益的比例。不必过于计较利润的多少，获利即可。真诚与他人合作，彼此信任，手段灵活，切勿自我封闭。\n' +
                '\n' +
                '【求名】\n' +
                '为了成长进步，务必有所投入，且应付诸实践。以损增益，成就理想。\n' +

                '\n' + '【外出】\n' +
                '有益可行。注意节省费用，防止损多于益。\n' +
                '\n' +
                '【婚恋】\n' +
                '全面权衡对方的长处与短处。大处着眼。\n' +
                '\n' +
                '【决策】\n' +
                '为人聪颖，善于权衡利弊。顺应自然发展规律，积极主动与他人合作，助人为乐，适度投入，资助他人，而自己要宁俭勿奢。如此，诸事都可以十分顺利。\n' +

                '\n' + '【解卦】\n' +
                '损：代表小有损失也，主凶中带吉之象。「赛翁失马、焉之非福」是此卦最好的解释。投资、事业、借贷、感情皆是失意不顺之时，然对事要有信心，学得经验将可得到更好的结果。若将要投资则不可。\n' +

                '\n' + '【解释】\n' +
                '有所损害、损失。\n' +
                '\n' + '【特性】\n' +
                '心地善良，富同情心，悲天悯人，体贴他人，善于照顾，安慰别人。牺牲小我，完成大我精神，生活节俭，大方助人。\n' +
                '\n' + '【运势】\n' +
                '诸事不如意，若倾于利欲不当之心则有灾，散财之损。必须能够悟其所损，方能挽回局势。与人共事商量则可收损失之象。\n' +
                '\n' + '【家运】\n' +
                '正处于受损之时，审慎挽救颓势也。\n' +
                '\n' + '【疾病】\n' +
                '现况病情虽重，积极治疗可愈。身体较衰弱，注意肠胃、咽喉、贫血等疾。\n' +
                '\n' + '【胎孕】\n' +
                '产母有凶象。\n' +
                '\n' + '【子女】\n' +
                '儿女多诚实孝顺，幸福之格。\n' +
                '\n' + '【周转】\n' +
                '可达目的。\n' +
                '\n' + '【买卖】\n' +
                '虽有损，但终得利。\n' +
                '\n' + '【等人】\n' +
                '可能会迟到。\n' +
                '\n' + '【寻人】\n' +
                '在东北或西方友人家中，可寻。否则会自回。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '有益可行。注意节省费用，防止损多于益。\n' +
                '\n' + '【考试】\n' +
                '多努力，榜上可题名。\n' +
                '\n' + '【诉讼】\n' +
                '有斗争之象，损失破财，和解反而获益。\n' +
                '\n' + '【求事】\n' +
                '可慢慢受重用。\n' +
                '\n' + '【改行】\n' +
                '可行，勿急躁，宜周密行事。\n' +
                '\n' + '【开业】\n' +
                '吉利，宜有耐力。',
                '\n' + '【事业】\n' +
                '大胆投入，勇于支持他人事业，必相得益彰。勇敢前进，敢作敢为。只要心地善良、纯洁、谦虚，事业必定与日俱增，前程无可限量。助人宜即时，要在应急，开拓事业，内部团结一致，人心所向，树立恒心，不怕冒险犯难。\n' +
                '\n' +
                '【经商】\n' +
                '勿追求小利，让利于顾客，反而必获丰厚利润。切忌贪心不足。在遇到危险时应向他人求教，争取诚实的援助。\n' +
                '\n' +
                '【求名】\n' +
                '真心实意地施惠于他人，自己必然可以得到诚心诚意的回报，并且获得支持，事业必成。\n' +

                '\n' + '【外出】\n' +
                '有利可获。大胆外出行动。\n' +
                '\n' +
                '【婚恋】\n' +
                '心胸开阔，乐于助人。人际关系好，得到众人的支持。自己贵在树立恒心，克服三心二意。尤其要向比自己优秀的人学习，借助对方的力量，充实和发展自己。树立必要的冒险精神。平日要施善于人。因此，即使遇到危险也会得到他人救助。\n' +

                '\n' + '【解卦】\n' +
                '益：代表利益、好处，是由上对下的一种好的给予，主吉象。对事业投资都有相当的帮助，可得贵人来相助、扶持。\n' +

                '\n' + '【解释】\n' +
                '有利益，有好处。\n' +
                '\n' + '【特性】\n' +
                '重大我，轻小我，热心公益，爱热闹场合，团体事务协调工作。对自己的生活要求少，在朋友口碑中不错。\n' +
                '\n' + '【运势】\n' +
                '乘盛吉之运，可得他人之助而诸事顺畅，家业有成。大致吉利之运也。\n' +
                '\n' + '【家运】\n' +
                '有喜悦之象，发达之意。\n' +
                '\n' + '【疾病】\n' +
                '可治愈，提防肝胆、肠胃之疾，或性病等。\n' +
                '\n' + '【胎孕】\n' +
                '胎有不安。\n' +
                '\n' + '【子女】\n' +
                '得家人之喜欢，天真顽皮。\n' +
                '\n' + '【周转】\n' +
                '可成。\n' +
                '\n' + '【买卖】\n' +
                '可得大利。\n' +
                '\n' + '【等人】\n' +
                '迟来。因有好事相告。\n' +
                '\n' + '【寻人】\n' +
                '难逢，防有害。若出走者会自回。\n' +
                '\n' + '【失物】\n' +
                '有望可寻回。\n' +
                '\n' + '【外出】\n' +
                '有利可获。大胆外出行动。\n' +
                '\n' + '【考试】\n' +
                '成绩优良，父母有喜悦之象。\n' +
                '\n' + '【诉讼】\n' +
                '有牢狱之灾，或事因女人、房地产引起，宜和解。\n' +
                '\n' + '【求事】\n' +
                '大好时机，勿错过。\n' +
                '\n' + '【改行】\n' +
                '有利。\n' +
                '\n' + '【开业】\n' +
                '开业者则大有利图。',
                '\n' + '【事业】\n' +
                '处在兴盛阶段，但已孕育着普遍的危险。务必施恩泽给他人，勿居功自傲，主动团结他人，不干冒险的事。时时提高警惕，防止小人的破坏。但不可莽撞，应持和缓的手段去决断小人，避免过犹不及。\n' +
                '\n' +
                '【经商】\n' +
                '市场正在走向衰落的状态，不宜大量活动，随时注意转向。开拓新领域时，加强同他人的合作。但务必充分注意别有用心的奸诈小人趁机捣乱和破坏。\n' +
                '\n' +
                '【求名】\n' +
                '虚心求教，充实自身实力。但应量力而行，不可盲目自大。更不可同小人合流，徒虚名而败坏事业。\n' +

                '\n' + '【外出】\n' +
                '不宜过于频繁，以逸待劳最佳。外出旅行吉利。\n' +
                '\n' +
                '【婚恋】\n' +
                '防止上介绍人的当，也应注意对方的目的。\n' +
                '\n' +
                '【决策】\n' +
                '性格矛盾，往往表面刚强而内心虚弱，在关键的时刻，败坏事业。虚心听取他人劝告，切勿鲁莽，多谋深思，用柔和的态度待人处世。\n' +

                '\n' + '【解卦】\n' +
                '夬：代表决定或缺失，是个状况卦，吉凶未定。所迟疑的事情，一定要有个决定不能再拖延，至于如何决定，可视变卦而定。否则在有所拖延，就成凶象了！\n' +

                '\n' + '【解释】\n' +
                '卦相奇特，角龙升天行大运。\n' +
                '\n' + '【特性】\n' +
                '思想敏锐，有理想抱负，果决，务实主义，实事求是，不投机冒险，有孤独感，宜从事专技工作。\n' +
                '\n' + '【运势】\n' +
                '日前虽然鸿运当头，终会遇到困难与危险，凡事不可轻举妄动，宜随时警惕自己，留心意外灾害。住所可能会有变动，亦有文书、契约之失利，易生错误。慎勿傲气或自以为是，不谦和者定有灾。\n' +
                '\n' + '【家运】\n' +
                '夕阳虽好，却近黄昏，往后有陷于惊动、痛苦之境。因此卦有盛极必反之象。\n' +
                '\n' + '【疾病】\n' +
                '病情重宜速治，防头部、呼吸系统、肿瘤等疾。\n' +
                '\n' + '【胎孕】\n' +
                '无灾。母胎宜节食物，且勿发怒气，否则有难产之虑。\n' +
                '\n' + '【子女】\n' +
                '有先吉后凶之象。\n' +
                '\n' + '【周转】\n' +
                '不可急踩，须诚意，光明之态度可成，否则有不利之象。\n' +
                '\n' + '【买卖】\n' +
                '须诚信交易，有失败破财之害。\n' +
                '\n' + '【等人】\n' +
                '不必等了，不会来。\n' +
                '\n' + '【寻人】\n' +
                '宜速寻找，延误不利。\n' +
                '\n' + '【失物】\n' +
                '相信失物已经损坏，不能寻回。\n' +
                '\n' + '【外出】\n' +
                '不宜过于频繁，以逸待劳最佳。外出旅行吉利。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '不宜与人争执，因势孤力弱，宜寻求法律途径解决。\n' +
                '\n' + '【求事】\n' +
                '有困难，或得不到好职位。\n' +
                '\n' + '【改行】\n' +
                '改行者要慎重考虑。\n' +
                '\n' + '【开业】\n' +
                '开业者不利，如已备妥小心难关重重。',
                '\n' + '【事业】\n' +
                '合时宜，循时序。但潜在着危机，阴阳不协调。务必依附于强者，多方联系，增强势力，防止小人渔利，争取形势好转。诸事宜及早准备，等待时机，一旦成熟立刻行动。\n' +
                '\n' +
                '【经商】\n' +
                '市场正从红火的状态走向衰危，及早脱身为妙，强行发展有危险。无正直的人相助，却有小人在暗中盘算。对此，务必保持清醒的头脑，寻求可靠的合作伙伴，隐忍待时，以图商业活动的再发展。\n' +
                '\n' +
                '【求名】\n' +
                '性格不很合群，内心自视过高，不免处于困境而不得志。应投靠真诚正直的人，在其支持下成功。\n' +

                '\n' + '【外出】\n' +
                '有困难，虽无大灾祸，但无人相助。\n' +
                '\n' +
                '【婚恋】\n' +
                '严格考察对方的道德品质，避免陷入“三角”纠纷。夫妻以诚相待，可转危为安。\n' +
                '\n' +
                '【决策】\n' +
                '吉利。可在有利的环境中顺利发展，但决不可冒险行事，以免适得其反。其次在于随时提防身边小人的陷害。为此，在邪恶未成势之前即应制止，宁可孤立也不可与小人结伴。\n' +

                '\n' + '【解卦】\n' +
                '姤：代表邂逅、不其而遇，非预想中的事情来发生。主是个状况卦，吉凶未定。逅，会带来意外之喜、也会带来意外之灾，需视动爻变卦吉凶来论断。而「姤」意，卜感情通常是有其他的感情发生的迹象、与情色十分有关系。\n' +

                '\n' + '【解释】\n' +
                '二女争一男。\n' +
                '\n' + '【特性】\n' +
                '杞人忧天，虎头蛇尾，易感伤，有艺术美学之天分，较理想化。\n' +
                '\n' + '【运势】\n' +
                '阴长阳衰，男人谨防因色受祸，诸事不如意，不能上进，沉于女色之凶象也。凡事进取宜慎重。\n' +
                '\n' + '【家运】\n' +
                '家运渐衰，不祥之兆。有女人口舌，亦有感情之因，损财之运。\n' +
                '\n' + '【疾病】\n' +
                '病情重，有恶化情况，注意泌尿生殖系统、胸肺科病。\n' +
                '\n' + '【胎孕】\n' +
                '孕无碍。然须注意子女均有品性不良之趋向。\n' +
                '\n' + '【子女】\n' +
                '子女多有志行不坚定，给父母亲增加麻烦，不幸之象，难管教也。\n' +
                '\n' + '【周转】\n' +
                '有意外阻挠，难调，另谋他法为宜。\n' +
                '\n' + '【买卖】\n' +
                '不能如意，受奸诈之害而导致失败也。\n' +
                '\n' + '【等人】\n' +
                '女方会来，男方不一定会来，因为途中遇到别的女人之故。\n' +
                '\n' + '【寻人】\n' +
                '会在意想不到的地方相遇。寻人不易，西北方。\n' +
                '\n' + '【失物】\n' +
                '尽快寻找，可失而得。失物似被重重物件压在最底部。\n' +
                '\n' + '【外出】\n' +
                '有困难，虽无大灾祸，但无人相助。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '提防桃色纠纷，是非谣言拨弄。\n' +
                '\n' + '【求事】\n' +
                '难成。\n' +
                '\n' + '【改行】\n' +
                '不利。\n' +
                '\n' + '【开业】\n' +
                '不宜，强行必受小人之害。',
                '\n' + '【事业】\n' +
                '兴旺发达，团结和睦。正在蒸蒸日上，但水多泛滥，人多竞争必然激烈。盛则必衰，切勿骄傲，谨慎小心为好。但也不得犹豫不决，宜勇往直前，开拓新的业务。务以高尚的品德服众。\n' +
                '\n' +
                '【经商】\n' +
                '真诚合作，团结互助。鼓励是事业兴旺发达的标志。大胆开拓市场，稳妥前进，有备无患，必可取得不断发展。\n' +
                '\n' +
                '【求名】\n' +
                '光靠个人努力，建树不会很大，定要取得他人的帮助，加上个人的条件方可达到理想的境界。\n' +

                '\n' + '【外出】\n' +
                '出行虽可能遇到小麻烦，但不要害怕，应以大局为重，积极行动。\n' +
                '\n' +
                '【婚恋】\n' +
                '勿急于求成，相亲相爱，美满幸福。\n' +
                '\n' +
                '【决策】\n' +
                '善于团结他人共同合作，具有领导才能。但心胸宽阔，眼光务必远大，以诚信、中正为本，不失原则，尤其要严格要求自己，宽容他人，遭到他人反对时，应认真反省自己，得出教训，以这种心境处世为人，终生可平安，事业必通达。\n' +

                '\n' + '【解卦】\n' +
                '萃：代表精华聚集之意，主吉中带小凶之状况卦。有经过挑选、或是在因缘际会下，而重新再一起共事的意义。\n' +

                '\n' + '【解释】\n' +
                '人才聚集。\n' +
                '\n' + '【特性】\n' +
                '富同情心，慈悲心，被动，略消极，乏耐性，博学不专。人缘佳，易有情爱困扰。\n' +
                '\n' + '【运势】\n' +
                '昌隆，得信于人，承上辈照顾，事业吉昌，但宜小心财务上的纠纷。\n' +
                '\n' + '【家运】\n' +
                '家庭融洽如意。防财务歧见之意外。\n' +
                '\n' + '【疾病】\n' +
                '凶象，宜速诊治，防胸腹及咽喉之病变。\n' +
                '\n' + '【胎孕】\n' +
                '胎安。然产母病多，注意保养身体。\n' +
                '\n' + '【子女】\n' +
                '手足相敬如宾。\n' +
                '\n' + '【周转】\n' +
                '可得到上辈支持。\n' +
                '\n' + '【买卖】\n' +
                '可获利。\n' +
                '\n' + '【等人】\n' +
                '一定会来，且有佳音相告。\n' +
                '\n' + '【寻人】\n' +
                '会自己回来，不用担心。\n' +
                '\n' + '【失物】\n' +
                '多半能失而复得。\n' +
                '\n' + '【外出】\n' +
                '出行虽可能遇到小麻烦，但不要害怕，应以大局为重，积极行动。\n' +
                '\n' + '【考试】\n' +
                '常保佳绩。\n' +
                '\n' + '【诉讼】\n' +
                '宜解不宜结。\n' +
                '\n' + '【求事】\n' +
                '可顺利，有成就。\n' +
                '\n' + '【改行】\n' +
                '吉利。\n' +
                '\n' + '【开业】\n' +
                '开业适时，可按计划进行。',
                '\n' + '【事业】\n' +
                '十分顺利，不断发展，依时而进，逐步上升，前程远大，但应逐步发展，切莫因顺利而冒进。谨慎小心，修养德行，追随有德行的前辈，真诚对待事业。\n' +
                '\n' +
                '【经商】\n' +
                '遵守商业道德，从小处着手，逐渐开拓，通过自己的勤劳，真诚竞争，稳重为好，切莫冒失而成大错。\n' +
                '\n' +
                '【求名】\n' +
                '努力学习，加强修养，以自己的聪明才智，必能得志，若有人引荐，前途更为广阔。但应注意节制。\n' +

                '\n' + '【外出】\n' +
                '十分顺利，尤其是南行，不会有灾祸。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方以诚相待，逐步培养情感，一定可以成功。\n' +
                '\n' +
                '【决策】\n' +
                '如意随愿。聪明、灵活、具有良好的成功基础，严格要求自己，谦虚和顺，虚心向长辈求教，逐步、稳健地发展自己的事业，将犹如树木的成长，终于会成栋梁之才。但在成长过程中，务必处处谨慎，坚持真诚和正直，大胆前进。\n' +

                '\n' + '【解卦】\n' +
                '升：代表一种情势有如乘风而起，会越来越旺。树木从地上不断向上，有进升之势。\n' +

                '\n' + '【解释】\n' +
                '逐渐高升。\n' +
                '\n' + '【特性】\n' +
                '有计划安排自己的人生，做事考虑周详，待人谦和，追求新的知识。喜欢多彩人生，重生活品味。\n' +
                '\n' + '【运势】\n' +
                '诸事向上发展，开运之象，南方有吉庆，可名利双收也。\n' +
                '\n' + '【家运】\n' +
                '渐进而升至繁荣，积小以成大之吉运。\n' +
                '\n' + '【疾病】\n' +
                '病情重，注意腹部，胆管之疾。\n' +
                '\n' + '【胎孕】\n' +
                '不宜修造动土，修造必犯产母。\n' +
                '\n' + '【子女】\n' +
                '子女渐有成功之象，可贺。\n' +
                '\n' + '【周转】\n' +
                '勿太过急，可成。\n' +
                '\n' + '【买卖】\n' +
                '多有利，应于亥卯日。\n' +
                '\n' + '【等人】\n' +
                '迟到。\n' +
                '\n' + '【寻人】\n' +
                '动身则见，但恐自身有阻，与他人同去可，宜向北方。会自己回来。\n' +
                '\n' + '【失物】\n' +
                '不易寻回，失物有被束之高阁的可能。\n' +
                '\n' + '【外出】\n' +
                '十分顺利，尤其是南行，不会有灾祸。\n' +
                '\n' + '【考试】\n' +
                '进步，进取有利。\n' +
                '\n' + '【诉讼】\n' +
                '宜持平和态度处理，可争取主动，胜算在握。\n' +
                '\n' + '【求事】\n' +
                '有利。\n' +
                '\n' + '【改行】\n' +
                '吉利。\n' +
                '\n' + '【开业】\n' +
                '吉利，渐渐获利。',
                '\n' + '【事业】\n' +
                '境况十分不佳，遭受到很大的困难。人生面临巨大的考验，如采取不正当的手段，会愈陷愈深。相反，如身陷困逆境地而不失节操，自勉自坚，泰然处之。不失其志。终能成事。\n' +
                '\n' +
                '【经商】\n' +
                '面临激烈竞争，很有破产的可能。切勿失望，而应在困境中奋斗。为此，只能靠平日加强修养。认真反省自己的行为，总结教训，重新奋起，但也不宜浮躁，应缓慢而进。同时，更要警惕因致富发财，得意忘形而陷入新的困境。\n' +
                '\n' +
                '【求名】\n' +
                '欲速则不达。应以谦虚的态度，缓慢前进，尤应有坚定的志向，唯有志才能促成事业的成功。\n' +

                '\n' + '【外出】\n' +
                '困难多，慎重考虑。\n' +
                '\n' +
                '【婚恋】\n' +
                '以乐观态度冷静处理，尤应注重人品。\n' +
                '\n' +
                '【决策】\n' +
                '聪明智慧，但怀才不遇。若不因困境而失去信心，坚持努力上进，放弃侥幸心理，锲而不舍，虽不一定能守全实现自己的理想，但终会有所成。\n' +

                '\n' + '【解卦】\n' +
                '困：代表很大的困难被困住了，主大凶象，四大难卦第四卦。四处无援，最困难之时。事事很难再有进展，只好静待时机，是此时最好的选择。\n' +

                '\n' + '【解释】\n' +
                '被困住。\n' +
                '\n' + '【特性】\n' +
                '不满足感，不喜平淡生活，生活过于理想化，爱变化。自立自强，辛勤工作，善于用脑工作，不适合领导工作。\n' +
                '\n' + '【运势】\n' +
                '不如意，被小人欺，劳而无功，破损之灾。一事难成，运衰也。宜守己待时。\n' +
                '\n' + '【家运】\n' +
                '家庭之主有屈于下风，被内助压迫者，亦常生反弹，吵架滋生。为黑暗时期，宜忍辱负重，期待黎明到来。若不谨守正道者，有失和、破兆也。\n' +
                '\n' + '【疾病】\n' +
                '危重之象，注意口腔咽喉，泌尿系统，甚至性病。\n' +
                '\n' + '【胎孕】\n' +
                '胎安。将来劳碌命格。\n' +
                '\n' + '【子女】\n' +
                '劳苦之命，但行为端正者，终可得福也。\n' +
                '\n' + '【周转】\n' +
                '求人不如求己，凡事需量入为出。若为女色破财，当然求助无门。\n' +
                '\n' + '【买卖】\n' +
                '不能如愿，有挫折。\n' +
                '\n' + '【等人】\n' +
                '受到阻碍，不来或迟到。\n' +
                '\n' + '【寻人】\n' +
                '途中可遇，来者自来也。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '困难多，慎重考虑。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '凡事不宜过于执着，防牢狱之灾。\n' +
                '\n' + '【求事】\n' +
                '不得时亦不得意，再待时机。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '开业者须再待时。',
                '\n' + '【事业】\n' +
                '处于平稳状态。既不宜贸然前进，也不必后退，而应以积极的态度努力进修，提高自己，充实个人实力，待机而起，将大有成就。最怕的是停顿不前，否则将会因不合时宜而被淘汰。\n' +
                '\n' +
                '【经商】\n' +
                '市场情况良好，可继续按照原来的方针。同时，继续本着勤劳、奋进的精神，诚心诚意地开拓市场，特别注意与人的合作，相互协助，在实践活动中丰富和积累经验。\n' +
                '\n' +
                '【求名】\n' +
                '本着正当的目的，为社会做贡献，因此而努力学习。应特别注意向贤德的人求教，以便被发现而受到推荐。\n' +

                '\n' + '【外出】\n' +
                '没有十分的必要和充分的把握不可随意出行。\n' +
                '\n' +
                '【婚恋】\n' +
                '不必过急，会有般配的伴侣。\n' +
                '\n' +
                '【决策】\n' +
                '聪明、机敏、乐于助人。同时，也会有机会充分施展个人的才能。只要能学习“水井”的精神，真诚奉献，不断丰富自己的才能，一定会受到社会的重视。\n' +

                '\n' + '【解卦】\n' +
                '井：代表小水之源，有格局小却能过活之心态，主小吉象。井水虽不能种植、畜养，却能养人度日，显示心力之有限，不能做太大的计划。也另代表视野没有很宽广，比较短视，保守心态重。\n' +

                '\n' + '【解释】\n' +
                '井底之蛙。\n' +
                '\n' + '【特性】\n' +
                '积极，主动，创新，改革，反应快，口才佳，组织能力强，有领导才能，贵人多助，社会名誉佳。\n' +
                '\n' + '【运势】\n' +
                '缺乏衡力，因井为静而不能移之物。故凡事已无法进取，不如守之泰然。\n' +
                '\n' + '【家运】\n' +
                '诸事宜心平气和处理，守其井水，供来往人用，为守旧之义。诸事宜防有变。\n' +
                '\n' + '【疾病】\n' +
                '病情较重且拖延，可能复发。\n' +
                '\n' + '【胎孕】\n' +
                '宜防有不成之兆。防产母有灾，作福祈保可平安。\n' +
                '\n' + '【子女】\n' +
                '儿女有顺良之象，宜防血光。\n' +
                '\n' + '【周转】\n' +
                '无望，再待时另调。\n' +
                '\n' + '【买卖】\n' +
                '尚可，但不可大作为。小交易有利。\n' +
                '\n' + '【等人】\n' +
                '迟来。\n' +
                '\n' + '【寻人】\n' +
                '东南或北方可寻。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '没有十分的必要和充分的把握不可随意出行。\n' +
                '\n' + '【考试】\n' +
                '尚可。\n' +
                '\n' + '【诉讼】\n' +
                '有刑罚之象，且受人牵连，及防房地产之诉讼。\n' +
                '\n' + '【求事】\n' +
                '安于本份，换新的工作也是小工作。\n' +
                '\n' + '【改行】\n' +
                '不利，守之尚可。\n' +
                '\n' + '【开业】\n' +
                '开业者不宜，此卦有破损之象。',
                '\n' + '【事业】\n' +
                '正处在转折的关键时刻，必须密切注意各种信息，认真思考。首先巩固自己的地位，完善个人的行为。时机成熟后，立即行动，积极进行变革。但应注意动机纯正，手段正当，不保守也不妄进，事业必定发达。\n' +
                '\n' +
                '【经商】\n' +
                '市场竞争十分激烈。为了立于不败之地，一定要敢于大胆求新，适时改变自己的经营方向，受到挫败，马上总结经验，重新奋起，拓展商业活动。注意竞争方式。\n' +
                '\n' +
                '【求名】\n' +
                '首先要努力提高自己，完善个人的学识和品德。同时，服从上级指挥搞好工作。二者的关系一定要得到妥善处理。\n' +

                '\n' + '【外出】\n' +
                '十分顺利，积极活动，扩大人际关系。\n' +
                '\n' +
                '【婚恋】\n' +
                '可能会出现一些节外生枝的麻烦事，应冷静处理，感情专注。\n' +
                '\n' +
                '【决策】\n' +
                '十分吉利、顺利。以自己的聪明才智，顺应形势，根据时代特点，依理而动，变革渐合时宜的事物，壮大自己的事业。但应谨慎，时机不成熟不可妄动，尤忌急功近利。行动之前应三思而行。\n' +

                '\n' + '【解卦】\n' +
                '革：代表该改革、革新之时候了。主是个状况卦，吉凶未定。事事情况虽不稳定、明朗，但只要有心改变，重新再来则成功机会大。一切不可固执不化、不变通。\n' +

                '\n' + '【解释】\n' +
                '革旧布新。\n' +
                '\n' + '【特性】\n' +
                '性急，反传统，爱改变现象，创新事物，旅行，变换工作，口齿伶俐。\n' +
                '\n' + '【运势】\n' +
                '不稳定，多变化之际，凡事均有所变动，故需弃腐朽而立新者，宜下决心改革。但仍须谨慎改革之道，善改则吉，恶改则凶。\n' +
                '\n' + '【家运】\n' +
                '多事之秋。慎重改变自己的生活方式，方能建立新的气象。\n' +
                '\n' + '【疾病】\n' +
                '病情多变，宜改换求医方法，注意心脏，眼目和咽喉之疾。\n' +
                '\n' + '【胎孕】\n' +
                '胎安。\n' +
                '\n' + '【子女】\n' +
                '子女与母亲有缘薄之象。\n' +
                '\n' + '【周转】\n' +
                '要即时改变方针。\n' +
                '\n' + '【买卖】\n' +
                '改变经营方式为宜。\n' +
                '\n' + '【等人】\n' +
                '因中途变卦不会来。\n' +
                '\n' + '【寻人】\n' +
                '速改变方向，向西、南方寻找。\n' +
                '\n' + '【失物】\n' +
                '因有弃旧迎新之意，故相信不能寻回。\n' +
                '\n' + '【外出】\n' +
                '十分顺利，积极活动，扩大人际关系。\n' +
                '\n' + '【考试】\n' +
                '越来越好。\n' +
                '\n' + '【诉讼】\n' +
                '要改变旧有对策，才可圆满解决。\n' +
                '\n' + '【求事】\n' +
                '不可守旧，改变职事有利之时机也。宜速把握良机。\n' +
                '\n' + '【改行】\n' +
                '大吉大利。\n' +
                '\n' + '【开业】\n' +
                '大吉大利。',
                '\n' + '【事业】\n' +
                '具备开拓事业的各种条件。耳聪目明，头脑冷静，应以端正的态度去为人处世，严于律已，慎终如始，刚柔兼备，与有才德的人合作，勿妄进失度，无往不利。\n' +
                '\n' +
                '【经商】\n' +
                '经过一番奋斗，已经可以比较顺利地发展自己的商业活动，遇到困难也能够克服。倘若坚持商业道德，参与正常竞争活动，无轻举妄动和邪思，刚中自守，商业可大发展，但若不量入为出，坐吃山空，必败业。\n' +
                '\n' +
                '【求名】\n' +
                '首先应实德积识，严于律已，不陷入与他人的怨仇之中，柔而上行，循序渐进。得到知人者的善用，更是前途广大。\n' +

                '\n' + '【外出】\n' +
                '无重大事情不宜外出。为发展事业外出顺利。\n' +
                '\n' +
                '【婚恋】\n' +
                '个人条件比较理想，顺利，但勿不切合自己的实际。\n' +
                '\n' +
                '【决策】\n' +
                '天资聪颖，反应敏捷，头脑灵活，具备随机应变，随时应变和随势应变的能力。因此，前程远大，即使暂时不受重视，无出路也无防，最终可实现抱负。\n' +

                '\n' + '【解卦】\n' +
                '鼎：代表三足而立，三人而行，平稳之势。另表拜神、礼佛的香炉，主吉象。事业投资应与人合伙为佳，诸事可在平稳中发展。唯对感情、婚姻略有凶象，出现三人行的机率很高，有外遇出轨的迹象。只是大家会很理智的来寻求解决。\n' +

                '\n' + '【解释】\n' +
                '极为旺盛。\n' +
                '\n' + '【特性】\n' +
                '因人成事，得利于他人之助，多有长辈照顾提拔。交游广阔，喜欢热闹，人多之处更能表现其才能。\n' +
                '\n' + '【运势】\n' +
                '能解决困难，取得事物之稳定而名利双收，会有不错的成就，最好新找外人来共事、合伙。\n' +
                '\n' + '【家运】\n' +
                '生平幸福之运，吉也。\n' +
                '\n' + '【疾病】\n' +
                '病情有变，但无大碍，为胆石，心脏，消化系统病。\n' +
                '\n' + '【胎孕】\n' +
                '不安，不久便产。男女将来皆豪杰，母宜保。\n' +
                '\n' + '【子女】\n' +
                '子女均才能出众，将成大功立大业之趋势也。\n' +
                '\n' + '【周转】\n' +
                '可成。\n' +
                '\n' + '【买卖】\n' +
                '有大利可得。\n' +
                '\n' + '【等人】\n' +
                '一定会来。\n' +
                '\n' + '【寻人】\n' +
                '南方或东南方。不必操心，会自己回来。\n' +
                '\n' + '【失物】\n' +
                '过一段时间后可寻回，惟已变形。\n' +
                '\n' + '【外出】\n' +
                '无重大事情不宜外出。为发展事业外出顺利。\n' +
                '\n' + '【考试】\n' +
                '登科上榜。\n' +
                '\n' + '【诉讼】\n' +
                '由小事引起，宜和解了事。\n' +
                '\n' + '【求事】\n' +
                '大利，有上辈提拔。\n' +
                '\n' + '【改行】\n' +
                '有得天独厚的条件，速进行。\n' +
                '\n' + '【开业】\n' +
                '吉利通达之象。',
                '\n' + '【事业】\n' +
                '处于不顺利、甚至面临危险的局面，对此务必提高警惕，有备方可无患，一旦出现突发事件，可以泰然处之，并且可以由此引吸取教训，一方面使损失减少，另一方面可以迅速复原，使事业迈上新的高度。\n' +
                '\n' +
                '【经商】\n' +
                '在激烈的商场竞争中，随时可能出现问题，陷入困境。冷静、从容地应付到来的现实，坚持不偏不激的中正原则，以刚毅的态度，从容不迫地去战胜困难。经过一番危机后，一定可以获得巨大利益。\n' +
                '\n' +
                '【求名】\n' +
                '很可能会遭到挫折。不必灰心，而应自我反省，找出问题的症结所在。\n' +

                '\n' + '【外出】\n' +
                '大胆出行，不必理会小的灾祸。\n' +
                '\n' +
                '【婚恋】\n' +
                '恋爱和家庭都可能出现变故，应以冷静态度处理，会有理想的结果。\n' +
                '\n' +
                '【决策】\n' +
                '处于人生的转折关头。应正确地总结过去，为开拓新的事业奠定基础。在前进的道路上应随时心怀恐惧，勤勤恳恳，坚持正确原则，在困难中前进，命运亨通。\n' +

                '\n' + '【解卦】\n' +
                '雷：代表奋发、震动有冲动不安稳的现象。六冲纯卦，主大好大坏之卦象。此卦要注意意外血光，有被惊吓之情形发生，运动比赛可为吉论。雷通常代表，声势浩大，却是有声无形虚象之意。与人交往最忌此卦，表面热心却只是虚应一番，无诚心，不能用真诚来对待，否则会很失望。\n' +

                '\n' + '【解释】\n' +
                '受惊，害怕。\n' +
                '\n' + '【特性】\n' +
                '心直口快，喜怒形于色，缺乏耐性，工作不稳定，爱变化，理财能力差，自立自强。\n' +
                '\n' + '【运势】\n' +
                '表面似盛泰，但正处于多事之秋，宜慎重，勿自视过高，无益也。有动荡、不安、惊惧之象耳。\n' +
                '\n' + '【家运】\n' +
                '家中常有变动惊惧、不平等、多争吵，影响家运进展，宜审慎处理家务事也。\n' +
                '\n' + '【疾病】\n' +
                '注意肝、神经系统、足疾，病情反覆。\n' +
                '\n' + '【胎孕】\n' +
                '临产妇得之无碍。\n' +
                '\n' + '【子女】\n' +
                '子女多有性刚者，但皆争气，奋发，并有少年劳苦老来福之象，有成有福。\n' +
                '\n' + '【周转】\n' +
                '抱持可有可无之心态，因此卦有被反悔之象。\n' +
                '\n' + '【买卖】\n' +
                '不可有始无终，否则难成。\n' +
                '\n' + '【等人】\n' +
                '会来，而且会提早到。\n' +
                '\n' + '【寻人】\n' +
                '此人临时起意而出走，但亦会马上回来。\n' +
                '\n' + '【失物】\n' +
                '可能是受某些事惊吓而遣失，令你惊惶失措，尝试于会发声的东西或电器处寻找。\n' +
                '\n' + '【外出】\n' +
                '大胆出行，不必理会小的灾祸。\n' +
                '\n' + '【考试】\n' +
                '可榜登科甲。\n' +
                '\n' + '【诉讼】\n' +
                '反覆难平，宜收敛脾气，心平气和。\n' +
                '\n' + '【求事】\n' +
                '不利。\n' +
                '\n' + '【改行】\n' +
                '改行吉利，为变动之时机也。\n' +
                '\n' + '【开业】\n' +
                '吉利，可依计划而行。',
                '\n' + '【事业】\n' +
                '在经过一段发展后，应进行调整，暂时停止行动，总结经验教训。不可因贪名利而妄进。自我克制，自我约束，谨言慎行，不要盲目追求他人。经过休整后，在适当时机到来时，再大显身手。\n' +
                '\n' +
                '【经商】\n' +
                '进入停滞不前的状态，切勿盲目冒进，而宜适时停止，进入安静退守，不要受外界干扰，尤其是应该保持内心和理智上的宁静，更不要轻易与他人合作。等待时机，必有光明前途。\n' +
                '\n' +
                '【求名】\n' +
                '应保持纯正的目的，静守正道。勿为功名诱惑，秉守忠厚，可得成功。\n' +

                '\n' + '【外出】\n' +
                '慎重为好，宜另择佳日。\n' +
                '\n' +
                '【婚恋】\n' +
                '重感情，彼此以诚相待，可白头到老。\n' +
                '\n' +
                '【决策】\n' +
                '性格沉静、内向，不喜好活动，也不善于交际，为人忠诚老实，讲信义。注意听取他人的建议，明哲保身。机遇不到，不宜积极活动，该止则止，勿抱不切实际的想法。\n' +

                '\n' + '【解卦】\n' +
                '山：代表停止、退守之意，六冲纯卦，主大好大坏之象。凡事应当知进退、量力而为。有如登山越岭般，需充分审视自己的体能，和山上的情势，绝不可强行逞能，适时的休息，方能平安度过。得此卦时应有大事或障碍、不顺阻挡在前，若能保守、修身养性则安，反则为凶。\n' +

                '\n' + '【解释】\n' +
                '不动，自己改变。\n' +
                '\n' + '【特性】\n' +
                '耐性佳，保守经营，努力奋斗，老成持重，有偏财运，精于计划分析，处事有条理，公私分明，择善固执。\n' +
                '\n' + '【运势】\n' +
                '应当洁身自爱，依赖心不要太重，否则不利。凡事不可轻举妄动，诸事宜守，相辅得吉。儒家有言\n' +
                '\n' + '【家运】\n' +
                '不和，家运停滞不发，改正自己以谋求开运之道，内有动荡、困境。\n' +
                '\n' + '【疾病】\n' +
                '难治，注意肠胃血管硬化及结核病。\n' +
                '\n' + '【胎孕】\n' +
                '难产之虞。\n' +
                '\n' + '【子女】\n' +
                '子女多有不和、不相辅之数。\n' +
                '\n' + '【周转】\n' +
                '难成。\n' +
                '\n' + '【买卖】\n' +
                '有些小是非，失利之象，但可成。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '西南方，难寻。\n' +
                '\n' + '【失物】\n' +
                '可以寻回，但要等一段时间。藏在家中、旅馆、寺院、山中某处。\n' +
                '\n' + '【外出】\n' +
                '慎重为好，宜另择佳日。\n' +
                '\n' + '【考试】\n' +
                '落榜。\n' +
                '\n' + '【诉讼】\n' +
                '因小事而化大，宜速求和，忌土姓人。\n' +
                '\n' + '【求事】\n' +
                '固守本份为宜。\n' +
                '\n' + '【改行】\n' +
                '不利。\n' +
                '\n' + '【开业】\n' +
                '开业不宜，须再待时。',
                '\n' + '【事业】\n' +
                '在经过一个相对静止的时期后，重新开始了事业的新阶段。这时最怕的是急躁冒进，务必循序上进，脚踏实地。同是注意品德修养，以沉着、谦逊的态度对待事业，可无往而不利。\n' +
                '\n' +
                '【经商】\n' +
                '正处在稳步向前发展的阶段，定要把握时机，既不可盲目乐观，也不要停步不前，而应采取逐步前进的策略，量力而行。遵守商业道德，以良好的服务，开拓市场。\n' +
                '\n' +
                '【求名】\n' +
                '效法树木植根山上，逐渐生长的精神。在品德和学业两个方面，扎扎实实地提高自己。同时，肩负起改善社会风俗的责任。求名应以渐进为宜，切不可争进。\n' +

                '\n' + '【外出】\n' +
                '准备充分，从容外出，一路平安。\n' +
                '\n' +
                '【婚恋】\n' +
                '不可操之过急。幸福、美满，夫妻白头和谐。\n' +
                '\n' +
                '【决策】\n' +
                '时来运转的时刻已经到来，切莫错过良机。务必小心谨慎，渐进有益有利。以谦和的态度处世，可以得到强有力的支持，会获得重大成功。\n' +

                '\n' + '【解卦】\n' +
                '渐：代表循序渐进，不可心急之意，主吉象。好事慢慢在进行中，一切遵循正理常规即可，事业投资均能有收益。感情婚姻，更是结果收成，欢喜结局之时。\n' +

                '\n' + '【解释】\n' +
                '逐渐好转。\n' +
                '\n' + '【特性】\n' +
                '感觉细腻，思想敏锐，重品味，讲情调之人，爱追求变化的事物，口才佳。反应快，为人热心，财运丰厚。\n' +
                '\n' + '【运势】\n' +
                '逐渐顺利，光明开运之象。凡事务必掌握时机，循序渐进，可得吉庆。须防款项交易之差错及色情之灾。\n' +
                '\n' + '【家运】\n' +
                '渐曙光明、幸福之象，诸事宜以顺乎自然为吉，反则有克也。\n' +
                '\n' + '【疾病】\n' +
                '恶化之象，注意胃、肠、背痛等。\n' +
                '\n' + '【胎孕】\n' +
                '无碍。\n' +
                '\n' + '【子女】\n' +
                '儿女多坚强笃实，态度温顺，将来有成就。\n' +
                '\n' + '【周转】\n' +
                '不成亦勿放弃，久调可成。\n' +
                '\n' + '【买卖】\n' +
                '渐进有利，过急不利，欲速则不达。\n' +
                '\n' + '【等人】\n' +
                '迟到。\n' +
                '\n' + '【寻人】\n' +
                '在东南或东北二方，过些时日可寻得。\n' +
                '\n' + '【失物】\n' +
                '多数不能寻回。\n' +
                '\n' + '【外出】\n' +
                '准备充分，从容外出，一路平安。\n' +
                '\n' + '【考试】\n' +
                '会取得好成绩。\n' +
                '\n' + '【诉讼】\n' +
                '宜进不宜退，据理力争可胜。\n' +
                '\n' + '【求事】\n' +
                '可寻得良好工作。\n' +
                '\n' + '【改行】\n' +
                '吉利。\n' +
                '\n' + '【开业】\n' +
                '吉利，渐有发展之象。',
                '\n' + '【事业】\n' +
                '正在进入新的阶段，很快将会出现另一番景象。这时首先必须具备远大眼光，识破利弊和吉凶，有备始能无患。其次是坚守纯正的品德和正确的原则。\n' +
                '\n' +
                '【经商】\n' +
                '开拓新的市场即将成功。在未来的商业活动中必须注意经营的方向和种类，加强与他人合作。\n' +
                '\n' +
                '【求名】\n' +
                '务必以坚定的决心，努力提高自己，为成名立业奠定基础，最可怕是儿女情长影响了自己的前途和事业。\n' +

                '\n' + '【外出】\n' +
                '十分顺利，即便发生意外也会有人相助。\n' +
                '\n' +
                '【婚恋】\n' +
                '顺其自然，不可违背原则，尤其不可强求，宁可晚婚也要选择理想的对象。夫妻经过生活考验，可和谐到老。\n' +
                '\n' +
                '【决策】\n' +
                '对于人生必须坚持公正纯洁的品德，正确对待婚姻和家庭，以幸福美满的家庭为基础，开拓和发展自己的事业。\n' +

                '\n' + '【解卦】\n' +
                '归妹：代表小妹急着先出嫁，为感情冲动、不理智之象。得此卦投资、升迁、合伙，大概与男女之间的感情用事，会有很大的关连。感情，第三者积极介入之象，或是自己一厢情愿冲动的妄想。\n' +

                '\n' + '【解释】\n' +
                '精神恍惚。\n' +
                '\n' + '【特性】\n' +
                '具有同情心，喜欢结交朋友，爱热闹，贵人运多，恋爱运早，喜追求变化性工作，容易转换工作，多学不专。\n' +
                '\n' + '【运势】\n' +
                '祸出百端，事物有违常理。初时有悦，不久反凶，祸害随至。\n' +
                '\n' + '【家运】\n' +
                '外观风光幸福，其实家内正起风波，有失和、祸害等不幸情况。\n' +
                '\n' + '【疾病】\n' +
                '病危重，极易恶化，为中风、肝病、气管及性病等。\n' +
                '\n' + '【胎孕】\n' +
                '无碍。秋占不利。\n' +
                '\n' + '【子女】\n' +
                '儿女有沉于情欲之中者。\n' +
                '\n' + '【周转】\n' +
                '希望渺小。\n' +
                '\n' + '【买卖】\n' +
                '表面上不错，其实亏了老本。\n' +
                '\n' + '【等人】\n' +
                '不会来。\n' +
                '\n' + '【寻人】\n' +
                '在东方或西方。\n' +
                '\n' + '【失物】\n' +
                '失物虽有被归还的可能，但自身仍有损失。\n' +
                '\n' + '【外出】\n' +
                '十分顺利，即便发生意外也会有人相助。\n' +
                '\n' + '【考试】\n' +
                '有重新再读一年之况。\n' +
                '\n' + '【诉讼】\n' +
                '事牵连于女性，宜力求和解，但我方必有损失。\n' +
                '\n' + '【求事】\n' +
                '放弃算了，再另做打算。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '开业者宜暂停，静候时机。',
                '\n' + '【事业】\n' +
                '处于十分顺利的鼎盛时期，各方面都很顺利，成就很大。但务必注意开始出现的衰败症状，防微杜渐，严防骄傲自满。同时，不必盲目追求不切实际的事情，全力保持当前状况的延长。\n' +
                '\n' +
                '【经商】\n' +
                '由于准确地把握了市场动态，商情对自己十分有利，可以大胆发展，但一定要密切注意行情，分析信息，稍有不慎，破产可能在一夜间。一旦破产应立即总结教训寻求真诚的合作者，早日走出困境。\n' +
                '\n' +
                '【求名】\n' +
                '经过努力和奋斗，已获得成就，应注意保持和稳步发展，切勿追求虚名。\n' +

                '\n' + '【外出】\n' +
                '十分顺利。但应提高警惕，防止乐极生悲。\n' +
                '\n' +
                '【婚恋】\n' +
                '可以成功，也会美满幸福，但若一方自持条件变化而动摇，则会导致不利。\n' +
                '\n' +
                '【决策】\n' +
                '由于个人天资聪颖，条件好，因此各方面十分顺利。但务必深刻认识人生无常的真理，一切应保持适度，切记物极必反。更不应自我封闭，陷入困境，切莫灰心。\n' +

                '\n' + '【解卦】\n' +
                '丰：代表丰收之象。凡事积极奋发可成，有兴致高昂，一时天雷勾动地火，闪电迅速达成之意，此卦最利于短期投资理财，感情方面，情投意合则可速成。\n' +

                '\n' + '【解释】\n' +
                '丰富，丰收。\n' +
                '\n' + '【特性】\n' +
                '随和谦虚，懒散但内心急躁，被动，拖延，须人催促，多偏财运或意外之好处，多异性之帮助。\n' +
                '\n' + '【运势】\n' +
                '运势极强，为收获之时，但不宜贪得无厌，须知足常乐，要防是非，损财甚至火险。\n' +
                '\n' + '【家运】\n' +
                '繁华幸福，但为人处事宜守分寸为要。\n' +
                '\n' + '【疾病】\n' +
                '病况重，注意肝足疾及血压，心脏等疾。\n' +
                '\n' + '【胎孕】\n' +
                '无碍。\n' +
                '\n' + '【子女】\n' +
                '对子女宜多加教养，免招不幸。\n' +
                '\n' + '【周转】\n' +
                '耐心应对，则可在短时间内达成。利在寅午未申日。\n' +
                '\n' + '【买卖】\n' +
                '正直经营可获利。贪则有失。\n' +
                '\n' + '【等人】\n' +
                '会来。亦会因故而很快离去。\n' +
                '\n' + '【寻人】\n' +
                '此人会自觉不安而自己回来。\n' +
                '\n' + '【失物】\n' +
                '尽快找寻，可失而复得。\n' +
                '\n' + '【外出】\n' +
                '十分顺利。但应提高警惕，防止乐极生悲。\n' +
                '\n' + '【考试】\n' +
                '及格有望。\n' +
                '\n' + '【诉讼】\n' +
                '自己虽势如破竹，胜券在握，但也应提高警惕。\n' +
                '\n' + '【求事】\n' +
                '有利。\n' +
                '\n' + '【改行】\n' +
                '改行不可三心二意。\n' +
                '\n' + '【开业】\n' +
                '开业者吉利。',
                '\n' + '【事业】\n' +
                '正处于创业、发展的阶段，各种困难很多。只要能坚持中正的方针，依正道办事，注意调查，处处小心谨慎，并根据情况的变化，采取应变措施，事业可顺利前进。\n' +
                '\n' +
                '【经商】\n' +
                '市场行情犹如旅途一样，意想不到的情况，会随时发生。因此，首先应采取慎重的原则，不要冒险。但也不应缩手缩脚，尤其不要猜疑。开发市场之前，应进行充分的调查研究。\n' +
                '\n' +
                '【求名】\n' +
                '根据时代的要求，丰富自己的知识和能力，不断提高自己，并且争取得到他人的相助。\n' +

                '\n' + '【外出】\n' +
                '很顺利，且经常出行。因此，要时时注意安全。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方的不确定因素较多，如确有感情，可顺其发展，再行确定。婚姻美满。\n' +
                '\n' +
                '【决策】\n' +
                '人生处于多变状态，一定要眼光远大，树立大志向，不断地充实自己，以诚心实意和谦虚的态度去待人处事，勿计较一时得失，广泛交际朋友。人生旅途十分顺利。\n' +

                '\n' + '【解卦】\n' +
                '旅：代表旅行、不定、不安稳之意。旅行者常居无定所，代表事事皆在浮动之中，虽不现凶象，但也是很令人烦心的。投资理财、感情、婚姻，大慨都是游戏的心态吧！\n' +

                '\n' + '【解释】\n' +
                '在火山口旅游，十分危险。\n' +
                '\n' + '【特性】\n' +
                '一生多外出旅行运，喜欢多变化事物，重效率，享受，亦多桃花运，异性缘浓。兴趣广泛，爱尝试冒险。\n' +
                '\n' + '【运势】\n' +
                '诸事变动不定，此时只好坚守信心，多参考他人意见，否则必有凶。\n' +
                '\n' + '【家运】\n' +
                '内面不和，是非多，意见不一。家运衰也。\n' +
                '\n' + '【疾病】\n' +
                '病情变化不定，宜速就医。\n' +
                '\n' + '【胎孕】\n' +
                '多是夜间产。\n' +
                '\n' + '【子女】\n' +
                '骨肉无情，不幸之兆。\n' +
                '\n' + '【周转】\n' +
                '小数目可，大数目不成。\n' +
                '\n' + '【买卖】\n' +
                '多碍难成，或交易不成。\n' +
                '\n' + '【等人】\n' +
                '临时变意不会来。\n' +
                '\n' + '【寻人】\n' +
                '此人为情所困，意志消沉而远离也，难寻。\n' +
                '\n' + '【失物】\n' +
                '尽快去找，应可寻回，失物似在屋舍之外，留意镜子之类物体，可提供线索。\n' +
                '\n' + '【外出】\n' +
                '很顺利，且经常出行。因此，要时时注意安全。\n' +
                '\n' + '【考试】\n' +
                '很差。\n' +
                '\n' + '【诉讼】\n' +
                '宜速战速决，时间一拖则不利。\n' +
                '\n' + '【求事】\n' +
                '白费心神。\n' +
                '\n' + '【改行】\n' +
                '不利。\n' +
                '\n' + '【开业】\n' +
                '开业者不得时机。',
                '\n' + '【事业】\n' +
                '正在发展中，应本着勇往直前的精神，战胜一切困难和险阻，奋发向上。万事起头难，开始会受挫折，但在有才德和有力量的人物帮助下，会有理想的结果。\n' +
                '\n' +
                '【经商】\n' +
                '市场竞争激烈，风险大。商业活动务必小心谨慎，遵守商业道德，以谦逊的态度对待同行和顾客，及至竞争对手，必定可以取得利益。\n' +
                '\n' +
                '【求名】\n' +
                '以谦逊的态度对待一切，但又不可自卑、软弱、做作，更不可虚伪，这样就可以进步很快。\n' +

                '\n' + '【外出】\n' +
                '一帆风顺，十分顺利，但仍得注意安全，防止发生意外事故。\n' +
                '\n' +
                '【婚恋】\n' +
                '不可抱轻率的态度，而应慎重，相互尊重。\n' +
                '\n' +
                '【决策】\n' +
                '头脑聪明、灵活，性格开朗，以谦虚的态度处世，会受到各个方面的欢迎，也会得到上级的重视。谦虚不可过度，应以刚健中正为前提，决不可表现为懦弱和虚伪。否则，不利于自身的成长。\n' +

                '\n' + '【解卦】\n' +
                '风：代表不定之象，时柔顺、时狂暴。六冲纯卦，主大好大坏之象。不易控制的局面，包括人、事、感情、投资，都是令人很难去掌控的、会有波折和变化。\n' +

                '\n' + '【解释】\n' +
                '粗暴。\n' +
                '\n' + '【特性】\n' +
                '求知欲强，喜欢追求新事物，新潮流，多外出旅行运，朋友多，财运佳，异性缘重，相识满天下。\n' +
                '\n' + '【运势】\n' +
                '此卦象征多波折，游离不定之运也。此时要处理不惊，坚定意志，万事果断而随机应变，不必一味跟从顺行。待人接物宜心平气和，则可得意外之收获。切记狂风之下必有所损。\n' +
                '\n' + '【家运】\n' +
                '家内已生风波，遵照长辈意见与指示方可渡过难关，否则动荡不安。\n' +
                '\n' + '【疾病】\n' +
                '久病则凶，且病情变化多端。\n' +
                '\n' + '【胎孕】\n' +
                '有碍。宜慎。\n' +
                '\n' + '【子女】\n' +
                '养儿育女，可谓无微不至，但有孝顺恩情之子女，福也。\n' +
                '\n' + '【周转】\n' +
                '小吉，大则难调。\n' +
                '\n' + '【买卖】\n' +
                '好时机，绝对有利。\n' +
                '\n' + '【等人】\n' +
                '主动约人，有结果。\n' +
                '\n' + '【寻人】\n' +
                '此人隐于附近朋友家中，好言相劝可回。大致是吵架出走，若是债务纠纷寻人者，注意发生冲突损伤。东南方位。\n' +
                '\n' + '【失物】\n' +
                '遗失在远处，不能寻回。\n' +
                '\n' + '【外出】\n' +
                '一帆风顺，十分顺利，但仍得注意安全，防止发生意外事故。\n' +
                '\n' + '【考试】\n' +
                '宜多用功。\n' +
                '\n' + '【诉讼】\n' +
                '不易和解，但一方肯让步则易解决。\n' +
                '\n' + '【求事】\n' +
                '不能达到理想，可随机应变。\n' +
                '\n' + '【改行】\n' +
                '需看情况，但必有所变动。\n' +
                '\n' + '【开业】\n' +
                '开业可行，但勿太急，慢慢进取有利。',
                '\n' + '【事业】\n' +
                '由于善长人际关系，能团结他人，获得援助。因此，各项事业都十分顺利。只要本人坚持中正之道，动机纯正，是非分明，以诚心与人和悦，前途光明。\n' +
                '\n' +
                '【经商】\n' +
                '很有利。可以取得多种渠道的支持。但在顺利时切莫忘记谨慎小心的原则，尤其警惕上小人的当。\n' +
                '\n' +
                '【求名】\n' +
                '只要自己目的纯正，并有真才实学，一定可以受到多方面的热情帮助和资助，实现追求目的。\n' +

                '\n' + '【外出】\n' +
                '一路平安，即使遇到困难也会有人帮助，解脱困境。\n' +
                '\n' +
                '【婚恋】\n' +
                '婚恋彼此满意，成功的可能性很大。但千万不要过于坚持己见。\n' +
                '\n' +
                '【决策】\n' +
                '为人聪颖，性格开朗，头脑灵活，心地善良，热心为公众服务，富有组织才能。因此，可以比较顺利地走上领导岗位。但一定要坚持中正原则，秉公办事，不得诌媚讨好上级，更不可欺压民众。永远保持谦虚品德，尤其不可过分自信，否则很容易为坏人包围。\n' +

                '\n' + '【解卦】\n' +
                '泽：代表少女纯真喜悦之象，却在纯真之中带有娇蛮、任性的态度。六冲卦象，大好大坏。忧喜参半！\n' +

                '\n' + '【解释】\n' +
                '喜悦，高兴。\n' +
                '\n' + '【特性】\n' +
                '细心，体贴，善解人意，口才佳，幽默感，宜从事公关，服务业。\n' +
                '\n' + '【运势】\n' +
                '有喜亦有忧，有誉亦有讥，虽得吉庆如意，然应守持正道，否则犯灾。\n' +
                '\n' + '【家运】\n' +
                '有和悦之气象，但要操守自律，行事不可越轨，有分寸可得吉运。若不操守自律，必犯色情之害而受殃耳。\n' +
                '\n' + '【疾病】\n' +
                '久病则凶，注意生活检点，戒酒色。\n' +
                '\n' + '【胎孕】\n' +
                '孕安。能带给家人喜悦，又与六亲和睦，有缘。但也不要过份溺爱才是。\n' +
                '\n' + '【子女】\n' +
                '骨肉情深，和好幸福之象。\n' +
                '\n' + '【周转】\n' +
                '可顺利，不须急也。\n' +
                '\n' + '【买卖】\n' +
                '有反覆之象，然尽力必成，可得大利之交易。\n' +
                '\n' + '【等人】\n' +
                '会来，且有喜讯相告。\n' +
                '\n' + '【寻人】\n' +
                '很快可知其下落。向西方寻可得。\n' +
                '\n' + '【失物】\n' +
                '遗失物似为金属或金钱，有望失而复得，但是迟一点。且多数已损毁或损失。\n' +
                '\n' + '【外出】\n' +
                '一路平安，即使遇到困难也会有人帮助，解脱困境。\n' +
                '\n' + '【考试】\n' +
                '成绩佳。\n' +
                '\n' + '【诉讼】\n' +
                '似为两个女性及金钱之事惹起，宜有和事老出面调解。\n' +
                '\n' + '【求事】\n' +
                '得利，但亦不可太大意。\n' +
                '\n' + '【改行】\n' +
                '吉利。\n' +
                '\n' + '【开业】\n' +
                '吉利。',
                '\n' + '【事业】\n' +
                '处于比较困难的地步，主要是因为人心不齐。要采取强有力的办法，求得安定团结。其中，最为重要的是坚持正道，秉持大公无私的精神，收人心以聚涣散。\n' +
                '\n' +
                '【经商】\n' +
                '市场状况混乱，方向不明。适当的冒险是完全必要的。但必须公正无私，克制私欲。争取有实力者的合作和支援也是完全有必要的。\n' +
                '\n' +
                '【求名】\n' +
                '必须静下心来努力学习和提高知识水平，增加才干。涣散的形势必须进行治理，这需要多方面的人才。前途是光明的，关键取决于个人的努力。\n' +

                '\n' + '【外出】\n' +
                '平安无事，水路更顺利。\n' +
                '\n' +
                '【婚恋】\n' +
                '只要双方都抱着纯正的目的，一定美满成功。\n' +
                '\n' +
                '【决策】\n' +
                '为了开拓事业，必须使用积极的手段，克服和战胜内部不团结的弊病。首先自己要除私心，牺牲小我，完成大我。切莫介入是非争端之中。这样，可以重新获得安定的局面。在良好的环境里，各项事业前途光明。\n' +

                '\n' + '【解卦】\n' +
                '涣：代表涣散之象，主凶象。心神不宁、精神不佳，人事向心力均已离散，有颓废不振的运势。事业、感情、婚姻根基开始有所松动。自身情绪不稳定，若能稳定情绪、定下心神，应会再恢复以往的神采风光。\n' +

                '\n' + '【解释】\n' +
                '涣散。\n' +
                '\n' + '【特性】\n' +
                '喜追求变化，缺乏耐性，工作不稳定，理财能力差，爱旅行外出，变换住所。对朋友热心，桃花运也多。\n' +
                '\n' + '【运势】\n' +
                '初有小损，但终可解困而心悦，凡事小心则百事亨通，任性放纵必败。\n' +
                '\n' + '【家运】\n' +
                '起初有损，有波折且困，但可渐入佳境。\n' +
                '\n' + '【疾病】\n' +
                '幼年体弱多病，长大后渐康，注意血液、内分泌。\n' +
                '\n' + '【胎孕】\n' +
                '临产妇无碍。\n' +
                '\n' + '【子女】\n' +
                '双亲百般劳苦为子女，而子女深知父母养育教导之恩，多得良好配偶，并有才艺之象。\n' +
                '\n' + '【周转】\n' +
                '目标准确则有利，否则波折多亦难成。\n' +
                '\n' + '【买卖】\n' +
                '虽难成，客户会再回来，但忍耐些时日可有利。\n' +
                '\n' + '【等人】\n' +
                '不一定会来。\n' +
                '\n' + '【寻人】\n' +
                '难寻。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '平安无事，水路更顺利。\n' +
                '\n' + '【考试】\n' +
                '尚可，宜再加油。\n' +
                '\n' + '【诉讼】\n' +
                '凡事宜大事化小，小事化无，诉讼可解。\n' +
                '\n' + '【求事】\n' +
                '虽有挫折，但可成。宜耐心。\n' +
                '\n' + '【改行】\n' +
                '择时视势而行，不必急于一时。\n' +
                '\n' + '【开业】\n' +
                '开业可，初时有小挫折，勿惊。',
                '\n' + '【事业】\n' +
                '正处在发展时期，一定要注意切勿冒进。但更不应放弃良好的机遇，只要坚持遵利守义的原则，可大胆行动。这样，事业可以继续兴旺发达。\n' +
                '\n' +
                '【经商】\n' +
                '市场行情好，对自己很有利，应该努力开拓，勿失去机会。不过，头脑一定要冷静，投入应有限度，适可而止，该收则收。\n' +
                '\n' +
                '【求名】\n' +
                '严格要求自己，办事知道节度，不走极端，可以通行无阻，顺利前进。\n' +

                '\n' + '【外出】\n' +
                '大胆外出顺利。\n' +
                '\n' +
                '【婚恋】\n' +
                '不要陷入情不自禁的地步，顺其自然会有好结果。\n' +
                '\n' +
                '【决策】\n' +
                '抓住机遇，勿失良机。适当节制自己的行动，不可勉强，更不可冒险，恰如其分。一切不可拘泥，该变就变。但是，该节制的时候一定要节制。节制必须有限度，过于节制就成为苦，不会有好的结果，这叫适得其反，务必十分注意。\n' +

                '\n' + '【解卦】\n' +
                '节：代表节制之象。主是个状况卦，吉凶未定之象。卦中所现，提醒卜卦之人，事事有过于放纵之象，尤其是在金钱方面，投资理财需节制有守为安。感情则要多留意自己的言行举止，已有过份之态或对对方过于放纵之势。\n' +

                '\n' + '【解释】\n' +
                '节制，节约。\n' +
                '\n' + '【特性】\n' +
                '理想高远，但与现实差距大，不过却有恒久的耐力，有志者是竟成。宽以待人，严以律己，社会声誉不错。\n' +
                '\n' + '【运势】\n' +
                '有志不能伸，诸事必须节制，不宜过份，更要戒酒色。\n' +
                '\n' + '【家运】\n' +
                '谨守做人处事的规范，则可得平安和乐之家庭。\n' +
                '\n' + '【疾病】\n' +
                '注意泌尿及消化系病变，宜速就医。\n' +
                '\n' + '【胎孕】\n' +
                '可求神保产母，否则有厄。\n' +
                '\n' + '【子女】\n' +
                '儿女多温柔孝顺。但若长辈行为不检反招破，得不偿失也。\n' +
                '\n' + '【周转】\n' +
                '远水难救近火。无望。\n' +
                '\n' + '【买卖】\n' +
                '不得时也。\n' +
                '\n' + '【等人】\n' +
                '不来，或须久等。\n' +
                '\n' + '【寻人】\n' +
                '人说皆是虚言。有所藏身，不必去寻。想回来自己会回来。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '大胆外出顺利。\n' +
                '\n' + '【考试】\n' +
                '须力求上进，不可怠忽。\n' +
                '\n' + '【诉讼】\n' +
                '局势僵持不下，宜让则讼可解。\n' +
                '\n' + '【求事】\n' +
                '再待时机，成功率小。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '开业宜缓图，计划周详后方可。',
                '\n' + '【事业】\n' +
                '以诚实忠信的态度对待事业，获得重大的成就，发展顺利。必须继续遵循正道的原则，不可掉以轻心，也不可过分地相信人，尤其不可以孤傲的性格自我封闭。否则事业将走向失败。\n' +
                '\n' +
                '【经商】\n' +
                '处理得很好，符合市场的要求。小心谨慎地经营，讲究信义，优质服务，可有满意的结果，甚至冒险犯难也不会出问题。\n' +
                '\n' +
                '【求名】\n' +
                '必须在道德修养的基础上，刻苦学习知识和本领，不断提高自己。同时，不要惧怕困难，努力向前。\n' +

                '\n' + '【外出】\n' +
                '十分顺利，根据工作的需要可以经常外出。\n' +
                '\n' +
                '【婚恋】\n' +
                '双方以诚信的态度相互对待，幸福美满。\n' +
                '\n' +
                '【决策】\n' +
                '以诚信为立身处世的基石，真诚待人，信守制度，坚持原则，和悦谦虚，可以战胜任何困难，即使出现了问题也可以亡羊补牢。但务必增强对人的观察能力，提高分辨是非的水平。\n' +

                '\n' + '【解卦】\n' +
                '中孚：代表诚信、实在。若为人处事一切以诚信为重，则事事皆可顺利而行。\n' +

                '\n' + '【解释】\n' +
                '得人缘，孚众望。\n' +
                '\n' + '【特性】\n' +
                '心地善良，富同情心，朋友多助，为人风趣，口才佳，反应快，桃花运早。重罗曼蒂克情调，讲气氛感觉。\n' +
                '\n' + '【运势】\n' +
                '如三月之春花，似锦大地，与人谋事均得利。诚者，立业之本也，若存邪曲之念则破吉运，须认清善恶。得此卦诚者佳，尤利他乡财路。\n' +
                '\n' + '【家运】\n' +
                '有突发大利，平安幸福之吉象。\n' +
                '\n' + '【疾病】\n' +
                '金木交战之象，注意胸腹脾胃等病。\n' +
                '\n' + '【胎孕】\n' +
                '安。\n' +
                '\n' + '【子女】\n' +
                '父母慈且诚信，故子女必孝，忠信门第也。\n' +
                '\n' + '【周转】\n' +
                '不成问题。\n' +
                '\n' + '【买卖】\n' +
                '可成亦有利。\n' +
                '\n' + '【等人】\n' +
                '必定来。\n' +
                '\n' + '【寻人】\n' +
                '不寻自来。\n' +
                '\n' + '【失物】\n' +
                '可失而复得，但需要一段时间。\n' +
                '\n' + '【外出】\n' +
                '十分顺利，根据工作的需要可以经常外出。\n' +
                '\n' + '【考试】\n' +
                '必定上榜。\n' +
                '\n' + '【诉讼】\n' +
                '为突发事件，宜把握第一时间处理，迟则反凶。\n' +
                '\n' + '【求事】\n' +
                '有利。\n' +
                '\n' + '【改行】\n' +
                '可按照计划行事。\n' +
                '\n' + '【开业】\n' +
                '开业者吉利，先苦后乐之象。',
                '\n' + '【事业】\n' +
                '行动宜谨慎小心，切勿冒险，尤其不得钻营。但只要坚持正当的行为，可勇敢向前，努力奋斗，开拓事业。不过，一定要把握尺度，不要好高骛远，以免招致灾祸。随机应变是关键。\n' +
                '\n' +
                '【经商】\n' +
                '对市场行情不十分掌握，可先稍试探一下，随即收敛行为。否则会导致危险。不应大规模行动，也不必胆小怕事。稳扎稳打，逐步发展。\n' +
                '\n' +
                '【求名】\n' +
                '应遵循中正的原则和循序渐进的道理，求得稳步前进。切勿追求虚名，更不得不顾实际情况攀高位。\n' +

                '\n' + '【外出】\n' +
                '慎重考虑和做好充分准备后再外出，否则不利。\n' +
                '\n' +
                '【婚恋】\n' +
                '应较长时间的彼此了解，不可仓促行事。\n' +
                '\n' +
                '【决策】\n' +
                '行动必须把握分寸，适可而止。盛时应防止办事过火，应即时收敛自己。过度将不足成事，反会招祸。一切行动都要因应变通，不可固执。\n' +

                '\n' + '【解卦】\n' +
                '小过：代表有志难伸、龙困浅滩，像犯了一个小的过错般，而被处罚。事事小有不顺，有被打压的迹象，最忌冲动犯上，必因此而埋下祸端。需沉潜、忍耐一段时间才能再有发挥的机运。\n' +

                '\n' + '【解释】\n' +
                '稍有过失。\n' +
                '\n' + '【特性】\n' +
                '谦虚，敬业，主动，积极，反应快，严于律己，不断吸收新知识，学习新事物。乐于助人，勇于认错。\n' +
                '\n' + '【运势】\n' +
                '行事不如意，宜谨守，与六亲不和之象。又忠言逆耳犯上得罪人之事，反为害己之因，故凡诸事均有枝节、苦烦，不能达志耳。\n' +
                '\n' + '【家运】\n' +
                '常生口角，有离家去外地之象。\n' +
                '\n' + '【疾病】\n' +
                '病情有恶化之象，但及时诊治则吉，防手足，肠胃之疾。\n' +
                '\n' + '【胎孕】\n' +
                '须谢灶神方可得安顺。\n' +
                '\n' + '【子女】\n' +
                '骨肉无情，兄弟不和，多劳苦之象耳。\n' +
                '\n' + '【周转】\n' +
                '小有望，大难成。\n' +
                '\n' + '【买卖】\n' +
                '小可，大不可。因过者必有错也。\n' +
                '\n' + '【等人】\n' +
                '不来，否则会迟到。\n' +
                '\n' + '【寻人】\n' +
                '只在原处逃避，当得见。如有债务之争，别找也罢，恐有过失之错。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '慎重考虑和做好充分准备后再外出，否则不利。\n' +
                '\n' + '【考试】\n' +
                '不理想。\n' +
                '\n' + '【诉讼】\n' +
                '不宜各走极端，宜多沟通和解。\n' +
                '\n' + '【求事】\n' +
                '无指望。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '开业者宜再待时机，否则不利。',
                '\n' + '【事业】\n' +
                '处在顺利和兴旺的阶段，甚至大功已经告成。如何才能保住这种势头，是问题的关键。必须认识盛极将衰的真理，必思患预防，防微杜渐，有备可无患。同时，一定要密切注意形势，抓住有利时机，继续奋发向上，推动事业发展。\n' +
                '\n' +
                '【经商】\n' +
                '由于市场形势有利，已经赚得许多。但同时也预示着危机的随时出现。因此，务必小心在意，事未到，先预防，终日提防戒惧。同时，认真观察市场的变化，随时转向，开拓新市场。\n' +
                '\n' +
                '【求名】\n' +
                '个人追求名利的基本条件已经具备。必须防止因此而变得僵化和保守，以致功亏一篑，半途而废。\n' +

                '\n' + '【外出】\n' +
                '利于出行，途中需注意安全。\n' +
                '\n' +
                '【婚恋】\n' +
                '起初顺利，只中途会出现挫折。\n' +
                '\n' +
                '【决策】\n' +
                '各方面条件都很好，环境又有利于成长。但因此反而会使自己满足，停止前进，甚至变得颓废，趋向没落。一定要不断刻苦努力，保持谦虚美德，常怀不安之心，防止不测事件发生。\n' +

                '\n' + '【解卦】\n' +
                '既济：代表水火阴阳调和，一时平安和乐之象。水火本相克，因一时环境或人事所影响，而暂时相安无事，但终十分久之象，必有所冲克而败。\n' +

                '\n' + '【解释】\n' +
                '已经得到利益，不可贪得无厌。\n' +
                '\n' + '【特性】\n' +
                '物质丰盛，但精神易空虚，外华内虚，交际广，知心少，心思细腻，多愁善感，喜宁静的生活。\n' +
                '\n' + '【运势】\n' +
                '功名双收。极盛之时，但须知物极必反，宜退守为吉，再进则凶。但得此卦者，皆有因一时顺利而忘本，大意失荆州，终有人不和、心迷乱之象。\n' +
                '\n' + '【家运】\n' +
                '生在富家之象，受祖上恩泽而不知珍惜者多，终而破运之兆。\n' +
                '\n' + '【疾病】\n' +
                '短期虽有好转，但好景不常。\n' +
                '\n' + '【胎孕】\n' +
                '不安。\n' +
                '\n' + '【子女】\n' +
                '初时幸福，长大后与双亲不和，慎之。\n' +
                '\n' + '【周转】\n' +
                '短期可成，长期无望。\n' +
                '\n' + '【买卖】\n' +
                '中途变卦不利。故初时见好就收，莫贪心拖延，否则不利。\n' +
                '\n' + '【等人】\n' +
                '已中途折回，不必再等。\n' +
                '\n' + '【寻人】\n' +
                '若是常出走之人，小时自回，长大再出走，难寻。甚或不回。\n' +
                '\n' + '【失物】\n' +
                '应可寻回。\n' +
                '\n' + '【外出】\n' +
                '利于出行，途中需注意安全。\n' +
                '\n' + '【考试】\n' +
                '实力有，但需视考期之日吉凶而定，小吉。\n' +
                '\n' + '【诉讼】\n' +
                '有理说不清，仍须把握最有利时机争取主动，迟则反见凶。\n' +
                '\n' + '【求事】\n' +
                '济急可，不宜长久。\n' +
                '\n' + '【改行】\n' +
                '不宜。\n' +
                '\n' + '【开业】\n' +
                '开业者最好慎重考虑，因此卦象好景不常。',
                '\n' + '【事业】\n' +
                '处于最后的关键时刻，成功与否就在眼前。因此务必不可掉以轻心。自我节制十分重要，坚持到底，必要时也可能慎重地进行适度冒险，以成就事业。\n' +
                '\n' +
                '【经商】\n' +
                '市场处于大变动的前夕，前景如何尚不明显。这是最为艰苦的时期，头脑必冷静，切勿冒险，不可行动过度，适度节制，观察动向，决定行动。\n' +
                '\n' +
                '【求名】\n' +
                '长期的努力和追求即会有结果。在成败的关键时刻应泰然自若，听天由命，顺其自然。\n' +

                '\n' + '【外出】\n' +
                '积极行动，十分有利。\n' +
                '\n' +
                '【婚恋】\n' +
                '已经渡过了困难阶段，再慎重地加把劲，会有美满的结果。\n' +
                '\n' +
                '【决策】\n' +
                '此卦的意思是“未完成”，表明前途无量，有充分发展的可能。因此，必须坚持中正的原则，以明智、中庸、诚信、谦逊的态度，成就事业。面对人生，唯有勤勤恳恳，极力尽人事，听天命，不必过分计较成功与否。\n' +

                '\n' + '【解卦】\n' +
                '未济：代表阴阳不调和，气血不顺。人事情绪不顺应是最大的影响主因，幸好此卦会有否极泰来之变化，一切均会雨过天晴，值的等待。\n' +

                '\n' + '【解释】\n' +
                '快要获利。\n' +
                '\n' + '【特性】\n' +
                '思想高远，又缺乏耐性，神经质，情绪不稳定，内心易有冲突感，孤独感，悲观，感性。宜往艺术哲学宗教发展。\n' +
                '\n' + '【运势】\n' +
                '气运不通，诸事不能如愿，有初衰后盛之象。凡事要有耐心去突破难关，前途将大有可望。与人共谋事者，终有大利，可为之。\n' +
                '\n' + '【家运】\n' +
                '初运不振，耐心熬过辛苦的路，可得幸福。\n' +
                '\n' + '【疾病】\n' +
                '病情不稳定，注意心肾不交、失眠、血液等病。\n' +
                '\n' + '【胎孕】\n' +
                '平安。\n' +
                '\n' + '【子女】\n' +
                '子女迟得，但终有繁荣之象，将来都能振奋家声，以慰祖德。\n' +
                '\n' + '【周转】\n' +
                '有不少困难，成败各半。\n' +
                '\n' + '【买卖】\n' +
                '初限不佳，渐有好转之象。\n' +
                '\n' + '【等人】\n' +
                '虽迟到，但有吉兆。\n' +
                '\n' + '【寻人】\n' +
                '负气出走，会自己回来。\n' +
                '\n' + '【失物】\n' +
                '不能寻回。\n' +
                '\n' + '【外出】\n' +
                '积极行动，十分有利。\n' +
                '\n' + '【考试】\n' +
                '不错，有好成绩。\n' +
                '\n' + '【诉讼】\n' +
                '宜拖延时间，日久可得贵人之助，和解了事。\n' +
                '\n' + '【求事】\n' +
                '枉费心机，无希望，再等候时机。\n' +
                '\n' + '【改行】\n' +
                '可进行。\n' +
                '\n' + '【开业】\n' +
                '勿因初时不佳而灰心，生意会转兴隆。'],
            selectedItem: null,
            selectedItem2: null,
            selectedItem3: null,
            selectedItem4: null,
            pointerAngle: 0,
            randomAngle: 0
        };
    },
    methods: {
        spinWheel() {
            this.selectedItem = null
            this.randomAngle = this.randomAngle + Math.floor(Math.random() * 360) + 480; // 生成一个0到359度的随机角度
            const wheel = this.$el.querySelector('.wheel');
            // 设置随机角度
            wheel.style.transition = 'transform 2s'; // 设置过渡时间为3秒
            wheel.style.transform = `translate(-50%, 0) rotate(${this.randomAngle}deg)`;

            setTimeout(() => {
                wheel.style.transition = 'transform 3s'; // 立即停止过渡
                // const randomIndex = Math.floor(Math.random() * this.items.length);
                let randomIndex = (60 - Math.floor((this.randomAngle % 360) / 5.625));
                if (randomIndex === -1) {
                    randomIndex = 63
                }
                if (randomIndex === -2) {
                    randomIndex = 62
                }
                if (randomIndex === -3) {
                    randomIndex = 61
                }
                this.selectedItem = this.items[randomIndex];
                this.selectedItem2 = this.gossipDetail[randomIndex];
                this.selectedItem3 = this.gossipExplain[randomIndex];
                this.selectedItem4 = this.detailExplain[randomIndex];
            }, 2000);
        },
    },
};
</script>

<style scoped>
.roulette {
    position: relative; /* 保持相对定位 */
    max-width: 100vw; /* 转盘的最大宽度 */
    margin: 50px auto; /* 居中父容器 */
    overflow-x: hidden; /* 仅隐藏水平方向的溢出内容 */
    overflow-y: auto; /* 允许垂直方向的滚动 */
}

.wheel {
    border-radius: 50%;
    background-image: url("@/assets/img/gossip2.png");
    background-position: center; /* 背景图片居中 */
    background-size: 85%; /* 背景图片覆盖整个元素 */
    background-repeat: no-repeat;
    width: 400px; /* 转盘宽度设置为父容器的100% */
    height: 400px; /* 转盘高度设置为父容器的100% */
    border: 1px dashed rgba(194, 67, 67, 0.4);
    position: relative;
    left: 50%;
    transform: translate(-50%, 0); /* 使用transform居中 */
    cursor: pointer;
}


.sector {
    --deg: calc(360deg / 64); /* 计算每个扇区的度数 */
    transform: rotate(var(--deg)) translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center; /* 旋转和缩放的基点 */
}

.sector-content {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的投影 */
    background-color: rgba(221, 186, 231, 0.1);
    border-radius: 3px;
    padding-right: 20px;
    color: #084870;
    font-size: 12px;
    font-weight: bolder;
    transition: transform 0.3s; /* 平滑过渡旋转效果 */
    transform: translate(-50%, -10%) rotate(20deg); /* 旋转文本并居中 */
    white-space: nowrap; /* 防止文本换行 */
    position: absolute;
    width: 15px; /* 扇区内容的宽度 */
    height: 18px; /* 扇区内容的高度 */
    text-align: center;
    line-height: 50px; /* 文本垂直居中 */
    margin-left: 170px; /* 内容水平偏移量 */
    top: 40px;
}

.pointer {
    z-index: 2;
    display: flex;
    position: relative;
    background-color: #ff4d4d;
    min-height: 1px;
    left: 50%;
    top: 202px;
    max-width: 165px;
}

.highlight {
    text-shadow: 2px 2px 1px rgba(211, 160, 160, 0.2);
    color: #f60000;
    text-decoration: underline;

}

.result {
    background-color: rgba(255, 255, 255, 0.8); /* 结果背景 */
    padding: 10px;
    border-radius: 5px; /* 结果圆角 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 结果投影 */
    text-align: left;
    position: relative;
    margin-top: 20px;
}
</style>