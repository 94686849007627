var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.begin)?_c('div',{staticClass:"answerBackground"},[(!_vm.begin)?_c('back'):_vm._e(),_c('div',{staticClass:"leftTopCenter"},[_vm._v("┏")]),_c('div',{staticClass:"rightTopCenter"},[_vm._v("┓")]),_c('div',{staticClass:"leftBottomCenter"},[_vm._v("┗")]),_c('div',{staticClass:"rightBottomCenter"},[_vm._v("┛")]),_vm._m(0)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flipBook"},[_c('div'),_c('img',{attrs:{"src":require("@/assets/img/filpBook.gif")}}),_c('div',[_vm._v("稍等，正在翻书")])]):_vm._e(),(!_vm.begin)?_c('div',{staticClass:"answerBookContainer"},[_c('div',{staticClass:"answerBook",on:{"click":function($event){return _vm.clickBook()}}},[_vm._m(1)])]):_vm._e(),(_vm.begin && _vm.loading === false)?_c('div',{staticClass:"answer"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticStyle:{"margin-top":"10px"}}),_c('div',[_vm._v("▼")]),_c('div',{staticStyle:{"margin-top":"-10px"}},[_vm._v("●")]),_c('div',{staticStyle:{"margin-top":"10px"}}),_c('div',{staticStyle:{"margin-top":"20%","font-size":"24px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.answer.answerZH))]),_vm._m(6),_c('div',{staticStyle:{"margin-top":"35%","font-size":"24px","font-weight":"normal","font-style":"italic"}},[_vm._v(_vm._s(_vm.answer.answerEn))]),_c('div',{staticStyle:{"margin-top":"70px"},on:{"click":function($event){return _vm.flushPage()}}},[_c('img',{attrs:{"src":require("@/assets/img/astrology.png")}}),_c('div',{staticStyle:{"font-size":"small"}},[_vm._v("再试一次")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"illustrate"},[_c('div',{staticStyle:{"font-size":"larger","font-weight":"bolder"}},[_vm._v("如何使用本书")]),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('div',[_vm._v("10-15秒集中精力思考你的问题")]),_c('div',[_vm._v("问题必须是一句完整的话")]),_c('div',[_vm._v("例如：“这项新的工作好吗？”")]),_c('div',[_vm._v("\"我会幸福吗?\"等等")]),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('div',[_vm._v("拿起手机，点击下方答案之书")]),_c('div',[_vm._v("默念或说出你想要问的问题")]),_c('div',[_vm._v("（每次只问一个）")]),_c('div',{staticStyle:{"margin-top":"20px"}}),_c('div',[_vm._v("在感觉合适的时候")]),_c('div',[_vm._v("你就会找到问题的答案")]),_c('div',{staticStyle:{"margin-top":"10px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"italic-text"},[_vm._v("THE BOOK OF ANSWERS")]),_c('div',{staticClass:"italic-text2"},[_vm._v("答案之书")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answerPageSide-left"},[_c('img',{attrs:{"src":require("@/assets/img/line.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answerPageSide-left2"},[_c('img',{attrs:{"src":require("@/assets/img/line2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answerPageSide-right"},[_c('img',{attrs:{"src":require("@/assets/img/line.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answerPageSide-right2"},[_c('img',{attrs:{"src":require("@/assets/img/line2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"120px"}},[_c('img',{attrs:{"src":require("@/assets/img/wildflower.png")}})])
}]

export { render, staticRenderFns }