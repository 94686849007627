import {accessLog} from "@/api/yunoApi";

export function goto(path) {
    this.$router.push('/' + path)
    accessLog(window.location.href).then()
}

export function goto2(router, path, params) {
    if (params) {
        router.push({path: '/' + path, query: params});
    } else {
        goto(path);
    }
}
