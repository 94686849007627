<template>
    <div id="calculator">
        <back></back>
        <div class="display">{{ display }}</div>
        <div class="buttons">
            <button v-for="button in buttons" :key="button.value" @click="handleButtonClick(button.value)"
                    class="button"
                    :class="{ 'double': button.value === '0', 'clear-btn': button.value === 'clear', 'operator-btn': isOperator(button.value), 'equal-btn': button.value === 'equal' }">
                {{ button.label }}
            </button>
        </div>
    </div>
</template>

<script>
import {goto} from "@/utils/pathUtil";
import Back from "@/components/back.vue";

export default {
    components: {Back},
    data() {
        return {
            display: '0',
            buttons: [
                {label: 'C', value: 'clear'},
                {label: '(', value: '('},
                {label: ')', value: ')'},
                {label: '÷', value: '/'},
                {label: '7', value: '7'},
                {label: '8', value: '8'},
                {label: '9', value: '9'},
                {label: '×', value: '*'},
                {label: '4', value: '4'},
                {label: '5', value: '5'},
                {label: '6', value: '6'},
                {label: '-', value: '-'},
                {label: '1', value: '1'},
                {label: '2', value: '2'},
                {label: '3', value: '3'},
                {label: '+', value: '+'},
                {label: '0', value: '0'},
                {label: '.', value: '.'},
                {label: '=', value: 'equal'}
            ]
        };
    },
    methods: {
        goto,
        handleButtonClick(value) {
            if (value === 'clear') {
                this.display = '0';
            } else if (value === 'equal') {
                this.calculate();
            } else {
                if (this.display === '0') {
                    this.display = value;
                } else {
                    this.display += value;
                }
            }
        },
        calculate() {
            try {
                this.display = eval(this.display);
            } catch (error) {
                this.display = 'Error';
            }
        },
        isOperator(value) {
            return ['+', '-', '*', '/', '^'].includes(value);
        }
    }
};
</script>

<style scoped>


#calculator {
    margin-top: 50px;
    flex: 99;
    width: 100%;
    height: 90vh; /* 让计算器填充整个视口高度 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(134, 189, 153, 0.23);
}

.display {
    font-size: 40px;
    margin-bottom: 20px;
    padding: 30px 15px; /* 调整垂直 padding */
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 10px;
    width: 93%;
    text-align: end;
    overflow: hidden;
}

.buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    width: 95%;
}

.button {
    font-size: 30px;
    padding: 35px 20px; /* 调整垂直 padding */
    border: none;
    border-radius: 10px;
    background-color: #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.double {
    grid-column: span 2;
}

.clear-btn {
    background-color: #ff6347; /* 红色 */
    color: #fff;
}

.operator-btn {
    background-color: #6a5acd; /* 紫色 */
    color: #fff;
}

.equal-btn {
    background-color: #32cd32; /* 绿色 */
    color: #fff;
}

.button:hover {
    background-color: #ccc;
    transform: scale(1.05); /* 添加放大效果 */
}
</style>
