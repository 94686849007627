<template>
    <div class="note-app">
        <header>
            <h1>传纸条</h1>
        </header>

        <main>
            <div class="tab-container">
                <div class="slider" :class="{ 'slide-right': activeTab === 'read' }"></div>
                <button @click="activeTab = 'write'" :class="{ active: activeTab === 'write' }">写纸条</button>
                <button @click="activeTab = 'read'" :class="{ active: activeTab === 'read' }">取纸条</button>
            </div>

            <transition name="fade" mode="out-in">
                <div v-if="activeTab === 'write'" key="write" class="write-note">
                    <textarea v-model="noteContent" placeholder="写点什么"></textarea>
                    <div class="code-input">
                        <input v-model="noteCode" placeholder="输入口令"/>
                        <button @click="generateRandomCode" class="generate-btn">生成一个</button>
                    </div>
                    <button @click="saveNote" :data-clipboard-text="link" class="save-btn">保存并分享</button>
                </div>

                <div v-else key="read" class="read-note">
                    <div class="code-input">
                        <input v-model="readCode" placeholder="输入口令"/>
                        <button @click="retrieveNote" class="retrieve-btn">取纸条</button>
                    </div>
                    <textarea v-model="retrievedContent" placeholder="纸条内容" readonly></textarea>
                </div>
            </transition>
        </main>
    </div>
</template>


<script>
import Back from "@/components/back.vue";
import {decrypt, encrypt} from "@/utils/AESUtil";
import ClipboardJS from "clipboard";
import {addNote, checkRepeatCode, getAnswer, getNote} from "@/api/yunoApi";
import {getTime2} from "@/utils/parse";

export default {
    name: "note",
    components: {
        Back
    },
    data() {
        return {
            activeTab: 'write',
            noteContent: '',
            noteCode: '',
            readCode: '',
            retrievedContent: '',
            link: 'https://www.yunox.cn/#/notes',
        }
    },
    methods: {
        generateRandomCode() {
            getAnswer().then(res => {
                this.noteCode = getTime2() % 2 === 0 ? res.answerZH : res.answerEn;
            });
        },
        saveNote() {
            if (!this.noteContent) {
                alert("内容为空");
                return;
            }
            if (!this.noteCode) {
                alert("请输入密码");
                return;
            }
            if (this.noteCode.length > 240) {
                alert("密码太长，不能超过240个字符");
                return;
            }

            const note = {
                content: this.noteContent,
                code: this.noteCode
            };

            checkRepeatCode(note).then(res => {
                if (res) {
                    addNote(note).then(() => {
                        alert("保存成功，链接已复制");
                        this.updateUrl();
                    });
                } else {
                    alert("密码重复，请更换");
                }
            });
        },
        retrieveNote() {
            if (this.readCode) {
                getNote(this.readCode).then(res => {
                    this.retrievedContent = res.content;
                });
            }
        },
        updateUrl() {
            // 构建基础 URL
            let baseUrl = 'https://www.yunox.cn/#/notes';
            let urlParams = new URLSearchParams(window.location.search);

            // 更新或设置参数
            if (this.noteCode) {
                urlParams.set('code', this.noteCode);
            }
            // 构建新的 URL
            let newUrl = baseUrl + '?' + urlParams.toString();
            // 如果你希望在 URL 更新时不刷新页面，可以设置 this.link 而不是直接修改 location
            this.link = newUrl;
        }
    },
    mounted() {
        new ClipboardJS('.save-btn');
    },
    created() {
        const hash = window.location.hash;
        if (hash.includes('#/notes')) {
            const searchParams = new URLSearchParams(hash.split('?')[1]);
            const code = searchParams.get('code');
            if (code) {
                this.activeTab = 'read';
                this.readCode = code;
                this.retrieveNote();
            }
        }
    },watch: {
        // 监听 code 的变化
        noteCode(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateUrl();
            }
        },
    },
}
</script>

<style scoped>

.note-app {
    max-width: 600px;
    margin: 30px auto;
    padding: 30px;
    font-family: 'Arial', sans-serif;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

header {
    text-align: center;
    margin-bottom: 30px;
}

h1 {
    color: #FF6B6B;
    font-size: 2.5em;
    margin-bottom: 20px;
}

.tab-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    background: #F0F0F0;
    border-radius: 50px;
    padding: 5px;
}

.slider {
    position: absolute;
    width: 50%;
    height: 90%;
    background: #cd4eb4;
    border-radius: 50px;
    transition: all 0.3s ease;
}

.slider.slide-right {
    transform: translateX(100%);
}

.tab-container button {
    z-index: 1;
    flex: 1;
    padding: 15px 0;
    border: none;
    background: none;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
    font-size: 16px;
}

.tab-container button.active {
    color: #ffffff;
}

textarea {
    width: calc(100% - 60px);
    height: 200px;
    padding: 15px;
    margin: 0 30px 20px;
    border: 2px solid #F0F0F0;
    border-radius: 15px;
    resize: vertical;
    transition: border-color 0.3s ease;
    font-size: 16px;
}

textarea:focus {
    border-color: #4ECDC4;
    outline: none;
}

.code-input {
    display: flex;
    margin: 0 30px 20px;
}

.code-input input {
    flex-grow: 1;
    padding: 12px 15px;
    border: 2px solid #F0F0F0;
    border-radius: 10px 0 0 10px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.code-input input:focus {
    border-color: #4ECDC4;
    outline: none;
}

.code-input button,
.save-btn,
.retrieve-btn {
    padding: 12px 20px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
}

.generate-btn {
    background-color: #f82727;
    border-radius: 0 10px 10px 0;
}

.save-btn,
.retrieve-btn {
    display: block;
    width: calc(100% - 60px);
    margin: 0 30px;
    background-color: #209b13;
    border-radius: 10px;
}

button:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.save-btn:active,
.retrieve-btn:active,
.generate-btn:active {
    animation: pulse 0.3s;
}
</style>