<template>
    <div style="background: rgba(185, 231, 195, 0.35)">
        <back></back>
        <div style="display: flex">
            <div class="title">不要不信邪<img src="../assets/img/notWhy.png"></div>
        </div>
        <div class="container" ref="container">
            <div class="why" v-for="index in displayedItems" :key="index">
                不为什么
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    name: 'ManyWhy',
    components: { Back },
    data() {
        return {
            totalItems: 100000,
            itemsPerPage: 1000,
            loadedItems: 1000
        };
    },
    computed: {
        displayedItems() {
            return Array.from({ length: this.loadedItems }, (_, index) => index);
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 100 ) { // 距离底部100像素时触发
                if (this.loadedItems < this.totalItems) {
                    this.loadedItems += 1000;
                }
            }
        }
    }
}
</script>

<style scoped>
.title {
    flex: 30;
    text-align: center;
    font-size: 250%;
    color: #dc0000;
}

.title img {
    max-width: 99%;
}

.container {
    width: 100%;
    display: flex;
    align-content: flex-start; /* 从顶部开始排列，避免底部多余的空间 */
    flex-wrap: wrap;
    height: 100%;
}

.why {
    font-size: 10px;
    font-weight: bolder;
}
</style>