export function generateRandomCode(length) {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz^~!@#$%^&*()_+-=,./;'<>?:";
    let randomString = '';
    for (let i = 0; i < length; i++) {
        let index = Math.floor(Math.random() * chars.length);
        randomString += chars.charAt(index);
    }
    return randomString;
}

export function generateRandomCode2(length) {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMOPQRSTUVWXYZ";
    let randomString = '';
    for (let i = 0; i < length; i++) {
        let index = Math.floor(Math.random() * chars.length);
        randomString += chars.charAt(index);
    }
    return randomString;
}

export function generateRandomArray(length) {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz^~!@#$%^&*()_+-=,./;'<>?:";
    let randomString = []; // 使用圆括号声明数组
    for (let i = 0; i < length; i++) {
        let index = Math.floor(Math.random() * chars.length);
        // 使用chars[index]来获取随机字符并添加到数组中
        randomString.push(chars[index]);
    }
    return randomString;
}