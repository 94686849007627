import service from "@/api/service";
import axios from "axios";

export async function getLineupInfo(id) {
    let res = await service({
        url: '/tft/getLineupInfo',
        method: 'post',
        params: {id: id}
    })
    return res.data
}

export async function getLineupList(pageNum, season) {
    let res = await service({
        url: '/tft/getLineupList',
        method: 'post',
        params: {pageNum: pageNum, season: season}
    })
    return res.data
}

export async function getLineupByHero(season, hero) {
    let res = await service({
        url: '/tft/getLineupByHero',
        method: 'post',
        params: {season: season, hero: hero}
    })
    return res.data
}

export async function getAllCharacter(season) {
    let res = await service({
        url: '/tft/getAllCharacter',
        method: 'post',
        params: {season: season}
    })
    return res.data
}

export async function getAllEquipment(season) {
    let res = await service({
        url: '/tft/getAllEquipment',
        method: 'post',
        params: {season: season}
    })
    return res.data
}

export async function addLineup(data) {
    let res = await service({
        url: '/tft/addLineup',
        method: 'post',
        data: data
    })
    return res.data
}
