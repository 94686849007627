import service from "@/api/service";
import axios from "axios";

export async function getAnswer() {
    let res = await service({
        url: '/lachesism/getAnswer',
        method: 'post',
    })
    return res.data
}

export async function getEat() {
    let res = await service({
        url: '/lachesism/getEat',
        method: 'post',
    })
    return res.data
}

export async function addFood(food) {
    let res = await service({
        url: '/lachesismManage/addFood',
        method: 'post',
        params: {foodName: food}
    })
    return res.data
}

export async function getGoldPrice() {
    let res = await service({
        url: '/lachesism/getGoldPrice',
        method: 'post',
    })
    return res.data
}

export async function getWeather(code) {
    let res = await service({
        url: '/lachesism/getWeather',
        method: 'post',
        params: {code: code}
    })
    return res.data
}

export async function findWeather(code) {
    let res = await service({
        url: '/v7/weather/3d',
        method: 'get',
        params: {location: code, key: 'fc245d31beb547e9ac4bc4276185fc7c'}
    })
    return res
}

export async function addWeather(weather) {
    let res = await service({
        url: '/lachesism/addWeather',
        method: 'post',
        data: weather
    })
    return res.data
}

export async function getAnimatedCharacterList(pageNum, pageSize) {
    let res = await service({
        url: '/lachesism/getAnimatedCharacterList',
        method: 'post',
        params: {pageNum: pageNum, pageSize: pageSize}
    })
    return res.data
}

export async function getConstellation(data) {
    let res = await service({
        url: '/lachesism/getConstellation',
        method: 'post',
        data: data

    })
    return res.data
}


export async function accessLog(path) {
    let res = await service({
        url: '/lachesismManage/accessLog',
        method: 'post',
        params: {path: path}
    })
    return res.data
}

export async function addNote(data) {
    let res = await service({
        url: '/lachesism/addNote',
        method: 'post',
        data: data

    })
    return res.data
}

export async function checkRepeatCode(data) {
    let res = await service({
        url: '/lachesism/checkRepeatCode',
        method: 'post',
        data: data

    })
    return res.data

}

export async function getNote(code) {
    let res = await service({
        url: '/lachesism/getNote',
        method: 'post',
        params: {code: code}
    })
    return res.data
}

export async function getFile(code) {
    let res = await service({
        url: '/lachesism/getFilePath',
        method: 'post',
        params: {code: code}
    })
    return res.data
}

export async function uploadFile(file, code) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('code', code);
    let res = await service({
        url: '/lachesism/uploadFile',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data
}

export async function scanUpload(file, sessionId) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('sessionId', sessionId);
    let res = await service({
        url: '/lachesism/scanUpload',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data
}

export async function uploadTempFile(file) {
    let formData = new FormData();
    formData.append('file', file);
    let res = await service({
        url: '/lachesism/uploadTempFile',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data
}

export async function getFolderInfo(data) {
    let res = await service({
        url: '/lachesismManage/getFolderInfo',
        method: 'post',
        data: data

    })
    return res.data
}

export async function deleteFile(data) {
    let res = await service({
        url: '/lachesismManage/deleteFile',
        method: 'post',
        data: data

    })
    return res.data
}

export async function getDBFileInfo(type) {
    let res = await service({
        url: '/lachesism/getDBFileInfo',
        method: 'post',
        params: {type: type}
    })
    return res.data
}

export async function getFamilyMotto(pageNum) {
    let res = await service({
        url: '/lachesism/getFamilyMotto',
        method: 'post',
        params: {pageNum: pageNum, pageSize: 30}
    })
    return res.data
}

export async function getGoldPriceToday(time) {
    let res = await service({
        url: '/lachesism/getGoldPriceToday',
        method: 'post',
        data: time
    })
    return res.data
}

export async function getGoldPriceHistory(time) {
    let res = await service({
        url: '/lachesism/getGoldPriceHistory',
        method: 'post',
        data: time
    })
    return res.data
}

export async function deleteDBFile(type, id) {
    let res = await service({
        url: '/lachesism/deleteDBFile',
        method: 'post',
        params: {type: type, id: id}
    })
    return res.data
}

export async function getLastPrice(day) {
    let res = await service({
        url: '/lachesism/getLastPrice',
        method: 'post',
        data: day
    })
    return res.data
}

export async function getToDoList(todos) {
    let res = await service({
        url: '/lachesism/getToDoList',
        method: 'post',
        data: todos
    })
    return res.data
}

export async function updateToDos(todos) {
    let res = await service({
        url: '/lachesism/updateToDos',
        method: 'post',
        data: todos
    })
    return res.data
}

export async function createToDoThing(todos) {
    let res = await service({
        url: '/lachesism/createToDoThing',
        method: 'post',
        data: todos
    })
    return res.data
}

export async function getBillList(bill) {
    let res = await service({
        url: '/lachesism/getBillList',
        method: 'post',
        data: bill
    })
    return res.data
}

export async function updateBill(bill) {
    let res = await service({
        url: '/lachesism/updateBill',
        method: 'post',
        data: bill
    })
    return res.data
}

export async function addBill(bill) {
    let res = await service({
        url: '/lachesism/addBill',
        method: 'post',
        data: bill
    })
    return res.data
}

export async function getPasteboardList(pasteboard) {
    let res = await service({
        url: '/lachesism/getPasteboardList',
        method: 'post',
        data: pasteboard
    })
    return res.data
}

export async function addPasteboard(pasteboard) {
    let res = await service({
        url: '/lachesism/addPasteboard',
        method: 'post',
        data: pasteboard
    })
    return res.data
}

export async function deletePasteboard(pasteboard) {
    let res = await service({
        url: '/lachesism/deletePasteboard',
        method: 'post',
        data: pasteboard
    })
    return res.data
}

export async function getRandomCriminalLaws(type) {
    let res = await service({
        url: '/lachesism/getRandomCriminalLaws',
        method: 'post',
        params: {type: type}
    })
    return res.data
}

export async function searchTips(word) {
    let res = await service({
        url: '/su',
        method: 'get',
        params: {wd: word, cb: 'suggestionsHandler'}
    })
    return res
}

export async function fuzzyGetCriminalLaw(keyWord) {
    let res = await service({
        url: '/lachesism/fuzzyGetCriminalLaw',
        method: 'post',
        data: keyWord
    })
    return res.data
}

export async function getWords() {
    let res = await service({
        url: '/lachesism/getWords',
        method: 'post',
    })
    return res.data
}

export async function getWordByKeyword(data) {
    let res = await service({
        url: '/lachesism/getWordByKeyword',
        method: 'post',
        data: data
    })
    return res.data
}

export async function getHousekeepingWiki(data) {
    let res = await service({
        url: '/lachesism/getHousekeepingWiki',
        method: 'post',
        data: data
    })
    return res.data
}

export async function addHousekeepingWiki(data) {
    let res = await service({
        url: '/lachesism/addHousekeepingWiki',
        method: 'post',
        data: data
    })
    return res.data
}
export async function getMemorandum(data) {
    let res = await service({
        url: '/lachesism/getMemorandum',
        method: 'post',
        data: data
    })
    return res.data
}
export async function updateMemorandum(data) {
    let res = await service({
        url: '/lachesism/updateMemorandum',
        method: 'post',
        data: data
    })
    return res.data
}
export async function addMemorandum(data) {
    let res = await service({
        url: '/lachesism/addMemorandum',
        method: 'post',
        data: data
    })
    return res.data
}
export async function addMotto(data) {
    let res = await service({
        url: '/lachesismManage/addMotto',
        method: 'post',
        data: data
    })
    return res.data
}